import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import { View, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon28Newsfeed, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

const MenuAbout = props => {
	const router = useRouter();

	function getWeekday() {
    let d = new Date();
    let weekday = d.getDay();
    let ruWeekday = (weekday > 0) ? weekday - 1 : 6;
    
    return ruWeekday;
  }

  const weekday = getWeekday()
  const weekDaysShort = ["Пн.","Вт.","Ср.","Чт.","Пт.","Сб.","Вс."]

	return (
		<Panel id={props.id}>
	    <PanelHeader left={<PanelHeaderBack onClick={() => router.navigate('menu')} />}>О компании</PanelHeader>
	    
	    {
	    	!!props.affiliate.detail_text &&
	    	<Group header={<Header>О компании</Header>}>
		      <Div>
		      	<Text dangerouslySetInnerHTML={{ __html: props.affiliate.detail_text }}></Text>
		      </Div>
		    </Group>
	    }
	    {
	    	!!props.affiliate.schedule &&
		    <Group header={<Header>Режим работы</Header>}>
		      {
		      	props.affiliate.schedule.map((item, key) => 
		      		<MiniInfoCell 
		      			before={<Text style={{ width: '24px', color: weekday == key ? 'var(--text_primary)' : 'var(--text_secondary)' }}>{ weekDaysShort[key] }</Text>}
          			textWrap="full"
          			textLevel={ weekday == key ? 'primary' : 'secondary' }
          		>
          			{ (item.time_from && item.time_to) ? item.time_from +' — '+ item.time_to : 'Выходной' }
          			{
          				weekday == key &&
          				(
          					props.affiliate.is_now_open
	          				? <span style={{ marginLeft: '10px', color: 'var(--text_secondary)'}}><span style={{ display: 'inline-block', verticalAlign: 'middle', width: '7px', height: '7px', borderRadius: '5px', marginRight: '10px', backgroundColor: '#3dcc4b' }}></span>Сейчас работаем</span>
	          				: <span style={{ marginLeft: '10px', color: 'var(--text_secondary)'}}><span style={{ display: 'inline-block', verticalAlign: 'middle', width: '7px', height: '7px', borderRadius: '5px', marginRight: '10px', backgroundColor: '#ff3347' }}></span>Не работаем</span>
	          			)
          			}
          		</MiniInfoCell>
		      	)
		      }
		    </Group>
		  }
		  {
		  	props.owner?.requisites?.company_name && !props.owner?.requisites?.hidden &&		  
		    <Group header={<Header>Реквизиты</Header>}>
		      { props.owner?.requisites?.company_name &&
		      	<SimpleCell multiline disabled>
				      <InfoRow header="Компания">
			          { props.owner?.requisites?.company_name }
			        </InfoRow>
			      </SimpleCell>}
          { props.owner?.requisites?.company_inn &&
          	<SimpleCell multiline disabled>
				      <InfoRow header="ИНН">
			          { props.owner?.requisites?.company_inn }
			        </InfoRow>
			      </SimpleCell>}
          { props.owner?.requisites?.company_kpp &&
          	<SimpleCell multiline disabled>
				      <InfoRow header="КПП">
			          { props.owner?.requisites?.company_kpp }
			        </InfoRow>
			      </SimpleCell>}
          { props.owner?.requisites?.company_ogrn &&
          	<SimpleCell multiline disabled>
				      <InfoRow header="ОГРН">
			          { props.owner?.requisites?.company_ogrn }
			        </InfoRow>
			      </SimpleCell>}
          { props.owner?.requisites?.company_address &&
          	<SimpleCell multiline disabled>
				      <InfoRow header="Юридический адрес">
			          { props.owner?.requisites?.company_address }
			        </InfoRow>
			      </SimpleCell>}
          { props.owner?.requisites?.bank_name &&
          	<SimpleCell multiline disabled>
				      <InfoRow header="Банк">
			          { props.owner?.requisites?.bank_name }
			        </InfoRow>
			      </SimpleCell>}
          { props.owner?.requisites?.bank_bik &&
          	<SimpleCell multiline disabled>
				      <InfoRow header="БИК">
			          { props.owner?.requisites?.bank_bik }
			        </InfoRow>
			      </SimpleCell>}
          { props.owner?.requisites?.bank_corr_account &&
          	<SimpleCell multiline disabled>
				      <InfoRow header="Корреспондентский счёт">
			          { props.owner?.requisites?.bank_corr_account }
			        </InfoRow>
			      </SimpleCell>}
          { props.owner?.requisites?.bank_payment_account &&
          	<SimpleCell multiline disabled>
				      <InfoRow header="Расчётный счёт">
			          { props.owner?.requisites?.bank_payment_account }
			        </InfoRow>
			      </SimpleCell>}
          { props.owner?.requisites?.company_phone &&
          	<SimpleCell multiline disabled>
				      <InfoRow header="Телефон">
			          { props.owner?.requisites?.company_phone }
			        </InfoRow>
			      </SimpleCell>}
          { props.owner?.requisites?.company_email &&
          	<SimpleCell multiline disabled>
				      <InfoRow header="E-mail">
			          { props.owner?.requisites?.company_email }
			        </InfoRow>
			      </SimpleCell>}
		    </Group>
		  }
	    <Spacing size={20} />
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    owner: state.owner.item,
  };
}

export default connect(mapStateToProps)(MenuAbout);


