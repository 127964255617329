import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import moment from 'moment'
import 'moment/locale/ru'
import { View, Spinner, Headline, Subhead, CardGrid, Card, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon28Newsfeed, Icon20FavoriteCircleFillYellow, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon28UnfavoriteOutline, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import * as ownerActions from '../store/actions/owner';

moment.locale('ru')

const MenuReviews = props => {
	const router = useRouter();

	useEffect(() => {
		async function fetchData() {
			await props.dispatch(ownerActions.getReviews(props.owner.id));
		}
		fetchData();
	}, []);

	return (
		<Panel id={props.id}>
	    <PanelHeader style={{paddingBottom: 0}} left={<PanelHeaderBack onClick={() => router.navigate('menu')} />}>Отзывы</PanelHeader>
	    {_.get(props, 'reviewsStatus.code', '') == 'success' &&
	      (!props.reviews.length)
	      ? <Placeholder stretched icon={<Icon28UnfavoriteOutline width={56} height={56} />}
            header="Ничего нет"
            action={<Button size="l" onClick={() => router.navigate('menu')}>Назад</Button>}>
            Пока никто не оставил свой отзыв<br />о нашей работе.
          </Placeholder>
	      	/*<View style={styles.EmptyPage}>
	          <ImageBackground style={styles.EmptyPage__img}
	            source={require('../assets/images/empty-bag.png')}
	            resizeMode="contain"
	            style={{width: 160, height: 200}}
	          >
	          </ImageBackground>
	          <Text style={styles.EmptyPage__title}>Ничего нет</Text>
	          <Text style={styles.EmptyPage__description}>
	            Пока никто не оставил свой отзыв{'\n'}о нашей работе.
	          </Text>
	          <View style={{alignItems: 'center', marginTop: 50}}>
	            <UIButton size="xl"
	              onPress={() => props.navigation.navigate('Menu')} 
	              style={{paddingHorizontal: 40}}>Назад</UIButton>
	          </View>
	        </View>*/
	      : <View style={{ margin: '12px 0'}}>
	          {
	            props.reviews.map(item => 
	            	<CardGrid size="l">
				          <Card mode="outline" style={{ padding: '10px 10px', marginBottom: '10px'}}>
										<Headline weight="semibold" style={{ marginBottom: '4px', display: 'flex', justifyContent: 'space-between' }}>
											{item.client?.name}
											<div style={{ display: 'flex'}}>
											{
	                      [...Array(Number(item.review?.mark?.code)).keys()].map(star => <Icon20FavoriteCircleFillYellow style={{marginLeft: '4px'}} />)
	                    }
	                    </div>
										</Headline>
										<Text style={{ color: 'var(--text_secondary)',  marginBottom: '8px'}}>{moment(item.date, "DD.MM.YYYY HH:mm:ss").format("D MMMM YYYY")}</Text>
										<Text dangerouslySetInnerHTML={{ __html: item.review?.text }}></Text>
				          </Card>
				        </CardGrid>
	            )
	          }
          	<Spacing size={20} />
          </View>
	    }
	    {_.get(props, 'reviewsStatus.code', '') == 'loading' && <Spinner style={{ margin: '12px 0'}} />}
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    reviews: state.owner.reviews,
    reviewsStatus: state.owner.reviewsStatus,
  };
}

export default connect(mapStateToProps)(MenuReviews);


