import { 
  GET_PUSH, 
  SOURCE,
  VK_USER_ID,
} from "../ActionTypes";

function commitAction(type, data) {
	return { type, data }
}

export function getPush(notification) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(commitAction(GET_PUSH, notification))
      resolve(notification)
    })
  }
}

export function setSource(source) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(commitAction(SOURCE, source))
      resolve(source)
    })
  }
}

export function setVkUserID(vk_user_id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(commitAction(VK_USER_ID, vk_user_id))
      resolve(vk_user_id)
    })
  }
}
