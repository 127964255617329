import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import bridge from '@vkontakte/vk-bridge'
import _ from 'lodash'
import { View, Div, Alert, Group, Header, Headline, FixedLayout, SimpleCell, InfoRow, Text, Button } from '@vkontakte/vkui';
// import { Icon28Newsfeed, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import {ERROR} from '../utils/errors'

import ProductCardMedium from '../components/ProductCardMedium';

import * as cartActions from '../store/actions/cart'

const ActionDetail = props => {

  if(!props.action) return null;

	const router = useRouter();

  function writeToClipboard(text) {
    bridge.send("VKWebAppCopyText", {text})
    .then(data => {
      bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
      props.onPopout(<Alert
        actions={[{
          title: 'OK',
          autoclose: true,
          mode: 'cancel'
        }]}
        actionsLayout="vertical"
        onClose={ () => props.onPopout(null)}
        header={text}
        text="Промо-код скопирован"
      />)
    })
  }

  const actionProducts = getActionProducts()

  function getActionProducts() {
    return props.products.filter(item => {
      return [].concat(props.action.products).filter(item2 => item2.id == item.id).length > 0
    }).map(item => {
      var sku = props.products.filter(item2 => {
        // return item.sku_ids.indexOf(item2.id) != -1
        // SKU у акционных товаров может и не быть
          return item.id == item2.id
      })
      item.sku = sku.map(function(item2){
        return {
          "id": item2.id,
          "price": item2.price,
          "old_price": item2.old_price,
          "weight": item2.weight,
          "volume": item2.volume,
          "size": item2.size,
          "name": item2.name,
          "preview_text": item2.preview_text,
          "property": item2.sku_for.title,
          "img": item2.img,
        }
      })
      return item
    })
  }

  function getPromocode(code) {
    // if(!props.isAuthenticated) {
    //   writeToClipboard(code)
    // } else {
      // Analytics.logEvent('promocode_get', {
      //   code: code,
      // })
      props.getPromocode(props.affiliate.id, code)
      .then(data => {
        // navigation.navigate('Cart')
      })
      .catch(err => {
        // ERROR.show(err)
        const error = ERROR.format(err)
        if(error.code == 'error' && error.message) {
          // Alert.alert(code, error.message);
          // bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
          props.onPopout(<Alert
            actions={[{
              title: 'OK',
              autoclose: true,
              mode: 'cancel'
            }]}
            actionsLayout="vertical"
            onClose={ () => props.onPopout(null)}
            header={code}
            text={error.message}
          />)
        }
      })
    // }
  }

	return (
		<div style={{paddingBottom: '50px'}}>
      <div class='ActionDetail__Img'>
        <img src={ 'https://dlvry.ru' + props.action.img.src } alt={ props.action.name } />
      </div>
      <Div>
        <Headline weight="semibold" style={{ fontSize: '24px', lineHeight: '1.2', marginBottom: '10px' }}>{ props.action.name }</Headline>
        <Text style={{ fontSize: '16px', lineHeight: '1.3', color: 'var(--text_secondary)', marginBottom: '20px'}} dangerouslySetInnerHTML={{ __html: props.action.detail_text || props.action.preview_text }}></Text>
        {
          !!props.action.promocode && 
          <div style={{paddingHorizontal: 12, paddingBottom: 20}}>
            <Button size="l" mode='outline'
              className="Action__Promocode"
              onClick={() => writeToClipboard(props.action.promocode) }
              >
              { props.action.promocode }
            </Button>
          </div>
        }
      </Div>
      {
        actionProducts.length > 0 &&
        <div style={{marginBottom: '20px'}}>
          <Header>Товары, участвующие в акции</Header>
          <div style={{padding: '0 2px'}}>
          {
            actionProducts.map((item, index) => (
              <ProductCardMedium 
                key={index}
                product={item} 
                isCanAdd
                onClick={() => props.onModal({type: 'PRODUCT_DETAIL', data: {product: item}})}
              />
            ))
          }
          </div>
        </div>
      }
      <div style={{height: '20px'}} />
      { 
        props.action.promocode.length > 0 &&
        <FixedLayout vertical="bottom" style={{ marginBottom: '-40px'}}>
          <Div className="ActionDetail__Bottom">
          {
            props.action.promocode != props.promocode.code &&
            <Button size="l" stretched onClick={() => getPromocode(props.action.promocode)}>{ (props.promocodeStatus.code == 'loading') ? 'Применяем...' : 'Применить промокод' }</Button>
          }
          {
            props.action.promocode == props.promocode.code && props.promocodeCheckStatus.code == 'success' &&
            <Button mode="outline" size="l" stretched onClick={() => getPromocode(props.action.promocode)}>Промокод активирован</Button>
          }
          {
            props.action.promocode == props.promocode.code && props.promocodeCheckStatus.code != 'success' && 
            <Button mode="outline" size="l" stretched onClick={() => getPromocode(props.action.promocode)}>Промокод не активирован</Button>
          }
          </Div>
        </FixedLayout>
      }
	  </div>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    affiliateInitStatus: state.affiliates.itemInitStatus,
    promocode: state.cart.promocode,
    promocodeStatus: state.cart.promocodeStatus,
    promocodeCheckStatus: state.cart.promocodeCheckStatus,
    products: state.catalog.products,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPromocode: (affiliate_id, code) => dispatch(cartActions.getPromocode(affiliate_id, code)),
    clearPromocode: () => dispatch(cartActions.clearPromocode()),
  }
}

ActionDetail.defaultProps = {
  onModal: () => {},
  onPopout: () => {},
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionDetail);

