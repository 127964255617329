import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/ru'
import { usePlatform, InfoRow, Separator, Text, IconButton, Counter, Div, Button, FixedLayout, CardGrid, ContentCard, Header, Cell, SimpleCell, RichCell, Avatar, ScreenSpinner, Spinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon24GearOutline, Icon24Place, Icon24Note, Icon28UserCircleOutline, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircleOutline, Icon24SmartphoneOutline } from '@vkontakte/icons';

import * as userActions from '../store/actions/user'

moment.locale('ru')

const PersonalOrder = props => {
	const router = useRouter();

	const order_id = router.getState().params?.order_id ?? 0

	useEffect(() => {
    props.getOrder(props.affiliate.id, order_id)
    .catch(err => {
      // ERROR.show(ERROR.format(err))
    })
  }, [])

	return (
		<Panel id={props.id}>
	    <PanelHeader left={<PanelHeaderBack onClick={() => router.navigate('personal/orders')} />} className="PersonalHeader">{ `Заказ #${order_id}` }</PanelHeader>
	    {_.get(props, 'orderStatus.code', '') === 'loading' && <Spinner style={{ margin: '12px 0'}} />}
	    {_.get(props, 'orderStatus.code', '') === 'success' && 
	    	<div>
	    		<Group>
            <Cell disabled>
              <InfoRow header="Создан">{moment(props.order.date, "DD.MM.YYYY HH:mm:ss").format("D MMMM в HH:mm")}</InfoRow>
            </Cell>
            <Cell disabled>
              <InfoRow header="Статус заказа">{ !!props.order.is_new ? 'Новый' : props.order.status?.name || 'Не известен' }</InfoRow>
            </Cell>
          </Group>
          {
            props.order.tracking?.length > 0 &&
            <Group header={<Header>История</Header>}>
              {
                props.order.tracking.map(track => {
                  return (
                    <Cell disabled key={track.id}
                      after={<Text style={{fontSize: '15px', fontWeight: '400', color: 'var(--text_tertiary)'}}>{moment(track.datetime, "DD.MM.YYYY HH:mm:ss").format("D MMMM в HH:mm")}</Text>}
                      >
                      <Text style={{fontSize: '16px', fontWeight: '500', color: (track.status.color) ? '#'+track.status.color : 'var(--text_primary)'}}>{ track.status.name }</Text>
                    </Cell>
                  )
                })
              }
            </Group>  
          }
          <Group header={<Header>Состав заказа</Header>}>
            {
              props.order.items.map((item, index) => 
                <Cell key={index} disabled multiline
                  after={<Text style={{fontSize: '15px', fontWeight: '400', color: 'var(--text_tertiary)'}}>{ item.data.type && 'Бесплатно' || (item.data.quantity * Number(item.data.price) + [].concat(item.data.options).filter(item => item).reduce((sum,item) => sum + Number(item.price), 0)) + ' ' + props.affiliate.currency.sign }</Text>}
                  >
                  <Text>{item.data.quantity + ' × ' + props.order.catalog_items[item.id]['name']}</Text>
                </Cell>
              )
            }
          </Group>
          <Group>
            <Cell disabled>
              <InfoRow header="Сумма">{props.order.sum + ' ' + props.affiliate.currency.sign }</InfoRow>
            </Cell>
            {
              props.order.discount > 0 &&
              <Cell disabled>
                <InfoRow header="Скидка">{props.order.discount + ' ' + props.affiliate.currency.sign}</InfoRow>
              </Cell>
            }
            <Cell disabled>
              <InfoRow v="Доставка">
                {
                  // order.delivery.code == 'self'
                  // ? 'Самовывоз'
                  // : 
                  (props.order.delivery_price > 0 ? props.order.delivery_price + ' ' + props.affiliate.currency.sign : 'Бесплатно' + (props.order.delivery.code == 'self' ? ' (самовывоз)' :'' )  )
                }
              </InfoRow>
            </Cell>
            <Cell disabled>
              <InfoRow header="ИТОГО к оплате">{props.order.total + ' ' + props.affiliate.currency.sign}</InfoRow>
            </Cell>
          </Group>
          <Group>
            {
              !!props.order.payment.name &&
              <Cell disabled>
                <InfoRow header="Оплата">{ props.order.payment.name }</InfoRow>
              </Cell>
            }
            {
              !!props.order.pay_status.name &&
              <Cell disabled>
                <InfoRow header="Статус оплаты">{ props.order.pay_status.name }</InfoRow>
              </Cell>
            }
            {
              !!props.order.source.name &&
              <Cell disabled>
                <InfoRow header="Источник">{ props.order.source.name }</InfoRow>
              </Cell>
            }
            {
              !!props.order.delivery.name &&
              <Cell disabled>
                <InfoRow header="Способ получения">{ props.order.delivery.name }</InfoRow>
              </Cell>
            }
            {
              !!props.order.delivery_type?.name &&
              <Cell disabled>
                <InfoRow header="Тип доставки">{ props.order.delivery_type.name }</InfoRow>
              </Cell>
            }
            {
              !!props.order.address.street &&
              <Cell disabled>
                <InfoRow header="Адрес доставки">{ props.order.address.street + ' ' + props.order.address.house + ' ' + props.order.address.apt }</InfoRow>
              </Cell>
            }
            {
              !!props.order.pickup_point?.name &&
              <Cell disabled>
                <InfoRow header="Точка самовывоза">{ props.order.pickup_point.name }</InfoRow>
              </Cell>
            }
            {
              !!(props.order.delivery_date || props.order.delivery_time) &&
              <Cell disabled>
                <InfoRow header={ props.order.delivery.code == 'self' ? 'Время самовывоза' : 'Время доставки'}>{ props.order.delivery_date + ' ' + props.order.delivery_time }</InfoRow>
              </Cell>
            }
            {
              !!props.order.persons &&
              <Cell disabled>
                <InfoRow header="Количество персон">{ props.order.persons }</InfoRow>
              </Cell>
            }
            {
              !!props.order.promocode.code &&
              <Cell disabled>
                <InfoRow header="Промокод">
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Text style={{fontSize: '16px', fontWeight: '600', textTransform: 'uppercase'}}>{ props.order.promocode.code }</Text>
                    <Text style={{fontSize: '15px', marginLeft: '5px', color: (_.get(props.order, 'promocode.data.is_checked', 0) == 1) ? 'var(--dynamic_green)' : 'var(--dynamic_red)'}}>{_.get(props.order, 'promocode.data.is_checked', 0) == 1 ? 'Активирован' : 'Не активирован'}</Text>
                  </div>
                </InfoRow>
              </Cell>
            }
          </Group>
          {
            !!props.order.comment &&
            <Group>
              <Cell disabled>
                <InfoRow header="Примечание к заказу">
                	<Text dangerouslySetInnerHTML={{ __html: props.order.comment }} style={{fontSize: '16px', lineHeight: '21px', color: 'var(--text_primary)'}}></Text>
                </InfoRow>
              </Cell>
            </Group>
          }
	    	</div>
	  	}
	  </Panel>
	)
};

const mapStateToProps = state => {
  return { 
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    client: state.user.client,
    order: state.user.order,
    orderStatus: state.user.orderStatus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getOrder: (affiliate_id, order_id) => dispatch(userActions.getOrder(affiliate_id, order_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalOrder);

