import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash';
import bridge from '@vkontakte/vk-bridge';
import InputMask from 'react-input-mask';
import { FormLayout, FormItem, Input, View, Spinner, Radio, HorizontalScroll, HorizontalCell, usePlatform, Separator, SliderSwitch, Alert, PanelHeaderButton, Headline, Subhead, CardGrid, Card, ContentCard, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon16Add, Icon16Minus, Icon16ChevronOutline, Icon20Check, Icon24DoNotDisturb, Icon24ClockOutline, Icon28Newsfeed, Icon24Place, Icon24Home, Icon20Dropdown, Icon28BlockOutline, Icon20FavoriteCircleFillYellow, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon28UnfavoriteOutline, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import * as userActions from '../store/actions/user';

const mapStateToProps = (state, props) => {
  return { 
    owner: state.owner.item,
    affiliate: state.affiliates.item,
    client: state.user.client,
    setPhoneStatus: state.user.setPhoneStatus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setPhone: (affiliate_id, phone) => dispatch(userActions.setPhone({affiliate_id, phone})),
  }
}

const SetPhone = props => {

  const platform = usePlatform()

  const [phone, changePhone] = useState('')
  const [loading, setLoading] = useState(false)

  function onFocus(e) {
    bridge.send("VKWebAppGetPhoneNumber", {})
    .then(data => {
      if(!!data.phone_number) {
        changePhone(data.phone_number)
        e.target.blur()
      }
    })
  }

  function onSubmit(e) {
    if(e) e.preventDefault();
    setLoading(true)
    const affiliate_id = props.affiliate.id
    props.setPhone(affiliate_id, phone)
    .then(data => {
      // props.onSuccess(data)
      setLoading(false)
    })
    .catch(err => {
      // props.onError(err)
      setLoading(false)
    })
  }

  return (
    <div className="SetPhone__Page">
  	<div className="SetPhone">
      {
        props.setPhoneStatus.code !== 'success'
        ? <Div>
            <div className="SetPhone__Header">
              <h2 className={`vkuiPlaceholder__header  vkuiTitle vkuiTitle--${platform} vkuiTitle--w-medium vkuiTitle--l-2`}>
                Ваш телефон
              </h2>
              <h3 className={`vkuiPlaceholder__text  vkuiHeadline vkuiHeadline--${platform} vkuiHeadline--w-regular`}>
                Для оформления заказа<br />
                нужен ваш телефон
              </h3>
            </div>
            <FormLayout onSubmit={onSubmit}>
              <FormItem 
                status={props.setPhoneStatus.code === 'error' && 'error'}
                bottom={props.setPhoneStatus.code === 'error' && props.setPhoneStatus.message}>
                <div role="presentation" className="vkuiInput vkuiInput--ios vkuiInput--sizeY-regular  vkuiFormField vkuiFormField--ios vkuiFormField--sizeY-regular">
                  <InputMask className="vkuiInput__el" style={{textAlign: 'center', fontSize: '18px', fontWeight: '500', fontFamily: 'monospace'}} mask={_.get(props, 'affiliate.phone_code.mask', '').replace(/#/g, "9")} maskChar=" " type="text" placeholder={_.get(props, 'affiliate.phone_code.mask', '').replace(/#/g, "_")} value={phone} onChange={e => changePhone(e.target.value)} onClick={(e) => onFocus(e)} />
                  <div role="presentation" className="vkuiFormField__border"></div>
                </div>
              </FormItem>
              <FormItem>
                <Button type="submit" stretched size="l"
                  before={loading && <Spinner size="small" style={{color: '#fff'}} />}
                  >Далее</Button>
              </FormItem>
            </FormLayout>
            <div className="SetPhone__Policy">Нажимая на кнопку, вы соглашаетесь с <a href={_.get(props, 'affiliate.privacy_page_url', 'https://dlvry.ru/empty/index.html')} className="Link" target='_blank'>политикой конфиденциальности</a>.</div>
          </Div>
        : <div>Ваш телефон успешно принят</div>
      }
    </div>
		</div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPhone);