import {ERROR} from '../../utils/errors'

import { 
  GET_PRODUCTS_REQUEST, 
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,

  GET_SECTIONS_REQUEST, 
  GET_SECTIONS_SUCCESS,
  GET_SECTIONS_ERROR,
} from "../ActionTypes";

const initialState = {
  products: [],
  productsStatus: {},
  sections: [],
  rootSections: [],
  sectionsStatus: {},
};

export default (state = initialState, action) => {
  let status = {}
  switch (action.type) {
    
    case GET_PRODUCTS_REQUEST:
      status = { code: 'loading' }
      return {
      	...state,
        productsStatus: status,
      }

    case GET_PRODUCTS_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        productsStatus: status,
        products: action.data,
      }

    case GET_PRODUCTS_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        productsStatus: status,
        products: [],
      }

    case GET_SECTIONS_REQUEST:
      status = { code: 'loading' }
      return {
        ...state,
        sectionsStatus: status,
      }

    case GET_SECTIONS_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        sectionsStatus: status,
        sections: action.data,
        rootSections: action.data.filter(section => section.depth_level == 2),
      }

    case GET_SECTIONS_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        sectionsStatus: status,
        sections: [],
        rootSections: [],
      }
    
    default:
      return state
  }
};