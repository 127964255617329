import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import bridge from '@vkontakte/vk-bridge';
import { View, Spinner, Alert, Footer, Headline, FixedLayout, Subhead, CardGrid, Card, ContentCard, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon28GiftOutline } from '@vkontakte/icons';

import ProductCard from '../components/ProductCard';
import CartClass from '../utils/CartClass';

import declOfNum from '../utils/utils';

// import * as cartActions from '../store/actions/cart';

const GiftsRule = props => {
	const router = useRouter();

	let Cart = new CartClass({
    orderForm: props.orderForm,
    products: props.products,
    cartProducts: props.cartProducts,
    cartGifts: props.cartGifts,
    cartMores: props.cartMores,
    promocode: props.promocode,
    promocodeCheckStatus: props.promocodeCheckStatus,
    owner: props.owner,
    affiliate: props.affiliate,
    client: props.client,
    deliveryArea: props.deliveryArea,
    basketRulesActions: props.basketRulesActions,
  })

	function getGiftsItems() {
    return props.products.filter(item => {
      return item.is_gift == 1
    }).map(item => {
      var sku = props.products.filter(item2 => {
        // return item.sku_ids.indexOf(item2.id) != -1
        // SKU у подарков не должно быть
        return item.id == item2.id
      })
      item.sku = sku.map(function(item2){
        return {
          "id": item2.id,
          "price": item2.price,
          "old_price": item2.old_price,
          "weight": item2.weight,
          "volume": item2.volume,
          "size": item2.size,
          "name": item2.name,
          "preview_text": item2.preview_text,
          "property": item2.sku_for.title,
          "img": item2.img,
        }
      })
      return item
    })
  }

  const [randomId, setRandomId] = useState(0)

  const giftSetsItems = Cart.getGiftSetsItems()

  const random_id = router.getState().params?.random_id ?? 0

  useEffect(() => {
    setRandomId(random_id)
  }, [])

  const giftSet = giftSetsItems.find(set => set.random_id == randomId)

  if(!giftSet) return null

  const selectedCount = giftSet.selected.reduce((sum, item) => sum + Number(item.data.quantity), 0)

	return (
		<Panel id={props.id}>
	    <PanelHeader left={<PanelHeaderBack onClick={() => router.navigate('cart')} />}>Подарки</PanelHeader>
	    {
        giftSet.items.length > 0        
        ? <div>
	        	<Header>Выберите {giftSet.max_count + ' ' + declOfNum(giftSet.max_count, ['подарок', 'подарка', 'подарков']) }</Header>
	        	<div className="BackgroundPage" style={{paddingBottom: '60px'}}>
		        	<Div style={{paddingTop: 0}}>
  		        	{
  		        		giftSet.items.map(item => (
  					      	<ProductCard key={`gift_${item.id}`} product={item} isGift 
                      randomId={giftSet.random_id}
                      basketRuleId={giftSet.basket_rule_id}
                      isCanAdd={giftSet.max_count > selectedCount}  />
  						    ))
  		        	}
                <Footer>
                  { giftSet.items.length + ' ' + declOfNum(giftSet.items.length, ['подарок', 'подарка', 'подарков']) }
                </Footer>
		        	</Div>
		        </div>
		        <FixedLayout vertical="bottom">
          		<Div className="ActionDetail__Bottom">
				        <Button size="l" stretched
				          onClick={() => router.navigate('cart')} >Назад в корзину</Button>
				      </Div>
				    </FixedLayout>
	        </div>
        : <Placeholder stretched 
        		icon={<Icon28GiftOutline width={56} height={56} />}
        		action={<Button size="l"
          		onClick={() => router.navigate('cart')} >Назад в корзину</Button>}
            header="Нет подарков :(">
          </Placeholder>
      }
	  </Panel>
	)
};

function mapStateToProps(state) {
  return { 
    products: state.catalog.products,
    cartProducts: state.cart.products,
    cartGifts: state.cart.gifts,
    cartMores: state.cart.mores,
    promocode: state.cart.promocode,
    promocodeStatus: state.cart.promocodeStatus,
    promocodeCheckStatus: state.cart.promocodeCheckStatus,
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    orderForm: state.cart.orderForm,
    client: state.user.client,
    isAuthenticated: state.user.isAuthenticated,
    deliveryArea: state.cart.deliveryArea,
    basketRulesActions: state.cart.basketRulesActions,
  }
}

export default connect(mapStateToProps)(GiftsRule);


