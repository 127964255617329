import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import bridge from '@vkontakte/vk-bridge';
import { View, Spinner, Alert, Headline, Subhead, CardGrid, Card, ContentCard, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
// import { Icon28Newsfeed, Icon28BlockOutline, Icon20FavoriteCircleFillYellow, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon28UnfavoriteOutline, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import './../empty.css';

const Empty = props => {

	const last_vk_group_id = props.source.last_vk_group_id

	return (
		<Panel id={props.id}>
	    <Placeholder stretched 
	    	icon={<div style={{display: 'block', width: '88px', height: '88px', background: 'url("https://dlvry.ru/img/dlvry-logo-sign--blue.png") no-repeat center center', backgroundSize: 'cover'}} />}
	    	action={
	    		<div style={{paddingTop: '20px'}}>
	    			{
	    				!!last_vk_group_id &&
	    				<div>
	    					<Button size="l" stretched href={`https://vk.com/app${props.source.vk_app_id}_-${last_vk_group_id}`}>Открыть в сообществе</Button>
	    					<div style={{margin: '15px 0', fontSize: '18px', color: 'var(--text_secondary)'}}>или</div>
	    				</div>
	    			}
	    			<Button size="l" stretched mode="outline" onClick={() => bridge.send("VKWebAppAddToCommunity")}>Добавить в своё сообщество</Button>
	    		</div>
	    	}
        header="DLVRY.APPS">
        Приложение DLVRY работает только внутри сообщества
      </Placeholder>
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    source: state.app.source,
  };
}

export default connect(mapStateToProps)(Empty);