import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import { usePlatform, PanelSpinner, Footer, Cell, Spinner, Separator, IconButton, Counter, Div, Button, FixedLayout, CardGrid, ContentCard, Header, SimpleCell, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon24GearOutline, Icon24Place, Icon28PlaceOutline, Icon24Add, Icon24Note, Icon28UserCircleOutline, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircleOutline, Icon24SmartphoneOutline } from '@vkontakte/icons';

import declOfNum from '../utils/utils';
import * as userActions from '../store/actions/user';

const PersonalAddresses = props => {
	const router = useRouter();

	useEffect(() => {
    props.getAddresses()
    .then(data => {
      
    })
    .catch(err => {})
  }, []);

	return (
		<Panel id={props.id}>
	    <PanelHeader left={<PanelHeaderBack onClick={() => router.navigate('personal')} />} className="PersonalHeader">Мои адреса</PanelHeader>
	    {_.get(props, 'addressesStatus.code', '') === 'loading' && <PanelSpinner />}
      {_.get(props, 'addressesStatus.code', '') === 'success' &&
        (!props.addresses.length)
        ? <Placeholder style={{paddingBottom: 0}} stretched icon={<Icon28PlaceOutline width={56} height={56} />}
            header="Адресов нет"
            action={<Button size="l" onClick={() => {
	                  router.navigate('personal/address', {address_id: 0, source_page: 'personal/addresses'})
	                }}>Добавить адрес</Button>}>
            Здесь будут храниться адреса доставки,<br />которые вы сохранили.
          </Placeholder>
        : <div style={{paddingBottom: '80px'}}>
            <Group>
            {
              props.addresses
              .map(item => {
                return (
                  <Cell 
                    key={item.id}
                    expandable
                    onClick={() => {
                    	router.navigate('personal/address', {address_id: item.id, source_page: 'personal/addresses'}, {reload: false, replace: false})
                    }}
                    description={item.street + ' ' + item.house}
                    >{item.name}</Cell>)
              })
            }
            </Group>
            {/*<Footer>
      				{ props.addresses.length + declOfNum(props.addresses.length, [' адрес', ' адреса', ' адресов']) }
      			</Footer>*/}
            <FixedLayout vertical="bottom">
          		<Div className="ActionDetail__Bottom">
          			<Button size="l" stretched onClick={() => {
	                  router.navigate('personal/address', {address_id: 0, source_page: 'personal/addresses'})
	                }}>Добавить адрес</Button>
          		</Div>
          	</FixedLayout>
          </div>
      
      }
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    owner: state.owner.item,
    affiliate: state.affiliates.item,
    affiliateInitStatus: state.affiliates.itemInitStatus,
    user: state.user.user,
    client: state.user.client,
    clientStatus: state.user.clientStatus,
    authStatus: state.user.authStatus,
    addresses: state.user.addresses,
    addressesStatus: state.user.addressesStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAddresses: () => dispatch(userActions.getAddresses()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalAddresses);

