import { createStore, applyMiddleware, combineReducers } from 'redux'
import { router5Middleware, router5Reducer } from 'redux-router5'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import affiliates from './reducers/affiliates';
import owner from './reducers/owner';
// import reviews from './reducers/reviews';
import actions from './reducers/actions';
import user from './reducers/user';
import catalog from './reducers/catalog';
import cart from './reducers/cart';
import app from './reducers/app';

export default function configureStore(router, initialState = {}) {
  const createStoreWithMiddleware = applyMiddleware(
    thunk,
    router5Middleware(router),
    createLogger()
  )(createStore)

  const store = createStoreWithMiddleware(
    combineReducers({
      router: router5Reducer,
      affiliates,
      owner,
      // reviews,
      actions,
      user,
      catalog,
      cart,
      app
    }),
    initialState
  )

  window.store = store
  return store
}