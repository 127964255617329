import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import { View, Div, Group, Header, SimpleCell, InfoRow } from '@vkontakte/vkui';
// import { Icon28Newsfeed, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

const DeliveryArea = props => {
	const router = useRouter();

	return (
		<div>
	    <Header>{ props.area.name }</Header>
    	{
    		!!props.area.description &&
    		<Div>
	      	{ props.area.description }
	      </Div>
    	}
      <Group>
      {
        _.get(props.area, 'order_conditions.config.min_sum.value', 0) > 0 &&
        <SimpleCell multiline disabled>
          <InfoRow header="Минимальный заказ">
          	{ `${_.get(props.area, 'order_conditions.config.min_sum.value', 0)} ${props.affiliate.currency.sign}` }
          </InfoRow>
        </SimpleCell>
      }
      {
        _.get(props.area, 'order_conditions.config.free_delivery_from.value', 0) > 0 &&
        <SimpleCell multiline disabled>
          <InfoRow header="Бесплатная доставка">
            от { `${_.get(props.area, 'order_conditions.config.free_delivery_from.value', 0)} ${props.affiliate.currency.sign}` }
          </InfoRow>
        </SimpleCell>
      }
      {
        _.get(props.area, 'order_conditions.config.delivery_price.value', 0) > 0 &&
        <SimpleCell multiline disabled>
          <InfoRow header="Стоимость доставки">
            { `${_.get(props.area, 'order_conditions.config.delivery_price.value', 0)} ${props.affiliate.currency.sign}` }
          </InfoRow>
        </SimpleCell>
      }
      {
        _.get(props.area, 'order_conditions.config.delivery_time.value', 0) > 0 &&
        <SimpleCell multiline disabled>
          <InfoRow header="Время доставки">
            до { _.get(props.area, 'order_conditions.config.delivery_time.value', 0) } мин.
          </InfoRow>
        </SimpleCell>
      }
      </Group>
	  </div>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    affiliateInitStatus: state.affiliates.itemInitStatus,
  };
}

export default connect(mapStateToProps)(DeliveryArea);

