import bridge from '@vkontakte/vk-bridge'
import { HTTP } from '../../utils/http-common';
import { ERROR } from '../../utils/errors'

import { 
  GET_PROMOCODE_REQUEST,
  GET_PROMOCODE_SUCCESS,
  GET_PROMOCODE_ERROR,
  CHECK_PROMOCODE,
  CLEAR_PROMOCODE,

  ADD_PRODUCT, 
  DEL_PRODUCT,
  CLEAR_PRODUCT,
  CLEAR_PRODUCTS,

  ADD_GIFT, 
  DEL_GIFT,
  CLEAR_GIFTS,

  ADD_RULE_GIFT,
  DEL_RULE_GIFT,

  SET_MORE,
  CLEAR_MORES,

  SAVE_ORDER_FORM,
  CLEAR_ORDER_FORM,

  SAVE_ORDER_REQUEST,
  SAVE_ORDER_SUCCESS,
  SAVE_ORDER_ERROR,

  TRANSACTION_ERROR,
  TRANSACTION_SUCCESS,
  TRANSACTION_REQUEST,
  TRANSACTION_CLEAR,

  GET_BASKET_RULES_REQUEST,
  GET_BASKET_RULES_SUCCESS,
  GET_BASKET_RULES_ERROR,
  CHECK_BASKET_RULES,

  GET_DELIVERY_AREAS_REQUEST,
  GET_DELIVERY_AREAS_SUCCESS,
  GET_DELIVERY_AREAS_ERROR,
  
  GET_DELIVERY_AREA_REQUEST,
  GET_DELIVERY_AREA_SUCCESS,
  GET_DELIVERY_AREA_NOT_FOUND,
  GET_DELIVERY_AREA_ERROR,

  CLEAR_DELIVERY_AREA,
} from "../ActionTypes";

function commitAction(type, data) {
	return { type, data }
}

export function getPromocode(affiliate_id, code) {
  return function (dispatch, getState) {
    const { user, cart, app } = getState()
    return new Promise((resolve, reject) => {
      if(!affiliate_id) {
        let err = ERROR.make(400, "Invalid parameter: affiliate_id")
        dispatch(commitAction(GET_PROMOCODE_ERROR, err))
        reject(err)
        return;
      }
      if(!code) {
        let err = ERROR.make(400, "Введите код!")
        // ERROR.show(err)
        dispatch(commitAction(GET_PROMOCODE_ERROR, err))
        reject(err)
        return;
      }
      dispatch(commitAction(GET_PROMOCODE_REQUEST))
      const client_id = user.client?.id
      HTTP.post("promocode", {affiliate_id, code, client_id})
      .then(resp => {
        dispatch(commitAction(GET_PROMOCODE_SUCCESS, resp.data.data))

        // Добавил 2021-06-27
        if(resp.data.data.add_items && resp.data.data.add_items.length > 0) {
          // console.log('_promocode_add_items_')
          resp.data.data.add_items.map(item => {
            const inCartItem = cart.products.find(cartItem => cartItem.id == item.id)
            let inCartItemCount = 0
            if(inCartItem && inCartItem.data && inCartItem.data.quantity) {
              inCartItemCount = inCartItem.data.quantity
            }
            const addItemsCount = item.count - inCartItemCount
            if(item.id && addItemsCount > 0) {
              for (var i = 0; i < addItemsCount; i++) {
                // commit("ADD", {id: item.id, data: {quantity: 1, price: item.price}, meta: {name: item.name}})
                dispatch(commitAction(ADD_PRODUCT, {id: item.id, data: {quantity: 1, price: item.price}, meta: {product: {name: item.name}}}))
              }
            }
          })
        }

        // if(!!resp.data.data.is_stop_actions) {
          dispatch(commitAction(CLEAR_GIFTS))
        // }
        dispatch(commitAction(CHECK_PROMOCODE, {
          client: user.client,
          source: app.source,
        }))
        dispatch(commitAction(CHECK_BASKET_RULES, {
          client: user.client,
          source: app.source,
        }))
        resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(GET_PROMOCODE_ERROR, err))
        // ERROR.show(err)
        reject(err)
      })
    })
  }
}

export function clearPromocode() {
  return function (dispatch, getState) {
    const { user, app } = getState()
    dispatch(commitAction(CLEAR_PROMOCODE))
    dispatch(commitAction(CLEAR_GIFTS))
    dispatch(commitAction(CHECK_BASKET_RULES, {
      client: user.client,
      source: app.source,
    }))
  }
}

export function addProduct(id, data = {}, meta = {}) {
  return function (dispatch, getState) {
    const { user, app } = getState()
    dispatch(commitAction(ADD_PRODUCT, {id, data, meta}))
    dispatch(commitAction(CLEAR_GIFTS))
    dispatch(commitAction(CHECK_PROMOCODE, {
      client: user.client,
      source: app.source,
    }))
    dispatch(commitAction(CHECK_BASKET_RULES, {
      client: user.client,
      source: app.source,
    }))
  }
}

export function delProduct(id, data = {}) {
  return function (dispatch, getState) {
    const { user, app } = getState()
    dispatch(commitAction(DEL_PRODUCT, {id, data}))
    dispatch(commitAction(CLEAR_GIFTS))
    dispatch(commitAction(CHECK_PROMOCODE, {
      client: user.client,
      source: app.source,
    }))
    dispatch(commitAction(CLEAR_MORES, {for_id: id}))
    dispatch(commitAction(CHECK_BASKET_RULES, {
      client: user.client,
      source: app.source,
    }))
  }
}

export function clearProduct(id, data = {}) {
  return function (dispatch, getState) {
    const { user, app } = getState()
    dispatch(commitAction(CLEAR_PRODUCT, {id, data}))
    dispatch(commitAction(CLEAR_GIFTS))
    dispatch(commitAction(CHECK_PROMOCODE, {
      client: user.client,
      source: app.source,
    }))
    dispatch(commitAction(CLEAR_MORES, {for_id: id}))
    dispatch(commitAction(CHECK_BASKET_RULES, {
      client: user.client,
      source: app.source,
    }))
  }
}

export function addGift(id, data = {}) {
  return function (dispatch) {
    dispatch(commitAction(ADD_GIFT, {id, data}))
  }
}

export function delGift(id, data = {}) {
  return function (dispatch) {
    dispatch(commitAction(DEL_GIFT, {id, data}))
  }
}

export function clearGifts() {
  return function (dispatch) {
    dispatch(commitAction(CLEAR_GIFTS))
  }
}

export function addRuleGift(id, random_id, data = {}) {
  return function (dispatch) {
    dispatch(commitAction(ADD_RULE_GIFT, {id, random_id, data}))
  }
}

export function delRuleGift(id, random_id, data = {}) {
  return function (dispatch) {
    dispatch(commitAction(DEL_RULE_GIFT, {id, random_id, data}))
  }
}

export function clearCart() {
  return function (dispatch, getState) {
    const { user, app } = getState()
    dispatch(commitAction(CLEAR_PRODUCTS))
    dispatch(commitAction(CLEAR_GIFTS))
    dispatch(commitAction(CLEAR_PROMOCODE))
    dispatch(commitAction(CLEAR_MORES))
    dispatch(commitAction(CLEAR_DELIVERY_AREA))
    dispatch(commitAction(CLEAR_ORDER_FORM))
    dispatch(commitAction(CHECK_BASKET_RULES, {
      client: user.client,
      source: app.source,
    }))
    bridge.send("VKWebAppTapticNotificationOccurred", {"type": "error"});
  }
}

export function setMore(for_id, index, id) {
  return function (dispatch) {
    dispatch(commitAction(SET_MORE, {for_id, index, id}))
  }
}

export function saveOrderForm(data = {}) {
  return function (dispatch, getState) {
    const { user, cart, affiliates, app } = getState()
    
    // Если изменился адрес, обновляем зону доставки
    if(cart.orderForm?.address_id !== data.address_id && affiliates.item.order_form.config.delivery_area?.active) {
      const address = {
        city: data.address_city,
        street: data.address_street,
        house: data.address_house
      }

      dispatch(getDeliveryArea(affiliates.item.id, address))
      .then(deliveryArea => {
        if(deliveryArea.id > 0) {
          data.delivery_area = deliveryArea.id
        } else {
          data.delivery_area = null
        }
        dispatch(commitAction(SAVE_ORDER_FORM, data))
        dispatch(commitAction(CHECK_BASKET_RULES, {
          client: user.client,
          source: app.source,
        }))
      })
    } else {
      dispatch(commitAction(SAVE_ORDER_FORM, data))
      dispatch(commitAction(CHECK_BASKET_RULES, {
        client: user.client,
        source: app.source,
      }))
    }

    // dispatch(commitAction(SAVE_ORDER_FORM, data))
    // dispatch(commitAction(CHECK_BASKET_RULES, {
    //   client: user.client,
      // source: app.source,
    // }))
  }
}

export function clearOrderForm() {
  return function (dispatch, getState) {
    const { user, app } = getState()
    dispatch(commitAction(CLEAR_ORDER_FORM))
    dispatch(commitAction(CHECK_BASKET_RULES, {
      client: user.client,
      source: app.source,
    }))
  }
}

export function saveOrder(affiliate_id, form) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      if(!affiliate_id) {
        let err = ERROR.make(400, "Invalid parameter: affiliate_id")
        dispatch(commitAction(SAVE_ORDER_ERROR, err))
        reject(err)
        return;
      }
      dispatch(commitAction(SAVE_ORDER_REQUEST))

      HTTP.post("affiliates/"+affiliate_id+"/orders", {form})
      .then(resp => {
        dispatch(commitAction(SAVE_ORDER_SUCCESS, resp.data))
        resolve(resp.data)
      })
      .catch(err => {
        //console.log(err.response)
        dispatch(commitAction(SAVE_ORDER_ERROR, err))
        reject(err)
      })
    })
  }
}

export function initTransaction(affiliate_id, order_id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      if(!affiliate_id) {
        let err = ERROR.make(400, {text: "Invalid parameters", options: {params: "affiliate_id"}})
        dispatch(commitAction(TRANSACTION_ERROR, err))
        reject(err)
        return
      }
      if(!order_id) {
        let err = ERROR.make(400, {text: "Invalid parameters", options: {params: "order_id"}})
        dispatch(commitAction(TRANSACTION_ERROR, err))
        reject(err)
        return
      }
      dispatch(commitAction(TRANSACTION_REQUEST))
      HTTP.post("transaction", {affiliate_id, order_id})
      .then(resp => {
        dispatch(commitAction(TRANSACTION_SUCCESS, resp.data.data))
        resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(TRANSACTION_ERROR, err))
        reject(err)
      })
    })
  }
}

export function clearTransaction() {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(commitAction(TRANSACTION_CLEAR))
      resolve({})
    })
  }
}

export function getDeliveryAreas(affiliate_id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      if(!affiliate_id) {
        let err = ERROR.make(400, "Invalid parameter: affiliate_id")
        dispatch(commitAction(GET_DELIVERY_AREAS_ERROR, err))
        reject(err)
        return;
      }
      dispatch(commitAction(GET_DELIVERY_AREAS_REQUEST))

      HTTP.get("affiliates/"+affiliate_id+"/delivery-areas")
      .then(resp => {
        dispatch(commitAction(GET_DELIVERY_AREAS_SUCCESS, resp.data.data.items))
        resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(GET_DELIVERY_AREAS_ERROR, err))
        // ERROR.show(err)
        reject(err)
      })
    })
  }
}

export function getDeliveryArea(affiliate_id, address) {
  return function (dispatch, getState) {
    const { user, app } = getState()
    return new Promise((resolve, reject) => {
      if(!affiliate_id) {
        let err = ERROR.make(400, "Invalid parameter: affiliate_id")
        dispatch(commitAction(GET_DELIVERY_AREA_ERROR, err))
        reject(err)
        return;
      }
      dispatch(commitAction(GET_DELIVERY_AREA_REQUEST))
      HTTP.get("affiliates/"+affiliate_id+"/delivery-area", {params: {city: address.city, street: address.street, house: address.house}})
      .then(resp => {
        if(!!resp.data.data) {
          dispatch(commitAction(GET_DELIVERY_AREA_SUCCESS, resp.data.data))
        } else {
          dispatch(commitAction(GET_DELIVERY_AREA_NOT_FOUND))
        }
        dispatch(commitAction(CHECK_BASKET_RULES, {
          client: user.client,
          source: app.source,
        }))
        resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(GET_DELIVERY_AREA_ERROR, err))
        // ERROR.show(err)
        reject(err)
      })
    })
  }
}

export function getBasketRules(affiliate_id) {
  return function (dispatch, getState) {
    const { user, app } = getState()
    return new Promise((resolve, reject) => {
      if(!affiliate_id) {
        let err = ERROR.make(400, "Invalid parameter: affiliate_id")
        dispatch(commitAction(GET_BASKET_RULES_ERROR, err))
        reject(err)
        return;
      }
      dispatch(commitAction(GET_BASKET_RULES_REQUEST))

      HTTP.get("affiliates/"+affiliate_id+"/basket-rules")
      .then(resp => {
        dispatch(commitAction(GET_BASKET_RULES_SUCCESS, resp.data.data.items))
        dispatch(commitAction(CHECK_BASKET_RULES, {
          client: user.client,
          source: app.source,
        }))
        resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(GET_BASKET_RULES_ERROR, err))
        // ERROR.show(err)
        reject(err)
      })
    })
  }
}