import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import bridge from '@vkontakte/vk-bridge';
import { usePlatform, View, Separator, Spinner, Alert, IconButton, Counter, Cell, Div, Button, FixedLayout, FormLayout, FormItem, Input, SelectMimicry, Select, CardGrid, ContentCard, Header, SimpleCell, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon24GearOutline, Icon24Place, Icon24Note, Icon28UserCircleOutline, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircleOutline, Icon24SmartphoneOutline } from '@vkontakte/icons';

import * as userActions from '../store/actions/user';
import * as cartActions from '../store/actions/cart';

const PersonalAddress = props => {
	const router = useRouter();

	const address_id = router.getState().params?.address_id ?? 0
	const pageSource = router.getState().params?.source_page ?? 'personal/addresses'

	const [addressForm, setAddressForm] = useState({})
  const [submitErrors, setSubmitErrors] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  useEffect(() => {
    props.getAddress(address_id)
    .catch(err => {})
  }, [address_id])

  useEffect(() => {
    setAddressForm({
      ...addressForm, 
      id: props.address.id,
      name: props.address.name,
      city: props.address.city,
      street: props.address.street,
      house: props.address.house,
      block: props.address.block,
      entrance: props.address.entrance,
      floor: props.address.floor,
      apt: props.address.apt,
    })
  }, [props.address])

  function onChangeField(field, value) {
    setSubmitErrors({...submitErrors, [field]: null})
    setAddressForm({...addressForm, [field]: value})
  }

  function submit() {
    // Очистим ошибки
    setSubmitErrors({})
    if(submitLoading) return;
    setSubmitLoading(true)
    props.saveAddress(props.affiliate.id,  address_id, addressForm) 
    .then((data) => {
      setSubmitSuccess(true)
      setSubmitLoading(false)
      // props.getAddresses()
      let address = {}
      Object.keys(data.data).map(field => {
        address['address_' + field] = data.data[field]
      })
      props.saveOrderForm({...props.orderForm, ...address})
      router.navigate(pageSource)
    })
    .catch((err) => {
      console.log('error:', err)
      if(_.get(err, 'response.data.errors', '').length > 0) {
        let errors = {}
        _.get(err, 'response.data.errors', []).map((item) => {
          errors[item.field] = item.error_msg
        })
        setSubmitErrors(errors)
        // _scrollView.current.scrollTo({ x: 0, y: 0, animated: true })
        window.scrollTo(0, 0)
      }
      setSubmitLoading(false)
    })
  }

  function deleteAddress() {
    // Очистим ошибки
    setSubmitErrors({})
    if(deleteLoading) return;
    setDeleteLoading(true)
    props.deleteAddress(address_id) 
    .then((data) => {
      setSubmitSuccess(true)
      setDeleteLoading(false)
      // props.getAddresses()
      router.navigate(pageSource)
    })
    .catch((err) => {
      console.log('error:', err)
      if(_.get(err, 'response.data.errors', '').length > 0) {
        let errors = {}
        _.get(err, 'response.data.errors', []).map((item) => {
          errors[item.field] = item.error_msg
        })
        setSubmitErrors(errors)
        // _scrollView.current.scrollTo({ x: 0, y: 0, animated: true })
        window.scrollTo(0, 0)
      }
      setDeleteLoading(false)
    })
  }

	return (
		<Panel id={props.id}>
	    <PanelHeader left={<PanelHeaderBack onClick={() => router.navigate(pageSource)} />} className="PersonalHeader">{ address_id ? 'Адрес доставки' : 'Новый адрес' }</PanelHeader>
	    {_.get(props, 'addressStatus.code', '') === 'loading' && <Spinner style={{ margin: '12px 0'}} />}
      {_.get(props, 'addressStatus.code', '') !== 'loading' &&
      	<div>
          <div style={{paddingBottom: '80px'}}>
            {/*{Object.keys(submitErrors).filter(field => submitErrors[field]).length > 0 &&
            <Group header="Ошибки">
              {Object.keys(submitErrors).filter(field => submitErrors[field]).map(field =>
              <Cell multiline disabled key={field}
                // before={<UIIcon name="report" color={colors.destructive} width={24} />}
                >
                { submitErrors[field] }
              </Cell>)}
            </Group>}*/}
            <Group>
              <FormLayout>
              	<FormItem top="Название" bottom={ submitErrors.name ?? 'Например: Дом или Работа'} status={(submitErrors.name) ? 'error' : null}>
                	<Input type="text" defaultValue={addressForm.name} onChange={ (e) => onChangeField('name', e.target.value) } placeholder='' />
              	</FormItem>
              </FormLayout>
            </Group>
            <Group>
              <FormLayout>
              	<FormItem top="Город" bottom={ submitErrors.city } status={(submitErrors.city) ? 'error' : (addressForm.city) ? 'valid' : null}>
                { (props.affiliate.addresses_source && props.affiliate.addresses_source.code && props.affiliate.addresses_source.code != 'none')
                  ? <SelectMimicry defaultValue={addressForm.city} onPress={ () => router.navigate('City') } placeholder="Выберите город" ></SelectMimicry>
                  : (props.affiliate.cities && props.affiliate.cities.length > 0)
                    ? <Select value={addressForm.city} options={props.affiliate.cities.map(item => {
                          return {label: item.name, value: item.name}
                        })} onChange={ (e) => onChangeField('city', e.target.value) } placeholder='Выберите город' />  
                    : <Input type="text" defaultValue={addressForm.city} onChange={ (e) => onChangeField('city', e.target.value) } placeholder='' />
                }
                </FormItem>
                <FormItem top="Улица" bottom={ submitErrors.street } status={(submitErrors.street) ? 'error' : (addressForm.street) ? 'valid' : null}>
                { (props.affiliate.addresses_source && props.affiliate.addresses_source.code && props.affiliate.addresses_source.code != 'none')
                  ? <SelectMimicry defaultValue={addressForm.street} onPress={ () => router.navigate('Street', {city: addressForm.city}) } placeholder="Выберите улицу"></SelectMimicry>
                  : <Input type="text" defaultValue={addressForm.street} onChange={ (e) => onChangeField('street', e.target.value) } placeholder='' />
                }
                </FormItem>
                <FormItem top="Дом" bottom={ submitErrors.house } status={(submitErrors.house) ? 'error' : null}>
                	<Input type="number" defaultValue={addressForm.house} onChange={ (e) => onChangeField('house', e.target.value) } placeholder='' />
                </FormItem>
                <FormItem top="Блок" bottom={ submitErrors.block } status={(submitErrors.block) ? 'error' : null}>
                	<Input type="text" defaultValue={addressForm.block} onChange={ (e) => onChangeField('block', e.target.value) } placeholder='' />
                </FormItem>
                <FormItem top="Подъезд" bottom={ submitErrors.entrance } status={(submitErrors.entrance) ? 'error' : null}>
                	<Input type="number" defaultValue={addressForm.entrance} onChange={ (e) => onChangeField('entrance', e.target.value) } placeholder='' />
                </FormItem>
                <FormItem top="Этаж" bottom={ submitErrors.floor } status={(submitErrors.floor) ? 'error' : null}>
                	<Input type="number" defaultValue={addressForm.floor} onChange={ (e) => onChangeField('floor', e.target.value) } placeholder='' />
                </FormItem>
                <FormItem top="Квартира / Офис" bottom={ submitErrors.apt } status={(submitErrors.apt) ? 'error' : null}>
                	<Input type="number" defaultValue={addressForm.apt} onChange={ (e) => onChangeField('apt', e.target.value) } placeholder='' />
                </FormItem>
              </FormLayout>
            </Group>
          </div>
          <FixedLayout vertical="bottom">
          	<Div className="ActionDetail__Bottom" style={{display: 'flex'}}>
	            { address_id > 0 &&
	              <Button style={{flex: '0.5', marginRight: '5px'}} mode='destructive' stretched disabled={deleteLoading} 
	              	before={deleteLoading && <Spinner size="small" style={{color: '#fff'}} />} size="l" 
	                onClick={() => 
	                	props.onPopout(<Alert
							      actions={[{
							        title: 'Отмена',
							        autoclose: true,
							        mode: 'cancel'
							      },
							      {
							        title: 'Удалить',
							        autoclose: true,
							        mode: 'destructive',
							        action: () => deleteAddress(),
							      }]}
							      actionsLayout="horizontal"
							      onClose={ () => props.onPopout(null) }
							      header="Удалить адрес?"
							      text="Вы уверены, что хотите удалить адрес?"
							    />)
	                }>{(deleteLoading)?'Удаляем':'Удалить'}</Button>
	            }
	            <Button style={{flex: address_id > 0 ? '0.5' : null, marginLeft: address_id > 0 ? '5px' : null}} stretched disabled={submitLoading} 
	            	before={submitLoading && <Spinner size="small" style={{color: '#fff'}} />} size="l" 
	            	onClick={() => submit()}>{(submitLoading)?'Сохраняем':'Сохранить'}</Button>
          	</Div>
          </FixedLayout>
      	</div>
      }
	  </Panel>
	)
};

const mapStateToProps = state => {
  return { 
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    address: state.user.address,
    addressStatus: state.user.addressStatus,
    orderForm: state.cart.orderForm,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // getAddresses: () => dispatch(userActions.getAddresses()),
    getAddress: (address_id) => dispatch(userActions.getAddress(address_id)),
    saveAddress: (affiliate_id, address_id, form) => dispatch(userActions.saveAddress(affiliate_id, address_id, form)),
    deleteAddress: (address_id) => dispatch(userActions.deleteAddress(address_id)),
    saveOrderForm: (data) => dispatch(cartActions.saveOrderForm(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalAddress);

