import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import bridge from '@vkontakte/vk-bridge';
import { usePlatform, View, Text, HorizontalScroll, Select, Separator, IconButton, Counter, Switch, Div, Button, FixedLayout, CardGrid, ContentCard, Header, Cell, SimpleCell, RichCell, InfoRow, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon16Add, Icon24DiscountOutline, Icon28ChecksOutline, Icon16Minus, Icon24Gift, Icon24DoNotDisturb, Icon24Error, Icon24Home, Icon16Gift, Icon24SmileOutline, Icon24Note, Icon28UserCircleOutline, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircleOutline, Icon24SmartphoneOutline } from '@vkontakte/icons';

import CartClass from '../utils/CartClass';
import * as cartActions from '../store/actions/cart'

import RecommendCard from '../components/RecommendCard';

import SetPhone from '../components/SetPhone'
import CheckPhone from '../components/SetPhone' // TODO
import MyCartClearHeaderButton from '../components/MyCartClearHeaderButton'

const Cart = props => {
	const router = useRouter();

	const isPhoneChecked = props.checkPhoneStatus.code === 'success' || !!props.user.account?.phone || !props.affiliate.need_phone_check
	const isPhoneSeted = props.setPhoneStatus.code === 'success' || props.client.phone || !!props.affiliate.need_phone_check

	let Cart = new CartClass({
    orderForm: props.orderForm,
    products: props.products,
    cartProducts: props.cartProducts,
    cartGifts: props.cartGifts,
    cartMores: props.cartMores,
    promocode: props.promocode,
    promocodeCheckStatus: props.promocodeCheckStatus,
    owner: props.owner,
    affiliate: props.affiliate,
    client: props.client,
    deliveryArea: props.deliveryArea,
    basketRulesActions: props.basketRulesActions,
  })

	const cartTopMessages = [].concat(props.basketRulesActions?.messages.filter(item => item.place === 'cart_top'))
  const cartBottomMessages = [].concat(props.basketRulesActions?.messages.filter(item => item.place === 'cart_bottom'))

	const orderConditions = Cart.orderConditions
  const orderSum = Cart.orderSum + Cart.orderRequiredSum
  // const orderRequiredSum = Cart.orderRequiredSum
  const orderDiscount = Cart.orderDiscount
  const orderDiscountDescription = Cart.orderDiscountDescription
  const orderMarkup = Cart.orderMarkup
  const orderMarkupDescription = Cart.orderMarkupDescription
  const orderSumDiscount = Cart.orderSumDiscount
  const deliveryPrice = Cart.deliveryPrice
  const orderTotal = Cart.orderTotal
  const cartItems = Cart.getCartItems()
  const productsRequiredItems = Cart.getProductsRequiredItems()
  const addonItems = Cart.getAddonItems()
  const freeItems = Cart.getFreeItems()
  const giftsItems = Cart.getGiftsItems()
  const promocodeGifts = Cart.getPromocodeGifts()
  const giftsRuleItems = Cart.getGiftsRuleItems()
  const giftSetsItems = Cart.getGiftSetsItems()

  const giftsSumMaxCount = Cart.calcGiftsSumMaxCount()
  const giftsSumCount = Cart.calcGiftsSumCount()
  
  const allGiftsMaxCount = Cart.calcAllGiftsMaxCount()
  const allGiftsCount = Cart.calcAllGiftsCount()

  const recommendsItems = Cart.getRecommendsItems()

	const allCartCount = props.cartProducts.length + promocodeGifts.length + productsRequiredItems.length

  const weekDay = ((new Date()).getDay() > 0) ? (new Date()).getDay()-1 : 6

  function add(id, data) {
    const product = props.products.find(item => item.id === id)
    const section_id = product.parent_section_ids[0]
    const section = props.sections.find(section => section.id == section_id) || {}
    const meta = {
      product: {
        id: id,
        name: product.name,
      },
      category: {
        id: section.id,
        name: section.name,
      },
      currency: props.affiliate.currency
    }
    props.addProduct(id, data, meta)
  }

  function getAllOptions(cartItem) {
    let allOptions = []
    cartItem.options_groups && cartItem.options_groups.map(group => {
      allOptions = allOptions.concat(group.options)
    })
    return allOptions
  }

  function setMore(for_id, index, id) {
    console.log('setMore', for_id, index, id)
    props.setMore(for_id, index, id)
  }

  // TODO в Utils
  function declOfNum(number, titles) {  
    var cases = [2, 0, 1, 1, 1, 2]
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ]
  }

  useEffect(() => {
    props.saveOrderForm({...props.orderForm, sum: orderSum})
  }, [orderSum])

  useEffect(() => {
    let initOrderForm = _.cloneDeep(props.orderForm) || {}
    if(!initOrderForm.delivery || !!props.affiliate.pickup_only) {
      initOrderForm.delivery = (!!props.affiliate.pickup_only) ? 'self' : 'delivery'
    }
    if(!initOrderForm.delivery_type) {
      initOrderForm.delivery_type = _.get(props, 'affiliate.delivery_types[0].name')
    }
    if(!initOrderForm.pickup_point) {
      initOrderForm.pickup_point = _.get(props, 'affiliate.pickup_points[0].name')
    }
    if(!initOrderForm.address_district) {
      initOrderForm.address_district = _.get(props, 'affiliate.districts[0].name')
    }
    if(!initOrderForm.payment) {
      initOrderForm.payment = _.get(props, 'affiliate.order_form.config.payment.values[0].value')
    }
    if(!initOrderForm.persons) {
      initOrderForm.persons = 1
    }
    Object.keys(_.get(props, 'affiliate.order_form.config', {})).map(field => {
      if(!initOrderForm[field]) {
        initOrderForm[field] = '';
      }
    });
    // if(!initOrderForm.name) {
    //   initOrderForm.name = _.get(props, 'client.name')
    // }
    // if(!initOrderForm.phone) {
    //   initOrderForm.phone = _.get(props, 'client.phone')
    // }
    props.saveOrderForm(initOrderForm)
  }, [])

	return (
		<Panel id={props.id}>
	    <PanelHeader className="PersonalHeader"
        left={(isPhoneSeted && isPhoneChecked && !!allCartCount) && <MyCartClearHeaderButton onPopout={popout => props.onPopout(popout)} />}
        >{ (isPhoneChecked && isPhoneSeted || true) ? 'Корзина' : 'Ваш телефон' }</PanelHeader>
	    {
        (props.authStatus.code === 'loading' || props.clientStatus.code === 'loading') &&
        <Placeholder style={{paddingBottom: 0}} stretched icon={<Icon28UserCircleOutline width={56} height={56} />}
          header="Авторизация...">
          Пожалуйста подождите.
        </Placeholder>
      }
      {
	    	(!(props.authStatus.code === 'loading' || props.clientStatus.code === 'loading') && !isPhoneSeted) &&
	    	<SetPhone />
	    }
	    {
	    	(!(props.authStatus.code === 'loading' || props.clientStatus.code === 'loading') && !isPhoneChecked) &&
	    	<CheckPhone />
	    }
	    {
	    	(!(props.authStatus.code === 'loading' || props.clientStatus.code === 'loading') && (isPhoneSeted && isPhoneChecked && !allCartCount)) &&
	    	<Placeholder stretched icon={<Icon28MarketOutline width={56} height={56} />}
          style={{paddingBottom: '0'}}
	        header="Ой, пусто!"
	        action={
            <>
            <Button size="l" onClick={() => router.navigate('home')}>Перейти в меню</Button>
            <Div>или</Div>
            <Button size="l" mode="tertiary" onClick={() => props.onModal({type: 'PROMOCODE'})}>ввести промокод</Button>
            <Separator />
            <Group style={{textAlign: 'left'}}>
              {(_.get(props, 'affiliate.order_form.config.delivery.active', 0) == 1 && !props.affiliate.pickup_only) &&
              <Cell 
                disabled
                before={<Icon24Home style={{ color: (props.orderForm.delivery === 'self') ? 'var(--color-1)' : 'var(--icon_secondary)' }} />}
                description={ (orderConditions.self_delivery_discount?.value > 0) ? `Скидка –${orderConditions.self_delivery_discount?.value}%` : null }
                after={<Switch 
                  checked={props.orderForm.delivery === 'self'}
                  onChange={() => {
                    props.saveOrderForm({...props.orderForm, delivery: (props.orderForm.delivery !== 'self') ? 'self' : 'delivery' })
                  }} />}
                >Самовывоз</Cell>}
              {_.get(props, 'affiliate.order_form.config.is_bday.active', 0) == 1 &&
              <Cell 
                disabled
                before={<Icon24SmileOutline style={{ color: (props.orderForm.is_bday === 1) ? 'var(--color-1)' : 'var(--icon_secondary)' }} />}
                after={<Switch 
                  checked={props.orderForm.is_bday === 1} 
                  onChange={() => {
                    props.saveOrderForm({...props.orderForm, is_bday: (props.orderForm.is_bday !== 1) ? 1 : 0})
                  }} />}
                >У меня день рождения!</Cell>}
            </Group>
            </>
          }>
	        Ваша корзина пуста, откройте «Меню» и&nbsp;выберите понравившийся товар.
	      </Placeholder>
	    }
	    {
	    	(!(props.authStatus.code === 'loading' || props.clientStatus.code === 'loading') && (isPhoneSeted && isPhoneChecked && !!allCartCount)) &&
	    	<div style={{paddingBottom: '80px'}}>
          {
            props.affiliate.is_now_open != 1 &&
            <div className="Warning">
              {
                  (props.affiliate.schedule[weekDay].time_from && props.affiliate.schedule[weekDay].time_to) 
                ? <div>
                    <Text className="Warning__Text">Сегодня работаем с { props.affiliate.schedule[weekDay].time_from } до { props.affiliate.schedule[weekDay].time_to }</Text>
                    {
                      !!props.affiliate.allow_ordering_at_any_time && <Text className="Warning__Text">Приготовим ваш заказ сразу после открытия.</Text>
                    }
                  </div>
                : <Text className="Warning__Text">Сегодня у нас выходной</Text>
              }
            </div>
          }
          {
            cartTopMessages.length > 0 &&
            cartTopMessages.map((message, index) => {
              return (<div className={`Message Message--type_${message.type}`} key={index}>
                {message.title?.length > 0 && <Text className="Message__Title">{ message.title }</Text>}
                {message.description?.length > 0 && <Text className="Message__Description">{ message.description }</Text>}
              </div>)
            })
          }
          {
            ( 
              (orderConditions.min_sum.value > orderSumDiscount && props.orderForm.delivery !== 'self') || 
              (orderConditions.free_delivery_from.value > orderSumDiscount && props.orderForm.delivery !== 'self')
            ) &&
            <Group>
              {orderConditions.min_sum.value > orderSumDiscount && props.orderForm.delivery !== 'self' &&
              <Cell 
                disabled
                before={<Icon24DoNotDisturb style={{color: 'var(--dynamic_red)'}} />}
                description={ 'Не хватает '+(orderConditions.min_sum.value - orderSumDiscount).toFixed(2)+' '+props.affiliate.currency.sign }>
                <Text style={{color: 'var(--dynamic_red)'}}>{ `Минимальный заказ: ${orderConditions.min_sum.value} ${props.affiliate.currency.sign}`}</Text>
              </Cell>}
              {orderConditions.free_delivery_from.value > orderSumDiscount && props.orderForm.delivery !== 'self' &&
              <Cell 
                disabled
                before={<Icon24Error style={{ color: 'var(--icon_tertiary)'}} />}
                description={ 'Не хватает '+(orderConditions.free_delivery_from.value - orderSumDiscount).toFixed(2)+' '+props.affiliate.currency.sign }>
                <Text>{ `Бесплатная доставка: от ${orderConditions.free_delivery_from.value} ${props.affiliate.currency.sign}`}</Text>
              </Cell>}
            </Group>
          }
          {
            (
              (_.get(props, 'affiliate.order_form.config.delivery.active', 0) == 1 && !props.affiliate.pickup_only) ||
              _.get(props, 'affiliate.order_form.config.is_bday.active', 0) == 1
            ) &&
            <Group>
              {(_.get(props, 'affiliate.order_form.config.delivery.active', 0) == 1 && !props.affiliate.pickup_only) &&
              <Cell 
                disabled
                before={<Icon24Home style={{ color: (props.orderForm.delivery === 'self') ? 'var(--color-1)' : 'var(--icon_secondary)' }} />}
                description={ (orderConditions.self_delivery_discount?.value > 0) ? `Скидка –${orderConditions.self_delivery_discount?.value}%` : null }
                after={<Switch 
                  checked={props.orderForm.delivery === 'self'}
                  onChange={() => {
                    props.saveOrderForm({...props.orderForm, delivery: (props.orderForm.delivery !== 'self') ? 'self' : 'delivery' })
                  }} />}
                >Самовывоз</Cell>}
              {_.get(props, 'affiliate.order_form.config.is_bday.active', 0) == 1 &&
              <Cell 
                disabled
                before={<Icon24SmileOutline style={{ color: (props.orderForm.is_bday === 1) ? 'var(--color-1)' : 'var(--icon_secondary)' }} />}
                after={<Switch 
                  checked={props.orderForm.is_bday === 1} 
                  onChange={() => {
                    props.saveOrderForm({...props.orderForm, is_bday: (props.orderForm.is_bday !== 1) ? 1 : 0})
                  }} />}
                >У меня день рождения!</Cell>}
            </Group>
          }
          <Group header={<Header>Состав заказа</Header>}>
            {
              cartItems.map(item => {
              return (
                <div className="CartItem" key={'item' + item.id + '_' + item.price + '_' + [].concat(item.options).filter(item => !!item).map(item => item.id).toString()}>
                  <div className="CartItem__Name">{ item.name }</div>
                  { 
                    (item.property?.length > 0 || item.options?.length > 0) &&
                    <div className="CartItem__Properties">
                      <Text className="CartItem__Property">
                      {item.property.length > 0 ? item.property : ''}
                      {item.property.length > 0 && item.options?.length > 0 ? ' · ' : ''}
                      {
                        item.options?.length > 0 
                        ? getAllOptions(props.products.find(cItem => cItem.id == item.id)).filter(cOption => item.options.map(option => option.id).indexOf(cOption.id) != -1).map(item => item.name).join(' · ')
                        : ''
                      }
                      </Text>
                    </div>
                  }
                  { 
                    !!item.promo &&
                    <div className="CartItem__Action">
                      <Icon16Gift className="CartItem__ActionIcon" />
                      <Text className="CartItem__ActionText">{ item.promo }</Text>
                    </div>
                  }
                  {
                    (item.more && item.more.length > 0) &&
                    <div style={{margin: '10px 0 15px'}}>
                      {[...Array(item.quantity)].map((x, index) => 
                        <div style={{ marginBottom: '10px'}} key={index}>
                          <Select 
                            defaultValue={_.get(props, "cartMores["+item.id+"]["+index+"]['id']", null)}
                            options={item.more.map(option => ({label: option.name, value: option.id}))} 
                            onChange={e => setMore(item.id, index, e.target.value)} placeholder='Выберите вариант' />
                        </div>
                      )}
                    </div>
                  }
                  <div className="CartItem__Buy">
                    {
                      !item.is_promo
                      ? <div className="CartItem__Sum">{ (item.quantity * (Number(item.price) + [].concat(item.options).filter(item => !!item).reduce((sum, item) => { return Number(sum) + Number(item.price) }, 0) )).toFixed(2) } { props.affiliate.currency.sign }</div>
                      : <div className="CartItem__Sum">Бесплатно</div>
                    }
                    <div className="CartItem__PlusMinus">
                      <Button size='m'
                        onClick={() => {
                          props.delProduct(item.id, {options: item.options})
                          bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                        }}><Icon16Minus style={{ color: '#fff', marginTop: '2px' }} width={18} height={18} /></Button>
                      <span>{ item.quantity }</span>
                      <Button size='m' 
                        onClick={() => {
                          add(item.id, {options: item.options})
                          bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
                        }}><Icon16Add style={{ color: '#fff', marginTop: '2px' }} width={18} height={18} /></Button>
                    </div>
                  </div>
                </div>
              )})
            }
            {
              productsRequiredItems.map(item => {
              return (
                <div className="CartItem" key={'required' + item.id}>
                  <div className="CartItem__Name">{ item.name }</div>
                  { 
                    (item.property?.length > 0 || item.options?.length > 0) &&
                    <div className="CartItem__Properties">
                      <Text className="CartItem__Property">
                      {item.property.length > 0 ? item.property : ''}
                      {item.property.length > 0 && item.options?.length > 0 ? ' · ' : ''}
                      {
                        item.options?.length > 0 
                        ? getAllOptions(props.products.find(cItem => cItem.id == item.id)).filter(cOption => item.options.map(option => option.id).indexOf(cOption.id) != -1).map(item => item.name).join(' · ')
                        : ''
                      }
                      </Text>
                    </div>
                  }
                  { 
                    !!item.promo &&
                    <div className="CartItem__Action">
                      <Icon16Add className="CartItem__ActionIcon" />
                      <Text className="CartItem__ActionText">{ item.promo }</Text>
                    </div>
                  }
                  <div className="CartItem__Buy">
                    {
                      <div className="CartItem__Sum">{ (item.quantity * (Number(item.price)) ).toFixed(2) } { props.affiliate.currency.sign }</div>
                    }
                    <div className="CartItem__PlusMinus">
                      <Button size='m' disabled><Icon16Minus style={{ color: '#fff', marginTop: '2px' }} width={18} height={18} /></Button>
                      <span>{ item.quantity }</span>
                      <Button size='m' disabled><Icon16Add style={{ color: '#fff', marginTop: '2px' }} width={18} height={18} /></Button>
                    </div>
                  </div>
                </div>
                )
              })
            }
            {
              addonItems.map(item => {
              return (
                <div className="CartItem" key={'addon' + item.id}>
                  <div className="CartItem__Name">{ item.name }</div>
                  <div className="CartItem__Action">
                    <Icon16Gift className="CartItem__ActionIcon" />
                    <Text className="CartItem__ActionText">Прилагается к каждому заказу</Text>
                  </div>
                  {/* <View style={styles.CartItemBuy}>
                    <Text style={styles.CartItemSum}>Бесплатно</Text>
                  </View> */}
                </div>
                )
              })
            }
            {
              freeItems.map(item => {
              return (
                <div className="CartItem" key={'gift' + item.id}>
                  <div className="CartItem__Name">
                    {item.quantity > 0 &&
                      <span style={{color: 'var(--text_secondary)'}}>{item.quantity} × </span>
                    }
                    { item.name }
                  </div>
                  <div className="CartItem__Action">
                    <Icon16Gift className="CartItem__ActionIcon" />
                    <Text className="CartItem__ActionText">{ item.action_title }</Text>
                  </div>
                  {/* <View style={styles.CartItemBuy}>
                    <Text style={styles.CartItemSum}>Подарок × {item.quantity}</Text>
                  </View> */}
                </div>
                )
              })
            }
            {
              giftsItems.map(item => {
              return (
                <div className="CartItem" key={'gift' + item.id}>
                  <div className="CartItem__Name">
                    {item.quantity > 0 &&
                      <span style={{color: 'var(--text_secondary)'}}>{item.quantity} × </span>
                    }
                    { item.name }
                  </div>
                  <div className="CartItem__Action">
                    <Icon16Gift className="CartItem__ActionIcon" />
                    <Text className="CartItem__ActionText">Подарок на выбор</Text>
                  </div>
                  {/* <View style={styles.CartItemBuy}>
                    <Text style={styles.CartItemSum}>Подарок × {item.quantity}</Text>
                  </View> */}
                </div>
                )
              })
            }
            {
              giftsRuleItems.map(item => {
              return (
                <div className="CartItem" key={'gift' + item.id}>
                  <div className="CartItem__Name">
                    {item.quantity > 0 &&
                      <span style={{color: 'var(--text_secondary)'}}>{item.quantity} × </span>
                    }
                    { item.name }
                  </div>
                  <div className="CartItem__Action">
                    <Icon16Gift className="CartItem__ActionIcon" />
                    <Text className="CartItem__ActionText">{ item.action_title }</Text>
                  </div>
                  {/* <View style={styles.CartItemBuy}>
                    <Text style={styles.CartItemSum}>Подарок × {item.quantity}</Text>
                  </View> */}
                </div>
                )
              })
            }
            {
              promocodeGifts.map(item => {
              return (
                <div className="CartItem" key={'promocodeGift' + item.id}>
                  <div className="CartItem__Name">
                    {item.quantity > 0 &&
                      <span style={{color: 'var(--text_secondary)'}}>{item.quantity} × </span>
                    }
                    { item.name }
                  </div>
                  <div className="CartItem__Action">
                    <Icon16Gift className="CartItem__ActionIcon" />
                    <Text className="CartItem__ActionText">{ props.promocode.name }</Text>
                  </div>
                  {/* <View style={styles.CartItemBuy}>
                    <Text style={styles.CartItemSum}>Подарок × {item.quantity}</Text>
                  </View> */}
                </div>
                )
              })
            }
          </Group>
          {
            recommendsItems.length > 0 &&
            <Group header={<Header>Рекомендуем к вашему заказу</Header>}>
              <HorizontalScroll>
                <div style={{display: 'flex', flexDirection: 'row', paddingLeft: '12px', paddingRight: '12px'}}>
                  {
                    recommendsItems.map(item => 
                      <RecommendCard 
                        key={item.id}
                        product={item} 
                        onClick={() => props.onModal({type: 'PRODUCT_DETAIL', data: {product: item, isRecommend: true} })}
                      />
                    )
                  }
                </div>
              </HorizontalScroll>
            </Group>
          }
          {
            (
              _.get(props, 'owner.tariff.scope.actions', 0) == 1 && 
              _.get(props, 'affiliate.order_form.config.promocode.active', 0) == 1 &&
              props.promocodeStatus.code !== 'success'
            ) &&
            <Div>
              <Button size="l" stretched mode="outline"
                onClick={() => props.onModal({type: 'PROMOCODE'})}>
                Ввести промокод</Button>
            </Div>
          }
          {
            props.promocodeStatus.code === 'success' &&
            <Group>
              <Header style={{marginBottom: '-10px'}}
                aside={
                  <Text style={{color: 'var(--color-1)'}} onClick={() => props.onModal({type: 'PROMOCODE'})}>Изменить</Text>
                }>Промокод</Header>
              <Div styles={{paddingTop: 0}}>
                <div className="PromocodeResult">
                { 
                  props.promocodeCheckStatus.code !== 'pre_success' &&
                  <div className={`PromocodeResult__Status PromocodeResult__Status--${props.promocodeCheckStatus.code}`}>
                    { props.promocodeCheckStatus.text || 'Промокод не активирован' }
                  </div>
                }
                <div className="PromocodeResult__Title">{ props.promocode.name }</div>
                {
                  props.promocode.description?.length > 0 && 
                  <div className="PromocodeResult__Description">{ props.promocode.description }</div>
                }
                {
                  props.promocodeCheckStatus.description && 
                  <div className={`PromocodeResult__Description PromocodeResult__Description--${props.promocodeCheckStatus.code}`}>{ props.promocodeCheckStatus.description }</div>
                }
                </div>
              </Div>
            </Group>
          }
          {
            (
              _.get(props, 'owner.tariff.scope.actions', 0) > 0 &&
              (!props.basketRulesActions.giftsDisable || !props.basketRulesActions.giftsSumDisable) &&
              !(props.promocodeCheckStatus.code == 'success' && props.promocode.is_stop_actions) &&

              ((_.get(props, 'affiliate.gifts_from', []).length > 0 && !props.basketRulesActions.giftsSumDisable) ||
              giftSetsItems.length > 0)
            ) && 
            <Group>
              <Header level={2}
                aside={(allGiftsMaxCount > 0) ? declOfNum(allGiftsCount, ['Выбран', 'Вабраны', 'Выбраны']) + ' ' + allGiftsCount + ' из ' + allGiftsMaxCount : null}>
                Подарки
              </Header>
              {
                _.get(props, 'affiliate.gifts_from', []).length > 0 && !props.basketRulesActions.giftsSumDisable &&
                props.affiliate.gifts_from.map(gifts_count => 
                  <Cell key={gifts_count.sum}
                    disabled={!(giftsSumMaxCount == gifts_count.max_count && orderSumDiscount >= gifts_count.sum)} 
                    before={(giftsSumMaxCount == gifts_count.max_count && orderSumDiscount >= gifts_count.sum) ? <Icon24Gift style={{color: 'var(--color-1)' }} /> : <Icon24DoNotDisturb style={{color: 'var(--icon_secondary)'}} />}
                    description={(giftsSumMaxCount == gifts_count.max_count && orderSumDiscount >= gifts_count.sum) ? 'Вы можете выбрать ' + giftsSumMaxCount + ' ' + declOfNum(giftsSumMaxCount, ['подарок', 'подарка', 'подарков']) : 'Мы дарим '+gifts_count.max_count+' '+declOfNum(gifts_count.max_count, ['подарок', 'подарка', 'подарков'])}
                    expandable={giftsSumMaxCount == gifts_count.max_count && orderSumDiscount >= gifts_count.sum && giftsSumCount < giftsSumMaxCount}
                    after={(giftsSumMaxCount != gifts_count.max_count || orderSumDiscount < gifts_count.sum || giftsSumCount < giftsSumMaxCount) ? null : <Icon28ChecksOutline style={{color: 'var(--dynamic_green)'}} />}
                    onClick={(giftsSumMaxCount == gifts_count.max_count && orderSumDiscount >= gifts_count.sum)? () => router.navigate('cart/gifts') : () => {}}>
                    <Text>Заказ от { gifts_count.sum + ' ' + _.get(props, 'affiliate.currency.sign') }</Text>
                  </Cell>
                )
              }
              {
                !props.basketRulesActions.giftsDisable &&
                giftSetsItems.map(set =>
                  <Cell key={set.random_id}
                    before={<Icon24Gift style={{color: 'var(--color-1)' }} />}
                    description={'Вы можете выбрать ' + set.max_count + ' ' + declOfNum(set.max_count, ['подарок', 'подарка', 'подарков'])}
                    expandable={set.max_count > set.selected.reduce((sum, item) => sum + Number(item.data.quantity), 0)}
                    after={(set.max_count > set.selected.reduce((sum, item) => sum + Number(item.data.quantity), 0)) ? null : <Icon28ChecksOutline style={{color: 'var(--dynamic_green)'}} />}
                    onClick={() => router.navigate('cart/giftsRule', {random_id: set.random_id})}
                    >
                    <Text>{ set.action_title }</Text>
                  </Cell>
                )
              }
            </Group> 
          }

          <Group>
            <Cell disabled>
              <InfoRow header="Сумма заказа">
                { `${orderSum.toFixed(2)} ${props.affiliate.currency.sign}` }
              </InfoRow>
            </Cell>
            {orderDiscount > 0 && <Cell disabled>
              <InfoRow header="Скидка">
                { `${orderDiscount.toFixed(2)} ${props.affiliate.currency.sign}` }
                {orderDiscountDescription.length > 0 && 
                  <div className="CartItem__Action">
                    <Icon24DiscountOutline width={16} height={16} className="CartItem__ActionIcon" />
                    <Text className="CartItem__ActionText">{ orderDiscountDescription }</Text>
                  </div>
                }
              </InfoRow>
            </Cell>}
            {
              orderMarkup > 0 && 
              <Cell disabled>
                <InfoRow header="Наценка">
                  { `${orderMarkup.toFixed(2)} ${props.affiliate.currency.sign}` }
                  {orderMarkupDescription.length > 0 && 
                    <div className="CartItem__Action">
                      <Icon16Add className="CartItem__ActionIcon" />
                      <Text className="CartItem__ActionText">{ orderMarkupDescription }</Text>
                    </div>
                  }
                </InfoRow>
              </Cell>
            }
            {/* <UICell component="View">
              <UIInfoRow title="Доставка">
                { (props.orderForm.delivery == 'self') ? 'Самовывоз' : (deliveryPrice > 0 ? `${deliveryPrice.toFixed(2)} ${props.affiliate.currency.sign}` : 'Бесплатно') }
              </UIInfoRow>
            </UICell>
            <UICell component="View">
              <UIInfoRow title="Итого к оплате">
                { `${orderTotal.toFixed(2)} ${props.affiliate.currency.sign}` }
              </UIInfoRow>
            </UICell> */}
          </Group>

          {
            typeof props.affiliate.cart_page_text !== 'undefined' && props.affiliate.cart_page_text.length > 0 &&
            <Group>
              <Div>
                <Text style={{fontSize: '15px', lineHeight: '21px', color: 'var(--text_secondary)'}} dangerouslySetInnerHTML={{ __html: props.affiliate.cart_page_text }}></Text>
              </Div>
            </Group>
          }

          {
            cartBottomMessages.length > 0 &&
            cartBottomMessages.map((message, index) => {
              return (<div className={`Message Message--type_${message.type}`} key={index}>
                {message.title?.length > 0 && <Text className="Message__Title">{ message.title }</Text>}
                {message.description?.length > 0 && <Text className="Message__Description">{ message.description }</Text>}
              </div>)
            })
          }

          {
            orderConditions.min_sum.value > orderSumDiscount && props.orderForm.delivery !== 'self' &&
            <Group>
              <Cell disabled
                before={<Icon24DoNotDisturb style={{color: 'var(--dynamic_red)' }} />}
                description={ 'Не хватает '+(orderConditions.min_sum.value - orderSumDiscount).toFixed(2)+' '+props.affiliate.currency.sign }>
                <Text style={{color: 'var(--dynamic_red)' }}>{ `Минимальный заказ: ${orderConditions.min_sum.value} ${props.affiliate.currency.sign}`}</Text>
              </Cell>
            </Group>
          }
          {
            (orderConditions.min_sum.value <= orderSumDiscount || props.orderForm.delivery === 'self') 
            && !!props.owner.active
            && (!!props.affiliate.is_now_open || !!props.affiliate.allow_ordering_at_any_time)
            && _.get(props, 'owner.tariff.scope.mobile_apps', 0) === 1 &&
            // && !basketRulesClose &&
            <FixedLayout vertical="bottom" style={{ backgroundColor: 'var(--modal_card_background)', borderTop: '.5px solid var(--field_border)'}}>
              <Div>
                <Button size="l" stretched
                  onClick={() => router.navigate('cart/order') }>Далее</Button>
              </Div>
            </FixedLayout>
          }
	    	</div>
	    }	   
	  </Panel>
	)
}

function mapStateToProps(state) {
  return {
    products: state.catalog.products,
    cartProducts: state.cart.products,
    cartGifts: state.cart.gifts,
    cartMores: state.cart.mores,
    promocode: state.cart.promocode,
    promocodeStatus: state.cart.promocodeStatus,
    promocodeCheckStatus: state.cart.promocodeCheckStatus,
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    orderForm: state.cart.orderForm,
    user: state.user.user,
    client: state.user.client,
    clientStatus: state.user.clientStatus,
    authStatus: state.user.authStatus,
    isAuthenticated: state.user.isAuthenticated,
    deliveryArea: state.cart.deliveryArea,
    basketRulesActions: state.cart.basketRulesActions,
    sections: state.catalog.sections,
    checkPhoneStatus: state.user.checkPhoneStatus,
    setPhoneStatus: state.user.setPhoneStatus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (id, data, meta) => dispatch(cartActions.addProduct(id, data, meta)),
    delProduct: (id, data) => dispatch(cartActions.delProduct(id, data)),
    clearProduct: (id, data) => dispatch(cartActions.clearProduct(id, data)),
    addGift: (id, data) => dispatch(cartActions.addGift(id, data)),
    delGift: (id) => dispatch(cartActions.delGift(id)),
    setMore: (for_id, index, id) => dispatch(cartActions.setMore(for_id, index, id)),
    saveOrderForm: (data) => dispatch(cartActions.saveOrderForm(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart);

