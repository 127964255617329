import {ERROR} from '../../utils/errors'
// import { Platform } from 'react-native';
// import * as Analytics from 'expo-firebase-analytics';
// import AppMetrica from 'react-native-appmetrica';

import { 
  GET_PROMOCODE_REQUEST,
  GET_PROMOCODE_SUCCESS,
  GET_PROMOCODE_ERROR,
  CHECK_PROMOCODE,
  CLEAR_PROMOCODE,

  ADD_PRODUCT, 
  DEL_PRODUCT,
  CLEAR_PRODUCT,
  CLEAR_PRODUCTS,

  ADD_GIFT, 
  DEL_GIFT,
  CLEAR_GIFTS,

  ADD_RULE_GIFT,
  DEL_RULE_GIFT,

  SET_MORE,
  CLEAR_MORES,

  SAVE_ORDER_FORM,
  CLEAR_ORDER_FORM,

  SAVE_ORDER_REQUEST,
  SAVE_ORDER_SUCCESS,
  SAVE_ORDER_ERROR,

  TRANSACTION_ERROR,
  TRANSACTION_SUCCESS,
  TRANSACTION_REQUEST,
  TRANSACTION_CLEAR,

  GET_BASKET_RULES_REQUEST,
  GET_BASKET_RULES_SUCCESS,
  GET_BASKET_RULES_ERROR,
  CHECK_BASKET_RULES,

  GET_DELIVERY_AREAS_REQUEST,
  GET_DELIVERY_AREAS_SUCCESS,
  GET_DELIVERY_AREAS_ERROR,
  
  GET_DELIVERY_AREA_REQUEST,
  GET_DELIVERY_AREA_SUCCESS,
  GET_DELIVERY_AREA_NOT_FOUND,
  GET_DELIVERY_AREA_ERROR,

  CLEAR_DELIVERY_AREA,
} from "../ActionTypes";

const initialState = {
  products: [],
  gifts: [],
  mores: {},
  promocode: {},
  promocodeStatus: {},
  promocodeCheckStatus: {},
  orderForm: {},

  basketRules: [],
  basketRulesStatus: {},
  basketRulesCheckStatus: {},
  basketRulesActions: {
    free: [],
    recommends: [],
    giftSets: [],
    discounts: [],
    fixPrices: [],
    cheapestFree: [],
    cheapestDiscounts: [],
    promocodesDisable: false,
    giftsDisable: false,
    giftsSumDisable: false,
    discountsDisable: false,
    freeDeliveryDisable: false,
    orderConditions: {},
    paymentTypes: [],
    paymentBonusDisable: false,
    deliveryTypes: [],
    productsRequired: [],
    messages: [],
    close: false,
  },
  deliveryAreas: [],
  deliveryAreasStatus: {},
  deliveryArea: {},
  deliveryAreaStatus: {},
};

export default (state = initialState, action) => {
  let status = {}
  switch (action.type) {

    case GET_PROMOCODE_REQUEST:
      status = { code: 'loading' }
      return {
        ...state,
        promocodeStatus: status,
      }

    case GET_PROMOCODE_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        promocodeStatus: status,
        promocode: action.data,
      }

    case GET_PROMOCODE_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        promocodeStatus: status,
        promocode: {},
      }

    case CHECK_PROMOCODE:
      return checkPromocode(state, action)

    case CLEAR_PROMOCODE:
      status = { code: 'clear' }
      return {
        ...state,
        promocode: {}, 
        promocodeStatus: status,
        promocodeCheckStatus: {},
      }
    
    case ADD_PRODUCT:
      return addProduct(state, action)

    case DEL_PRODUCT:
      return delProduct(state, action)

    case CLEAR_PRODUCT:
      return clearProduct(state, action)

    case CLEAR_PRODUCTS:
      return clearProducts(state, action)

    case ADD_GIFT:
      return addGift(state, action)

    case DEL_GIFT:
      return delGift(state, action)

    case CLEAR_GIFTS:
      return clearGifts(state, action)

    case ADD_RULE_GIFT:
      return addRuleGift(state, action)

    case DEL_RULE_GIFT:
      return delRuleGift(state, action)

    case SET_MORE:
      return setMore(state, action)

    case CLEAR_MORES:
      return clearMores(state, action)

    case SAVE_ORDER_FORM:
      return saveOrderForm(state, action)

    case CLEAR_ORDER_FORM:
      return clearOrderForm(state, action)

    case SAVE_ORDER_REQUEST:
      return state

    case SAVE_ORDER_SUCCESS:
      return state

    case SAVE_ORDER_ERROR:
      return state

    case TRANSACTION_SUCCESS:
      return {
        ...state,
        transaction: action.data,
      }

    case TRANSACTION_CLEAR:
      return {
        ...state,
        transaction: {},
      }

    case GET_DELIVERY_AREAS_REQUEST:
      status = { code: 'loading' }
      return {
        ...state,
        deliveryAreasStatus: status,
      }

    case GET_DELIVERY_AREAS_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        deliveryAreasStatus: status,
        deliveryAreas: action.data,
      }

    case GET_DELIVERY_AREAS_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        deliveryAreasStatus: status,
        deliveryAreas: [],
      }

    case GET_DELIVERY_AREA_REQUEST:
      status = { code: 'loading' }
      return {
        ...state,
        deliveryAreaStatus: status,
      }

    case GET_DELIVERY_AREA_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        deliveryAreaStatus: status,
        deliveryArea: action.data,
      }

    case GET_DELIVERY_AREA_NOT_FOUND:
      status = { code: 'not_found' }
      return {
        ...state,
        deliveryAreaStatus: status,
        deliveryArea: {},
      }

    case GET_DELIVERY_AREA_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        deliveryAreaStatus: status,
        deliveryArea: {},
      }

    case CLEAR_DELIVERY_AREA:
      status = {}
      return {
        ...state,
        deliveryAreaStatus: status,
        deliveryArea: {},
      }

    case GET_BASKET_RULES_REQUEST:
      status = { code: 'loading' }
      return {
        ...state,
        basketRulesStatus: status,
      }

    case GET_BASKET_RULES_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        basketRulesStatus: status,
        basketRules: action.data,
      }

    case GET_BASKET_RULES_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        basketRulesStatus: status,
        basketRules: {},
      }

    case CHECK_BASKET_RULES:
      return checkBasketRules(state, action)
    
    default:
      return state
  }
}

function checkPromocode(state, action) {
  // if(state.basketRulesActions.promocodesDisable) {
  //   return {
  //     ...state,
  //     promocodeCheckStatus: {}
  //   }
  // }
  const client = _.get(action, 'data.client', {})
  const source = _.get(action, 'data.source', {})
  let promocode = state.promocode
  if(!promocode || !promocode.code) return state
  let cartItems = state.products
  let status = {}
  let isChecked = true
  let statusDescription = ''
  
  let count = [].concat(cartItems).filter(item => !!item).reduce((sum, item) => {
    return sum + item.data.quantity
  }, 0)

  let countForItems = [].concat(cartItems).filter(item => !!item).filter(item => promocode.for_items && promocode.for_items.indexOf(item.id) != -1).reduce((sum, item) => {
    return sum + item.data.quantity
  }, 0)

  let countWithItems = [].concat(cartItems).filter(item => !!item).filter(item => promocode.with_items && promocode.with_items.indexOf(item.id) != -1).reduce((sum, item) => {
    return sum + item.data.quantity
  }, 0)
  
  let sum = [].concat(cartItems).filter(item => !!item).reduce((sum, item) => {
    return sum + item.data.quantity * (Number(item.data.price) + [].concat(item.data.options).filter(item => !!item).reduce((sum, item) => { return Number(sum) + Number(item.price)}, 0))
  }, 0)

  let d = new Date();
  let m = d.getMinutes();
  let h = d.getHours();
  let currentTime = ''
  if(h < 10) {
    currentTime += '0'
  }
  currentTime += h + ':'
  if(m < 10) {
    currentTime += '0'
  }
  currentTime += m
  let preorderTime = (state.orderForm.set_the_time == 'set' && state.orderForm.delivery_time) ? state.orderForm.delivery_time : currentTime
  let weekDay = d.getDay();

  // Проверим условие (на MIN сумму заказа)
  if(promocode.conditions.min_sum > 0 && promocode.conditions.min_sum > sum) {
    statusDescription = 'Сумма заказа должна быть больше ' + promocode.conditions.min_sum
    isChecked = false
  }

  // Проверим условие (на MAX сумму заказа)
  if(promocode.conditions.max_sum > 0 && promocode.conditions.max_sum < sum) {
    statusDescription = 'Сумма заказа должна быть меньше ' + promocode.conditions.max_sum
    isChecked = false
  }

  // Проверим условие (на время заказа С)
  if(promocode.conditions.time_from) {
    // let timeFrom = promocode.conditions.time_from.split(":");
    // let hourFrom = timeFrom[0];
    // if(hourFrom == '00') {hourFrom = 24}
    // let minFrom = timeFrom[1];
    // let promocodeTimeFrom = hourFrom+"."+minFrom;
    const promocodeTimeFrom = promocode.conditions.time_from
    if(currentTime < promocodeTimeFrom) {
      console.log('%cpromocode.conditions.time_from', 'padding: 2px 4px; background-color: #ffcdd2')
      statusDescription = 'Промокод действителен с ' + promocode.conditions.time_from
      isChecked = false
    }
  }

  // Проверим условие (на время заказа ДО)
  if(promocode.conditions.time_to) {
    // let timeTo = promocode.conditions.time_to.split(":");
    // let hourTo = timeTo[0];
    // if(hourTo == '00') {hourTo = 24}
    // let minTo = timeTo[1];
    // let promocodeTimeTo = hourTo+"."+minTo;
    const promocodeTimeTo = promocode.conditions.time_to
    if(currentTime > promocodeTimeTo) {
      console.log('%cpromocode.conditions.time_to', 'padding: 2px 4px; background-color: #ffcdd2')
      statusDescription = 'Промокод действителен до ' + promocode.conditions.time_to
      isChecked = false
    }
  }

  // Проверим условие (на время предзаказа С)
  if(promocode.conditions.preorder_time_from) {
    // let timeFrom = promocode.conditions.preorder_time_from.split(":");
    // let hourFrom = timeFrom[0];
    // if(hourFrom == '00') {hourFrom = 24}
    // let minFrom = timeFrom[1];
    // let promocodeTimeFrom = hourFrom+"."+minFrom;
    const promocodePreorderTimeFrom = promocode.conditions.preorder_time_from
    if(preorderTime < promocodePreorderTimeFrom) {
      console.log('%cpromocode.conditions.preorder_time_from', 'padding: 2px 4px; background-color: #ffcdd2')
      statusDescription = 'Промокод действителен на предзаказ с ' + promocode.conditions.preorder_time_from
      isChecked = false
    }
  }

  // Проверим условие (на время предзаказа ДО)
  if(promocode.conditions.preorder_time_to) {
    // let timeTo = promocode.conditions.preorder_time_to.split(":");
    // let hourTo = timeTo[0];
    // if(hourTo == '00') {hourTo = 24}
    // let minTo = timeTo[1];
    // let promocodeTimeTo = hourTo+"."+minTo;
    const promocodePreorderTimeTo = promocode.conditions.preorder_time_to
    if(preorderTime > promocodePreorderTimeTo) {
      console.log('%cpromocode.conditions.preorder_time_to', 'padding: 2px 4px; background-color: #ffcdd2')
      statusDescription = 'Промокод действителен на предзаказ до ' + promocode.conditions.preorder_time_to
      isChecked = false
    }
  }

  // Проверим условие (на дни недели)
  if(promocode.conditions.week_days) {
    if(parseInt(promocode.conditions.week_days[weekDay]) != 1) {
      statusDescription = 'Сегодня промокод не работает'
      isChecked = false
    }
  }

  // Проверим условие (на способ получения)
  if(promocode.conditions.delivery) {
    // state.orderForm.delivery: 'delivery' || 'self'
    const orderFormDelivery = state.orderForm.delivery || 'delivery'
    if(parseInt(promocode.conditions.delivery[ orderFormDelivery ]) != 1) {
      statusDescription = 'Промокод работает только на ' + (orderFormDelivery == 'delivery' ? 'самовывоз' : 'доставку')
      isChecked = false
    }
  }

  // Проверим условие (на платформы)
  if(promocode.conditions.platforms) {
    const platforms = {
      'site': 'Сайт',
      'vkapp': 'ВК',
      'ios': 'Мобильное приложение iOS',
      'android': 'Мобильное приложение Android',
    }
    const orderPlatform = source.type || 'unknown'
    if(parseInt(promocode.conditions.platforms[ orderPlatform ]) != 1) {
      statusDescription = 'Промокод можно активировать только через ' + Object.keys(promocode.conditions.platforms).filter(key => !!promocode.conditions.platforms[key]).map(key => platforms[key]).join(' или ')
      isChecked = false
    }
  }

  // Проверим условие (на MIN количество товара в корзине)
  if(promocode.conditions.min_count > 0) {
    if(promocode.for_items.length > 0 && promocode.conditions.min_count > countForItems) {
      statusDescription = 'Не выполнено условие на минимальное количество товара в корзине'
      isChecked = false
    } else if(promocode.conditions.min_count > count) {
      statusDescription = 'Не выполнено условие на минимальное количество товара в корзине'
      isChecked = false
    }
  }

  // Проверим условие (на MAX количество товара в корзине)
  if(promocode.conditions.max_count > 0) {
    if(promocode.for_items.length > 0 && promocode.conditions.max_count < countForItems) {
      statusDescription = 'Не выполнено условие на максимальное количество товара в корзине'
      isChecked = false
    } else if(promocode.conditions.max_count < count) {
      statusDescription = 'Не выполнено условие на максимальное количество товара в корзине'
      isChecked = false
    }
  }

  // Проверим условие (на MIN количество товаров обязательных к заказу)
  if(promocode.conditions.min_with > 0) {
    if(promocode.with_items.length > 0 && promocode.conditions.min_with > countWithItems) {
      statusDescription = 'Не выполнено условие на минимальное количество обязательных товаров'
      isChecked = false
    }
  }

  // Проверим условие (на MAX количество товаров обязательных к заказу)
  if(promocode.conditions.max_with > 0) {
    if(promocode.with_items.length > 0 && promocode.conditions.max_with < countWithItems) {
      statusDescription = 'Не выполнено условие на максимальное количество обязательных товаров'
      isChecked = false
    }
  }

  // Проверим на первый заказ клиента
  if(promocode.conditions.is_first_order == 1) {
    if(client && client.last_order && client.last_order.id) {
      statusDescription = 'Промокод работает только для новых клиентов'
      isChecked = false
    }
  }

  if(isChecked) {
    if(promocode.type.code === 'basket_rules') {
      // Доп проверки происходят в BASKET_RULES_CHECK
      status = {
        code: 'promocode_check_pre_success',
        text: 'Акция может быть активирована',
        // description: 'Прочитайте описание и выполните требуемые действия, чтобы акция сработала',
      }
    } else {
      status = {
        code: 'success',
        text: 'Акция активирована',
      }
    }
  } else {
    status = {
      code: 'warning',
      text: 'Не выполнены условия',
      description: statusDescription || 'Прочитайте описание и выполните требуемые действия, чтобы акция сработала',
    }
  }
  
  return {
    ...state,
    promocodeCheckStatus: status
  }
}

function addProduct(state, action) {
  let id = action.data.id
  // let _data = {...action.data.data}
  let _data = JSON.parse(JSON.stringify(action.data.data))
  const _meta = JSON.parse(JSON.stringify(action.data.meta))
  // let products = [].concat(state.products)
  let products = []
  for (var i = 0; i <= state.products.length - 1; i++) {
    products.push(JSON.parse(JSON.stringify(state.products[i])))
  }
  if(Array.isArray(_data.options)) {
    _data.options.map(item => {
      Object.keys(item).forEach(key => {
        if(['id','code','price','options_group_id'].indexOf(key) == -1) {
          delete item[key]
        }
      })
      return item
    })
  }

  let addedQuantity = 1
  if(products.filter(function(item){ return item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(_data.options).filter(item => !!item).map(item => item.id).sort().toString() }).length > 0) {
    products.map(function(item){
      if(item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(_data.options).filter(item => !!item).map(item => item.id).sort().toString() ) {
        item.data.quantity++
      }
      return item
    })
  } else {
    if(typeof _data != 'object') _data = {}
    let item = {id, data: _data}
    const min_quantity = Number(_data.min_quantity) || 1
    addedQuantity = min_quantity
    item.data.quantity = min_quantity
    products.push(item)
  }
  
  // if (typeof localStorage != 'undefined') {
  //   localStorage.setItem('cartItems', JSON.stringify(state.products))
  // }

  // Analytics

  // Analytics.logEvent('add_to_cart', {
  //   item_id: id,
  //   item_name: _meta.product?.name,
  //   item_category: _meta.category?.name,
  //   quantity: addedQuantity,
  //   price: _data.price,
  //   currency: _meta.currency?.code,
  // })

  // AppMetrica.reportEvent('add_to_cart', {
  //   item_id: id,
  //   item_name: _meta.product?.name,
  //   item_category: _meta.category?.name,
  //   quantity: addedQuantity,
  //   price: _data.price,
  //   currency: _meta.currency?.code,
  // })

  return {
    ...state,
    products,
  }
}

function delProduct(state, action) {
  let id = action.data.id
  // let data = action.data.data
  let data = JSON.parse(JSON.stringify(action.data.data))
  // let products = [...state.products]
  let products = []
  for (var i = 0; i <= state.products.length - 1; i++) {
    products.push(JSON.parse(JSON.stringify(state.products[i])))
  }
  let itemInCart = products.filter(function(item){ return item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(data.options).filter(item => !!item).map(item => item.id).sort().toString() }).shift()
  const min_quantity = Number(itemInCart.data.min_quantity) || 1
  if(typeof itemInCart != 'undefined') {
    if(itemInCart.data.quantity > min_quantity) {
      products.map(function(item){
        if(item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(data.options).filter(item => !!item).map(item => item.id).sort().toString() ) {
          item.data.quantity--
        }
        return item
      })
    } else {
      products = products.filter(function(item) {
        return !(item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(data.options).filter(item => !!item).map(item => item.id).sort().toString() )
      })
    }
  }
  // if(typeof localStorage != 'undefined') {
  //   localStorage.setItem('cartItems', JSON.stringify(state.products))
  // }

  return {
    ...state,
    products,
  }
}

function clearProduct(state, action) {
  let id = action.data.id
  let data = JSON.parse(JSON.stringify(action.data.data))
  let products = []
  for (var i = 0; i <= state.products.length - 1; i++) {
    products.push(JSON.parse(JSON.stringify(state.products[i])))
  }
  let itemInCart = products.filter(function(item){ return item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(data.options).filter(item => !!item).map(item => item.id).sort().toString() }).shift()
  if(typeof itemInCart != 'undefined') {
    products = products.filter(function(item) {
      return !(item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(data.options).filter(item => !!item).map(item => item.id).sort().toString() )
    })
  }
  
  return {
    ...state,
    products,
  }
}

function clearProducts(state, action) {
  // if(typeof localStorage != 'undefined') {
  //   localStorage.setItem('cartItems', JSON.stringify(state.products))
  // }

  return {
    ...state,
    products: [],
  }
}

function addGift(state, action) {
  let id = action.data.id
  let data = JSON.parse(JSON.stringify(action.data.data))
  let gifts = []
  for (var i = 0; i <= state.gifts.length - 1; i++) {
    gifts.push(JSON.parse(JSON.stringify(state.gifts[i])))
  }
  
  if(gifts.filter((item) => { return item.id == id }).length > 0) {
    gifts = gifts.map((item) => {
      if(item.id == id) {
        item.data.quantity++
      }
      return item
    })
  } else {
    if(typeof data != 'object') data = {}
    var item = {id, data}
    item.data.quantity = 1
    gifts.push(item)
  }
  // if(typeof localStorage != 'undefined') {
  //   localStorage.setItem('cartGifts', JSON.stringify(state.gifts))
  // }

  return {
    ...state,
    gifts,
  }
}

function delGift(state, action) {
  let id = action.data.id
  let data = JSON.parse(JSON.stringify(action.data.data))
  let gifts = []
  for (var i = 0; i <= state.gifts.length - 1; i++) {
    gifts.push(JSON.parse(JSON.stringify(state.gifts[i])))
  }
  let itemInCart = gifts.filter(item => { return item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(data.options).filter(item => !!item).map(item => item.id).sort().toString() }).shift()
  if(typeof itemInCart != 'undefined') {
    if(itemInCart.data.quantity > 1) {
      gifts.map(item => {
        if(item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(data.options).filter(item => !!item).map(item => item.id).sort().toString() ) {
          item.data.quantity--
        }
        return item
      })
    } else {
      gifts = gifts.filter(function(item) {
        return !(item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(data.options).filter(item => !!item).map(item => item.id).sort().toString() )
      })
    }
  }
  // if(typeof localStorage != 'undefined') {
  //   localStorage.setItem('cartItems', JSON.stringify(state.products))
  // }

  return {
    ...state,
    gifts,
  }
}

function clearGifts(state, action) {
  return {
    ...state,
    gifts: [],
  }
}

function addRuleGift(state, action) {
  const id = action.data.id
  const random_id = action.data.random_id
  const data = JSON.parse(JSON.stringify(action.data.data))

  let basketRulesActions = JSON.parse(JSON.stringify(state.basketRulesActions))

  basketRulesActions.giftSets.forEach((set, index, array) => {
    if(set.random_id === random_id) {
      if(array[index]['selected'].filter(item => item.id == id).length > 0) {
        array[index]['selected'] = array[index]['selected'].map(item => {
          if(item.id == id) {
            item.data.quantity++
          }
          return item
        })
      } else {
        if(typeof data != 'object') data = {}
        let item = {id, data}
        item.data.quantity = 1
        array[index]['selected'].push(item)
      }
    }
  })

  return {
    ...state,
    basketRulesActions,
  }
}

function delRuleGift(state, action) {
  const id = action.data.id
  const random_id = action.data.random_id
  // const data = JSON.parse(JSON.stringify(action.data.data))
  
  let basketRulesActions = JSON.parse(JSON.stringify(state.basketRulesActions))

  basketRulesActions.giftSets.forEach((set, index, array) => {
    if(set.random_id === random_id) {
      var selectedItem = array[index]['selected'].filter(item => item.id == id).shift()
      if(typeof selectedItem != 'undefined') {
        if(selectedItem.data.quantity > 1) {
          array[index]['selected'] = array[index]['selected'].map(item => {
            if(item.id == id) {
              item.data.quantity--
            }
            return item
          })
        } else {
          array[index]['selected'] = array[index]['selected'].filter(item => item.id != id)
        }
      }
    }
  })

  return {
    ...state,
    basketRulesActions,
  }
}

function setMore(state, action) {
  let mores = {}
  Object.keys(state.mores).map(key => mores[key] = JSON.parse(JSON.stringify(state.mores[key])) )

  _.set(mores, "["+action.data.for_id+"]["+action.data.index+"]['id']", action.data.id)
  return {
    ...state,
    mores,
  }
}

function clearMores(state, action) {
  let mores = {}
  Object.keys(state.mores).map(key => mores[key] = JSON.parse(JSON.stringify(state.mores[key])) )
  
  if(action.data && action.data.for_id > 0) {
    delete mores[action.data.for_id]
  } else {
    mores = {}
  }
  return {
    ...state,
    mores,
  }
}

function saveOrderForm(state, action) {
  const orderForm = JSON.parse(JSON.stringify(action.data))
  // if(typeof localStorage != 'undefined') {
  //   localStorage.setItem('cartItems', JSON.stringify(state.products))
  // }

  return {
    ...state,
    orderForm,
  }
}

function clearOrderForm(state, action) {
  // if(typeof localStorage != 'undefined') {
  //   localStorage.setItem('cartItems', JSON.stringify(state.products))
  // }

  return {
    ...state,
    orderForm: {},
  }
}

function checkBasketRules(state, action) {
  // const { client, source } = action.data
  // const source = {
  //   type: 'vkapp', //Platform.OS,
  //   utm: [],
  // }
  const client = _.get(action, 'data.client', {})
  const source = _.get(action, 'data.source', {})

  const isDev = false;

  isDev && console.log('======== BASKET_RULES_CHECK ========')

  let checkedBasketRules = []

  let productsRecommends = []
  let productsFree = []
  let productsGiftSets = []
  let promocodesDisable = false
  let giftsDisable = false
  let giftsSumDisable = false
  let discountsDisable = false
  let freeDeliveryDisable = false
  let discounts = []
  let fixPrices = []
  let cheapestFree = []
  let cheapestDiscounts = []
  let orderConditions = {}
  let paymentTypes = []
  let paymentBonusDisable = false
  let deliveryTypes = []
  let productsRequired = []
  let messages = []
  let close = false

  // пользовательские переменные (доступны во всеха правилах)
  let variables = {}

  let basket_rule_id = null

  let basketRules = state.basketRules
  if(!basketRules || !basketRules.length) return state

  // let cartItems = state.products
  
  // let status = {}
  
  let isFinish = false

  try {
    // Проверим все правила
    basketRules.forEach((item, index, array) => {
      isDev && console.log('%cBasketRule: #%s «%s»', 'font-size: 120%; font-weight: bold;', item.id, item.name)
      // console.log(item)
      basket_rule_id = item.id
      if(!isFinish) {
        if(checkCondition(item.conditions)) {
          isDev && console.log('✅ Checked!!!')
          checkedBasketRules.push(Number(basket_rule_id))
          item.actions.forEach((action) => {
            doAction(action)
          })
          doAction({type: 'activate_promocode'})
          if(item.is_finish) {
            isFinish = true
          }
        } else {
          isDev && console.log('🚫 NOT Checked!!!')          
        }
      }
    })
    doAction({type: 'deactivate_promocode'})
  } catch(err) {
    console.log('BASKET_RULES_CHECK', err)
  }


  /// CONDITIONS ///

  function checkCondition(condition) {
    let isChecked = false
    if(condition.type === 'group') {
      isChecked = checkConditionGroup(condition)
    } else if(condition.type === 'week_days') {
      isChecked = checkConditionWeekDays(condition)
    } else if(condition.type === 'source') {
      isChecked = checkConditionSource(condition)
    } else if(condition.type === 'utms') {
      isChecked = checkConditionUtms(condition)
    } else if(condition.type === 'date_time') {
      isChecked = checkConditionDateTime(condition)
    } else if(condition.type === 'preorder_date_time') {
      isChecked = checkConditionPreorderDateTime(condition)
    } else if(condition.type === 'products_count') {
      isChecked = checkConditionProductsCount(condition)
    } else if(condition.type === 'products_sum') {
      isChecked = checkConditionProductsSum(condition)
    } else if(condition.type === 'order_total') {
      isChecked = checkConditionOrderTotal(condition)
    } else if(condition.type === 'order_sum') {
      isChecked = checkConditionOrderSum(condition)
    } else if(condition.type === 'confirm_order_without_call') {
      isChecked = checkConditionConfirmOrderWithoutCall(condition)
    } else if(condition.type === 'contactless_delivery') {
      isChecked = checkConditionContactlessDelivery(condition)
    } else if(condition.type === 'is_bday') {
      isChecked = checkConditionIsBDay(condition)
    } else if(condition.type === 'bdate') {
      isChecked = checkConditionBDate(condition)
    } else if(condition.type === 'age') {
      isChecked = checkConditionAge(condition)
    } else if(condition.type === 'delivery') {
      isChecked = checkConditionDelivery(condition)
    } else if(condition.type === 'delivery_area') {
      isChecked = checkConditionDeliveryArea(condition)
    } else if(condition.type === 'pickup_point') {
      isChecked = checkConditionPickupPoint(condition)
    } else if(condition.type === 'delivery_type') {
      isChecked = checkConditionDeliveryType(condition)
    } else if(condition.type === 'payment_type') {
      isChecked = checkConditionPaymentType(condition)
    } else if(condition.type === 'first_order') {
      isChecked = checkConditionFirstOrder(condition)
    } else if(condition.type === 'client_external_crm_id') {
        isChecked = checkConditionClientExternalCrmId(condition)
    } else if(condition.type === 'last_order') {
      isChecked = checkConditionLastOrder(condition)
    } else if(condition.type === 'promocode') {
      isChecked = checkConditionPromocode(condition)
    } else if(condition.type === 'promocode_type') {
      isChecked = checkConditionPromocodeType(condition)
    } else if(condition.type === 'promocode_tags') {
      isChecked = checkConditionPromocodeTags(condition)
    } else if(condition.type === 'client_tags') {
      isChecked = checkConditionClientTags(condition)
    } else if(condition.type === 'preorder_days') {
      isChecked = checkConditionPreorderDays(condition)
    } else if(condition.type === 'preorder_minutes') {
      isChecked = checkConditionPreorderMinutes(condition)
    } else if(condition.type === 'preorder_week_days') {
      isChecked = checkConditionPreorderWeekDays(condition)
    } else if(condition.type === 'orders_count') {
      isChecked = checkConditionOrdersCount(condition)    
    } else if(condition.type === 'orders_sum') {
      isChecked = checkConditionOrdersSum(condition)
    } else if(condition.type === 'platform_orders_count') {
      isChecked = checkConditionPlatformOrdersCount(condition)
    } else if(condition.type === 'basket_rule') {
      isChecked = checkConditionBasketRule(condition)
    }
    isDev && console.log('%ccheckCondition:%s=%s', 'padding: 2px 4px; background-color: ' + ((isChecked) ? '#dcedc8' : '#ffcdd2'), condition.type, isChecked)
    return isChecked
  }

  function checkConditionGroup(condition) {
    // Пустая группа всегда TRUE
    if(!condition.children.length) return true
    let isChecked = false
    const all = condition.data.all
    const truth = condition.data.truth || 'TRUE'
    if(all === 'OR') {
      isChecked = false
    } else {
      isChecked = true
    }
    condition.children.forEach((item, index, array) => {
      if(all === 'OR') {
        if(!isChecked) {
          if(checkCondition(item)) isChecked = true
        }
      } else {
        if(isChecked) {
          if(!checkCondition(item)) isChecked = false
        }
      }
    })
    if(truth === 'FALSE') {
      return !isChecked
    }
    return isChecked
  }

  function checkConditionWeekDays(condition) {
    const currentDate = new Date();
    const weekDay = currentDate.getDay();
    const weekDayCodes = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday']
    const weekDayCode = weekDayCodes[weekDay]

    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(condition.data.value.indexOf(weekDayCode) !== -1) {
        isChecked = true
      }
    } else {
      if(condition.data.value.indexOf(weekDayCode) === -1) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionSource(condition) {
    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(condition.data.value.indexOf(source.type) !== -1) {
        isChecked = true
      }
    } else {
      if(condition.data.value.indexOf(source.type) === -1) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionUtms(condition) {
    if(!Array.isArray(source.utm)) return false
    let isChecked = false
    const all = condition.data.all
    if(all === 'OR') {
      isChecked = false
    } else {
      isChecked = true
    }
    condition.children.forEach((item, index, array) => {
      if(all === 'OR') {
        if(checkConditionUtm(item)) isChecked = true
      } else {
        if(!checkConditionUtm(item)) isChecked = false
      }
    })
    return isChecked
  }

  function checkConditionUtm(condition) {
    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(source.utm.filter(item => item.name === condition.type && item.value === condition.data.value).length > 0) {
        isChecked = true
      }
    } else if(condition.data.logic === 'NOT_EQUAL') {
      if(source.utm.filter(item => item.name === condition.type && item.value === condition.data.value).length === 0) {
        isChecked = true
      }
    } else if(condition.data.logic === 'CONTAIN') {
      if(source.utm.filter(item => item.name === condition.type && item.value.indexOf(condition.data.value) !== -1).length > 0) {
        isChecked = true
      }
    } else if(condition.data.logic === 'NOT_CONTAIN') {
      if(source.utm.filter(item => item.name === condition.type && item.value.indexOf(condition.data.value) !== -1).length === 0) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionDateTime(condition) {
    let isChecked = false
    const all = condition.data.all
    if(all === 'OR') {
      isChecked = false
    } else {
      isChecked = true
    }
    condition.children.forEach((item, index, array) => {
      if(item.type === 'date') {
        if(all === 'OR') {
          if(checkConditionDate(item)) isChecked = true
        } else {
          if(!checkConditionDate(item)) isChecked = false
        }
      } else if(item.type === 'time') {
        if(all === 'OR') {
          if(checkConditionTime(item)) isChecked = true
        } else {
          if(!checkConditionTime(item)) isChecked = false
        }
      }
    })
    return isChecked
  }

  function checkConditionDate(condition) {
    // const currentDate = new Date()
    // currentDate.setHours(0,0,0,0)
    
    const conditionYear = condition.data.value.split('.')[2]
    const conditionMonth = condition.data.value.split('.')[1]-1
    const conditionDay = condition.data.value.split('.')[0]
    const conditionDate = new Date(conditionYear, conditionMonth, conditionDay)
    
    let isChecked = false
    isChecked = compareConditionDate(condition)
    // if(condition.data.logic === 'EQUAL') {
    //   if(currentDate.valueOf() === conditionDate.valueOf()) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'NOT_EQUAL') {
    //   if(currentDate.valueOf() !== conditionDate.valueOf()) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'LESS') {
    //   if(currentDate.valueOf() < conditionDate.valueOf()) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'GREATER') {
    //   if(currentDate.valueOf() > conditionDate.valueOf()) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'LESS_OR_EQUAL') {
    //   if(currentDate.valueOf() <= conditionDate.valueOf()) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'GREATER_OR_EQUAL') {
    //   if(currentDate.valueOf() >= conditionDate.valueOf()) {
    //     isChecked = true
    //   }
    // }
    return isChecked
  }

  function checkConditionTime(condition) {
    const currentTime = new Date()
    
    const conditionHour = condition.data.value.split(':')[0]
    const conditionMin = condition.data.value.split(':')[1]
    const conditionTime = new Date()
    conditionTime.setHours(conditionHour, conditionMin)

    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(currentTime.valueOf() === conditionTime.valueOf()) {
        isChecked = true
      }
    } else if(condition.data.logic === 'NOT_EQUAL') {
      if(currentTime.valueOf() !== conditionTime.valueOf()) {
        isChecked = true
      }
    } else if(condition.data.logic === 'LESS') {
      if(currentTime.valueOf() < conditionTime.valueOf()) {
        isChecked = true
      }
    } else if(condition.data.logic === 'GREATER') {
      if(currentTime.valueOf() > conditionTime.valueOf()) {
        isChecked = true
      }
    } else if(condition.data.logic === 'LESS_OR_EQUAL') {
      if(currentTime.valueOf() <= conditionTime.valueOf()) {
        isChecked = true
      }
    } else if(condition.data.logic === 'GREATER_OR_EQUAL') {
      if(currentTime.valueOf() >= conditionTime.valueOf()) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionPreorderDateTime(condition) {
    let isChecked = false
    const all = condition.data.all
    if(all === 'OR') {
      isChecked = false
    } else {
      isChecked = true
    }
    condition.children.forEach((item, index, array) => {
      if(item.type === 'preorder_date') {
        if(all === 'OR') {
          if(checkConditionPreorderDate(item)) isChecked = true
        } else {
          if(!checkConditionPreorderDate(item)) isChecked = false
        }
      } else if(item.type === 'preorder_time') {
        if(all === 'OR') {
          if(checkConditionPreorderTime(item)) isChecked = true
        } else {
          if(!checkConditionPreorderTime(item)) isChecked = false
        }
      }
    })
    return isChecked
  }

  function checkConditionPreorderDate(condition) {
    
    const conditionYear = condition.data.value.split('.')[2]
    const conditionMonth = condition.data.value.split('.')[1]-1
    const conditionDay = condition.data.value.split('.')[0]
    const conditionDate = new Date(conditionYear, conditionMonth, conditionDay)

    let isChecked = false
    if(state.orderForm.set_the_time == 'set' && state.orderForm.delivery_date) {
      const preorderDateDMY = state.orderForm.delivery_date
      isChecked = compareConditionDate(condition, preorderDateDMY)
    } else {
      // Сравниваем с текущей датой
      isChecked = compareConditionDate(condition)
    }
    
    return isChecked
  }

  function checkConditionPreorderTime(condition) {
    const preorderTime = new Date()

    if(state.orderForm.set_the_time == 'set' && state.orderForm.delivery_time) {
      const preorderHM = state.orderForm.delivery_time
      const preorderHour = preorderHM.split(':')[0]
      const preorderMinute = preorderHM.split(':')[1]
      preorderTime.setHours(preorderHour, preorderMinute)
    }
    
    const conditionHour = condition.data.value.split(':')[0]
    const conditionMin = condition.data.value.split(':')[1]
    const conditionTime = new Date()
    conditionTime.setHours(conditionHour, conditionMin)

    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(preorderTime.valueOf() === conditionTime.valueOf()) {
        isChecked = true
      }
    } else if(condition.data.logic === 'NOT_EQUAL') {
      if(preorderTime.valueOf() !== conditionTime.valueOf()) {
        isChecked = true
      }
    } else if(condition.data.logic === 'LESS') {
      if(preorderTime.valueOf() < conditionTime.valueOf()) {
        isChecked = true
      }
    } else if(condition.data.logic === 'GREATER') {
      if(preorderTime.valueOf() > conditionTime.valueOf()) {
        isChecked = true
      }
    } else if(condition.data.logic === 'LESS_OR_EQUAL') {
      if(preorderTime.valueOf() <= conditionTime.valueOf()) {
        isChecked = true
      }
    } else if(condition.data.logic === 'GREATER_OR_EQUAL') {
      if(preorderTime.valueOf() >= conditionTime.valueOf()) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionPreorderWeekDays(condition) {
    let preorderDate = new Date();
    if(state.orderForm.set_the_time == 'set' && state.orderForm.delivery_date) {
      const preorderDateDMY = state.orderForm.delivery_date
      const preorderYear = preorderDateDMY.split('.')[2]
      const preorderMonth = preorderDateDMY.split('.')[1]-1
      const preorderDay = preorderDateDMY.split('.')[0]
      preorderDate = new Date(preorderYear, preorderMonth, preorderDay)
    }
    
    const weekDay = preorderDate.getDay();
    const weekDayCodes = ['sunday','monday','tuesday','wednesday','thursday','friday','saturday']
    const weekDayCode = weekDayCodes[weekDay]

    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(condition.data.value.indexOf(weekDayCode) !== -1) {
        isChecked = true
      }
    } else {
      if(condition.data.value.indexOf(weekDayCode) === -1) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionConfirmOrderWithoutCall(condition) {
    return parseInt(state.orderForm.confirm_order_without_call) === parseInt(condition.data.value)
  }

  function checkConditionContactlessDelivery(condition) {
    return parseInt(state.orderForm.contactless_delivery) === parseInt(condition.data.value)
  }

  function checkConditionIsBDay(condition) {
    return parseInt(state.orderForm.is_bday) === parseInt(condition.data.value)
  }

  function checkConditionBDate(condition) {
    if(!client.bday) return false
    return compareConditionDate(condition, client.bday)
  }

  function checkConditionAge(condition) {
    if(!client.bday) return false

    let currentDate = new Date()
    currentDate.setHours(0,0,0,0)
    const clientBDateDMY = client.bday
    const clientBDateYear = clientBDateDMY.split('.')[2]
    const clientBDateMonth = clientBDateDMY.split('.')[1]-1
    const clientBDateDay = clientBDateDMY.split('.')[0]
    const clientBDateDate = new Date(clientBDateYear, clientBDateMonth, clientBDateDay)
    
    const ageDifMs = currentDate.getTime() - clientBDateDate.getTime()
    const ageDate = new Date(ageDifMs) // miliseconds from epoch
    const age = Math.abs(ageDate.getUTCFullYear() - 1970)

    return compareConditionNumber(condition, age)
  }

  function checkConditionDelivery(condition) {
    const is_delivery = (state.orderForm.delivery === 'delivery') ? 1 : 0
    return  is_delivery === parseInt(condition.data.value)
  }

  function checkConditionProductsCount(condition) {
    let isChecked = false
    let productsCount = 0

    const filteredItems = filterProducts(condition)

    filteredItems.forEach(item => {
      productsCount += parseInt(item.data.quantity)
    })

    isChecked = compareConditionNumber(condition, productsCount)
    // if(condition.data.logic === 'EQUAL') {
    //   if(productsCount === parseInt(condition.data.value)) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'NOT_EQUAL') {
    //   if(productsCount !== parseInt(condition.data.value)) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'LESS') {
    //   if(productsCount < parseInt(condition.data.value)) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'GREATER') {
    //   if(productsCount > parseInt(condition.data.value)) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'LESS_OR_EQUAL') {
    //   if(productsCount <= parseInt(condition.data.value)) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'GREATER_OR_EQUAL') {
    //   if(productsCount >= parseInt(condition.data.value)) {
    //     isChecked = true
    //   }
    // }
    return isChecked
  }

  function checkConditionProductsSum(condition) {
    let isChecked = false
    let productsSum = 0

    const filteredItems = filterProducts(condition)

    filteredItems.forEach(item => {
      productsSum += (Number(item.data.price) + calcProductOptionsSum(item)) * parseInt(item.data.quantity) // TODO options?
    })

    isChecked = compareConditionNumber(condition, productsSum)
    // if(condition.data.logic === 'EQUAL') {
    //   if(productsSum === Number(condition.data.value)) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'NOT_EQUAL') {
    //   if(productsSum !== Number(condition.data.value)) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'LESS') {
    //   if(productsSum < Number(condition.data.value)) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'GREATER') {
    //   if(productsSum > Number(condition.data.value)) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'LESS_OR_EQUAL') {
    //   if(productsSum <= Number(condition.data.value)) {
    //     isChecked = true
    //   }
    // } else if(condition.data.logic === 'GREATER_OR_EQUAL') {
    //   if(productsSum >= Number(condition.data.value)) {
    //     isChecked = true
    //   }
    // }
    return isChecked
  }

  function filterProducts(condition) {
    let filteredItems = []
    
    const all = condition.data.all
    if(all === 'OR') {
      filteredItems = []
    } else {
      filteredItems = state.products
    }
    condition.children.forEach((item, index, array) => {
      if(item.type) { // ! Добавил 12-06-2020
        let conditionItems = []
        if(item.type === 'product_id') {
          conditionItems = getConditionProduct(item)
        } else if(item.type === 'product_option_id') {
          conditionItems = getConditionProductOption(item)
        } else if(item.type === 'product_price') {
          conditionItems = getConditionProductPrice(item)
        } else if(item.type === 'product_count') {
          conditionItems = getConditionProductCount(item)
        } else if(item.type === 'product_sum') {
          conditionItems = getConditionProductSum(item)
        }

        if(all === 'OR') {
          filteredItems = productsConcat(filteredItems, conditionItems)
        } else {
          filteredItems = productsIntersect(filteredItems, conditionItems)
        }
      }
    })

    return filteredItems
  }

  function getConditionProduct(condition) {
    if(condition.data.logic === 'EQUAL') {
      return state.products.filter(item => condition.data.value.filter(condItem => parseInt(condItem) === parseInt(item.id)).length > 0)
    } else {
      return state.products.filter(item => condition.data.value.filter(condItem => parseInt(condItem) === parseInt(item.id)).length === 0)
    }
  }

  function getConditionProductOption(condition) {
    if(condition.data.logic === 'EQUAL') {
      return state.products.filter(item => condition.data.value.filter(condItem => item.data.options && item.data.options.filter(option => parseInt(condItem) === parseInt(option.id)).length > 0).length > 0)
    } else {
      return state.products.filter(item => condition.data.value.filter(condItem => item.data.options && item.data.options.filter(option => parseInt(condItem) === parseInt(option.id)).length > 0).length === 0)
    }
  }

  function getConditionProductPrice(condition) {
    if(condition.data.logic === 'EQUAL') {
      return state.products.filter(item => Number(item.data.price) === Number(condition.data.value))
    } else if(condition.data.logic === 'NOT_EQUAL') {
      return state.products.filter(item => Number(item.data.price) !== Number(condition.data.value))
    } else if(condition.data.logic === 'LESS') {
      return state.products.filter(item => Number(item.data.price) < Number(condition.data.value))
    } else if(condition.data.logic === 'GREATER') {
      return state.products.filter(item => Number(item.data.price) > Number(condition.data.value))
    } else if(condition.data.logic === 'LESS_OR_EQUAL') {
      return state.products.filter(item => Number(item.data.price) <= Number(condition.data.value))
    } else if(condition.data.logic === 'GREATER_OR_EQUAL') {
      return state.products.filter(item => Number(item.data.price) >= Number(condition.data.value))
    }
  }

  function getConditionProductCount(condition) {
    if(condition.data.logic === 'EQUAL') {
      return state.products.filter(item => parseInt(item.data.quantity) === parseInt(condition.data.value))
    } else if(condition.data.logic === 'NOT_EQUAL') {
      return state.products.filter(item => parseInt(item.data.quantity) !== parseInt(condition.data.value))
    } else if(condition.data.logic === 'LESS') {
      return state.products.filter(item => parseInt(item.data.quantity) < parseInt(condition.data.value))
    } else if(condition.data.logic === 'GREATER') {
      return state.products.filter(item => parseInt(item.data.quantity) > parseInt(condition.data.value))
    } else if(condition.data.logic === 'LESS_OR_EQUAL') {
      return state.products.filter(item => parseInt(item.data.quantity) <= parseInt(condition.data.value))
    } else if(condition.data.logic === 'GREATER_OR_EQUAL') {
      return state.products.filter(item => parseInt(item.data.quantity) >= parseInt(condition.data.value))
    }
  }

  function getConditionProductSum(condition) {
    if(condition.data.logic === 'EQUAL') {
      return state.products.filter(item => ((Number(item.data.price) + calcProductOptionsSum(item)) * parseInt(item.data.quantity)) === Number(condition.data.value))
    } else if(condition.data.logic === 'NOT_EQUAL') {
      return state.products.filter(item => ((Number(item.data.price) + calcProductOptionsSum(item)) * parseInt(item.data.quantity)) !== Number(condition.data.value))
    } else if(condition.data.logic === 'LESS') {
      return state.products.filter(item => ((Number(item.data.price) + calcProductOptionsSum(item)) * parseInt(item.data.quantity)) < Number(condition.data.value))
    } else if(condition.data.logic === 'GREATER') {
      return state.products.filter(item => ((Number(item.data.price) + calcProductOptionsSum(item)) * parseInt(item.data.quantity)) > Number(condition.data.value))
    } else if(condition.data.logic === 'LESS_OR_EQUAL') {
      return state.products.filter(item => ((Number(item.data.price) + calcProductOptionsSum(item)) * parseInt(item.data.quantity)) <= Number(condition.data.value))
    } else if(condition.data.logic === 'GREATER_OR_EQUAL') {
      return state.products.filter(item => ((Number(item.data.price) + calcProductOptionsSum(item)) * parseInt(item.data.quantity)) >= Number(condition.data.value))
    }
  }

  function calcProductOptionsSum(product) {
    const productOptionsSum = [].concat(product.data.options)
      .filter(item => !!item)
      .reduce((sum, item) => Number(sum) + Number(item.price), 0)
    return Number(productOptionsSum)
  }

  function productsConcat(oldItems, newItems) {
    newItems.forEach(newItem => {
      if(oldItems.filter(oldItem => parseInt(oldItem.id) === parseInt(newItem.id)).length == 0) {
        oldItems.push(newItem)
      }
    })
    return oldItems
  }

  function productsIntersect(oldItems, newItems) {
    return oldItems.filter(oldItem => newItems.filter(newItem => parseInt(newItem.id) === parseInt(oldItem.id)).length > 0)
  }

  // Отказался от условия 'order_total'
  function checkConditionOrderTotal(condition) {
    const orderTotal = Number(state.orderForm.total)
    return compareConditionNumber(condition, orderTotal)
  }

  function checkConditionOrderSum(condition) {
    const orderSum = Number(state.orderForm.sum)
    return compareConditionNumber(condition, orderSum)
  }

  function checkConditionFirstOrder(condition) {
    if(typeof client === 'undefined' || !client.id) return false
    let isChecked = false
    if(condition.data.value == 1) {
      isChecked = typeof client.last_order === 'undefined' || !client.last_order.id
    } else {
      isChecked = typeof client.last_order !== 'undefined' && client.last_order.id > 0
    }
    return isChecked
  }

  function checkConditionClientExternalCrmId(condition) {
    if(typeof client === 'undefined' || !client.id) return false
    let isChecked = false
    if(condition.data.value == 1) {
      isChecked = client.external_crm_id
    } else {
      isChecked = !client.external_crm_id
    }
    return isChecked
  }

  function checkConditionLastOrder(condition) {
    if(!condition.children.length) return false
    if(!client.last_order || !client.last_order.id) return false
    
    let isChecked = false
    const all = condition.data.all
    if(all === 'OR') {
      isChecked = false
    } else {
      isChecked = true
    }
    condition.children.forEach((item, index, array) => {
      if(item.type === 'last_order_sum') {
        const lastOrderSum = Number(client.last_order.sum)
        if(all === 'OR') {
          if(compareConditionNumber(item, lastOrderSum)) isChecked = true
        } else {
          if(!compareConditionNumber(item, lastOrderSum)) isChecked = false
        }
      } else if(item.type === 'last_order_total') {
        const lastOrderTotal = Number(client.last_order.total)
        if(all === 'OR') {
          if(compareConditionNumber(item, lastOrderTotal)) isChecked = true
        } else {
          if(!compareConditionNumber(item, lastOrderTotal)) isChecked = false
        }
      } else if(item.type === 'last_order_date') {
        const lastOrderDate = client.last_order.date
        if(all === 'OR') {
          if(compareConditionDate(item, lastOrderDate)) isChecked = true
        } else {
          if(!compareConditionDate(item, lastOrderDate)) isChecked = false
        }
      } else if(item.type === 'last_order_days') {
        let currentDate = new Date()
        currentDate.setHours(0,0,0,0)
        const lastOrderDMY = client.last_order.date.split(' ')[0]
        const lastOrderYear = lastOrderDMY.split('.')[2]
        const lastOrderMonth = lastOrderDMY.split('.')[1]-1
        const lastOrderDay = lastOrderDMY.split('.')[0]
        const lastOrderDate = new Date(lastOrderYear, lastOrderMonth, lastOrderDay)
        const lastOrderDays = Math.ceil((currentDate.getTime() - lastOrderDate.getTime())  / (1000 * 3600 * 24))
        // console.log('lastOrderDays', lastOrderDays)
        if(all === 'OR') {
          if(compareConditionNumber(item, lastOrderDays)) isChecked = true
        } else {
          if(!compareConditionNumber(item, lastOrderDays)) isChecked = false
        }
      }
    })

    return isChecked
  }

  function compareConditionNumber(condition, value) {
    if(condition.data.logic === 'EQUAL') {
      return Number(value) === Number(condition.data.value)
    } else if(condition.data.logic === 'NOT_EQUAL') {
      return Number(value) !== Number(condition.data.value)
    } else if(condition.data.logic === 'LESS') {
      return Number(value) < Number(condition.data.value)
    } else if(condition.data.logic === 'GREATER') {
      return Number(value) > Number(condition.data.value)
    } else if(condition.data.logic === 'LESS_OR_EQUAL') {
      return Number(value) <= Number(condition.data.value)
    } else if(condition.data.logic === 'GREATER_OR_EQUAL') {
      return Number(value) >= Number(condition.data.value)
    }
  }

  function compareConditionDate(condition, value) {

    const conditionYear = condition.data.value.split('.')[2]
    const conditionMonth = condition.data.value.split('.')[1]-1
    const conditionDay = condition.data.value.split('.')[0]
    const conditionDate = new Date(conditionYear, conditionMonth, conditionDay)

    let valueDate = null
    if(typeof value === 'undefined' || value === false) {
      valueDate = new Date()
      valueDate.setHours(0,0,0,0)
    } else {
      const valueDMY = value.split(' ')[0]
      const valueYear = valueDMY.split('.')[2]
      const valueMonth = valueDMY.split('.')[1]-1
      const valueDay = valueDMY.split('.')[0]
      valueDate = new Date(valueYear, valueMonth, valueDay)
    }

    if(condition.data.logic === 'EQUAL') {
      return valueDate.valueOf() === conditionDate.valueOf()
    } else if(condition.data.logic === 'NOT_EQUAL') {
      return valueDate.valueOf() !== conditionDate.valueOf()
    } else if(condition.data.logic === 'LESS') {
      return valueDate.valueOf() < conditionDate.valueOf()
    } else if(condition.data.logic === 'GREATER') {
      return valueDate.valueOf() > conditionDate.valueOf()
    } else if(condition.data.logic === 'LESS_OR_EQUAL') {
      return valueDate.valueOf() <= conditionDate.valueOf()
    } else if(condition.data.logic === 'GREATER_OR_EQUAL') {
      return valueDate.valueOf() >= conditionDate.valueOf()
    }
  }

  function checkConditionPromocode(condition) {
    // if(state.basketRulesActions.promocodesDisable) return false

    if(condition.data.logic === 'NONE') {
      if(promocodesDisable || !state.promocode || !state.promocode.code) {
        return true
      } else {
        return false
      }
    }

    if(promocodesDisable) return false
    
    if(state.promocode && state.promocode.type && state.promocode.type.code === 'basket_rules') {
      // Изменил 2021-09-01 
      // Теперь state.promocodeCheckStatus.code === 'promocode_check_pre_success' (из checkPromocode)
      // А если warning - то промокод не активирован по условиям самого промокода
      // if(state.promocodeCheckStatus.code !== 'success') {
      if(state.promocodeCheckStatus.code === 'promocode_check_pre_success') {
        state.promocodeCheckStatus = {
          code: 'pre_success',
          text: 'Акция может быть активирована',
        }
      }
    }
    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(state.promocode && state.promocode.code && 
        condition.data.value.filter(item => state.promocode.code.toUpperCase() === item.toUpperCase()).length > 0 &&
        state.promocodeCheckStatus && ['success','pre_success'].indexOf(state.promocodeCheckStatus.code) != -1
      ) isChecked = true
    } else {
      if(state.promocode && state.promocode.code && 
        state.promocodeCheckStatus && ['success','pre_success'].indexOf(state.promocodeCheckStatus.code) != -1
      ) isChecked = true
    }
    return isChecked
  }

  function checkConditionPromocodeType(condition) {
    // Добавил 2021-09-01
    if(state.promocode && state.promocode.type && state.promocode.type.code === 'basket_rules') {
      if(state.promocodeCheckStatus.code === 'promocode_check_pre_success') {
        state.promocodeCheckStatus = {
          code: 'pre_success',
          text: 'Акция может быть активирована',
        }
      }
    }
    if(
      // state.basketRulesActions.promocodesDisable || 
      promocodesDisable || 
      !state.promocode || !state.promocode.type ||
      ['success','pre_success'].indexOf(state.promocodeCheckStatus.code) === -1
    ) return false
    
    const promocodeTypeCode = state.promocode.type.code
    
    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(condition.data.value.indexOf(promocodeTypeCode) !== -1) {
        isChecked = true
      }
    } else {
      if(condition.data.value.indexOf(promocodeTypeCode) === -1) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionPromocodeTags(condition) {
    // Добавил 2021-09-01
    if(state.promocode && state.promocode.type && state.promocode.type.code === 'basket_rules') {
      if(state.promocodeCheckStatus.code === 'promocode_check_pre_success') {
        state.promocodeCheckStatus = {
          code: 'pre_success',
          text: 'Акция может быть активирована',
        }
      }
    }
    if(
      // state.basketRulesActions.promocodesDisable || 
      promocodesDisable || 
      !state.promocode || !state.promocode.type ||
      ['success','pre_success'].indexOf(state.promocodeCheckStatus.code) === -1
    ) return false
      
    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      condition.data.value.forEach(tag_id => {
        if(state.promocode.tags && state.promocode.tags.indexOf(tag_id) !== -1) {
          isChecked = true
        }
      });
    } else {
      isChecked = true
      condition.data.value.forEach(tag_id => {
        if(state.promocode.tags && state.promocode.tags.indexOf(tag_id) !== -1) {
          isChecked = false
        }
      });
    }
    return isChecked
  }

  function checkConditionClientTags(condition) {      
    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      condition.data.value.forEach(tag_id => {
        if(client.tags && client.tags.indexOf(tag_id) !== -1) {
          isChecked = true
        }
      });
    } else {
      isChecked = true
      condition.data.value.forEach(tag_id => {
        if(client.tags && client.tags.indexOf(tag_id) !== -1) {
          isChecked = false
        }
      });
    }
    return isChecked
  }

  function checkConditionDeliveryArea(condition) {

    const deliveryAreaID = state.orderForm.delivery_area || ''
    
    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(condition.data.value.indexOf(deliveryAreaID) !== -1) {
        isChecked = true
      }
    } else {
      if(condition.data.value.indexOf(deliveryAreaID) === -1) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionPickupPoint(condition) {
      
    const pickupPointCode = state.orderForm.pickup_point || ''
    
    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(condition.data.value.indexOf(pickupPointCode) !== -1) {
        isChecked = true
      }
    } else {
      if(condition.data.value.indexOf(pickupPointCode) === -1) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionDeliveryType(condition) {
      
    const deliveryTypeCode = state.orderForm.delivery_type || ''
    
    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(condition.data.value.indexOf(deliveryTypeCode) !== -1) {
        isChecked = true
      }
    } else {
      if(condition.data.value.indexOf(deliveryTypeCode) === -1) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionPaymentType(condition) {
      
    const paymentTypeCode = state.orderForm.payment || ''
    
    let isChecked = false
    if(condition.data.logic === 'EQUAL') {
      if(condition.data.value.indexOf(paymentTypeCode) !== -1) {
        isChecked = true
      }
    } else {
      if(condition.data.value.indexOf(paymentTypeCode) === -1) {
        isChecked = true
      }
    }
    return isChecked
  }

  function checkConditionPreorderDays(condition) {
    console.log('checkConditionPreorderDays')
    let currentDate = new Date()
    currentDate.setHours(0,0,0,0)

    let preorderDate = new Date()
    if(state.orderForm.set_the_time == 'set' && state.orderForm.delivery_date) {
      const preorderDateDMY = state.orderForm.delivery_date
      const preorderDateYear = preorderDateDMY.split('.')[2]
      const preorderDateMonth = preorderDateDMY.split('.')[1]-1
      const preorderDateDay = preorderDateDMY.split('.')[0]
      preorderDate = new Date(preorderDateYear, preorderDateMonth, preorderDateDay)
    }
    preorderDate.setHours(0,0,0,0)

    const diff = Math.round((preorderDate-currentDate)/(1000*60*60*24))

    return compareConditionNumber(condition, diff)
  }

  function checkConditionPreorderMinutes(condition) {
    let currentDateTime = new Date()
    
    let preorderDateTime = new Date()
    if(state.orderForm.set_the_time == 'set' && state.orderForm.delivery_date && state.orderForm.delivery_time) {
      const preorderDateDMY = state.orderForm.delivery_date
      const preorderDateYear = preorderDateDMY.split('.')[2]
      const preorderDateMonth = preorderDateDMY.split('.')[1]-1
      const preorderDateDay = preorderDateDMY.split('.')[0]
      const preorderDateHM = state.orderForm.delivery_time
      const preorderDateHour = preorderDateHM.split(':')[0]
      const preorderDateMinute = preorderDateHM.split(':')[1]
      preorderDateTime = new Date(preorderDateYear, preorderDateMonth, preorderDateDay, preorderDateHour, preorderDateMinute)
    }

    const diff = Math.round((preorderDateTime-currentDateTime)/(1000*60))

    return compareConditionNumber(condition, diff)
  }

  function checkConditionOrdersCount(condition) {
    // if(!client.last_orders || !client.last_orders[condition.data.months]) return false
    const count = _.get(client, 'last_orders['+condition.data.months+'].count', 0)
    return compareConditionNumber(condition, count)
  }

  function checkConditionOrdersSum(condition) {
    // if(!client.last_orders || !client.last_orders[condition.data.months]) return false
    const sum = _.get(client, 'last_orders['+condition.data.months+'].sum', 0)
    return compareConditionNumber(condition, sum)
  }

  function checkConditionPlatformOrdersCount(condition) {
    let count = 0
    if(condition.data.platform === 'all') {
      count = _.get(client, 'last_orders['+condition.data.months+'].count', 0)
    } else {
      count = _.get(client, 'last_orders['+condition.data.months+']['+condition.data.platform+'_count]', 0)
    }
    return compareConditionNumber(condition, count)
  }

  function checkConditionBasketRule(condition) {
    let isChecked = false
    if(condition.data.logic === 'CHECKED') {
      if(checkedBasketRules.indexOf(condition.data.value) !== -1) {
        isChecked = true
      }
    } else {
      if(checkedBasketRules.indexOf(condition.data.value) === -1) {
        isChecked = true
      }
    }
    return isChecked
  }

  /// ACTIONS ///

  function doAction(action) {
    if(action.type === 'products_recommend') {
      doActionRecommends(action)
    } else if(action.type === 'products_free') {
      doActionProductsFree(action)
    } else if(action.type === 'products_gift') {
      doActionProductsGift(action)
    } else if(action.type === 'promocodes_disable') {
      doActionPromocodesDisable(action)
    } else if(action.type === 'gifts_disable') {
      doActionGiftsDisable(action)
    } else if(action.type === 'gifts_sum_disable') {
      doActionGiftsSumDisable(action)
    } else if(action.type === 'discounts_disable') {
      doActionDiscountsDisable(action)
    } else if(action.type === 'free_delivery_disable') {
      doActionFreeDeliveryDisable(action)
    } else if(action.type === 'activate_promocode') {
      doActionActivatePromocode(action)
    } else if(action.type === 'deactivate_promocode') {
      doActionDeactivatePromocode(action)
    } else if(action.type === 'products_discount') {
      doActionProductsDiscount(action)
    } else if(action.type === 'products_fix_price') {
      doActionProductsFixPrice(action)
    } else if(action.type === 'products_cheapest_free') {
      doActionProductsCheapestFree(action)
    } else if(action.type === 'products_cheapest_discount') {
      doActionProductsCheapestDiscount(action)
    } else if(action.type === 'order_conditions') {
      doActionOrderConditions(action)
    } else if(action.type === 'payment_types') {
      doActionPaymentTypes(action)
    } else if(action.type === 'payment_bonus_disable') {
      doActionPaymentBonusDisable(action)
    } else if(action.type === 'delivery_types') {
      doActionDeliveryTypes(action)
    } else if(action.type === 'products_required') {
      doActionProductsRequired(action)
    } else if(action.type === 'calc_products') {
      doActionCalcProducts(action)
    } else if(action.type === 'calc') {
      doActionCalc(action)
    } else if(action.type === 'close') {
      doActionClose(action)
    } else if(action.type === 'message') {
      doActionMessage(action)
    }
  }

  function doActionRecommends(action) {
    action.data.products.forEach((item, index, array) => {
      productsRecommends.push(parseInt(item))
    })
  }

  function doActionProductsFree(action) {
    let quantity = 0
    // Если переменная
    if(isNaN(action.data.quantity)) {
      quantity = Number(variables[action.data.quantity])
    } else {
      quantity = action.data.quantity
    }
    if(quantity > 0) {
      const productFree = {
        id: action.data.product,
        data: {
          basket_rule_id: basket_rule_id,
          action_title: action.data.title,
          quantity: parseInt(quantity),
        }
      }
      productsFree.push(productFree)
    }
  }

  function doActionProductsGift(action) {
    let quantity = 0
    // Если переменная
    if(isNaN(action.data.quantity)) {
      quantity = Number(variables[action.data.quantity])
    } else {
      quantity = action.data.quantity
    }
    if(quantity > 0) {
      const productsGiftSet = {
        random_id: Math.random(),
        basket_rule_id: basket_rule_id,
        action_title: action.data.title || ((quantity > 1) ? 'Подарки на выбор' : 'Подарок на выбор'),
        products: action.data.products,
        max_count: quantity,
        selected: [],
      }
      productsGiftSets.push(productsGiftSet)
    }
  }

  function doActionPromocodesDisable(action) {
    isDev && console.log('doActionPromocodesDisable')
    promocodesDisable = true

    // const status = {
    //   code: 'clear',
    // }
    // Vue.set(state, 'promocodeStatus', status)
    // Отключим любые промокоды
    state.promocodeCheckStatus = {
      code: 'warning',
      description: 'Все промокоды отключены.'
    }
    // Vue.set(state, 'promocode', {})
  }

  function doActionGiftsDisable(action) {
    isDev && console.log('doActionGiftsDisable')
    giftsDisable = true
    // Vue.set(state, 'gifts', [])
  }

  function doActionGiftsSumDisable(action) {
    isDev && console.log('doActionGiftsSumDisable')
    giftsSumDisable = true
    state.gifts = []
  }

  function doActionDiscountsDisable(action) {
    isDev && console.log('doActionDiscountsDisable')
    discountsDisable = true
    // 
    // Отключим промокоды со скидкой
    if(state.promocode && state.promocode.type && ['discount','amount'].indexOf(state.promocode.type.code) != -1) {
      state.promocodeCheckStatus = {
        code: 'warning',
        description: 'Все скидки отключены.'
      }
    }
  }

  function doActionFreeDeliveryDisable(action) {
    isDev && console.log('doActionFreeDeliveryDisable')
    freeDeliveryDisable = true
    // 
  }

  function doActionActivatePromocode(action) {
    if(state.promocodeCheckStatus && state.promocodeCheckStatus.code && state.promocodeCheckStatus.code === 'pre_success') {
      state.promocodeCheckStatus = {
        code: 'success',
        text: 'Акция активирована',
      }
    }
  }

  function doActionDeactivatePromocode(action) {
    if(state.promocodeCheckStatus && state.promocodeCheckStatus.code && (state.promocodeCheckStatus.code === 'pre_success' || state.promocodeCheckStatus.code === 'promocode_check_pre_success')) {
      state.promocodeCheckStatus = {
        code: 'warning',
        text: 'Акция не активирована',
      }
    }
  }

  function doActionProductsDiscount(action) {
    const filteredItems = filterProducts(action)
    const products = filteredItems.map(item => item.id)
    // console.log('!!!', filteredItems)
    discounts.push({
      basket_rule_id: basket_rule_id,
      title: action.data.title,
      products: products,
      unit: action.data.unit,
      value: action.data.value,
    })
    // console.log('discounts', discounts)
  }

  function doActionProductsFixPrice(action) {
    if(action.children && action.children.length > 0) {
      let fixPrice = {
        basket_rule_id: basket_rule_id,
        title: action.data.title,
        value: action.data.value,
        products_groups: [],
      }
      action.children.forEach(group => {
        if(group.data.value && group.data.value.length > 0) {
          fixPrice.products_groups.push({
            products: group.data.value,
            count: group.data.count,
          })
        }
      })
      if(fixPrice.products_groups.length > 0) {
        fixPrices.push(fixPrice)
      }
    }
  }

  function doActionProductsCheapestFree(action) {
    const filteredItems = filterProducts(action)
    const products = filteredItems.map(item => item.id)
    // console.log('!!!', filteredItems)
    let quantity = 0
    // Если переменная
    if(isNaN(action.data.value)) {
      quantity = Number(variables[action.data.value])
    } else {
      quantity = action.data.value
    }
    if(products && quantity > 0) {
      cheapestFree.push({
        basket_rule_id: basket_rule_id,
        products: products,
        value: quantity,
        title: action.data.title,
      })
    }
  }

  function doActionProductsCheapestDiscount(action) {
    const filteredItems = filterProducts(action)
    const products = filteredItems.map(item => item.id)
    // console.log('!!!', filteredItems)
    let quantity = 0
    // Если переменная
    if(isNaN(action.data.count)) {
      quantity = Number(variables[action.data.count])
    } else {
      quantity = action.data.count
    }
    if(products && quantity > 0) {
      cheapestDiscounts.push({
        basket_rule_id: basket_rule_id,
        products: products,
        count: quantity, // количество товаров со скидкой
        unit: action.data.unit,
        value: action.data.value, // величина скидки
        title: action.data.title,
      })
    }
    // console.log('discounts', discounts)
  }

  function doActionOrderConditions(action) {
    action.data.conditions.forEach(condition => {
      if(condition.value.length > 0 && !isNaN(condition.value)) {
        orderConditions[condition.code] = Number(condition.value)
      }
    })
  }

  function doActionPaymentTypes(action) {
    action.data.types.filter(type => type.value).map(type => {
      if(paymentTypes.indexOf(type.code) == -1) {
        paymentTypes.push(type.code)
      }
    })
  }

  function doActionPaymentBonusDisable(action) {
    isDev && console.log('doActionPaymentBonusDisable')
    paymentBonusDisable = true
  }

  function doActionDeliveryTypes(action) {
    action.data.types.filter(type => type.value).map(type => {
      if(deliveryTypes.indexOf(type.code) == -1) {
        deliveryTypes.push(type.code)
      }
    })
  }

  function doActionProductsRequired(action) {
    let quantity = 0
    // Если переменная
    if(isNaN(action.data.quantity)) {
      quantity = Number(variables[action.data.quantity])
    } else {
      quantity = action.data.quantity
    }
    if(action.data.product && quantity > 0) {
      productsRequired.push({
        id: action.data.product,
        quantity: quantity,
        title: action.data.title,
      })
    }  
  }

  function doActionCalcProducts(action) {
    if(!action.data.var) return false
    const filteredItems = filterProducts(action)
    
    let result = 0
    if(action.data.unit === 'count') {
      let productsCount = 0
      filteredItems.forEach(item => {
        productsCount += parseInt(item.data.quantity)
      })
      result = productsCount
    } else if(action.data.unit === 'sum') {
      let productsSum = 0
      filteredItems.forEach(item => {
        productsSum += (Number(item.data.price) + calcProductOptionsSum(item)) * parseInt(item.data.quantity) // TODO options?
      })
      result = productsSum
    }

    variables[action.data.var] = result
  }

  function doActionCalc(action) {
    if(!action.data.var) return false
    
    let result = 0
    let operator = 'operator_plus' // Начинаем всегда с 0+
    action.children.forEach(item => {
      let curValue = null
      // Если переменная
      if(item.type === 'var') {
        curValue = Number(variables[item.data.value])
      } else if(item.type === 'number') {
        curValue = Number(item.data.value)
      } else {
        operator = item.type
      }
      if(curValue !== null) {
        if(operator === 'operator_plus') {
          result += curValue
        } else if(operator === 'operator_minus') {
          result -= curValue
        } else if(operator === 'operator_divide') {
          if(curValue) {
            result = result / curValue
          } else {
            result = 0
          }
        } else if(operator === 'operator_multiply') {
          result = result * curValue
        }
        operator = ''
      }
      if(operator === 'operator_round_down') {
        result = Math.floor(result)
        operator = ''
      } else if(operator === 'operator_round_up') {
        result = Math.ceil(result)
        operator = ''
      }
    })

    variables[action.data.var] = result
  }

  function doActionClose(action) {
    const title = action.data.title || 'Приём заказов приостановлен'
    // console.log('doActionClose', title)
    close = {
      title: title,
    }
  }

  function doActionMessage(action) {
    if((action.data.title || action.data.description) && action.data.place) {
      messages.push({
        type: action.data.type || 'default',
        place: action.data.place,
        title: action.data.title,
        description: action.data.description,
      })
    }
  }

  // К уже существующим в стейте добавим новые. А старые из стейта удалим.
  const oldGiftSets = state.basketRulesActions.giftSets
    .filter(item => {
      return productsGiftSets.filter(item2 => item2.basket_rule_id === item.basket_rule_id && item2.max_count === item.max_count).length > 0 
    })
  const newGiftSets = productsGiftSets.filter(item => {
      return state.basketRulesActions.giftSets.filter(item2 => item2.basket_rule_id === item.basket_rule_id && item2.max_count === item.max_count).length == 0
    })

  const basketRulesActions = {
    recommends: productsRecommends,
    free: (!state.promocode.is_stop_actions) ? productsFree : [],
    giftSets: (!giftsDisable && !state.promocode.is_stop_actions) ? oldGiftSets.concat(newGiftSets) : [],
    promocodesDisable: promocodesDisable,
    giftsDisable: giftsDisable,
    giftsSumDisable: giftsSumDisable,
    discountsDisable: discountsDisable,
    freeDeliveryDisable: freeDeliveryDisable,
    discounts: (!state.promocode.is_finish) ? discounts : [], // TODO мб добавить is_finish по подобию is_stop_acctions ???
    fixPrices: (!state.promocode.is_finish) ? fixPrices : [], // TODO мб добавить is_finish по подобию is_stop_acctions ???
    cheapestFree: (!state.promocode.is_stop_actions) ? cheapestFree : [],
    cheapestDiscounts: (!state.promocode.is_finish) ? cheapestDiscounts : [], // TODO мб добавить is_finish по подобию is_stop_acctions ???
    orderConditions: orderConditions,
    paymentTypes: paymentTypes,
    paymentBonusDisable: paymentBonusDisable,
    deliveryTypes: deliveryTypes,
    productsRequired: productsRequired,
    close: close,
    messages: messages,
  }

  // console.log(basketRulesActions)

  return {
    ...state,
    basketRulesActions,
  }
}