// import { AsyncStorage } from 'react-native'
import { HTTP } from '../../utils/http-common';
import { ERROR } from '../../utils/errors'

import {
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_ERROR,

    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_ERROR,

    GET_CLIENT_REQUEST,
    GET_CLIENT_SUCCESS,
    GET_CLIENT_ERROR,

    UPD_CLIENT_REQUEST,
    UPD_CLIENT_SUCCESS,
    UPD_CLIENT_ERROR,

    CLIENT_CLEAR,

    SMS_CODE_REQUEST,
    SMS_CODE_SUCCESS,
    SMS_CODE_ERROR,

    CHECK_PHONE_REQUEST,
    CHECK_PHONE_SUCCESS,
    CHECK_PHONE_ERROR,

    SET_PHONE_REQUEST,
    SET_PHONE_SUCCESS,
    SET_PHONE_ERROR,

    SAVE_TOKEN,
    CLEAR_TOKEN,

    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_ERROR,

    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,

    GET_ADDRESSES_REQUEST,
    GET_ADDRESSES_SUCCESS,
    GET_ADDRESSES_ERROR,

    GET_ADDRESS_REQUEST,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_ERROR,

    SAVE_ADDRESS_REQUEST,
    SAVE_ADDRESS_SUCCESS,
    SAVE_ADDRESS_ERROR,

    DELETE_ADDRESS_REQUEST,
    DELETE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_ERROR,

    CHECK_PROMOCODE,
    CHECK_BASKET_RULES,

} from "../ActionTypes";

function commitAction(type, data) {
    return { type, data }
}

export function getSmsCode(affiliate_id, phone) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            if (!affiliate_id) {
                let err = ERROR.make(400, "Invalid parameter: affiliate_id")
                dispatch(commitAction(SMS_CODE_ERROR, err))
                reject(err)
                return;
            }
            if (!phone) {
                let err = ERROR.make(400, "Invalid parameter: phone")
                dispatch(commitAction(SMS_CODE_ERROR, err))
                reject(err)
                return;
            }
            dispatch(commitAction(SMS_CODE_REQUEST))

            HTTP.get("checkphone", { params: { affiliate_id, phone } })
                .then(resp => {
                    // console.log(resp.data)
                    dispatch(commitAction(SMS_CODE_SUCCESS, resp.data.data))
                    resolve(resp.data.data)
                })
                .catch(err => {
                    // console.log(err)
                    dispatch(commitAction(SMS_CODE_ERROR, err))
                    reject(err)
                })
        })
    }
}

export function checkPhone(affiliate_id, phone, code) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            if (!affiliate_id) {
                let err = ERROR.make(400, "Invalid parameter: affiliate_id")
                dispatch(commitAction(CHECK_PHONE_ERROR, err))
                reject(err)
                return;
            }
            if (!phone) {
                let err = ERROR.make(400, "Invalid parameter: phone")
                dispatch(commitAction(CHECK_PHONE_ERROR, err))
                reject(err)
                return;
            }
            if (!code) {
                let err = ERROR.make(400, "Invalid parameter: code")
                dispatch(commitAction(CHECK_PHONE_ERROR, err))
                reject(err)
                return;
            }
            dispatch(commitAction(CHECK_PHONE_REQUEST))
            dispatch(commitAction(CLEAR_TOKEN))

            HTTP.post("checkphone", { affiliate_id, phone, code })
                .then(resp => {
                    // console.log(resp.data)
                    dispatch(commitAction(CHECK_PHONE_SUCCESS, resp.data.data))
                    // AsyncStorage.setItem('token', resp.data.token)
                    dispatch(commitAction(SAVE_TOKEN, resp.data.token))
                    resolve(resp.data)
                })
                .catch(err => {
                    // console.log(err)
                    dispatch(commitAction(CHECK_PHONE_ERROR, err))
                    reject(err)
                })
        })
    }
}

export function setPhone ({affiliate_id, phone}) {
  return function(dispatch, getState) {
    const { app } = getState()
    return new Promise((resolve, reject) => {
      if(!affiliate_id) {
        let err = ERROR.make(400, {text: "Invalid parameters", options: {params: "affiliate_id"}})
        dispatch(commitAction(GET_CLIENT_ERROR, err))
        reject(err)
        return
      }
      dispatch(commitAction(SET_PHONE_REQUEST))
      HTTP.get("client", {params: {affiliate_id, phone}})
      .then(resp => {
        dispatch(commitAction(GET_CLIENT_SUCCESS, resp.data.data))
        dispatch(commitAction(CHECK_BASKET_RULES, {
          client: resp.data.data,
          source: app.source,
        }))
        dispatch(commitAction(SET_PHONE_SUCCESS))

        // const ymID = rootGetters['affiliates/item']['yandex_metrika'] || 0
        // if(ymID > 0 && typeof ym !== 'undefined') {
        //   ym(ymID, 'setUserID', resp.data.data.id.toString())
        //   const ymParams = {
        //     isNewClient: !resp.data.data.last_order || !resp.data.data.last_order.id
        //   }
        //   ym(ymID, 'params', ymParams)
        // }

        resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(GET_CLIENT_ERROR, err))
        dispatch(commitAction(SET_PHONE_ERROR, err))
        reject(err)
      })
    })
  }
}

export function checkToken() {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            // AsyncStorage.getItem('token')
            //     .then(data => {
            //         // console.log(data)
            //         if (data) {
            //             dispatch(commitAction(SAVE_TOKEN, data))
            //         }
            //         resolve(data)
            //     })
            //     .catch(err => {
            //         // console.log(err)
            //         reject(err)
            //     })
        })
    }
}

export function checkVkMiniAppSign (QueryString) {  
  return function(dispatch) {
    return new Promise((resolve, reject) => {
      HTTP.post("checkvkminiappsign", {QueryString})
      .then(resp => {
        resolve(resp.data)
      })
      .catch(err => {
        reject(err)
      })
    })
  }
}

export function vkAuth (vk_user) {
  return function(dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(commitAction(AUTH_REQUEST))
      HTTP.post("vkauth", {vk_user})
      .then(resp => {
        dispatch(commitAction(AUTH_SUCCESS, resp.data))
        resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(AUTH_ERROR, err))
        reject(err)
      })
    })
  }
}

export function logout() {
    return function(dispatch) {
        dispatch(commitAction(CLEAR_TOKEN))
        dispatch(commitAction(CHECK_PROMOCODE))
    }
}

export function getUser() {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            dispatch(commitAction(GET_USER_REQUEST))

            HTTP.get("user")
                .then(resp => {
                    // console.log(resp.data)
                    dispatch(commitAction(GET_USER_SUCCESS, resp.data.data))
                    resolve(resp.data.data)
                })
                .catch(err => {
                    // console.log(err)
                    dispatch(commitAction(GET_USER_ERROR, err))
                    reject(err)
                })
        })
    }
}

export function getClient({affiliate_id, user_id, phone, vk_user_id}) {
    return function(dispatch, getState) {
        const { app } = getState()
        return new Promise((resolve, reject) => {
            if (!affiliate_id) {
                let err = ERROR.make(400, "Invalid parameter: affiliate_id")
                dispatch(commitAction(GET_CLIENT_ERROR, err))
                reject(err)
                return;
            }
            // if(!user_id) {
            //   let err = ERROR.make(400, "Invalid parameter: user_id")
            //   dispatch(commitAction(GET_CLIENT_ERROR, err))
            //   reject(err)
            //   return;
            // }
            dispatch(commitAction(GET_CLIENT_REQUEST))

            HTTP.get("client", { params: { affiliate_id, user_id, phone, vk_user_id } })
                .then(resp => {
                    // console.log(resp.data)
                    dispatch(commitAction(GET_CLIENT_SUCCESS, resp.data.data))
                    dispatch(commitAction(CHECK_PROMOCODE, {client: resp.data.data}))
                    dispatch(commitAction(CHECK_BASKET_RULES, {client: resp.data.data, source: app.source}))
                    
                    // const ymID = rootGetters['affiliates/item']['yandex_metrika'] || 0
                    // if(ymID > 0 && typeof ym !== 'undefined') {
                    //   ym(ymID, 'setUserID', resp.data.data.id.toString())
                    //   const ymParams = {
                    //     isNewClient: !resp.data.data.last_order || !resp.data.data.last_order.id
                    //   }
                    //   ym(ymID, 'params', ymParams)
                    // }
                    resolve(resp.data.data)
                })
                .catch(err => {
                    // console.log(err)
                    dispatch(commitAction(GET_CLIENT_ERROR, err))
                    reject(err)
                })
        })
    }
}

export function clearClient() {
  return function(dispatch) {
    dispatch(commitAction(CLIENT_CLEAR))
  }
}

export function onAllowMessagesFromCommunity ({affiliate_id, vk_group_id, vk_user}) {
  return function(dispatch) {
    return new Promise((resolve, reject) => {
      HTTP.post("onAllowMessagesFromCommunity", {affiliate_id, vk_group_id, vk_user})
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  }
}

export function updateClient(affiliate_id, data) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            if (!affiliate_id) {
                let err = ERROR.make(400, "Invalid parameter: affiliate_id")
                dispatch(commitAction(UPD_CLIENT_ERROR, err))
                reject(err)
                return;
            }
            dispatch(commitAction(UPD_CLIENT_REQUEST))
            HTTP.post("client", { affiliate_id, data })
                .then(resp => {
                    // console.log(resp.data)
                    dispatch(commitAction(UPD_CLIENT_SUCCESS, resp.data.data))
                    resolve(resp.data.data)
                })
                .catch(err => {
                    // console.log(err)
                    dispatch(commitAction(UPD_CLIENT_ERROR, err))
                    reject(err)
                })
        })
    }
}

export function getOrders(affiliate_id, count, offset) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            if (!affiliate_id) {
                let err = ERROR.make(400, "Invalid parameter: affiliate_id")
                dispatch(commitAction(GET_ORDERS_ERROR, err))
                reject(err)
                return;
            }
            dispatch(commitAction(GET_ORDERS_REQUEST))

            HTTP.get("client/orders", { params: { affiliate_id, count, offset } })
                .then(resp => {
                    // console.log('getOrders:', resp.data)
                    dispatch(commitAction(GET_ORDERS_SUCCESS, resp.data.data.items))
                    resolve(resp.data.data)
                })
                .catch(err => {
                    // console.log(err)
                    dispatch(commitAction(GET_ORDERS_ERROR, err))
                    reject(err)
                })
        })
    }
}

export function getOrder(affiliate_id, order_id) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            if (!affiliate_id) {
                let err = ERROR.make(400, "Invalid parameter: affiliate_id")
                dispatch(commitAction(GET_ORDER_ERROR, err))
                reject(err)
                return;
            }
            if (!order_id) {
                let err = ERROR.make(400, "Invalid parameter: order_id")
                dispatch(commitAction(GET_ORDER_ERROR, err))
                reject(err)
                return;
            }
            dispatch(commitAction(GET_ORDER_REQUEST))

            HTTP.get("client/orders/" + order_id, { params: { affiliate_id } })
                .then(resp => {
                    // console.log('getOrder:', resp.data)
                    dispatch(commitAction(GET_ORDER_SUCCESS, resp.data.data))
                    resolve(resp.data.data)
                })
                .catch(err => {
                    // console.log(err)
                    dispatch(commitAction(GET_ORDER_ERROR, err))
                    reject(err)
                })
        })
    }
}


export function getAddresses() {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            dispatch(commitAction(GET_ADDRESSES_REQUEST))

            HTTP.get("user/addresses")
                .then(resp => {
                    dispatch(commitAction(GET_ADDRESSES_SUCCESS, resp.data.data.items))
                    resolve(resp.data.data)
                })
                .catch(err => {
                    // console.log(err)
                    dispatch(commitAction(GET_ADDRESSES_ERROR, err))
                    reject(err)
                })
        })
    }
}

export function getAddress(address_id) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            if (!address_id) {
                let err = ERROR.make(400, "Invalid parameter: address_id")
                dispatch(commitAction(GET_ADDRESS_ERROR, err))
                reject(err)
                return;
            }
            dispatch(commitAction(GET_ADDRESS_REQUEST))

            HTTP.get("user/addresses/" + address_id)
                .then(resp => {
                    // console.log('getOrder:', resp.data)
                    dispatch(commitAction(GET_ADDRESS_SUCCESS, resp.data.data))
                    resolve(resp.data.data)
                })
                .catch(err => {
                    // console.log(err)
                    dispatch(commitAction(GET_ADDRESS_ERROR, err))
                    reject(err)
                })
        })
    }
}

export function saveAddress(affiliate_id, address_id, form) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            if (!affiliate_id) {
                let err = ERROR.make(400, "Invalid parameter: affiliate_id")
                dispatch(commitAction(SAVE_ADDRESS_ERROR, err))
                reject(err)
                return;
            }
            dispatch(commitAction(SAVE_ADDRESS_REQUEST))
            HTTP.post("user/addresses/" + address_id, { affiliate_id, form })
                .then(resp => {
                    // console.log(resp.data)
                    dispatch(commitAction(SAVE_ADDRESS_SUCCESS, resp.data))
                    resolve(resp.data)
                })
                .catch(err => {
                    console.log(err)
                    dispatch(commitAction(SAVE_ADDRESS_ERROR, err))
                    reject(err)
                })
        })
    }
}

export function deleteAddress(address_id) {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            if (!address_id) {
                let err = ERROR.make(400, "Invalid parameter: address_id")
                dispatch(commitAction(DELETE_ADDRESS_ERROR, err))
                reject(err)
                return;
            }
            dispatch(commitAction(DELETE_ADDRESS_REQUEST))
            HTTP.delete("user/addresses/" + address_id)
                .then(resp => {
                    // console.log(resp.data)
                    dispatch(commitAction(DELETE_ADDRESS_SUCCESS, resp.data))
                    resolve(resp.data)
                })
                .catch(err => {
                    // console.log(err)
                    dispatch(commitAction(DELETE_ADDRESS_ERROR, err))
                    reject(err)
                })
        })
    }
}