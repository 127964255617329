import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash';
import bridge from '@vkontakte/vk-bridge';
import { PanelHeaderContent, Gallery, Tooltip, PanelSpinner, Snackbar, View, Spinner, HorizontalScroll, HorizontalCell, usePlatform, Separator, SliderSwitch, Alert, PanelHeaderButton, Headline, Subhead, CardGrid, Card, ContentCard, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon16ChevronOutline, Icon20Check, Icon24DoNotDisturb, Icon24ClockOutline, Icon28Newsfeed, Icon24Place, Icon24Home, Icon20Dropdown, Icon28BlockOutline, Icon20FavoriteCircleFillYellow, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon28UnfavoriteOutline, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import TabsSelect from '../components/UI/TabsSelect';
import ProductCard from '../components/ProductCard';

import * as actionsActions from '../store/actions/actions';
import * as cartActions from '../store/actions/cart';

const Home = props => {

	if(_.get(props, 'affiliateStatus.code', '') === 'error') {
  	return (
  		<Placeholder stretched icon={<Icon28BlockOutline width={56} height={56} />}
        header="Ошибка!">
        { _.get(props, 'affiliateStatus.message', 'Филиал не найден') }
      </Placeholder>
  	)
  }

	if(!props.affiliate.id) {
  	return (
  		<Panel id={props.id}>
		    <PanelHeader>Загрузка...</PanelHeader>
	    </Panel>
  	)
  }

	const router = useRouter();
	const platform = usePlatform();

	const [snackbar, setSnackbar] = useState(null);
	
	useEffect(() => {
		async function getActions() {
			await props.dispatch(actionsActions.getActions(props.affiliate.id));
		}
		function initOrderForm() {
			let initOrderForm = _.cloneDeep(props.orderForm) || {}
	    if(!initOrderForm.delivery || !!props.affiliate.pickup_only) {
	      initOrderForm.delivery = (!!props.affiliate.pickup_only) ? 'self' : 'delivery'
	    }
	    if(!initOrderForm.pickup_point) {
	      // initOrderForm.pickup_point = _.get(props, 'affiliate.pickup_points[0].name')
	    }
	    if(!initOrderForm.address_district) {
	      initOrderForm.address_district = _.get(props, 'affiliate.districts[0].name')
	    }
	    props.dispatch(cartActions.saveOrderForm(initOrderForm));
		}
		if(props.affiliate.id) {
			if(props.actionsStatus.code !== 'success' && !props.actions.length) {
				getActions();
			}
			initOrderForm();
		}
	}, [props.affiliate]);

	const orderConditions = props.affiliate.order_conditions?.config ?? {}
	Object.keys(_.get(props.deliveryArea, 'order_conditions.config', {})).forEach(key => {
		orderConditions[key] = props.deliveryArea.order_conditions?.config[key]
	})

	const slidesPerView = Number(_.get(props, "affiliate.template['mobile.header.actions.slidesPerView']", 0)) || 1

	const topItems = props.products
    .filter(product => product.is_top > 0)
    .filter(item => !item.sku_for?.id || item.sku_for?.id == item.id)

  const topItemsElements = topItems.map(item => {
    const sku = props.products.filter(item2 => {
      return item.sku_ids.indexOf(item2.id) != -1
    })
    item.sku = sku.map(item => {
      return {
        "id": item.id,
        "price": item.price,
        "old_price": item.old_price,
        "weight": item.weight,
        "volume": item.volume,
        "size": item.size,
        "name": item.name,
        "preview_text": item.preview_text,
        "detail_text": item.detail_text,
        "property": item.sku_for.title,
        "img": item.img,
        "options_groups": item.options_groups,
        "nutritional": item.nutritional,
        "nutritional_total": item.nutritional_total,
      }
    })
    return (<ProductCard 
    	key={item.id}
    	product={item}
      isGift={false}
      isCanAdd
      onClick={() => props.onModal({type: 'PRODUCT_DETAIL', data: {product: item}})}
      // onSnackbar={onSnackbar}
    />)
  })

  function onSnackbar(data) {
  	if (snackbar) return;
  	setSnackbar(<Snackbar
        onClose={() => setSnackbar(null)}
        // onActionClick={() => this.setState({ text: 'Сообщение Ивану было отменено.' })}
        after={!!data.image && <Avatar src={ data.image } size={32} mode="app" />}
      	>
        { data.text }
      </Snackbar>);
  }

	return (
		<Panel className="BackgroundPage" id={props.id}>
	    <PanelHeader left={
	    	props.affiliates.length > 1
	    	? null
	    	// ?	<PanelHeaderButton onClick={() => props.onModal({type: 'AFFILIATES'})}>
	    	// 		<Icon24Place width={22} height={22} style={{marginRight: '4px', marginLeft: '3px'}} /> 
	    	// 		<span style={{marginRight: '3px'}}>{ props.affiliate.name }</span> <Icon20Dropdown />
	    	// 	</PanelHeaderButton>
	    	: <PanelHeaderButton onClick={() => props.onModal({type: 'CONTACTS'})}>
	    			<Icon24Phone />
	    		</PanelHeaderButton>
	    }>
	    { 
	    	props.affiliates.length < 2 
	    	? 'Меню'
	    	: <PanelHeaderContent 
	    			style={{color: 'var(--color-1)'}}
	    			onClick={() => props.onModal({type: 'AFFILIATES'})}
	    			before={<Icon24Place />} 
	    			aside={<Icon20Dropdown style={{color: 'var(--color-1)'}} />}>
	    			<Text style={{fontWeight: '500', fontSize: '18px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{ props.affiliate.name }</Text>
	    	</PanelHeaderContent>
	    }
	    </PanelHeader>

	    {/*{
	    	(!saved_affiliate_id && props.affiliates.length > 1) &&
	    	<Div>
тут можно изменить филиал 
	    	</Div>
	    }*/}

	    {
        // !props.affiliate.pickup_only && 
        !(!!props.affiliate.pickup_only && _.get(props, 'affiliate.pickup_points', []).length < 2) &&
        <>
        <Div style={{paddingBottom: 0}}>
        	<div className='HomeDelivery'>
        		{
        			(!props.affiliate.pickup_only && _.get(props, 'affiliate.order_form.config.delivery.active', 0) == 1) &&
        			<div>
        				<SliderSwitch 
				        	activeValue={props.orderForm?.delivery}
				        	options={[
			              { name: 'Доставка', value: 'delivery' },
			              { name: 'Самовывоз', value: 'self' }
			            ]}
				          onSwitch={(value) => {
				          	props.dispatch(cartActions.saveOrderForm({...props.orderForm, 'delivery': value}));
				          	bridge.send("VKWebAppTapticSelectionChanged", {});
				          }}
				        />
				        {
				        	(props.orderForm?.delivery !== 'self' || (_.get(props, 'affiliate.pickup_points', []).length > 1 && _.get(props, 'affiliate.order_form.config.pickup_point.active', 0) == 1)) &&
					        // <Separator style={{margin: '11px -8px 10px'}} />
					        <div style={{margin: '11px 5px 10px', borderTop: '0.75px solid rgba(255,255,255,0.5)'}} />
					      }
        			</div>
        		}
        		{ 
		        	props.orderForm?.delivery !== 'self' &&
		        	<div>
				        <div style={{textAlign: 'center'}}>
				        	<Button mode="tertiary" size="l" style={{color: '#fff'}}
				        		before={
				        			// (props.deliveryAreaStatus.code === 'loading' && <Icon24ClockOutline width={20} height={20} style={{marginTop: '-3px'}} />) ||
				        			(props.deliveryAreaStatus.code === 'loading' && <Spinner size="small" style={{marginTop: '-3px', color: '#fff'}} />) ||
				        			(props.orderForm.address_id && (props.deliveryAreaStatus.code === 'success' || _.get(props, 'affiliate.order_form.config.delivery_area.active', 0) != 1) && <Icon20Check width={20} height={20} style={{marginTop: '-3px'}} />) ||
				        			(props.deliveryAreaStatus.code === 'not_found' && <Icon24DoNotDisturb width={20} height={20} style={{marginTop: '-3px'}} />) ||
				        			(!props.orderForm.address_id && <Icon24Place width={20} height={20} style={{marginTop: '-3px'}} />)
				        		}
				        		after={<Icon16ChevronOutline />}
				        		onClick={() => props.onModal({type: 'ADDRESS'})}
				        		>{(props.orderForm.address_id > 0) ? `${props.orderForm.address_street} ${props.orderForm.address_house}` : 'Указать адрес доставки'}</Button>
				        </div>
				        {
	                props.deliveryAreaStatus.code === 'not_found' && 
	                <div style={{marginTop: '-7px', marginBottom: '10px', textAlign: 'center', color: '#fff'}}>
	                  <Text style={{marginTop: 3, fontWeight: '500', color: '#ffffff'}}>Зона доставки не определена</Text>
	                  {_.get(props, 'affiliate.order_form.config.delivery_area.required', 0) == 1 &&
	                    <Text style={{marginTop: 3, lineHeight: 1, color: '#ffffffaa'}}>Пожалуйста, укажите другой адрес</Text>}
	                  {_.get(props, 'affiliate.order_form.config.delivery_area.required', 0) != 1 &&
	                    <Text style={{marginTop: 3, lineHeight: 1, color: '#ffffffaa'}}>Условия доставки на ваш адрес<br />уточняйте у оператора</Text>}
	                </div>
	              }
	              {
	                props.deliveryAreaStatus.code === 'success' && 
	                <div style={{marginTop: '-7px', marginBottom: '10px', textAlign: 'center', color: '#fff'}}>
	                	<Text style={{marginTop: 3, color: '#fff'}}>{ props.deliveryArea?.name }</Text>
	                </div>
	              }
				      </div>
				    }
		        { 
		        	props.orderForm?.delivery === 'self' && _.get(props, 'affiliate.pickup_points', []).length > 1 && _.get(props, 'affiliate.order_form.config.pickup_point.active', 0) == 1 &&
		        	<div>
				        <div style={{textAlign: 'center'}}>
				        	<Button mode="tertiary" size="l" style={{color: '#fff'}}
				        		// before={<Icon20Check />}
				        		before={<Icon24Home width={20} height={20} style={{marginTop: '-3px'}} />}
				        		after={<Icon16ChevronOutline />}
				        		onClick={() => props.onModal({type: 'PICKUP_POINT'})}
				        		>{(props.orderForm.pickup_point) ? _.get(props, 'affiliate.pickup_points', []).find(item => item.code == props.orderForm.pickup_point)?.name : 'Выбрать точку самовывоза'}</Button>
				        </div>
				      </div>
			      }
		      </div>
	      </Div>
      
      {
      	(Object.keys(orderConditions).length > 0 && props.orderForm?.delivery !== 'self') &&
	      <div className="HomeConditions">
	      	<HorizontalScroll>
	      		<div className="HomeConditions__Container">
	      			{
	      				!!orderConditions.delivery_time?.value &&
	      				<Card size="l" mode="outline" className="HomeConditions__Card">
		      				<div className="HomeConditions__Item">
			      				<div className="HomeConditions__ItemTitle">Время доставки</div>
			      				<div className="HomeConditions__ItemValue">до {orderConditions.delivery_time?.value} мин.</div>
		      				</div>
		      			</Card>
	      			}
	      			{
	      				(!orderConditions.delivery_time?.value && !!orderConditions.delivery_min_time?.value) &&
	      				<Card size="l" mode="outline" className="HomeConditions__Card">
		      				<div className="HomeConditions__Item">
			      				<div className="HomeConditions__ItemTitle">Время доставки</div>
			      				<div className="HomeConditions__ItemValue">от {orderConditions.delivery_min_time?.value} мин.</div>
		      				</div>
		      			</Card>
	      			}
	      			{
	      				!!orderConditions.min_sum?.value &&
		      			<Card size="l" mode="outline" className="HomeConditions__Card">
		      				<div className="HomeConditions__Item">
			      				<div className="HomeConditions__ItemTitle">Минимальный заказ</div>
			      				<div className="HomeConditions__ItemValue">от {orderConditions.min_sum?.value} {props.affiliate.currency?.sign}</div>
		      				</div>
		      			</Card>
		      		}
		      		{
		      			!!orderConditions.delivery_price?.value &&
		      			<Card size="l" mode="outline" className="HomeConditions__Card">
		      				<div className="HomeConditions__Item">
			      				<div className="HomeConditions__ItemTitle">Стоимость доставки</div>
			      				<div className="HomeConditions__ItemValue">{orderConditions.delivery_price?.value} {props.affiliate.currency?.sign}</div>
		      				</div>
		      			</Card>
		      		}
		      		{
		      			!!orderConditions.free_delivery_from?.value &&
		      			<Card size="l" mode="outline" className="HomeConditions__Card">
		      				<div className="HomeConditions__Item">
			      				<div className="HomeConditions__ItemTitle">Бесплатная доставка</div>
			      				<div className="HomeConditions__ItemValue">от {orderConditions.free_delivery_from?.value} {props.affiliate.currency?.sign}</div>
		      				</div>
		      			</Card>
		      		}
	      		</div>
	      	</HorizontalScroll>
	      </div>
	    }
	      </>
	    }
      {
      	(_.get(props, 'actionsStatus.code', '') === 'success') &&
	      (
		      props.actions.length
		      ?
		      <div className="HomeActions">
		      	<HorizontalScroll>
		      		<div className="HomeActions__Container">
		          {
		            props.actions.map(item => 
	          	  	<div key={item.id} className='HomeActions__Item' style={{minWidth: `${100 / slidesPerView}%`}}
	          	  		onClick={() => props.onModal({type: 'ACTION_DETAIL', data: {action: item}})}>
										<img src={'https://dlvry.ru' + item.img?.src} alt={item.name} />
									</div>
		            )
		          }
	          	</div>
	          </HorizontalScroll>
	        </div>
		      :  
		    	props.affiliate.header_images?.length > 0 &&
			    <Gallery
		        slideWidth="100%"
		        align="center"
		        style={{ height: 'auto', background: 'var(--background_page)', marginTop: '-2px' }}
		      >
		      	{
		      		props.affiliate.header_images.map((img, index) => (
		      			<div key={index}>
		      				<img src={`https://dlvry.ru${img.src}`} alt='img' style={{width: '100%', display: 'block'}} />
		      			</div>
		      		))
		      	}
		      </Gallery>
	      )
	    }

	    {
	    	props.productsStatus.code === 'success' &&
	      <div className={`HorizontalMenu HorizontalMenu--${platform}`}>
	      	<HorizontalScroll>
	      		<div className="HorizontalMenu__Container">
	      			{
	      				props.rootSections.map(section => 
	      					<Button key={section.id} mode="secondary" size="m" 
	      						onClick={() => router.navigate('home/section', {section_id: section.id})}>{ section.name }</Button> 
	      				)
	      			}
	          </div>
	      	</HorizontalScroll>
	      </div>
	    }

      {
      	props.productsStatus.code === 'loading' 
      	? <PanelSpinner />
      	: <>
		      {
		      	!topItems.length &&
			      <div className="BackgroundPage">
			      	<Div className="SectionsGrid">
				      {
								props.rootSections.map(section => 
									<div className="SectionsGrid__Item" key={section.id}
										onClick={() => router.navigate('home/section', {section_id: section.id})}>
										<div className="SectionsGrid__ItemImg">
											<img src={`https://dlvry.ru${section.img?.src}`} alt={section.name} />
										</div>
										<div className="SectionsGrid__ItemTitle">{ section.name }</div>
									</div> 
								)
							}
							</Div>
						</div>
					}
		      {
		      	topItems.length > 0 &&
		      	<div className="BackgroundPage">
		    	  	<Header>Популярное</Header>
		      		<Div style={{paddingTop: 0}}>
		      			{ topItemsElements }
				      </Div>
		      	</div>
		      }
		      </>
		  }
      <div style={{height: '20px'}} />

      { snackbar }
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    affiliateStatus: state.affiliates.itemStatus,
    affiliates: state.affiliates.items,
    owner: state.owner.item,
    rootSections: state.catalog.rootSections,
    sectionsStatus: state.catalog.sectionsStatus,
    orderForm: state.cart.orderForm,
    actions: state.actions.items,
    actionsStatus: state.actions.itemsStatus,
    deliveryArea: state.cart.deliveryArea,
    deliveryAreaStatus: state.cart.deliveryAreaStatus,
    products: state.catalog.products,
    productsStatus: state.catalog.productsStatus,
    source: state.app.source,
  };
}

export default connect(mapStateToProps)(Home);


