import { HTTP } from '../../utils/http-common';
import {ERROR} from '../../utils/errors'
import { 
  GET_ACTIONS_REQUEST, 
  GET_ACTIONS_SUCCESS,
  GET_ACTIONS_ERROR,
} from "../ActionTypes";

function commitAction(type, data) {
	return { type, data }
}

export function getActions(affiliate_id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      if(!affiliate_id) {
        let err = ERROR.make(400, "Invalid parameter: affiliate_id")
        dispatch(commitAction(GET_ACTIONS_ERROR, err))
        reject(err)
        return;
      }
      dispatch(commitAction(GET_ACTIONS_REQUEST))

      const platform = 'vkapp'
      HTTP.get("affiliates/"+affiliate_id+"/actions", {params: {active: 1, platform}})
    	.then(resp => {
        // console.log(resp.data)
        dispatch(commitAction(GET_ACTIONS_SUCCESS, resp.data.data.items))
        resolve(resp.data.data)
      })
      .catch(err => {
        // console.log(err)
        dispatch(commitAction(GET_ACTIONS_ERROR, err))
        reject(err)
      })
    })
  }
}