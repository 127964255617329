import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import { View, Div, Group, Header, Cell } from '@vkontakte/vkui';
import { Icon24Phone, Icon24LogoInstagram, Icon24LogoVkOutline } from '@vkontakte/icons';

const Contacts = props => {
	const router = useRouter();

	return (
		<div>
	    {/*<Header>{ props.area.name }</Header>*/}
      <Group>
        {_.get(props, 'affiliate.phones', []).map((item, key) =>
          <Cell
            expandable 
            multiline
            href={`tel:${item.phone}`}
            before={<Icon24Phone style={{color: 'var(--icon_tertiary)'}} />}
            description={item.description}
            >
            {item.phone}
          </Cell>
        )}
        {!!_.get(props, 'affiliate.instagram', '') && 
          <Cell
            // description="Наш instagram"
            expandable
            before={<Icon24LogoInstagram style={{color: 'var(--icon_tertiary)'}} />}
            href={`https://instagram.com/${props.affiliate.instagram}`}>
            {/*{`@${props.affiliate.instagram}`}*/}
            Наш instagram
          </Cell>
        }
        {_.get(props, 'affiliate.vk_groups', []).length > 0 && 
          <Cell
            // description="Мы ВКонтакте"
            expandable
            before={<Icon24LogoVkOutline style={{color: 'var(--icon_tertiary)'}} />}
            href={`https://vk.com/club${props.affiliate.vk_groups[0]['id']}`}>
            Мы ВКонтакте
          </Cell>
        }
      </Group>
	  </div>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    affiliateInitStatus: state.affiliates.itemInitStatus,
  };
}

export default connect(mapStateToProps)(Contacts);

