import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router5';
import bridge from "@vkontakte/vk-bridge";
import createRouter from './create-router';
import configureStore  from './store/reducers';
import App from "./App";

function parseQueryString(string) {
  return string.slice(1).split('&')
    .map((queryParam) => {
      let kvp = queryParam.split('=');
      return {key: kvp[0], value: kvp[1]}
    })
    .reduce((query, kvp) => {
      query[kvp.key] = kvp.value;
      return query
    }, {})
};

const queryParams = parseQueryString(window.location.search);
const hashParams = parseQueryString(window.location.hash);

const router = createRouter();
const store = configureStore(router);

// Init VK  Mini App
bridge.send("VKWebAppInit");

router.start(() => {
  ReactDOM.render((
    <Provider store={store}>
      <RouterProvider router={router}>
        <App router={router} queryParams={queryParams} hashParams={hashParams} />
      </RouterProvider>
    </Provider>
  ), document.getElementById('root'))
})

if (process.env.NODE_ENV === "development") {
  // import("./eruda").then(({ default: eruda }) => {}); //runtime download
}
