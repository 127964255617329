import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import { usePlatform, View, Div, Button, FixedLayout, CardGrid, ContentCard, Header, SimpleCell, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon28Newsfeed, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

const Menu = props => {
	const router = useRouter();
	const platform = usePlatform(); // android или ios

	return (
		<Panel id={props.id}>
	    <PanelHeader>Ещё</PanelHeader>
	    {
	    	!!props.affiliate?.id &&
		    <Group>
		      <RichCell
		      	multiline
		        disabled
		        before={<Avatar mode="image" src={ 'https://dlvry.ru' + _.get(props.affiliate, 'preview_picture.src') } />}
		        caption={ _.get(props.affiliate, 'addresses', []).map(addr => addr.address).join(", ") }
		      >
		        { props.affiliate.name }
		      </RichCell>
		    </Group>
		  }
		  {
		  	!!props.affiliate?.id &&
		    <Group header={<Header>Информация</Header>}>
		      <SimpleCell 
		        before={<Icon24Info style={{color: 'var(--icon_tertiary)'}} />}
		        expandable
		        onClick={() => router.navigate('menu/about')}
		      >
		        О компании
		      </SimpleCell>
		      <SimpleCell 
		        before={<Icon24Phone style={{color: 'var(--icon_tertiary)'}} />}
		        expandable
		        onClick={() => router.navigate('menu/contacts')}
		      >
		        Контакты
		      </SimpleCell>
		      <SimpleCell 
		        before={<Icon24Favorite style={{color: 'var(--icon_tertiary)'}} />}
		        expandable
		        onClick={() => router.navigate('menu/reviews')}
		      >
		        Отзывы
		      </SimpleCell>
		      <SimpleCell 
		        before={<Icon24MoneyCircle style={{color: 'var(--icon_tertiary)'}} />}
		        expandable
		        onClick={() => router.navigate('menu/info')}
		      >
		        Доставка и оплата
		      </SimpleCell>
		    </Group>
		  }
	    {
	    	_.get(props, 'owner.tariff.scope.white_label', 0) !== 1 &&
	    	<Group>
		      <SimpleCell 
		          before={<Icon24SmartphoneOutline style={{color: 'var(--icon_tertiary)'}} />}
		          expandable
		          onClick={() => router.navigate('menu/aboutApp')}
		        >
		        О приложении
		      </SimpleCell>
		    </Group>
	    }
	    {/* По просьбе модераторов ВК пришлось скрыть :/
		    {
	      	platform == 'ios' && props.affiliate?.ios_app_url &&
	      	<Div>
		      	<a href={ props.affiliate?.ios_app_url } target="_blank" class="AppButton AppButton--ios">Доступно в App Store</a>
		      </Div>
	      }
	      {
	      	platform == 'android' && props.affiliate?.android_app_url &&
	      	<Div>
		      	<a href={ props.affiliate?.android_app_url } target="_blank" class="AppButton AppButton--android">Доступно в Google Play</a>
		      </Div>
	      }
    	*/}
	    <FixedLayout vertical="bottom">
				{
					_.get(props, 'owner.tariff.scope.white_label', 0) !== 1 &&
					<Div style={{ background: 'var(--background_content)'}}>
						<Button href="https://new.dlvry.ru" mode="secondary" styled={{ background: 'var(--color-1)' }} size="l" stretched>Работает на технологии DLVRY</Button>
					</Div>
				}				
	    </FixedLayout>
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    owner: state.owner.item,
    affiliate: state.affiliates.item,
    affiliateInitStatus: state.affiliates.itemInitStatus,
  };
}

export default connect(mapStateToProps)(Menu);

