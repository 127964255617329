import {ERROR} from '../../utils/errors'

import { 
  GET_AFFILIATES_REQUEST, 
  GET_AFFILIATES_SUCCESS,
  GET_AFFILIATES_ERROR,

  GET_AFFILIATE_REQUEST, 
  GET_AFFILIATE_SUCCESS,
  GET_AFFILIATE_ERROR,

  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_CITIES_ERROR,

  GET_STREETS_REQUEST,
  GET_STREETS_SUCCESS,
  GET_STREETS_ERROR,

  SET_AFFILIATE,

  SSI_REQUEST,
  SSI_LOADED,
  SSI_ERROR
} from "../ActionTypes";

const initialState = {
  items: [],
  itemsStatus: {},
  item: {},
  itemStatus: {},
  itemInitStatus: {},
  // itemInitID: (typeof localStorage !== 'undefined') ? localStorage.getItem('affiliate_id') : null, // TODO !?
  cities: [],
  citiesStatus: {},
  streets: [],
  streetsStatus: {},
};

export default (state = initialState, action) => {
  let status = {}
  switch (action.type) {

    case SSI_REQUEST:
      status = { code: 'loading' }
      return {
        ...state,
        itemsStatus: status,
        itemStatus: status,
        itemInitStatus: status,
      }
    case SSI_LOADED:
      status = { code: 'success' }
      return {
        ...state,
        itemsStatus: status,
        items: action.data.affiliates,
        itemStatus: status,
        item: action.data.affiliate,
        itemInitStatus: status,
        // metaStatus: status,
        // meta: action.data.meta,
      }
    case SSI_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        itemsStatus: status,
        itemStatus: status,
        itemInitStatus: status,
      }
    
    case GET_AFFILIATES_REQUEST:
      status = { code: 'loading' }
      return {
      	...state,
        itemsStatus: status,
      }

    case GET_AFFILIATES_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        itemsStatus: status,
        items: action.data,
      }

    case GET_AFFILIATES_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        itemsStatus: status,
        items: [],
      }

    case GET_AFFILIATE_REQUEST:
      status = { code: 'loading' }
      return {
        ...state,
        itemStatus: status,
      }

    case GET_AFFILIATE_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        itemStatus: status,
        item: action.data,
      }

    case GET_AFFILIATE_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        itemStatus: status,
        item: {},
      }

    case SET_AFFILIATE:
      status = { code: 'success' }
      return {
        ...state,
        itemStatus: status,
        item: action.data,
      }

    case GET_CITIES_REQUEST:
      status = { code: 'loading' }
      return {
        ...state,
        citiesStatus: status,
      }

    case GET_CITIES_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        citiesStatus: status,
        cities: action.data,
      }

    case GET_CITIES_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        citiesStatus: status,
        cities: [],
      }

    case GET_STREETS_REQUEST:
      status = { code: 'loading' }
      return {
        ...state,
        streetsStatus: status,
      }

    case GET_STREETS_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        streetsStatus: status,
        streets: action.data,
      }

    case GET_STREETS_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        streetsStatus: status,
        streets: [],
      }
    
    default:
      return state
  }
};