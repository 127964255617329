import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { View, ModalRoot, ModalPage, usePlatform, ModalPageHeader, PanelHeaderButton, PanelHeaderClose } from '@vkontakte/vkui';
import { Icon24Dismiss } from '@vkontakte/icons';

import GetStartedDefault from '../panels/GetStarted';

const GetStarted = props => {

	const platform = usePlatform()

	const [modalData, setModalData] = useState({})
	const [activeModal, setActiveModal] = useState(null);
	const [popout, setPopout] = useState(null);

	const modal = (
    <ModalRoot activeModal={activeModal} onClose={() => setActiveModal(null)}>
      
    </ModalRoot>
  );

  function toggleModal(modal) {
  	if(!modal) {
  		setActiveModal(null);
  	} else {
  		setModalData(modal.data);
  		setActiveModal(modal.type);
  	}
  }

  function togglePopout(popout) {
  	if(!popout) {
  		setPopout(null);
  	} else {
  		setPopout(popout);
  	}
  }

	return (
		<View id={props.id} activePanel={props.activePanel} modal={modal} popout={popout}>
			<GetStartedDefault id="default" onModal={toggleModal} onPopout={togglePopout} />		
		</View>
	)
};

export default GetStarted;
