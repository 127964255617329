import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import { Div, Text, Group, Header, Alert, SimpleCell, Spinner, FormLayout, withModalRootContext, FormLayoutGroup, FormItem, Input, Button } from '@vkontakte/vkui';
// import { Icon28Newsfeed, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import {ERROR} from '../utils/errors'

import * as cartActions from '../store/actions/cart'

const Promocode = props => {
	const router = useRouter();

  const [code, changeCode] = useState('')

  useEffect(() => {
    changeCode(props.promocode.code)
  }, [])

  function clear() {
    if(props.promocodeStatus.code == 'success') {
      changeCode('')
      props.clearPromocode()
      props.updateModalHeight();
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    if(props.promocodeStatus.code == 'success') {
      clear()
      props.updateModalHeight();
    } else {
      props.getPromocode(props.affiliate.id, code)
      .then(data => {
        props.updateModalHeight();
        // navigation.navigate('Cart')
        // props.onPopout(<Alert
        //   actions={[{
        //     title: 'OK',
        //     autoclose: true,
        //     mode: 'cancel'
        //   }]}
        //   actionsLayout="vertical"
        //   onClose={ () => props.onPopout(null)}
        //   header={props.promocodeCheckStatus.text}
        //   // text={}
        // />)
        // props.onModal(null)
      })
      .catch(err => {
        props.updateModalHeight();
        // ERROR.show(err)
        const error = ERROR.format(err)
        if(error.code == 'error' && error.message) {
          // Alert.alert(code, error.message);
          // bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
          props.onPopout(<Alert
            actions={[{
              title: 'OK',
              autoclose: true,
              mode: 'cancel'
            }]}
            actionsLayout="vertical"
            onClose={ () => props.onPopout(null)}
            header={code}
            text={error.message}
          />)
        }
        // props.onModal(null)
      })
    }
  }

	return (
		<div style={{paddingBottom: '20px'}}>
	    <FormLayout onSubmit={onSubmit}>
        <FormLayoutGroup mode="horizontal">
          <FormItem 
            style={{minWidth: '55%', marginRight: '10px'}}
            status={props.promocodeStatus.code === 'error' ? 'error' : (props.promocodeCheckStatus.code == 'success' ? 'valid' : null)}
            >
            <Input type="text" placeholder="Введите промокод" onClick={() => clear()} value={code} onChange={e => changeCode(e.target.value)} />
          </FormItem>
          <FormItem style={{ margin: 0}}>
            <Button type="submit" stretched size="l" mode={props.promocodeStatus.code == 'success' ? 'outline' : 'primary'}>
              { (props.promocodeStatus.code == 'loading') ? <Spinner size="small" style={{ color: '#fff' }} /> : ((props.promocodeStatus.code == 'success') ? 'Сбросить' : 'Применить') }
            </Button>
          </FormItem>
        </FormLayoutGroup>
      </FormLayout>
      {
        props.promocodeStatus.code === 'error' && 
        <Div styles={{paddingTop: 0}}>
          <div className="PromocodeResult__Description PromocodeResult__Description--error">{props.promocodeStatus.message}</div>
        </Div>
      }
      {
        props.promocodeStatus.code === 'success' &&
        <Div styles={{paddingTop: 0}}>
          <div className="_PromocodeResult">
          { 
            props.promocodeCheckStatus.code !== 'pre_success' &&
            <div className={`PromocodeResult__Status PromocodeResult__Status--${props.promocodeCheckStatus.code}`}>
              { props.promocodeCheckStatus.text || 'Промокод не активирован' }
            </div>
          }
          <div className="PromocodeResult__Title">{ props.promocode.name }</div>
          {
            props.promocode.description?.length > 0 && 
            <div className="PromocodeResult__Description">{ props.promocode.description }</div>
          }
          {
            props.promocodeCheckStatus.description && 
            <div className={`PromocodeResult__Description PromocodeResult__Description--${props.promocodeCheckStatus.code}`}>{ props.promocodeCheckStatus.description }</div>
          }
          </div>
        </Div>
      }
	  </div>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    promocode: state.cart.promocode,
    promocodeStatus: state.cart.promocodeStatus,
    promocodeCheckStatus: state.cart.promocodeCheckStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPromocode: (affiliate_id, code) => dispatch(cartActions.getPromocode(affiliate_id, code)),
    clearPromocode: () => dispatch(cartActions.clearPromocode()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withModalRootContext(Promocode));

