import {ERROR} from '../../utils/errors'

import { 
  GET_OWNER_REQUEST, 
  GET_OWNER_SUCCESS,
  GET_OWNER_ERROR,

  GET_REVIEWS_REQUEST, 
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_ERROR,
} from "../ActionTypes";

const initialState = {
  item: {},
  itemStatus: {},
  reviews: [],
  reviewsStatus: {},
};

export default (state = initialState, action) => {
  let status = {}
  switch (action.type) {
    
    case GET_OWNER_REQUEST:
      status = { code: 'loading' }
      return {
      	...state,
        itemStatus: status,
      }

    case GET_OWNER_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        itemStatus: status,
        item: action.data,
      }

    case GET_OWNER_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        itemStatus: status,
        item: {},
      }

    case GET_REVIEWS_REQUEST:
      status = { code: 'loading' }
      return {
        ...state,
        reviewsStatus: status,
      }

    case GET_REVIEWS_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        reviewsStatus: status,
        reviews: action.data,
      }

    case GET_REVIEWS_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        reviewsStatus: status,
        reviews: [],
      }
    
    default:
      return state
  }
};