import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash';
import bridge from '@vkontakte/vk-bridge';
import { View, Spinner, Radio, HorizontalScroll, HorizontalCell, usePlatform, Separator, SliderSwitch, Alert, PanelHeaderButton, Headline, Subhead, CardGrid, Card, ContentCard, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon16Add, Icon16Minus, Icon16ChevronOutline, Icon20Check, Icon16New, Icon24DoNotDisturb, Icon24ClockOutline, Icon28Newsfeed, Icon24Place, Icon24Home, Icon20Dropdown, Icon28BlockOutline, Icon20FavoriteCircleFillYellow, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon28UnfavoriteOutline, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import * as cartActions from '../store/actions/cart';

const mapStateToProps = (state, props) => {
  return { 
    owner: state.owner.item,
    affiliate: state.affiliates.item,
    cartProducts: state.cart.products,
    cartGifts: state.cart.gifts,
    basketRulesActions: state.cart.basketRulesActions,
    sections: state.catalog.sections,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (id, data, meta) => dispatch(cartActions.addProduct(id, data, meta)),
    delProduct: (id, data) => dispatch(cartActions.delProduct(id, data)),
    addGift: (id, data) => dispatch(cartActions.addGift(id, data)),
    delGift: (id, data) => dispatch(cartActions.delGift(id, data)),
    addRuleGift: (id, random_id, data) => dispatch(cartActions.addRuleGift(id, random_id, data)),
    delRuleGift: (id, random_id, data) => dispatch(cartActions.delRuleGift(id, random_id, data)),
  }
}

const areEqual = (prevProps, nextProps) => { 
  // const product = nextProps.product
	if(nextProps.isGift) {
		// const prevCartGifts = prevProps.cartGifts
		// const nextCartGifts = nextProps.cartGifts
		// // Сравниваем сумму всех SKU в корзине 
		// return product.sku.reduce((sum, item) => sum + getCount(item.id, prevCartGifts), 0) === product.sku.reduce((sum, item) => sum + getCount(item.id, nextCartGifts), 0)
		return false
	} else {
		const prevCartProducts = prevProps.cartProducts
		const nextCartProducts = nextProps.cartProducts
		// Сравниваем сумму всех SKU в корзине 
    // return product.sku.reduce((sum, item) => sum + getCount(item.id, prevCartProducts), 0) === product.sku.reduce((sum, item) => sum + getCount(item.id, nextCartProducts), 0)
    // Теперь если товар лежит в корзине – всегда обновляем его (зато не пересчитываем сумму)

    // Изменил 2021-09-10
    // return prevCartProducts.concat(nextCartProducts).filter(item => item.id == nextProps.product.id).length == 0
    return prevCartProducts.concat(nextCartProducts).filter(item => nextProps.product.sku_ids.indexOf(item.id) != -1).length == 0
	}
};

let isMounted;

const ProductCardMedium = React.memo((props) => {
  
  const product = props.product

	const [curSkuId, setCurSkuId] = useState(product.sku[0].id)
  const [curSku, setCurSku] = useState(product.sku[0])
  
  const [imageAspectRatio, setImageAspectRatio] = useState(0);

  function getCount(id, items) {
    let count = 0
    if(typeof items != 'undefined') {
      count = items.reduce((previousValue, currentItem) => {
        if(currentItem.id == id) {
          return previousValue + currentItem.data.quantity
        } else {
          return previousValue
        }
      }, 0)
    }
    return count
  }

	function add(id, data) {
    bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
    if(!props.isCanAdd) {
      return
    }
    if(props.isGift) {
      if(props.randomId) {
        if(props.basketRuleId) {
          data.basket_rule_id = props.basketRuleId
        }
        props.addRuleGift(id, props.randomId, data)
      } else {
        props.addGift(id, data)
      }
    } else {
      const section_id = product.parent_section_ids[0]
      const section = props.sections.find(section => section.id == section_id) || {}
      const meta = {
        product: {
          id: curSku.id,
          name: curSku.name,
        },
        category: {
          id: section.id,
          name: section.name,
        },
        currency: props.affiliate.currency
      }
      if(product.min_quantity) {
        data.min_quantity = product.min_quantity
      }
      props.addProduct(id, data, meta)
      // props.dispatch(cartActions.addProduct(id, data, meta))
      
      // Лишнее 
      // props.onSnackbar({
      //   text: `Товар добавлен в корзину ${curSku.name}`,
      //   image: !!curSku.img?.src && `https://dlvry.ru${curSku?.img?.src}`
      // })
    }
  }

  function del(id, data) {
    bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
    if(props.isGift) {
      if(props.randomId) {
        // if(props.basketRuleId) {
        //   data.basket_rule_id = props.basketRuleId
        // }
        props.delRuleGift(id, props.randomId, data)
      } else {
        props.delGift(id, data)
      }
    } else {
      props.delProduct(id, data)
    }
  }

  const imgSrc = (curSku?.img?.src.indexOf('default-400x300') === -1) ? 'https://dlvry.ru' + curSku?.img?.src : 'https://dlvry.ru/img/default-400x300.png';

  let inCartCount = 0
  if(props.isGift) {
    if(props.randomId) {
      const set = props.basketRulesActions.giftSets.find(set => set.random_id == props.randomId)
      inCartCount = getCount(curSku.id, set.selected)
    } else {
      inCartCount = getCount(curSku.id, props.cartGifts)
    }
  } else {
   inCartCount = getCount(curSku.id, props.cartProducts)
  }

	useEffect(() => {
	  const newSku = product.sku.find(item => {
      return item.id == curSkuId
    })
    if(newSku.id !== curSku.id) {
      setCurSku(newSku)
    }
  },[curSkuId])

  useEffect(() => {
    isMounted = true;

    let arImageAspectRatio = [3, 2]
    if(props.affiliate.template && props.affiliate.template['mobile.products.images.size']) {
    	if(props.affiliate.template['mobile.products.images.size'] == 'original') {
        arImageAspectRatio = null
    	} else {
      	arImageAspectRatio = props.affiliate.template['mobile.products.images.size'].split("x")
    	}
    }
    if(arImageAspectRatio) {
      setImageAspectRatio(arImageAspectRatio[0] / arImageAspectRatio[1])
    }

    return () => {
      isMounted = false;
    }
  }, []);

  return (
  	<div className={"ProductCard ProductCard--medium" + (!!props.noImage ? " ProductCard--noimage" : '')}>
      {
        !props.noImage &&
        <div onClick={ props.onClick }>
          <div className="ProductCard__Img" style={{backgroundImage: `url(${imgSrc})` }} />
        </div>
      }
      <div style={{flex: 1}}>
        <div className="ProductCard__Text" onClick={ props.onClick }>
      		<div className="ProductCard__Title">
      			{ curSku.name }
            {
              curSku.weight > 0 && <span> { curSku.weight }&nbsp;гр.</span> ||
              curSku.volume > 0 && <span> { curSku.volume }&nbsp;л.</span> ||
              curSku.size && <span> { curSku.size }</span>
            }
        	</div>
          {typeof props.owner.labels != 'undefined' && !!product.labels &&
          <div className="ProductCard__Labels">
            {product.labels.filter(label => !!props.owner.labels[label]).map(label => 
              <div className="ProductCard__Label" key={label} 
                style={{ paddingRight: (!!props.owner.labels[label]['title']) ? '8px' : '4px', paddingLeft: !!props.owner.labels[label]['icon'] ? '4px' : '8px',  backgroundColor: '#'+props.owner.labels[label]['bg_color'], color: '#'+props.owner.labels[label]['color']}}>
                {/*{!!props.owner.labels[label]['icon'] && <UIIcon name={props.owner.labels[label]['icon']} width={20} style={{marginRight: (!!props.owner.labels[label]['title']) ? '4px' : null}} color={'#'+props.owner.labels[label]['color']} />}*/}
                {!!props.owner.labels[label]['icon'] && <Icon16New width={18} height={18} style={{marginRight: (!!props.owner.labels[label]['title']) ? '4px' : null, color: '#'+props.owner.labels[label]['color']}} />}
                {!!props.owner.labels[label]['title'] && <Text className="ProductCard__LabelText">{ props.owner.labels[label]['title'] }</Text>}
              </div>
            )}
          </div>}
        	<div className="ProductCard__Description" dangerouslySetInnerHTML={{ __html: curSku.preview_text }}></div>
        </div>
        {
          product.sku.length > 1 &&
          <div className="ProductCard__Options">
            {
              product.sku.map(sku => 
                <Radio value={sku.id} checked={curSkuId == sku.id} key={sku.id} onChange={ () => {setCurSkuId(sku.id)} }>{ sku.property }</Radio>
              )
            }
          </div>
        }
        
        <div className="ProductCard__Bottom">
          <div className="ProductCard__Price">
            { !props.isGift && curSku.old_price > 0 &&
            <div className="ProductCard__PriceOld">
              { curSku.old_price } { props.affiliate.currency?.sign }
            </div>}
            <div>{ props.isGift ? 'подарок' : (curSku.price > 0 ? curSku.price +' '+ props.affiliate.currency?.sign : '') }</div>
          </div>
          { !props.isGift && curSku.options_groups && curSku.options_groups.length > 0
            ? <div>
                <Button size='m' mode={(!inCartCount) ? 'commerce' : 'primary'}
                  onClick={ props.onClick }
                  >{(inCartCount > 0) ? '×' + inCartCount + ' добавить' : 'Выбрать'}</Button>
              </div>
            : inCartCount > 0
              ? <div className="ProductCard__PlusMinus">
                  <Button size='m'
                    onClick={() => {
                      del(curSku.id)
                      // Haptics.selectionAsync()
                    }}><Icon16Minus style={{ color: '#fff', marginTop: '2px' }} width={18} height={18} /></Button>
                  <span>{ inCartCount }</span>
                  <Button size='m' icon='plus' 
                    disabled={!props.isCanAdd}
                    onClick={() => {
                      add(curSku.id, {price: curSku.price})
                      // Haptics.selectionAsync()
                    }}><Icon16Add style={{ color: '#fff', marginTop: '2px' }} width={18} height={18} /></Button>
                </div>
              : <div>
                  {
                    props.isCanAdd &&
                    <Button size='m' mode='commerce'
                      disabled={!props.isCanAdd}
                      onClick={() => {
                        add(curSku.id, {price: curSku.price})
                        // Haptics.selectionAsync()
                      }}>{ (props.isGift) ? 'Выбрать' : 'В корзину'}</Button>
                  }
                </div>
            } 
        </div>
      </div>
		</div>
  );
}, areEqual)

ProductCardMedium.defaultProps = {
  isGift: false,
  isCanAdd: true,
  noImage: false,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardMedium);