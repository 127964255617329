import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { View, ModalRoot, ModalPage, usePlatform, ModalPageHeader, PanelHeaderButton, PanelHeaderClose } from '@vkontakte/vkui';
import { Icon24Dismiss } from '@vkontakte/icons';

import PersonalDefault from '../panels/Personal';
import PersonalAddresses from '../panels/PersonalAddresses';
import PersonalAddress from '../panels/PersonalAddress';
import PersonalOrders from '../panels/PersonalOrders';
import PersonalOrder from '../panels/PersonalOrder';

// import DeliveryAreaModal from '../modals/DeliveryArea';
import LoyaltyModal from '../modals/Loyalty';


const Personal = props => {

	const platform = usePlatform()

	const [modalData, setModalData] = useState({})
	const [activeModal, setActiveModal] = useState(null);
	const [popout, setPopout] = useState(null);

	useEffect(() => {
		setPopout(props.popout)
	}, [props.popout]);

	const modal = (
    <ModalRoot activeModal={activeModal} onClose={() => setActiveModal(null)}>
      <ModalPage id="ORDER_DETAIL"
      	settlingHeight={100}
	      header={
	        <ModalPageHeader
	          right={platform === 'ios' && <PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss/></PanelHeaderButton>}
	          left={platform === 'android' && <PanelHeaderClose onClick={() => setActiveModal(null)}/>}
	        >
	        Заказ
        </ModalPageHeader>
      }
      	>
      	{/*<DeliveryAreaModal area={deliveryAreaModal} />*/}
      </ModalPage>
      <ModalPage id="LOYALTY"
      	// settlingHeight={75}
	      header={
	        <ModalPageHeader
	          right={platform === 'ios' && <PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss/></PanelHeaderButton>}
	          left={platform === 'android' && <PanelHeaderClose onClick={() => setActiveModal(null)}/>}
	        >
	        Бонусные баллы
        </ModalPageHeader>
      }
      	>
      	<LoyaltyModal />
      </ModalPage>
    </ModalRoot>
  );

  function toggleModal(modal) {
  	if(!modal) {
  		setActiveModal(null);
  	} else {
  		setModalData(modal.data);
  		setActiveModal(modal.type);
  	}
  }

  function togglePopout(popout) {
  	if(!popout) {
  		setPopout(null);
  	} else {
  		setPopout(popout);
  	}
  }

	return (
		<View id={props.id} activePanel={props.activePanel} modal={modal} popout={popout}>
			<PersonalDefault id="default" onModal={toggleModal} onPopout={togglePopout} />
			<PersonalAddresses id="addresses" onModal={toggleModal} onPopout={togglePopout} />
			<PersonalAddress id="address" onModal={toggleModal} onPopout={togglePopout} />
			<PersonalOrders id="orders" onModal={toggleModal} onPopout={togglePopout} />
			<PersonalOrder id="order" onModal={toggleModal} onPopout={togglePopout} />
		</View>
	)
};

export default Personal;
