export const GET_OWNER_REQUEST = "GET_OWNER_REQUEST";
export const GET_OWNER_SUCCESS = "GET_OWNER_SUCCESS";
export const GET_OWNER_ERROR = "GET_OWNER_ERROR";

export const GET_AFFILIATES_REQUEST = "GET_AFFILIATES_REQUEST";
export const GET_AFFILIATES_SUCCESS = "GET_AFFILIATES_SUCCESS";
export const GET_AFFILIATES_ERROR = "GET_AFFILIATES_ERROR";

export const GET_AFFILIATE_REQUEST = "GET_AFFILIATE_REQUEST";
export const GET_AFFILIATE_SUCCESS = "GET_AFFILIATE_SUCCESS";
export const GET_AFFILIATE_ERROR = "GET_AFFILIATE_ERROR";

export const SET_AFFILIATE = "SET_AFFILIATE";

export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_ERROR = "GET_CITIES_ERROR";

export const GET_STREETS_REQUEST = "GET_STREETS_REQUEST";
export const GET_STREETS_SUCCESS = "GET_STREETS_SUCCESS";
export const GET_STREETS_ERROR = "GET_STREETS_ERROR";

export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_ERROR = "GET_REVIEWS_ERROR";

export const GET_PRODUCTS_REQUEST = "GET_PRODUCTS_REQUEST";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";

export const GET_SECTIONS_REQUEST = "GET_SECTIONS_REQUEST";
export const GET_SECTIONS_SUCCESS = "GET_SECTIONS_SUCCESS";
export const GET_SECTIONS_ERROR = "GET_SECTIONS_ERROR";

export const GET_PROMOCODE_REQUEST = "GET_PROMOCODE_REQUEST";
export const GET_PROMOCODE_SUCCESS = "GET_PROMOCODE_SUCCESS";
export const GET_PROMOCODE_ERROR = "GET_PROMOCODE_ERROR";
export const CHECK_PROMOCODE = "CHECK_PROMOCODE";
export const CLEAR_PROMOCODE = "CLEAR_PROMOCODE";

export const ADD_PRODUCT = "ADD_PRODUCT";
export const DEL_PRODUCT = "DEL_PRODUCT";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const CLEAR_PRODUCTS = "CLEAR_PRODUCTS";

export const ADD_GIFT = "ADD_GIFT";
export const DEL_GIFT = "DEL_GIFT";
export const CLEAR_GIFTS = "CLEAR_GIFTS";

export const ADD_RULE_GIFT = "ADD_RULE_GIFT";
export const DEL_RULE_GIFT = "DEL_RULE_GIFT";

export const SET_MORE = "SET_MORE";
export const CLEAR_MORES = "CLEAR_MORES";

export const SAVE_ORDER_FORM = "SAVE_ORDER_FORM";
export const CLEAR_ORDER_FORM = "CLEAR_ORDER_FORM";

export const SAVE_ORDER_REQUEST = "SAVE_ORDER_REQUEST";
export const SAVE_ORDER_SUCCESS = "SAVE_ORDER_SUCCESS";
export const SAVE_ORDER_ERROR = "SAVE_ORDER_ERROR";

export const TRANSACTION_ERROR = "TRANSACTION_ERROR";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_REQUEST = "TRANSACTION_REQUEST";
export const TRANSACTION_CLEAR = "TRANSACTION_CLEAR";

export const GET_ACTIONS_REQUEST = "GET_ACTIONS_REQUEST";
export const GET_ACTIONS_SUCCESS = "GET_ACTIONS_SUCCESS";
export const GET_ACTIONS_ERROR = "GET_ACTIONS_ERROR";

export const GET_CLIENT_REQUEST = "GET_CLIENT_REQUEST";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_ERROR = "GET_CLIENT_ERROR";

export const UPD_CLIENT_REQUEST = "UPD_CLIENT_REQUEST";
export const UPD_CLIENT_SUCCESS = "UPD_CLIENT_SUCCESS";
export const UPD_CLIENT_ERROR = "UPD_CLIENT_ERROR";

export const CLIENT_CLEAR = "CLIENT_CLEAR";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_ERROR = "GET_USER_ERROR";

export const SMS_CODE_REQUEST = "SMS_CODE_REQUEST";
export const SMS_CODE_SUCCESS = "SMS_CODE_SUCCESS";
export const SMS_CODE_ERROR = "SMS_CODE_ERROR";

export const CHECK_PHONE_REQUEST = "CHECK_PHONE_REQUEST";
export const CHECK_PHONE_SUCCESS = "CHECK_PHONE_SUCCESS";
export const CHECK_PHONE_ERROR = "CHECK_PHONE_ERROR";

export const SET_PHONE_REQUEST = "SET_PHONE_REQUEST";
export const SET_PHONE_SUCCESS = "SET_PHONE_SUCCESS";
export const SET_PHONE_ERROR = "SET_PHONE_ERROR";

export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";

export const SAVE_TOKEN = "SAVE_TOKEN";
export const CLEAR_TOKEN = "CLEAR_TOKEN";

export const GET_ORDERS_REQUEST = "GET_ORDERS_REQUEST";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_ERROR = "GET_ORDER_ERROR";

export const GET_ADDRESSES_REQUEST = "GET_ADDRESSES_REQUEST";
export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS";
export const GET_ADDRESSES_ERROR = "GET_ADDRESSES_ERROR";

export const GET_ADDRESS_REQUEST = "GET_ADDRESS_REQUEST";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_ERROR = "GET_ADDRESS_ERROR";

export const SAVE_ADDRESS_REQUEST = "SAVE_ADDRESS_REQUEST";
export const SAVE_ADDRESS_SUCCESS = "SAVE_ADDRESS_SUCCESS";
export const SAVE_ADDRESS_ERROR = "SAVE_ADDRESS_ERROR";

export const DELETE_ADDRESS_REQUEST = "DELETE_ADDRESS_REQUEST";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_ERROR = "DELETE_ADDRESS_ERROR";

export const GET_BASKET_RULES_REQUEST = "GET_BASKET_RULES_REQUEST";
export const GET_BASKET_RULES_SUCCESS = "GET_BASKET_RULES_SUCCESS";
export const GET_BASKET_RULES_ERROR = "GET_BASKET_RULES_ERROR";
export const CHECK_BASKET_RULES = "CHECK_BASKET_RULES";

export const GET_DELIVERY_AREAS_REQUEST = "GET_DELIVERY_AREAS_REQUEST";
export const GET_DELIVERY_AREAS_SUCCESS = "GET_DELIVERY_AREAS_SUCCESS";
export const GET_DELIVERY_AREAS_ERROR = "GET_DELIVERY_AREAS_ERROR";

export const GET_DELIVERY_AREA_REQUEST = "GET_DELIVERY_AREA_REQUEST";
export const GET_DELIVERY_AREA_SUCCESS = "GET_DELIVERY_AREA_SUCCESS";
export const GET_DELIVERY_AREA_NOT_FOUND = "GET_DELIVERY_AREA_NOT_FOUND";
export const GET_DELIVERY_AREA_ERROR = "GET_DELIVERY_AREA_ERROR";

export const CLEAR_DELIVERY_AREA = "CLEAR_DELIVERY_AREA";

export const GET_PUSH = "GET_PUSH";

export const SSI_REQUEST = "SSI_REQUEST";
export const SSI_LOADED = "SSI_LOADED";
export const SSI_ERROR = "SSI_ERROR";

export const SOURCE = "SOURCE";
export const VK_USER_ID = "VK_USER_ID";

export const DELIVERY_AREAS_LOADED = "DELIVERY_AREAS_LOADED";