// import { AsyncStorage } from 'react-native'
import { HTTP } from '../../utils/http-common';
import { ERROR } from '../../utils/errors'

import {
    AUTH_REQUEST,
    AUTH_SUCCESS,
    AUTH_ERROR,

    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_ERROR,

    GET_CLIENT_REQUEST,
    GET_CLIENT_SUCCESS,
    GET_CLIENT_ERROR,

    UPD_CLIENT_REQUEST,
    UPD_CLIENT_SUCCESS,
    UPD_CLIENT_ERROR,

    CLIENT_CLEAR,

    SMS_CODE_REQUEST,
    SMS_CODE_SUCCESS,
    SMS_CODE_ERROR,

    CHECK_PHONE_REQUEST,
    CHECK_PHONE_SUCCESS,
    CHECK_PHONE_ERROR,

    SET_PHONE_REQUEST,
    SET_PHONE_SUCCESS,
    SET_PHONE_ERROR,

    SAVE_TOKEN,
    CLEAR_TOKEN,

    GET_ORDERS_REQUEST,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_ERROR,

    GET_ORDER_REQUEST,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,

    GET_ADDRESSES_REQUEST,
    GET_ADDRESSES_SUCCESS,
    GET_ADDRESSES_ERROR,

    GET_ADDRESS_REQUEST,
    GET_ADDRESS_SUCCESS,
    GET_ADDRESS_ERROR,

    SAVE_ADDRESS_REQUEST,
    SAVE_ADDRESS_SUCCESS,
    SAVE_ADDRESS_ERROR,

    DELETE_ADDRESS_REQUEST,
    DELETE_ADDRESS_SUCCESS,
    DELETE_ADDRESS_ERROR,

} from "../ActionTypes";

const initialState = {
  client: {},
  clientStatus: {},
  token: (typeof localStorage != 'undefined') ? localStorage.getItem('user-token') || '' : '', // TODO !?
  authStatus: {},
  user: (typeof localStorage != 'undefined') ? JSON.parse(localStorage.getItem('user')) || {} : {}, // TODO !?
  isAuthenticated: false,
  smsCodeStatus: {},
  checkPhoneStatus: {},
  setPhoneStatus: {},
  orders: [],
  ordersStatus: {},
  order: {},
  orderStatus: {},
  addresses: [],
  addressesStatus: {},
  address: {},
  addressStatus: {},
};

export default (state = initialState, action) => {
    let status = {}
    switch (action.type) {

        case AUTH_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                authStatus: status,
            }

        case AUTH_SUCCESS:
            status = { code: 'success' }
            typeof localStorage != 'undefined' && localStorage.setItem('user-token', action.data.token)
            typeof localStorage != 'undefined' && localStorage.setItem('user', JSON.stringify(action.data.data))
            HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + action.data.token
            return {
                ...state,
                authStatus: status,
                user: action.data.data,
                token: action.data.token,
            }

        case AUTH_ERROR:
            status = ERROR.format(action.data)
            typeof localStorage != 'undefined' && localStorage.removeItem('user-token')
            typeof localStorage != 'undefined' && localStorage.removeItem('user')
            delete HTTP.defaults.headers.common['Authorization']
            return {
                ...state,
                authStatus: status,
                user: {},
                token: '',
            }

        case GET_USER_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                userStatus: status,
            }

        case GET_USER_SUCCESS:
            status = { code: 'success' }
            return {
                ...state,
                userStatus: status,
                user: action.data,
            }

        case GET_USER_ERROR:
            status = ERROR.format(action.data)
            return {
                ...state,
                userStatus: status,
                user: {},
            }

        case CLIENT_CLEAR:
            status = { code: '' }
            return {
                ...state,
                clientStatus: status,
                client: {},
                // user: {}, // TODO ? мб юзера не чистить? токен ведь все равно не удаляем
                checkPhoneStatus: status,
                smsCodeStatus: status,
                setPhoneStatus: status,
            }
    
        case GET_CLIENT_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                clientStatus: status,
            }

        case GET_CLIENT_SUCCESS:
            status = { code: 'success' }
            return {
                ...state,
                clientStatus: status,
                client: action.data,
            }

        case GET_CLIENT_ERROR:
            status = ERROR.format(action.data)
            return {
                ...state,
                clientStatus: status,
                client: {},
            }

        case UPD_CLIENT_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                clientStatus: status,
            }

        case UPD_CLIENT_SUCCESS:
            status = { code: 'success' }
            return {
                ...state,
                clientStatus: status,
                client: action.data,
            }

        case UPD_CLIENT_ERROR:
            status = ERROR.format(action.data)
            return {
                ...state,
                clientStatus: status,
                // client: {},
            }

        case SAVE_TOKEN:
            return {
                ...state,
                token: action.data,
                isAuthenticated: true,
            }

        case CLEAR_TOKEN:
            // AsyncStorage.setItem('token', '')
            return {
                ...state,
                token: '',
                isAuthenticated: false,
                user: {},
                client: {},
                userStatus: {},
                clientStatus: {},
            }

        case SMS_CODE_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                smsCodeStatus: status,
            }

        case SMS_CODE_SUCCESS:
            status = { code: 'success' }
            return {
                ...state,
                smsCodeStatus: status,
            }

        case SMS_CODE_ERROR:
            status = ERROR.format(action.data)
            return {
                ...state,
                smsCodeStatus: status,
            }

        case CHECK_PHONE_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                checkPhoneStatus: status,
                user: {},
                userStatus: status,
            }

        case CHECK_PHONE_SUCCESS:
            status = { code: 'success' }
            return {
                ...state,
                checkPhoneStatus: status,
                user: action.data,
                userStatus: status,
            }

        case CHECK_PHONE_ERROR:
            status = ERROR.format(action.data)
            return {
                ...state,
                checkPhoneStatus: status,
                user: {},
                userStatus: status,
            }

        case SET_PHONE_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                setPhoneStatus: status,
            }

        case SET_PHONE_SUCCESS:
            status = { code: 'success' }
            return {
                ...state,
                setPhoneStatus: status,
            }

        case SET_PHONE_ERROR:
            status = ERROR.format(action.data)
            return {
                ...state,
                setPhoneStatus: status,
            }

        case GET_ORDERS_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                ordersStatus: status,
            }

        case GET_ORDERS_SUCCESS:
            status = { code: 'success' }
            return {
                ...state,
                ordersStatus: status,
                orders: action.data,
            }

        case GET_ORDERS_ERROR:
            status = ERROR.format(action.data)
            return {
                ...state,
                ordersStatus: status,
                orders: [],
            }

        case GET_ORDER_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                orderStatus: status,
            }

        case GET_ORDER_SUCCESS:
            status = { code: 'success' }
            return {
                ...state,
                orderStatus: status,
                order: action.data,
            }

        case GET_ORDER_ERROR:
            status = ERROR.format(action.data)
            return {
                ...state,
                orderStatus: status,
                order: {},
            }

        case GET_ADDRESSES_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                addressesStatus: status,
            }

        case GET_ADDRESSES_SUCCESS:
            status = { code: 'success' }
            return {
                ...state,
                addressesStatus: status,
                addresses: action.data,
            }

        case GET_ADDRESSES_ERROR:
            status = ERROR.format(action.data)
            return {
                ...state,
                addressesStatus: status,
                addresses: [],
            }

        case GET_ADDRESS_REQUEST:
            status = { code: 'loading' }
            return {
                ...state,
                addressStatus: status,
            }

        case GET_ADDRESS_SUCCESS:
            status = { code: 'success' }
            return {
                ...state,
                addressStatus: status,
                address: action.data,
            }

        case GET_ADDRESS_ERROR:
            status = ERROR.format(action.data)
            return {
                ...state,
                addressStatus: status,
                address: {},
            }

        default:
            return state
    }
};