import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import bridge from '@vkontakte/vk-bridge';
import _ from 'lodash'
import { View, Div, Alert, Group, Header, Headline, FixedLayout, SimpleCell, InfoRow, Text, Button, Radio, Checkbox, Separator } from '@vkontakte/vkui';
import { Icon16Minus, Icon16Add, Icon24DoNotDisturb, Icon16New } from '@vkontakte/icons';

import * as cartActions from '../store/actions/cart';

const ProductDetail = props => {
	const router = useRouter();

  const product = props.product
  if(typeof product == 'undefined') {
    return null
  }

  const [curSkuId, setCurSkuId] = useState(product.sku[0].id)
  const [curSku, setCurSku] = useState(product.sku[0])
  const [options, setOptions] = useState({})

  function setOption(key, value) {
    setOptions({...options, [key]: value})
  }
  
  function add(id, data) {
    const section_id = product.parent_section_ids[0]
    const section = props.sections.find(section => section.id == section_id) || {}
    const meta = {
      product: {
        id: curSku.id,
        name: curSku.name,
      },
      category: {
        id: section.id,
        name: section.name,
      },
      currency: props.affiliate.currency
    }
    if(product.min_quantity) {
      data.min_quantity = product.min_quantity
    }
    props.addProduct(id, data, meta)
  }

  function getCount(id, items, options) {
    let count = 0
    if(typeof items != 'undefined') {
      count = items.reduce((sum, item) => {
        if(item.id == id && [].concat(item.data.options).filter(item => !!item).map(item => item.id).sort().toString() == [].concat(options).filter(item => !!item).map(item => item.id).sort().toString() ) {
          return sum + item.data.quantity
        } else {
          return sum
        }
      }, 0)
    }
    return count
  }
  
  function allOptions() {
    let allOptions = []
    curSku.options_groups && curSku.options_groups.map(group => {
      allOptions = allOptions.concat(group.options)
    })
    return allOptions.filter(item => !!item)
  }
  
  function calcSelectedOptions() {
    let selectedOptions = Object.keys(options)
    .filter(item => {
      return !isNaN(item) || options[item] === true
    })
    .map(item => {
      if(isNaN(item)) {
        return item.split(":")[1]
      } else {
        return options[item]
      }
    })
    return selectedOptions
  }

  const selectedOptions = calcSelectedOptions()

  function toggleHiddenOptions() {
    if(_.get(curSku, 'options_groups', []).length > 0) {
      curSku.options_groups.map(options_group => {
        if(options_group.min_selected == 1 && options_group.max_selected == 1) {
          // console.log('Скрытые активные ('+options_group.name+')', options_group.options.filter(item => item && item.conditions && item.conditions.with_options && item.conditions.with_options.filter(wo => selectedOptions.indexOf(wo) !== -1).length == 0).filter(option => selectedOptions.indexOf(option.id) !== -1).length )
          if([].concat(options_group.options).filter(item => item && item.conditions && item.conditions.with_options && item.conditions.with_options.filter(wo => selectedOptions.indexOf(wo) !== -1).length == 0).filter(option => selectedOptions.indexOf(option.id) !== -1).length > 0) {
            const firstVisibleOption = [].concat(options_group.options).find(item => item && item.conditions && item.conditions.with_options && item.conditions.with_options.filter(wo => selectedOptions.indexOf(wo) !== -1).length > 0)
            if(firstVisibleOption && firstVisibleOption.id) {
              setOption(options_group.id, firstVisibleOption.id)
            }
          }
        } else {
          if([].concat(options_group.options).filter(item => item && item.conditions && item.conditions.with_options && item.conditions.with_options.filter(wo => selectedOptions.indexOf(wo) !== -1).length == 0).filter(option => selectedOptions.indexOf(option.id) !== -1).length > 0) {
            setOption(options_group.id+':'+option.id, false)
          }
        }
      })
    }
  }

  function dataOptions() {
    return allOptions().filter(item => !!item && selectedOptions.indexOf(item.id) != -1)
  }

  function calcPrice() {
    let basePrice = curSku.price || 0
    
    let price = allOptions().reduce((sum, option) => {
      if(selectedOptions.indexOf(option.id) != -1) {
        return Number(sum) + Number(option.price)
      } else {
        return Number(sum) 
      }
    }, basePrice)
    return price
  }

  function calcWeight() {
    let baseWeight = curSku.weight || 0
    
    let weight = allOptions().reduce((sum, option) => {
      if(selectedOptions.indexOf(option.id) != -1) {
        return Number(sum) + Number(option.weight)
      } else {
        return Number(sum) 
      }
    }, baseWeight)
    return weight
  }

  function checkOptions() {
    if(!curSku.options_groups) return true
    return curSku.options_groups.reduce((sum, group) => {
      const groupOptions = [].concat(group.options).filter(item => !!item)
      if(!groupOptions.length) return sum
      let inGroupSelectedOptions = groupOptions.filter(item => {
        return selectedOptions.indexOf(item.id) != -1
      }) || []
      let groupSelected = inGroupSelectedOptions.length
      if(group.min_selected <= groupSelected && groupSelected <= group.max_selected) {
        return sum
      } else {
        return sum + 1
      }
    }, 0) == 0
  }

  function checkGroupOptions(groupId) {
    let group = [].concat(curSku.options_groups).filter(group => group.id == groupId).shift()
    let inGroupSelectedOptions = [].concat(group.options).filter(item => !!item).filter(option => {
      return selectedOptions.indexOf(option.id) != -1
    }) || []
    let groupSelected = inGroupSelectedOptions.length
    if(group.min_selected <= groupSelected && groupSelected <= group.max_selected) {
      return true
    } else {
      return false
    }
  }

  function calcSelectedOptionsCount(options_group) {
    return [].concat(options_group.options).filter(item => selectedOptions.indexOf(item.id) != -1).length || 0
  }

  function getVisibleOptions(options_group) {
    return [].concat(options_group.options).filter(item => item && (!item.conditions || !item.conditions.with_options || item.conditions.with_options.filter(wo => selectedOptions.indexOf(wo) !== -1).length > 0))
  }

  const inCartCount = getCount(curSku.id, props.cartProducts, dataOptions())
  const price = calcPrice()
  const weight = calcWeight()

  useEffect(() => {
    // Analytics.logEvent('view_item', {
    //   item_id: product.id,
    //   // item_location_id: '',
    // })
    // AppMetrica.reportEvent('view_item', {
    //   item_id: product.id,
    //   // item_location_id: '',
    // })
    // Analytics.logEvent('product_detail_view', {
    //   product_id: product.id,
    //   product_name: product.name,
    // })
    // AppMetrica.reportEvent('product_detail_view', {
    //   product_id: product.id,
    //   product_name: product.name,
    // })

    // Установим опции по умолчанию
    // TODO ? чет работает (не для всех групп опций)
    // if(_.get(curSku, 'options_groups', []).length > 0) {
    //   curSku.options_groups.map(options_group => {
    //     if(options_group.min_selected == 1 && options_group.max_selected == 1) {
    //       if(options_group.options.filter(item => item).length > 0) {
    //         setOption(options_group.id, options_group.options.filter(item => item)[0]['id'])
    //       }
    //     }
    //   })
    // }
  }, [])

  useEffect(() => {
    setCurSku(product.sku.filter(item => item.id == curSkuId).shift())
  }, [curSkuId])

  useEffect(() => {
    toggleHiddenOptions()
  }, [selectedOptions])

  let buyButtonsView = null
  if(price > 0 || curSku.can_buy_0) {
    if(inCartCount > 0) {
      buyButtonsView = 
        <div className="ProductDetail__PlusMinus">
          <Button size='l'
            onClick={() => {
              props.delProduct(curSku.id, {options: dataOptions()})
              bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }}><Icon16Minus style={{ color: '#fff', marginTop: '2px' }} width={18} height={18} /></Button>
          <span>{ inCartCount }</span>
          <Button size='l'
            onClick={() => {
              add(curSku.id, {options: dataOptions()})
              bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }}><Icon16Add style={{ color: '#fff', marginTop: '2px' }} width={18} height={18} /></Button>
        </div>
    } else {
      buyButtonsView = 
        <div>
          <Button size='l' mode='commerce'
            onClick={() => {
              const data = {
                price: curSku.price, 
                options: dataOptions(),
              }
              if(props.isRecommend) {
                data.recommend = 1
              }
              add(curSku.id, data)
              bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
            }}>В корзину</Button>
        </div>
    }
  }
  if(!checkOptions()) {
    buyButtonsView = 
      <div>
        <Button size='l' mode='outline'>Выберите опции</Button>
      </div>
  }

  const isNutritional = (curSku.nutritional?.energy || curSku.nutritional?.protein || curSku.nutritional?.fats || curSku.nutritional?.carbohydrates)
  const isNutritionalTotal = (curSku.nutritional_total?.energy || curSku.nutritional_total?.protein || curSku.nutritional_total?.fats || curSku.nutritional_total?.carbohydrates)

	return (
		<div className="ProductDetail">
      <div class='ActionDetail__Img'>
        <img src={ 'https://dlvry.ru' + props.product.img?.src } alt={ props.product.name } />
      </div>
      <Div>
        <div className="ProductDetail__Title">
          { curSku.name }
          {
            curSku.weight > 0 && <span> { curSku.weight }&nbsp;гр.</span> ||
            curSku.volume > 0 && <span> { curSku.volume }&nbsp;л.</span> ||
            curSku.size && <span> { curSku.size }</span>
          }
        </div>
        <div className="ProductDetail__Description" dangerouslySetInnerHTML={{ __html: curSku.detail_text || curSku.preview_text }}></div>
        {
          typeof props.owner.labels != 'undefined' && !!product.labels &&
          <div className="ProductDetail__Labels">
            {product.labels.filter(label => !!props.owner.labels[label]).map(label => 
              <div className="ProductDetail__Label" key={label} 
                style={{ paddingRight: (!!props.owner.labels[label]['title']) ? '8px' : '4px', paddingLeft: !!props.owner.labels[label]['icon'] ? '4px' : '8px',  backgroundColor: '#'+props.owner.labels[label]['bg_color'], color: '#'+props.owner.labels[label]['color']}}>
                {/*{!!props.owner.labels[label]['icon'] && <UIIcon name={props.owner.labels[label]['icon']} width={20} style={{marginRight: (!!props.owner.labels[label]['title']) ? '4px' : null}} color={'#'+props.owner.labels[label]['color']} />}*/}
                {!!props.owner.labels[label]['icon'] && <Icon16New width={18} height={18} style={{marginRight: (!!props.owner.labels[label]['title']) ? '4px' : null, color: '#'+props.owner.labels[label]['color']}} />}
                {!!props.owner.labels[label]['title'] && <Text className="ProductCard__LabelText">{ props.owner.labels[label]['title'] }</Text>}
              </div>
            )}
          </div>
        }
        { 
          isNutritional && 
          <div className="ProductDetail__Nutritional">
            <div className="ProductDetail__NutritionalHeader">Пищевая ценность на 100 г</div>
            {curSku.nutritional?.energy &&
              <div className="ProductDetail__NutritionalItem">
                <div className="ProductDetail__NutritionalText">Энерг. ценность</div>
                <div className="ProductDetail__NutritionalText">{ curSku.nutritional?.energy } ккал</div>
              </div>}
            {curSku.nutritional?.protein &&
              <div className="ProductDetail__NutritionalItem">
                <div className="ProductDetail__NutritionalText">Белки</div>
                <div className="ProductDetail__NutritionalText">{ curSku.nutritional?.protein } г</div>
              </div>}
            {curSku.nutritional?.fats &&
              <div className="ProductDetail__NutritionalItem">
                <div className="ProductDetail__NutritionalText">Жиры</div>
                <div className="ProductDetail__NutritionalText">{ curSku.nutritional?.fats } г</div>
              </div>}
            {curSku.nutritional?.carbohydrates &&
              <div className="ProductDetail__NutritionalItem">
                <div className="ProductDetail__NutritionalText">Углеводы</div>
                <div className="ProductDetail__NutritionalText">{ curSku.nutritional?.carbohydrates } г</div>
              </div>}
            <Separator style={{ margin: '12px -12px 10px' }} />
            {weight &&
              <div className="ProductDetail__NutritionalItem">
                <div className="ProductDetail__NutritionalText">Вес</div>
                <div className="ProductDetail__NutritionalText">{ weight } гр.</div>
              </div>}
          </div>
        }
        { 
          (!isNutritional && isNutritionalTotal) &&
          <div className="ProductDetail__Nutritional">
            <div className="ProductDetail__NutritionalHeader">Пищевая ценность {!!product.unit?.name && '1 ' + product.unit?.name}</div>
            {curSku.nutritional_total?.energy &&
              <div className="ProductDetail__NutritionalItem">
                <div className="ProductDetail__NutritionalText">Энерг. ценность</div>
                <div className="ProductDetail__NutritionalText">{ curSku.nutritional_total?.energy } ккал</div>
              </div>}
            {curSku.nutritional_total?.protein &&
              <div className="ProductDetail__NutritionalItem">
                <div className="ProductDetail__NutritionalText">Белки</div>
                <div className="ProductDetail__NutritionalText">{ curSku.nutritional_total?.protein } г</div>
              </div>}
            {curSku.nutritional_total?.fats &&
              <div className="ProductDetail__NutritionalItem">
                <div className="ProductDetail__NutritionalText">Жиры</div>
                <div className="ProductDetail__NutritionalText">{ curSku.nutritional_total?.fats } г</div>
              </div>}
            {curSku.nutritional_total?.carbohydrates &&
              <div className="ProductDetail__NutritionalItem">
                <div className="ProductDetail__NutritionalText">Углеводы</div>
                <div className="ProductDetail__NutritionalText">{ curSku.nutritional_total?.carbohydrates } г</div>
              </div>}
            <Separator style={{ margin: '12px -12px 10px' }} />
            {weight &&
              <div className="ProductDetail__NutritionalItem">
                <div className="ProductDetail__NutritionalText">Вес</div>
                <div className="ProductDetail__NutritionalText">{ weight } гр.</div>
              </div>}
          </div>
        }
        {
          product.sku.length > 1 &&
          <div className="ProductDetail__Properties">
            <div className="ProductDetail__PropertiesHeader">Выберите вариант</div>
            {
              product.sku.map(sku => 
              <Radio 
                value={sku.id} checked={curSkuId == sku.id} key={sku.id} 
                onChange={() => {
                  setCurSkuId(sku.id)
                }}>{ sku.property }</Radio>)
            }
          </div>
        }
        {
          (curSku.options_groups && curSku.options_groups.length > 0) &&
          curSku.options_groups
          .filter(optionsGroup => getVisibleOptions(optionsGroup).length > 0 )
          .map(optionsGroup => 
            <div className="ProductDetail__Properties" key={optionsGroup.id}>
              <div className={"ProductDetail__PropertiesHeader " + (!checkGroupOptions(optionsGroup.id) && 'ProductDetail__PropertiesHeader--error')}>{ optionsGroup.name }</div>
              {
                optionsGroup.max_selected > 1 &&
                <div className="ProductDetail__PropertiesSubheader">
                  {
                    optionsGroup.min_selected != optionsGroup.max_selected 
                    ? 'Выберите' + (optionsGroup.min_selected > 0 ? ' от ' + optionsGroup.min_selected : '') + ' до ' + optionsGroup.max_selected
                    : 'Выберите ' + optionsGroup.min_selected
                  }
                </div>
              }
              {
                optionsGroup.description?.length > 1 &&
                <div className="ProductDetail__PropertiesSubheader">
                  { optionsGroup.description }
                </div>
              }
              {
                optionsGroup.min_selected == 1 && optionsGroup.max_selected == 1
                ? getVisibleOptions(optionsGroup).map(option => 
                  <Radio checked={options[optionsGroup.id] == option.id} key={option.id} 
                    numberOfLines={0}
                    description={option.description}
                    onChange={() => {
                      setOption(optionsGroup.id, option.id)
                    }}>
                    { option.name } 
                    <span style={{color: 'var(--text_secondary)'}}>{ (option.price > 0 ? ' +' + option.price + '\u00A0' + props.affiliate.currency.sign : '')}</span>
                  </Radio>)
                : getVisibleOptions(optionsGroup).map(option => 
                  <Checkbox checked={options[optionsGroup.id+':'+option.id]} key={option.id} 
                    numberOfLines={0}
                    disabled={ !options[optionsGroup.id+':'+option.id] && calcSelectedOptionsCount(optionsGroup) >= optionsGroup.max_selected }
                    description={option.description}
                    onChange={() => {
                      setOption(optionsGroup.id+':'+option.id, !options[optionsGroup.id+':'+option.id])
                    }}>
                    { option.name }
                    <span style={{color: 'var(--text_secondary)'}}>{ (option.price > 0 ? ' +' + option.price + '\u00A0' + props.affiliate.currency.sign : '')}</span>
                  </Checkbox>)
              }
            </div>
          )
        }
        {
          product.min_quantity > 0 &&
          <div className="ProductDetail__MinQnt">
            Минимальное количество товара в заказе: {product.min_quantity}&nbsp;{product.unit?.name || 'шт.'}
          </div>
        }
      </Div>
      <FixedLayout vertical="bottom" style={{ marginBottom: '-40px'}}>
        <Div className="ProductDetail__Bottom">
          <div className="ProductDetail__Price">
            { curSku.old_price > 0 &&
              <div className="ProductDetail__PriceOld">
                { curSku.old_price }
              </div>
            }
            {
              price > 0 &&
              <div>{ price } { props.affiliate.currency.sign }</div>
            }
          </div>
          { buyButtonsView }
        </Div>
      </FixedLayout>
	  </div>
	)
};

function mapStateToProps(state) {
  return {
    owner: state.owner.item,
    affiliate: state.affiliates.item,
    cartProducts: state.cart.products,
    sections: state.catalog.sections,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (id, data, meta) => dispatch(cartActions.addProduct(id, data, meta)),
    delProduct: (id, data) => dispatch(cartActions.delProduct(id, data)),
    addGift: (id, data) => dispatch(cartActions.addGift(id, data)),
    delGift: (id) => dispatch(cartActions.delGift(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);

