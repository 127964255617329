import _ from 'lodash';
// import { showMessage } from "react-native-flash-message";
// import Colors from "../constants/Colors"

const HTTP_STATUS_CODES = {
	400: {"text": "Bad Request"},
	401: {"text": "Unauthorized"},
	402: {"text": "Payment Required"},
	403: {"text": "Forbidden"},
	404: {"text": "Not Found"},
	405: {"text": "Method Not Allowed"},
}

export const ERROR = {
	// show: function(err, title) {
	// 	let message = title
	// 	let description = ""

	// 	if(typeof err === 'string') {
	// 		description = err
	// 	} else if(typeof err.statusText != 'undefined' && err.statusText) {
	// 		if(title) {
	// 			description = err.statusText
	// 		} else {
	// 			message = err.statusText
	// 		}
	// 	} else {
	// 		if(!title) {
	// 			message = "Error"
	// 		}
	// 	}

	// 	if(typeof err.message == 'object' && err.message.text) {
	// 		if(description) {
	// 			description += ": " + err.message.text
	// 		} else {
	// 			description = err.message.text
	// 		}
	// 	} else if(typeof err.message != 'undefined') {
	// 		if(description) {
	// 			description += ": " + err.message
	// 		} else {
	// 			description = err.message
	// 		}
	// 	}

	// 	showMessage({
 //      message: message,
 //      description: description,
 //      type: "default",
 //      backgroundColor: Colors.red,
 //      color: "#ffffff",
 //    })
	// },
	make: function(status, message) {
		let error = {
			status,
      message,
		} 
		return this.format(error)
	},
	format: function(err) {
		let error = {}
		error.code = _.get(err, 'code') || 'error'
		error.status = _.get(err, 'response.status') || _.get(err, 'status') || 0
		error.statusText = _.get(err, 'response.statusText') || _.get(err, 'statusText') || _.get(HTTP_STATUS_CODES, [error.status, "text"]) || ''
		error.message = _.get(err, 'response.data.error.error_msg') || _.get(err, 'message') || 'ups..'
		return error
	}
}