import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash';
import bridge from '@vkontakte/vk-bridge';
import { View, Spinner, Radio, HorizontalScroll, HorizontalCell, usePlatform, Separator, SliderSwitch, Alert, PanelHeaderButton, Headline, Subhead, CardGrid, Card, ContentCard, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon16Add, Icon16Minus, Icon16ChevronOutline, Icon20Check, Icon24DoNotDisturb, Icon24ClockOutline, Icon28Newsfeed, Icon24Place, Icon24Home, Icon20Dropdown, Icon28BlockOutline, Icon20FavoriteCircleFillYellow, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon28UnfavoriteOutline, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import * as cartActions from '../store/actions/cart';

const mapStateToProps = (state, props) => {
  return { 
    owner: state.owner.item,
    affiliate: state.affiliates.item,
    cartProducts: state.cart.products,
    sections: state.catalog.sections,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (id, data, meta) => dispatch(cartActions.addProduct(id, data, meta)),
    delProduct: (id, data) => dispatch(cartActions.delProduct(id, data)),
  }
}

const RecommendCard = props => {
  
  const product = props.product

  const [curSkuId, setCurSkuId] = useState(product.sku[0].id)
  const [curSku, setCurSku] = useState(product.sku[0])
  
  function add(id, data) {
    data.recommend = 1
    const section_id = product.parent_section_ids[0]
    const section = props.sections.find(section => section.id == section_id) || {}
    const meta = {
      product: {
        id: curSku.id,
        name: curSku.name,
      },
      category: {
        id: section.id,
        name: section.name,
      },
      currency: props.affiliate.currency
    }
    if(product.min_quantity) {
      data.min_quantity = product.min_quantity
    }
    props.addProduct(id, data, meta)
    bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
  }

  const imgSrc = (curSku?.img?.src.indexOf('default-400x300') === -1) ? 'https://dlvry.ru' + curSku?.img?.src : 'https://dlvry.ru/img/default-400x300.png';

  useEffect(() => {
    const newSku = product.sku.find(item => {
      return item.id == curSkuId
    })
    if(newSku.id !== curSku.id) {
      setCurSku(newSku)
    }
  },[curSkuId])

  return (
    <div>
  	<div className="RecommendCard">
      <div>
        <div className="RecommendCard__Img" onClick={props.onClick} style={{backgroundImage: `url(${imgSrc})`}} />
      </div>
      <div className="RecommendCard__Inner">
        <div className="RecommendCard__Text" onClick={props.onClick}>
          <div className="RecommendCard__Title">
            { curSku.name }
          </div>
          <div className="RecommendCard__Price">{ props.isGift ? 'подарок' : curSku.price +' '+ props.affiliate.currency.sign }</div>
        </div>
        <div className="RecommendCard__Buy">
          {
          (product.options_groups && product.options_groups.length > 0)
          || (product.sku && product.sku.length > 1)
          ? <Button size='m' mode='outline'
              style={{borderRadius: '4px', flex: 1}}
              onClick={ props.onClick }>Выбрать</Button>
          : <Button size='m' mode='outline'
              style={{borderRadius: '4px', flex: 1}}
              onClick={() => {
                add(curSku.id, {price: curSku.price})
              }}>В корзину</Button>
          }
        </div>
      </div>
    </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(RecommendCard);