import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import { Cell, Div, Group, Spinner, Header, SimpleCell, InfoRow, withModalRootContext } from '@vkontakte/vkui';
import { Icon24CheckCircleOn, Icon24CheckCircleOff, Icon24Add, Icon24Place, Icon24PlaceOutline } from '@vkontakte/icons';

import * as affiliatesActions from '../store/actions/affiliates';
import * as cartActions from '../store/actions/cart';

const Affiliates = props => {
	const router = useRouter();

  useEffect(() => {
    // async function getActions() {
    //   await props.dispatch(actionsActions.getActions(props.affiliate.id));
    // }
    // props.getAddresses()
    // .then(data => {
    //   props.updateModalHeight();
    //   if(!data.items || !data.items.length) {
    //     // props.navigation.navigate('NewAddress', {address_id: 0})
    //   }
    // })
    // .catch(err => {})
    // function initOrderForm() {
    //   let initOrderForm = _.cloneDeep(props.orderForm) || {}
    //   if(!initOrderForm.delivery || !!props.affiliate.pickup_only) {
    //     initOrderForm.delivery = (!!props.affiliate.pickup_only) ? 'self' : 'delivery'
    //   }
    //   if(!initOrderForm.pickup_point) {
    //     // initOrderForm.pickup_point = _.get(props, 'affiliate.pickup_points[0].name')
    //   }
    //   if(!initOrderForm.address_district) {
    //     initOrderForm.address_district = _.get(props, 'affiliate.districts[0].name')
    //   }
    //   props.dispatch(cartActions.saveOrderForm(initOrderForm));
    // }
    // if(props.affiliate.id) {
    //   if(props.actionsStatus.code !== 'success' && !props.actions.length) {
    //     getActions();
    //   }
    //   initOrderForm();
    // }
  }, []);

	return (
		<div>      
      <Group>
      {
        props.affiliates
        .map(item => {
          return (
            <Cell 
              key={item.id}
              multiline
              before={
                props.affiliate.id === item.id
                ? <Icon24CheckCircleOn style={{color: 'var(--color-1)'}} />
                : <Icon24CheckCircleOff style={{color: 'var(--icon_secondary)'}} />
              }
              onClick={() => {                
                if(props.affiliate.id !== item.id) {
                  props.clearCart()
                  props.getAffiliate(item.id)
                }
                props.onModal(null)
              }}
              description={item.addresses.map(address => address.address).join(", ")}
              >{item.name}</Cell>)
        })
      }
      </Group>
	  </div>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    affiliates: state.affiliates.items,
    owner: state.owner.item,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // setAffiliate: data => dispatch(affiliatesActions.setAffiliate(data)),
    getAffiliate: affiliate_id => dispatch(affiliatesActions.getAffiliate(affiliate_id)),
    clearCart: () => dispatch(cartActions.clearCart()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withModalRootContext(Affiliates));

