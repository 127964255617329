import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { View, ModalRoot, ModalPage, usePlatform, ModalPageHeader, PanelHeaderButton, PanelHeaderClose } from '@vkontakte/vkui';
import { Icon24Dismiss } from '@vkontakte/icons';

import MenuDefault from '../panels/Menu';
import MenuAbout from '../panels/MenuAbout';
import MenuContacts from '../panels/MenuContacts';
import MenuReviews from '../panels/MenuReviews';
import MenuInfo from '../panels/MenuInfo';
import MenuAboutApp from '../panels/MenuAboutApp';

import DeliveryAreaModal from '../modals/DeliveryArea';

const Menu = props => {

	const platform = usePlatform()

	const [deliveryAreaModal, setDeliveryAreaModal] = useState({})
	const [activeModal, setActiveModal] = useState(null);

	const modal = (
    <ModalRoot activeModal={activeModal} onClose={() => setActiveModal(null)}>
      <ModalPage id="deliveryArea"
      	settlingHeight={75}
	      header={
	        <ModalPageHeader
	          right={platform === 'ios' && <PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss/></PanelHeaderButton>}
	          left={platform === 'android' && <PanelHeaderClose onClick={() => setActiveModal(null)}/>}
	        >
	        Зона доставки
        	</ModalPageHeader>
      	}
      	>
      	<DeliveryAreaModal area={deliveryAreaModal} />
      </ModalPage>
    </ModalRoot>
  );

  function toggleModalDeliveryArea(data) {
  	if(!data) {
  		setActiveModal(null);
  	} else {
  		setDeliveryAreaModal(data);
  		setActiveModal('deliveryArea');
  	}
  }

	return (
		<View id={props.id} activePanel={props.activePanel} modal={modal}>
			<MenuDefault id="default" />		
			<MenuAbout id="about" />
			<MenuContacts id="contacts" />
			<MenuReviews id="reviews" />
			<MenuInfo id="info" onModal={toggleModalDeliveryArea} />
			<MenuAboutApp id="aboutApp" />
		</View>
	)
};

export default Menu;
