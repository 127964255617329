import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash';
import bridge from '@vkontakte/vk-bridge';
import { Gallery, View, Spinner, Footer, Search, HorizontalScroll, HorizontalCell, usePlatform, Separator, SliderSwitch, Alert, PanelHeaderButton, Headline, Subhead, CardGrid, Card, ContentCard, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon16ChevronOutline, Icon24SearchSlashOutline, Icon20Check, Icon24DoNotDisturb, Icon24ClockOutline, Icon28Newsfeed, Icon24Place, Icon24Home, Icon20Dropdown, Icon28BlockOutline, Icon20FavoriteCircleFillYellow, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon28UnfavoriteOutline, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import TabsSelect from '../components/UI/TabsSelect';
import ProductCard from '../components/ProductCard';
import ProductCardMedium from '../components/ProductCardMedium';

import * as actionsActions from '../store/actions/actions';
import * as cartActions from '../store/actions/cart';

import declOfNum from '../utils/utils';



const HomeSection = props => {

	const router = useRouter();
	const platform = usePlatform();

  const [section_id, setSectionId] = useState(0)

  useEffect(() => {
    if(router.getState().params?.section_id) {
      setSectionId(router.getState().params?.section_id)
    }
  }, [router.getState().params?.section_id]);

  useEffect(() => {
    if(router.getState().params?.product_id) {
      const product = props.products.find(item => item.id == router.getState().params?.product_id)
      if(!!product) {
        const sku = props.products.filter(item => {
          return product.sku_ids.indexOf(item.id) != -1
        })
        product.sku = sku.map(item => {
          return {
            "id": item.id,
            "price": item.price,
            "old_price": item.old_price,
            "weight": item.weight,
            "volume": item.volume,
            "size": item.size,
            "name": item.name,
            "preview_text": item.preview_text,
            "detail_text": item.detail_text,
            "property": item.sku_for.title,
            "img": item.img,
            "options_groups": item.options_groups,
            "nutritional": item.nutritional,
            "nutritional_total": item.nutritional_total,
          }
        })
        props.onModal({type: 'PRODUCT_DETAIL', data: {product}})
      }
    }
  }, [router.getState().params?.product_id, props.products]);

	// const section_id = router.getState().params?.section_id
	const section = props.sections.find(section => section_id == section.id) || {}
  const sectionTemplate = _.get(section, 'template.code', 'grid')

	const affiliateProductsTemplate = _.get(props.affiliate, 'template["mobile.products.type"]', 'grid')

	const [activeLabel, setActiveLabel] = useState(null);
  const [activeSubsection, setActiveSubsection] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

	useEffect(() => {
		setActiveSubsection(null)
		setActiveLabel(null)
		setSearchQuery('')
		window.scrollTo(0, 0)
	}, [section_id]);

	const sectionProducts = props.products
    .filter(product => product.all_parent_section_ids.indexOf(section_id) != -1)
    .filter(item => !item.sku_for?.id || item.sku_for?.id == item.id)

  const filteredProducts = sectionProducts
    .filter(item => !activeSubsection || (item.all_parent_section_ids.indexOf(activeSubsection) !== -1))
    .filter(item => !activeLabel || (item.labels && item.labels.indexOf(activeLabel) !== -1))
    .filter(item => searchQuery.length == 0 || item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1 )

  const sectionProductsCount = sectionProducts.length;
  const filteredProductsCount = filteredProducts.length;

  function sectionSubSections() {
    return props.sections
      .filter(item => item.parent_section_id == section_id)
      .map(item => {
        return {
          id: item.id,
          name: item.name,
        }
      })
  }

  function sectionLabels() {
    // const sectionProducts = props.products
    //   .filter(product => product.all_parent_section_ids.indexOf(section.id) != -1)
    //   .filter(item => !item.sku_for.id || item.sku_for.id == item.id);

    let sectionLabelsCodes = Object.keys(props.owner.labels).filter(label => {
      return sectionProducts.filter(item => {
        return typeof item.labels == 'object' && item.labels.indexOf(label) != -1
      }).length > 0
    })
    let sectionLabels = []
    for (var i = 0; i < Object.keys(props.owner.labels).length; i++) {
      let key = Object.keys(props.owner.labels)[i]
      if(sectionLabelsCodes.indexOf(key) != -1) {
        sectionLabels.push(props.owner.labels[key])
      }
    }
    return sectionLabels
  }

  const subSections = sectionSubSections()

  let subSectionsScroll;
  if(subSections.length > 0) {
    subSectionsScroll = 
    <div className={`HorizontalSubmenu HorizontalSubmenu--${platform}`}>
      <HorizontalScroll>
      	<div className="HorizontalSubmenu__Container">
      		<Button key={section.id} mode={!activeSubsection ? "primary" : "secondary"} size="m"
      			onClick={() => setActiveSubsection(null)}>
      			Все разделы
      		</Button>          
          {subSections.map((subSection, key) => {
            return <Button key={section.id} mode={activeSubsection === subSection.id ? "primary" : "secondary"} size="m"
	      			onClick={() => setActiveSubsection(subSection.id)}>
	      			{ subSection.name }
	      		</Button>
          })}
        </div>
      </HorizontalScroll>
    </div>
  }

  // TODO
  const labels = [] // sectionLabels();

  // let labelsScroll;
  // if(labels.length > 0) {
  //   labelsScroll = 
  //     <View style={{width: '100%', marginTop: 12}}>
  //       <ScrollView horizontal 
  //         style={{}}
  //         showsHorizontalScrollIndicator={false}> 
  //         <View style={{height: 34, paddingLeft: 12, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
  //           <TouchableOpacity 
  //             style={{marginRight: 12, marginBottom: 0, lineHeight: 34, paddingHorizontal: 15, borderRadius: 5, 
  //               backgroundColor: !activeLabel ? colors.tint_color : '#fff', 
  //               // borderColor: !activeLabel ? 'transparent' : colors.tint_color,
  //               // borderWidth: 1,
  //               flexDirection: 'row', alignItems: 'center'}}
  //             onPress={() => {
  //               setActiveLabel(null);
  //             }}>
  //             <Text style={{fontSize: 15, fontWeight: '600', lineHeight: 34, 
  //               color: !activeLabel ? '#fff' : colors.tint_color}}>Все метки</Text>
  //           </TouchableOpacity> 
  //           {labels.map((label, key) => {
  //             return <TouchableOpacity key={key} 
  //               style={{marginRight: 12, marginBottom: 0, lineHeight: 34, borderRadius: 5, 
  //                 backgroundColor: '#fff', 
  //                 flexDirection: 'row', alignItems: 'center'}}
  //               onPress={() => {
  //                 setActiveLabel(label.code);
  //               }}>
  //                 <View
  //                   style={{
  //                     // opacity: (key == 2 ? 1 : 0.8),
  //                     lineHeight: 34, paddingBottom: 1, paddingHorizontal: (!!label.title) ? 12 : 4, paddingLeft: (!!label.icon && !!label.title) ? 8 : null, borderRadius: 5, 
  //                     backgroundColor: activeLabel === label.code ? '#'+label.bg_color : '#fff', 
  //                     flexDirection: 'row', alignItems: 'center'}}>
  //                   {!!label.icon && <UIIcon name={label.icon} width={20} style={{marginRight: (!!label.title) ? 4 : null, lineHeight: 34}} color={activeLabel === label.code ? '#'+label.color : colors.tint_color} />}
  //                   {!!label.title && <Text style={{fontSize: 15, fontWeight: '500', lineHeight: 34, 
  //                     color: activeLabel === label.code ? '#'+label.color : colors.tint_color
  //                     }}>{ label.title }</Text>}
  //                 </View>
  //               </TouchableOpacity>
  //           })}
  //         </View>
  //       </ScrollView>
  //     </View>
  //   _FlatListData.push({
  //     key: 'filter',
  //     view: labelsScroll,
  //   })
  // }

  const sectionItemsElements = filteredProducts.map(item => {
    const sku = props.products.filter(item2 => {
      return item.sku_ids.indexOf(item2.id) != -1
    })
    item.sku = sku.map(item => {
      return {
        "id": item.id,
        "price": item.price,
        "old_price": item.old_price,
        "weight": item.weight,
        "volume": item.volume,
        "size": item.size,
        "name": item.name,
        "preview_text": item.preview_text,
        "detail_text": item.detail_text,
        "property": item.sku_for.title,
        "img": item.img,
        "options_groups": item.options_groups,
        "nutritional": item.nutritional,
        "nutritional_total": item.nutritional_total,
      }
    })
    if(affiliateProductsTemplate == 'grid' && sectionTemplate != 'list') {
      return (<ProductCard 
        key={item.id}
        product={item}
        isGift={false}
        isCanAdd
        onClick={() => props.onModal({type: 'PRODUCT_DETAIL', data: {product: item}})}
      />)
    } else {
      return (<ProductCardMedium
        key={item.id}
        product={item}
        noImage={sectionTemplate == 'list'}
        isGift={false}
        isCanAdd
        onClick={() => props.onModal({type: 'PRODUCT_DETAIL', data: {product: item}})}
      />)
    }
    
  })

  if(props.affiliateInitStatus?.code !== 'success') {
    return (
      <Panel className="BackgroundPage" id={props.id}>
        <PanelHeader>Загрузка...</PanelHeader>
      </Panel>
    )
  }

	return (
		<Panel className="BackgroundPage" id={props.id}>
	    <PanelHeader left={<PanelHeaderBack onClick={() => router.navigate('home', {}, {replace: true})} />}>{ section.name }</PanelHeader>
      <Search value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}} />
      <div className={`HorizontalMenu HorizontalMenu--${platform}`} style={{marginTop: '-5px'}}>
      	<HorizontalScroll>
      		<div className="HorizontalMenu__Container">
      			{
      				props.rootSections.map(section => 
      					<Button key={section.id} mode={section.id == section_id ? "primary" : "secondary"} size="m" 
      						onClick={() => router.navigate('home/section', {section_id: section.id}, {reload: true, replace: true})}>{ section.name }</Button> 
      				)
      			}
          </div>
      	</HorizontalScroll>
      </div>
      { subSectionsScroll }
      {
      	filteredProductsCount > 0
      	?	<div className="BackgroundPage">
	      		<Div>
	      			{ sectionItemsElements }
	      			<Footer>
	      				{ (filteredProductsCount !== sectionProductsCount ? declOfNum(filteredProductsCount, ['Показан', 'Показаны', 'Показано']) + ' ' : '') + filteredProductsCount + ' ' + declOfNum(filteredProductsCount, ['товар', 'товара', 'товаров']) + (filteredProductsCount !== sectionProductsCount ? ' из ' + sectionProductsCount : '') + ' в этом разделе' }
	      			</Footer>
			      </Div>
	      	</div>
	      : sectionProductsCount > 0
          ? <Placeholder style={{marginTop: '150px'}} stretched icon={<Icon24SearchSlashOutline width={56} height={56} />}
  		        header="Ничего не найдено"
  		        action={
  	            <Button size="l" onClick={() => {
  	            	setActiveLabel(null);
                  setActiveSubsection(null);
                  setSearchQuery('');
  	            }}>Очистить фильтр</Button>
  	          }>
  		        Попробуйте изменить параметры поиска, или очистить фильтр.
  		      </Placeholder>
          : <Placeholder style={{marginTop: '150px'}} stretched icon={<Icon24SearchSlashOutline width={56} height={56} />}
              header="Товаров нет"
              >
              В этом разделе пусто.<br />Пожалуйста, выберите другой раздел.
            </Placeholder>
      }
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    affiliateStatus: state.affiliates.itemStatus,
    affiliateInitStatus: state.affiliates.itemInitStatus,
    affiliates: state.affiliates.items,
    owner: state.owner.item,
    sections: state.catalog.sections,
    rootSections: state.catalog.rootSections,
    orderForm: state.cart.orderForm,
    actions: state.actions.items,
    actionsStatus: state.actions.itemsStatus,
    deliveryArea: state.cart.deliveryArea,
    deliveryAreaStatus: state.cart.deliveryAreaStatus,
    products: state.catalog.products,
  };
}

export default connect(mapStateToProps)(HomeSection);


