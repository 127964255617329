import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import { Cell, Div, Text, Group, Spinner, Header, SimpleCell, InfoRow } from '@vkontakte/vkui';
import { Icon24CheckCircleOn, Icon24CheckCircleOff, Icon24Add, Icon24Place } from '@vkontakte/icons';

import declOfNum from '../utils/utils';

import * as userActions from '../store/actions/user';
import * as cartActions from '../store/actions/cart';

const Loyalty = props => {
	const router = useRouter();

  useEffect(() => {
    // async function getActions() {
    //   await props.dispatch(actionsActions.getActions(props.affiliate.id));
    // }
    // props.getAddresses()
    // .then(data => {
    //   props.updateModalHeight();
    //   if(!data.items || !data.items.length) {
    //     // props.navigation.navigate('NewAddress', {address_id: 0})
    //   }
    // })
    // .catch(err => {})
    // function initOrderForm() {
    //   let initOrderForm = _.cloneDeep(props.orderForm) || {}
    //   if(!initOrderForm.delivery || !!props.affiliate.pickup_only) {
    //     initOrderForm.delivery = (!!props.affiliate.pickup_only) ? 'self' : 'delivery'
    //   }
    //   if(!initOrderForm.pickup_point) {
    //     // initOrderForm.pickup_point = _.get(props, 'affiliate.pickup_points[0].name')
    //   }
    //   if(!initOrderForm.address_district) {
    //     initOrderForm.address_district = _.get(props, 'affiliate.districts[0].name')
    //   }
    //   props.dispatch(cartActions.saveOrderForm(initOrderForm));
    // }
    // if(props.affiliate.id) {
    //   if(props.actionsStatus.code !== 'success' && !props.actions.length) {
    //     getActions();
    //   }
    //   initOrderForm();
    // }
  }, []);

	return (
		<Div style={{paddingBottom: '20px'}}>
      {
        !!_.get(props, 'client.loyalty.status.name', '') &&
        <div>
          <Text style={{fontSize: '18px', fontWeight: '600', marginBottom: '7px', color: 'var(--text_primary)'}}>Ваш статус: <span style={{color: 'var(--color-1)'}}>{ _.get(props, 'client.loyalty.status.name', 'не определен') }</span></Text>
          {!!_.get(props, 'client.loyalty.status_score', 0) && <Text style={{marginBottom: '5px', fontSize: '16px', lineHeight: '20px',  color: 'var(--text_primary0'}}>Ваш статусный баланс — { _.get(props, 'client.loyalty.status_score', 0) } { declOfNum(_.get(props, 'client.loyalty.status_score', 0), ['балл', 'балла', 'баллов']) }.</Text>}
          {!!_.get(props, 'client.loyalty.status.max_percent', 0) && !!_.get(props, 'client.score', 0) && <Text style={{marginBottom: '5px', fontSize: '16px', lineHeight: '20px',  color: 'var(--text_primary)'}}>Вы можете оплатить бонусами до { Math.ceil(_.get(props, 'client.loyalty.status.max_percent', 0)) }% от суммы заказа.</Text>}

          <div style={{margin: '15px 0', height: '1px', backgroundColor: '#eee'}} /> 
        </div>
      }
      {
        (typeof props.affiliate.loyalty_text != 'undefined' && props.affiliate.loyalty_text.length > 0)
        ? <div>
            <Text style={{fontSize: '18px', fontWeight: '600', marginBottom: '7px', color: 'var(--text_primary)'}}>Программа лояльности</Text>
            <Text dangerouslySetInnerHTML={{ __html: props.affiliate.loyalty_text }} style={{fontSize: '16px', lineHeight: '20px', color: 'var(--text_primary)'}} />
          </div>
        : <div>
            <Text style={{fontSize: '18px', fontWeight: '600', marginBottom: '7px', color: 'var(--text_primary)'}}>Как получить бонусы?</Text>
            <Text style={{fontSize: '16px', lineHeight: '20px', color: 'var(--text_primary)'}}>С каждой покупки через приложение{'\n'}мы начисляем кэшбэк.</Text>
            
            <div style={{margin: '15px 0', height: '1px', backgroundColor: '#eee'}} /> 
            <Text style={{fontSize: '18px', fontWeight: '600', marginBottom: '7px', color: 'var(--text_primary)'}}>Как потратить бонусы?</Text>
            <Text style={{fontSize: '16px', lineHeight: '20px', color: 'var(--text_primary)'}}>Оплачивайте покупки бонусными баллами{'\n'}при оформлении заказа.</Text>
          </div>
      }
	  </Div>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    client: state.user.client,    
  };
}


export default connect(mapStateToProps)(Loyalty);

