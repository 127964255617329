import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import bridge from '@vkontakte/vk-bridge';
import { usePlatform, Checkbox, SelectMimicry, Subhead, View, Text, Textarea, HorizontalScroll, Select, Input, Separator, IconButton, Counter, Switch, Div, Button, FixedLayout, CardGrid, ContentCard, Header, Cell, SimpleCell, RichCell, InfoRow, Avatar, ScreenSpinner, Spinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder, FormLayoutGroup, FormLayout, FormItem, SliderSwitch } from '@vkontakte/vkui';
import { Icon16Gift, Icon24DiscountOutline, Icon28RecentOutline, Icon28DoneOutline, Icon20Check, Icon24ReportOutline, Icon16Add, Icon16Minus, Icon24DoNotDisturb, Icon24Error, Icon24Home, Icon24SmileOutline, Icon24Note, Icon28UserCircleOutline, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircleOutline, Icon24SmartphoneOutline } from '@vkontakte/icons';

import CartClass from '../utils/CartClass';
import * as cartActions from '../store/actions/cart'
import * as userActions from '../store/actions/user'

import SetPhone from '../components/SetPhone'
import CheckPhone from '../components/SetPhone' // TODO

import declOfNum from '../utils/utils'

const Order = props => {
	const router = useRouter();

  if(!props.affiliate.id) return null;

	const isPhoneChecked = props.checkPhoneStatus.code === 'success' || !!props.user.account?.phone || !props.affiliate.need_phone_check
	const isPhoneSeted = props.setPhoneStatus.code === 'success' || props.client.phone || !!props.affiliate.need_phone_check

	let Cart = new CartClass({
    orderForm: props.orderForm,
    products: props.products,
    cartProducts: props.cartProducts,
    cartGifts: props.cartGifts,
    cartMores: props.cartMores,
    promocode: props.promocode,
    promocodeCheckStatus: props.promocodeCheckStatus,
    owner: props.owner,
    affiliate: props.affiliate,
    client: props.client,
    deliveryArea: props.deliveryArea,
    basketRulesActions: props.basketRulesActions,
  })

  const basketRulesClose = props.basketRulesActions?.close || false
  const basketRulesDeliveryTypes = props.basketRulesActions?.deliveryTypes || []

  const [submitErrors, setSubmitErrors] = useState({})
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)

  const [showPaidButton, setShowPaidButton] = useState(false)

	const orderConditions = Cart.orderConditions
  const preorderTimeValues = Cart.preorderTimeValues
  const orderSum = Cart.orderSum + Cart.orderRequiredSum
  // const orderRequiredSum = Cart.orderRequiredSum
  
  const orderDiscount = Cart.orderDiscount
  const orderDiscountDescription = Cart.orderDiscountDescription
  const orderMarkup = Cart.orderMarkup
  const orderMarkupDescription = Cart.orderMarkupDescription
  const orderSumDiscount = Cart.orderSumDiscount
  const orderPaymentBonus = Cart.orderPaymentBonus
  const deliveryPrice = Cart.deliveryPrice
  const orderTotal = Cart.orderTotal
  const cartItems = Cart.getCartItems()
  const noFreeCartItems = Cart.noFreeCartItems
  const addonItems = Cart.getAddonItems()
  const moresItems = Cart.getMoresItems()
  const freeItems = Cart.getFreeItems()
  const giftsItems = Cart.getGiftsItems()
  const promocodeGifts = Cart.getPromocodeGifts()
  const giftsRuleItems = Cart.getGiftsRuleItems()

  const cartFree = Cart.cartFree
  const cartGiftsRules = Cart.cartGiftsRules

  const allGiftsMaxCount = Cart.calcAllGiftsMaxCount()
  const allGiftsCount = Cart.calcAllGiftsCount()

  const useBonusLimit = calcUseBonusLimit()

  const allCartCount = props.cartProducts.length + promocodeGifts.length

  useEffect(() => {
    if(!useBonusLimit) {
      props.saveOrderForm({...props.orderForm, payment_bonus: 0})
    }
  }, [useBonusLimit])

  function calcUseBonusLimit() {
    if(props.basketRulesActions?.paymentBonusDisable) {
      return 0
    }

    let score_limit = 0

    // LP DLVRY
    if(_.get(props.owner, 'loyalty.code', '') == 'dlvry') {
      score_limit = _.get(props.client, 'loyalty.status.max_percent', 0)
    } else
    // Frontpad
    if(_.get(props.owner, 'loyalty.code', '') == 'frontpad') {
      score_limit = _.get(props.client, 'score_limit', 0)
    } else
    // IIKO
    if(_.get(props.owner, 'loyalty.code', '') == 'iiko') {
      score_limit = _.get(props.client, 'score_limit', 0)
    }

    let limit = [].concat(noFreeCartItems).filter(item => !!item).reduce((sum, item) => {
      const product = props.products.find(product => product.id === item.id)
      let max_bonus_pay = (!isNaN(product.max_bonus_pay)) ? Number(product.max_bonus_pay) : score_limit
      if(max_bonus_pay > score_limit) max_bonus_pay = score_limit
      return sum + max_bonus_pay * item.data.quantity * (Number(item.data.price) + [].concat(item.data.options).filter(item => !!item).reduce((sum, item) => { return Number(sum) + Number(item.price)}, 0)) / 100
    }, 0)

    const orderSumMinusDiscount = orderSum - orderDiscount
    if(limit > orderSumMinusDiscount * score_limit / 100) {
      limit = orderSumMinusDiscount * score_limit / 100
    }
    
    if(limit > props.client.score) {
      limit = props.client.score
    }
    return Math.floor(limit)
  }

  let preorderTimeValuesFiltered = []
  if(preorderTimeValues && preorderTimeValues.length > 0) {
    if(props.orderForm.delivery_date == dateFormat(+ new Date())) {
      const dateStart = new Date();
      const hhStart = dateStart.getHours()
      const miStart = dateStart.getMinutes()

      let currentTime = '';
      if(hhStart < 10) {
        currentTime += '0'
      }
      currentTime += hhStart + ':'
      if(miStart < 10) {
        currentTime += '0'
      }
      currentTime += miStart
      
      preorderTimeValues.forEach(item => {
        if(item.value > currentTime) {
          preorderTimeValuesFiltered.push(item)
        }
      })
    } else {
      preorderTimeValuesFiltered = preorderTimeValues
    }
  } else {
    preorderTimeValuesFiltered = []
  }

  function getPaymentTypes() {
    let types = _.get(props.affiliate, 'order_form.config.payment.values', [])
    let basketRulesPaymentTypes = props.basketRulesActions?.paymentTypes
    if(basketRulesPaymentTypes && basketRulesPaymentTypes.length > 0) {
      types = types.filter(type => basketRulesPaymentTypes.indexOf(type.value) != -1)
    }
    return types
  }

  const paymentTypes = getPaymentTypes()
  
  useEffect(() => {
    // console.log('OrderScreen useEffect()')
    props.saveOrderForm({
      ...props.orderForm, 
      promocode: props.promocode.code,
      source: props.source.type,
      items: props.cartProducts,
      gifts: props.cartGifts,
      basket_rules_gifts: cartGiftsRules,
      basket_rules_free: cartFree,
      mores: props.cartMores,
      addons: addonItems.map(item => { return {
        id: item.id,
        data: {
          addon_type: item.addon_type,
        }
      }}),
      // name: props.client.name ?? props.user.full_name,
      name: props.user.full_name ?? props.client.name,
      phone: props.client.phone,
      email: props.client.email,
      vk_user_id: props.user.vk_user_id,
      set_the_time: 'now',
      use_bonus: 0,
      payment_bonus: useBonusLimit, 
    })
    // props.navigation.setParams({
    //   // clearCart: () => props.clearCart(),
    // })
  }, [props.client])

  useEffect(() => {
    let addressErrors = {}
    Object.keys(submitErrors).forEach(field => {
      if(field.indexOf('address_') >= 0) {
        addressErrors[field] = null
      }
    })
    setSubmitErrors({...submitErrors, ...addressErrors})
  }, [props.orderForm.address_id])

  function filteredTimes() {
    var times = []
    for (var i = 0; i < 24; i++) {
      let hh = (i < 10)?'0'+i:i;
      times.push(hh+':00')
      times.push(hh+':30')
    }
    return times
  }

  function dateFormat(timestamp) {
    var date = new Date();
    date.setTime(timestamp)
    var dd = date.getDate()
    var mm = date.getMonth() + 1 //January is 0!
    var yyyy = date.getFullYear()
    if(dd<10) {
        dd = '0'+dd
    } 
    if(mm<10) {
        mm = '0'+mm
    } 
    date = dd + '.' + mm + '.' + yyyy
    return date
  }

  function clearPhone() {
    props.clearClient()
    // props.clearAddress()
    router.navigate("cart")
  }

  // TODO
  // Хотя адрес можно не чистить, т.к. авторизация юзера сохраняется при очистке телефона (клиента)
  // clearAddress () {
  //   let emptyAddress = {}
  //   Object.keys(this.form).forEach(field => {
  //     if(field.indexOf('address_') != -1) {
  //       this.form[field] = ''
  //       emptyAddress[field] = undefined
  //     }
  //   })
  //   this.$store.dispatch('cart/setOrderForm', emptyAddress)
  //   this.form.delivery_area = null
  //   this.form.delivery_coordinates = null
  //   this.$store.commit('cart/DELIVERY_AREA_CLEAR')
  //   this.$store.commit('cart/ORDER_SAVE_CLEAR')
  // },

  function onChangeField(field, value) {
    setSubmitErrors({...submitErrors, [field]: null})
    props.saveOrderForm({...props.orderForm, [field]: value})
  }

  function submit() {
    if(!props.isAuthenticated) {
      // props.navigation.navigate('Auth', {screen: 'EnterPhone', params: {source: 'Order'}})
      // return;
    }
    // Очистим ошибки
    setSubmitErrors({})
    if(submitLoading) return;
    setSubmitLoading(true)
    props.saveOrder(props.affiliate.id, props.orderForm) 
    .then(data => {
      // this.$toasted.success(this.$t("Заказ успешно сохранен"));
      // this.$router.push("/orderSuccess")
      // this.$store.dispatch("cart/clearCart", affiliate_id)
      // this.$set(form, 'loading', false)
      // console.log('success:', data)
      props.clearCart()
      // props.clearOrderForm()
      props.saveOrderForm({
        ...props.orderForm, 
        comment: '',
      })
      // setSubmitSuccess(true)
      // setSubmitLoading(false)
      const orderItems = []
        .concat(cartItems.map(item => {
          return {
            item_id: item.id,
            item_name: item.name,
            quantity: item.quantity,
            price: item.price,
          }
        }))
        .concat(moresItems.map(item => {
          return {
            item_id: item.id,
            item_name: item.name,
            quantity: item.quantity,
            price: 0,
          }
        }))
        .concat(addonItems.map(item => {
          return {
            item_id: item.id,
            item_name: item.name,
            quantity: (item.addon_type === 'per-person') ? (props.orderForm.persons || 1) : 1,
            price: 0,
          }
        }))
        .concat(giftsItems.map(item => {
          return {
            item_id: item.id,
            item_name: item.name,
            quantity: item.quantity,
            price: 0,
          }
        }))
        .concat(promocodeGifts.map(item => {
          return {
            item_id: item.id,
            item_name: item.name,
            quantity: item.quantity,
            price: 0,
          }
        }))
        .concat(freeItems.map(item => {
          return {
            item_id: item.id,
            item_name: item.name,
            quantity: item.quantity,
            price: 0,
          }
        }))
        .concat(giftsRuleItems.map(item => {
          return {
            item_id: item.id,
            item_name: item.name,
            quantity: item.quantity,
            price: 0,
          }
        }))
      // Analytics.logEvent('purchase', {
      //   // affiliation: '',
      //   currency: props.affiliate.currency.code,
      //   items: orderItems,
      //   // shipping: '',
      //   // tax: '',
      //   transaction_id: data.data.id,
      //   value: orderTotal
      // })
      // AppMetrica.reportEvent('purchase', {
      //   // affiliation: '',
      //   currency: props.affiliate.currency.code,
      //   items: orderItems,
      //   // shipping: '',
      //   // tax: '',
      //   transaction_id: data.data.id,
      //   value: orderTotal
      // })
      // Добавил 2021-09-02
      const new_payment = data.data.payment
      const new_order_id = data.data.id
      if(new_payment == 'online') {
        props.initTransaction(props.affiliate.id, new_order_id)
        .then(data => {
          setSubmitSuccess(true)
          setSubmitLoading(false)
          setTimeout(() => {setShowPaidButton(true)}, 10000)
        })
      } else {
        setSubmitSuccess(true)
        setSubmitLoading(false)
      }
    })
    .catch((err) => {
      console.log('error:', err)
      if(_.get(err, 'response.data.errors', '').length > 0) {
        let errors = {}
        _.get(err, 'response.data.errors', []).map((item) => {
          errors[item.field] = item.error_msg
        })
        setSubmitErrors(errors)
        // _scrollView.current.scrollTo({ x: 0, y: 0, animated: true })
        window.scrollTo(0, 0)
      } else {
        // ERROR.show(ERROR.format(err));
      }
      setSubmitLoading(false)
    })
  }

  if(submitSuccess) {
    return (
      <Panel id={props.id}>
      <PanelHeader className="PersonalHeader"
        left={<PanelHeaderBack onClick={() => router.navigate('cart')} />}
        >Заказ</PanelHeader>
      {props.transaction?.url
      ? <Placeholder stretched icon={<Icon28RecentOutline width={56} height={56} />}
          header="Оплатите заказ"
          action={
            <div>
              <Button size="l" target="_blank" href={props.transaction?.url}>Оплатить заказ</Button>
              {
                showPaidButton &&
                <div style={{marginTop: '8px'}}>
                  <Button size="l" mode="tertiary"
                    onClick={() => router.navigate('home')} 
                    style={{marginTop: '10px'}}>Уже оплатил</Button>
                </div>
              }
            </div>
            }>
          Ваш заказ сформирован и будет отправлен в работу сразу после оплаты
        </Placeholder>
      : <Placeholder stretched icon={<Icon28DoneOutline style={{color: 'var(--dynamic_green)'}} width={56} height={56} />}
          header="Заказ оформлен!"
          action={
              <Button size="l" onClick={() => router.navigate('home')}>Заказать ещё</Button>
            }>
          {_.get(props, 'affiliate.msgs.ORDER_SUCCESS_DESCRIPTION', 'Наш оператор свяжется с вами для подтверждения заказа.')}
        </Placeholder>
      }
      </Panel>
    )
  }

	return (
		<Panel id={props.id}>
	    <PanelHeader className="PersonalHeader"
        left={<PanelHeaderBack onClick={() => router.navigate('cart')} />}
        >Заказ</PanelHeader>
	    {
	    	!isPhoneSeted &&
	    	<SetPhone />
	    }
	    {
	    	!isPhoneChecked &&
	    	<CheckPhone />
	    }
	    {
	    	(isPhoneSeted && isPhoneChecked && !allCartCount) &&
	    	<Placeholder stretched icon={<Icon28MarketOutline width={56} height={56} />}
	        header="Ой, пусто!"
	        action={<Button size="l" onClick={() => router.navigate('home')}>Перейти в меню</Button>}>
	        Ваша корзина пуста, откройте «Меню» и&nbsp;выберите понравившийся товар.
	      </Placeholder>
	    }
	    {
	    	(isPhoneSeted && isPhoneChecked && !!allCartCount) &&
	    	<div style={{paddingBottom: '80px'}}>
          {
            basketRulesClose &&
            <div className="Warning">
              <Text className="Warning__Text">{ basketRulesClose.title }</Text>
            </div>
          }

          {
            Object.keys(submitErrors).filter(field => submitErrors[field]).length > 0 &&
            <Group header={<Header>Ошибки</Header>}>
              {Object.keys(submitErrors).filter(field => submitErrors[field]).map(field =>
              <Cell multiline disabled key={field}
                before={<Icon24ReportOutline style={{color: 'var(--dynamic_red)'}} />}>
                { submitErrors[field] }
              </Cell>)}
            </Group>
          }

          <Group>
            <FormLayout>
              <Header mode="primary">Личные данные</Header>
              <FormLayoutGroup>
                {_.get(props, 'affiliate.order_form.config.name.active', 0) == 1 && 
                  <FormItem status={submitErrors.name ? 'error' : null} bottom={submitErrors.name}>
                    <Input type="text" defaultValue={props.orderForm.name} onChange={ (e) => onChangeField('name', e.target.value) } status={(submitErrors.name) ? 'error' : null} placeholder='Имя' />
                  </FormItem>}
                {_.get(props, 'affiliate.order_form.config.phone.active', 0) == 1 && 
                  <FormLayoutGroup mode="horizontal">
                    <FormItem 
                      style={{flex: '1.5'}}
                      bottom={submitErrors.phone}
                      status={submitErrors.phone ? 'error' : (props.client.phone ? 'valid' : null)}>
                      <Input after={<Icon20Check style={{color: 'var(--dynamic_green)'}} />} style={{opacity: '1'}} type="phone" defaultValue={props.orderForm.phone} disabled placeholder={_.get(props, 'affiliate.phone_code.code', 'Телефон')} />
                    </FormItem>
                    <FormItem
                      // style={{flex: '2'}}
                      >
                      <Button style={{width: '100%'}} size="l" mode="outline" onClick={() => clearPhone()}>Изменить</Button>
                    </FormItem>
                  </FormLayoutGroup>}
                {_.get(props, 'affiliate.order_form.config.email.active', 0) == 1 && 
                  <FormItem status={(submitErrors.email) ? 'error' : null} bottom={submitErrors.email}>
                    <Input type="email" defaultValue={props.orderForm.email} onChange={ (e) => onChangeField('email', e.target.value) } placeholder='E-mail' />
                  </FormItem>}
                {_.get(props, 'affiliate.order_form.config.card.active', 0) == 1 && 
                  <FormItem status={(submitErrors.card) ? 'error' : null} bottom={submitErrors.card}>
                    <Input type="text" defaultValue={props.orderForm.card} onChange={ (e) => onChangeField('card', e.target.value) } placeholder='Карта клиента' />
                  </FormItem>}
              </FormLayoutGroup>
              <Header mode="primary">Доставка и оплата</Header>
              {(_.get(props, 'affiliate.order_form.config.delivery.active', 0) == 1 && !props.affiliate.pickup_only) &&                 
                <FormItem top='Доставка или Самовывоз'
                  bottom={submitErrors.delivery}
                  status={(submitErrors.delivery) ? 'error' : null}>
                  <SliderSwitch activeValue={props.orderForm.delivery} 
                    options={[
                      { name: 'Доставка', value: 'delivery' },
                      { name: 'Самовывоз', value: 'self' },
                    ]} 
                    onSwitch={ (value) => {
                      onChangeField('delivery', value) 
                      bridge.send("VKWebAppTapticSelectionChanged", {});
                    }} />
                </FormItem>}
              {_.get(props, 'affiliate.delivery_types', []).length > 1 && _.get(props, 'affiliate.order_form.config.delivery_type.active', 0) == 1 && props.orderForm.delivery == 'delivery' && 
                <FormItem top='Тип доставки' status={(submitErrors.delivery_type) ? 'error' : null} bottom={submitErrors.delivery_type}>
                  <Select defaultValue={props.orderForm.delivery_type} 
                    options={_.get(props, 'affiliate.delivery_types', []).filter(item => !basketRulesDeliveryTypes.length || basketRulesDeliveryTypes.indexOf(item.code) !== -1).map((item) => {return {label: item.name, value: item.code}})} 
                    onChange={ (e) => onChangeField('delivery_type', e.target.value) } placeholder='Выберите вариант' />
                  </FormItem>}
              {_.get(props, 'affiliate.pickup_points', []).length > 1 && _.get(props, 'affiliate.order_form.config.pickup_point.active', 0) == 1 && props.orderForm.delivery == 'self' && 
                <FormItem top='Точка самовывоза' status={(submitErrors.pickup_point) ? 'error' : null} bottom={submitErrors.pickup_point}>
                  <Select defaultValue={props.orderForm.pickup_point} 
                    options={_.get(props, 'affiliate.pickup_points', []).map((item) => {return {label: item.name, value: item.code}})} 
                    onChange={ (e) => onChangeField('pickup_point', e.target.value) } placeholder='Выберите вариант' />
                  </FormItem>}
              { props.orderForm.delivery !== 'self' &&
                <FormItem top='Адрес доставки'
                  status={(Object.keys(submitErrors).filter(field => (field.indexOf('address_') >= 0 || field.indexOf('delivery_area') >= 0) && submitErrors[field]).length > 0) ? 'error' : null}>
                  <SelectMimicry 
                    onClick={() => {
                      // router.navigate('order/address')
                      props.onModal({type: 'ADDRESS'})
                    }}
                    placeholder='Указать адрес доставки'>
                    {(props.orderForm.address_id > 0) ? `${props.orderForm.address_street} ${props.orderForm.address_house}` : null}
                  </SelectMimicry>
                </FormItem>}
              {
                (props.deliveryAreaStatus.code == 'not_found' && props.orderForm.delivery !== 'self') &&
                <Div style={{marginTop: '-14px'}}>
                  <Text style={{fontSize: '14px', fontWeight: '500', color: 'var(--dynamic_red)'}}>Зона доставки не определена</Text>
                  {_.get(props, 'affiliate.order_form.config.delivery_area.required', 0) == 1 &&
                    <Text style={{marginTop: '3px', fontSize: '14px', color: 'var(--text_secondary)'}}>Пожалуйста, укажите другой адрес</Text>}
                  {_.get(props, 'affiliate.order_form.config.delivery_area.required', 0) != 1 &&
                    <Text style={{marginTop: '3px', fontSize: '14px', color: 'var(--text_secondary)'}}>Условия доставки на ваш адрес уточняйте у оператора</Text>}
                </Div>
              }
              {
                (props.deliveryAreaStatus.code == 'loading' && props.orderForm.delivery !== 'self') &&
                <Div style={{marginTop: '-14px'}}>
                  <Text style={{fontSize: '14px', fontWeight: '500', color: 'var(--dynamic_orange)'}}>Определение зоны доставки...</Text>
                </Div>
              }
              {(_.get(props, 'affiliate.order_form.config.delivery_date.active', 0) == 1 || _.get(props, 'affiliate.order_form.config.delivery_time.active', 0) == 1 ) &&
                <FormLayoutGroup>
                  <FormItem top={(props.orderForm.delivery === 'self') ? "Время самовывоза" : "Время доставки"}
                    >
                    <SliderSwitch activeValue={props.orderForm.set_the_time} options={[
                      { name: 'Ближайшее', value: 'now' },
                      { name: 'Назначить время', value: 'set' },
                    ]} 
                    placeholder='Выберите вариант'
                    onSwitch={ (value) => {
                      onChangeField('set_the_time', value)
                      bridge.send("VKWebAppTapticSelectionChanged", {});
                    }} />
                  </FormItem>
                  {
                    (props.orderForm.set_the_time == 'set' && props.affiliate.order_form.config.delivery_date && props.affiliate.order_form.config.delivery_date.active == 1) &&
                    <FormItem status={(submitErrors.delivery_date) ? 'error' : null} bottom={submitErrors.delivery_date}>
                      <Select defaultValue={props.orderForm.delivery_date} options={[...Array(30)].map((item, index) => {
                        return {label: dateFormat((+ new Date()) + 1000*60*60*24*index), value: dateFormat((+ new Date()) + 1000*60*60*24*index)}
                      })} onChange={ (e) => onChangeField('delivery_date', e.target.value) } placeholder='Выберите день' />
                    </FormItem>
                  }
                  {
                    (props.orderForm.set_the_time == 'set' && props.affiliate.order_form.config.delivery_time && props.affiliate.order_form.config.delivery_time.active == 1) &&
                    (
                      (preorderTimeValues && preorderTimeValues.length > 0)
                      ? <FormItem status={(submitErrors.delivery_time) ? 'error' : null} bottom={submitErrors.delivery_time}>
                          <Select defaultValue={props.orderForm.delivery_time} options={preorderTimeValuesFiltered.map(item => {
                            return {label: item.name, value: item.value}
                          })} onChange={ (e) => onChangeField('delivery_time', e.target.value) } placeholder='Выберите время' />
                        </FormItem>
                      : <FormItem status={(submitErrors.delivery_time) ? 'error' : null} bottom={submitErrors.delivery_time}>
                          <Select defaultValue={props.orderForm.delivery_time} options={filteredTimes().map(item => {
                            return {label: item, value: item}
                          })} onChange={ (e) => onChangeField('delivery_time', e.target.value) } placeholder='Выберите время' />
                        </FormItem>
                    )
                  }
                </FormLayoutGroup>}
              {(_.get(props, 'affiliate.order_form.config.payment.values', []).length > 1 || _.get(props, 'affiliate.order_form.config.payment_breakast.active', 0) == 1) &&
                <FormLayoutGroup>
                {_.get(props, 'affiliate.order_form.config.payment.values', []).length > 1 &&
                  <FormItem top="Оплата"
                    bottom={submitErrors.payment}
                    status={(submitErrors.payment) ? 'error' : null}>
                    {/*<SelectMimicry 
                      onClick={() => {
                        router.navigate('order/payment')
                      }}                      
                      placeholder='Указать способ оплаты'>
                      { _.get(props, 'affiliate.order_form.config.payment.values', []).find(item => item.value === props.orderForm.payment)?.text }
                    </SelectMimicry>*/}
                    <Select defaultValue={props.orderForm.payment} options={paymentTypes.map(item => {
                      return {label: item.text, value: item.value}
                    })} onChange={ (e) => onChangeField('payment', e.target.value) } placeholder='Способ оплаты' />
                  </FormItem>
                }
                {_.get(props, 'affiliate.order_form.config.payment_breakast.active', 0) == 1 && props.orderForm.payment == 'cash' &&
                  <FormItem status={(submitErrors.payment_breakast) ? 'error' : null} bottom={submitErrors.payment_breakast}>
                    <Input type="number" defaultValue={props.orderForm.payment_breakast} onChange={ (e) => onChangeField('payment_breakast', e.target.value) } placeholder='Сдачи с купюры' />
                  </FormItem>}
                </FormLayoutGroup>}
              {(_.get(props, 'owner.loyalty.code', '') && _.get(props, 'owner.loyalty.code', '') !== 'none' && useBonusLimit > 0) &&
                <FormLayoutGroup style={{marginTop: '15px', marginBottom: '5px', backgroundColor: '#ffc10788'}}>
                  <Cell disabled
                    after={<Switch 
                      checked={props.orderForm.use_bonus === 1} 
                      onChange={() => {
                        props.saveOrderForm({...props.orderForm, use_bonus: (props.orderForm.use_bonus !== 1) ? 1 : 0 })
                      }} />}
                    >Использовать бонусные баллы</Cell>
                  {_.get(props, 'orderForm.use_bonus', 0) == 1 &&
                    <FormItem status={(submitErrors.payment_bonus) ? 'error' : null} bottom={submitErrors.payment_bonus}>
                      <Input type="number" defaultValue={props.orderForm.payment_bonus.toString()} onChange={ (e) => onChangeField('payment_bonus', e.target.value) } placeholder='Бонусные баллы' />
                      <Text style={{marginTop: '5px', opacity: '0.5'}}>Можно использовать { useBonusLimit } из { props.client.score } { declOfNum(props.client.score, ['балл', 'балла', 'баллов']) }.</Text>
                    </FormItem>}
                </FormLayoutGroup>}
              {_.get(props, 'affiliate.order_form.config.comment.active', 0) == 1 &&
                <FormItem top="Примечание к заказу" 
                  bottom={submitErrors.comment}
                  status={(submitErrors.comment) ? 'error' : null}>
                  <Textarea defaultValue={props.orderForm.comment} 
                    onChange={ (e) => onChangeField('comment', e.target.value) } placeholder='' />
                  </FormItem>}
              {_.get(props, 'affiliate.order_form.config.persons.active', 0) == 1 && 
                  <FormItem top='Количество персон' 
                    bottom={submitErrors.persons}
                    status={(submitErrors.persons) ? 'error' : null}>
                    <Select defaultValue={props.orderForm.persons} 
                      options={[...Array(20).keys()].map(item => {return {label: (item+1).toString(), value: item+1}})} 
                      onChange={ (e) => onChangeField('persons', e.target.value) } placeholder='Выберите вариант' />
                  </FormItem>
                }
              {(_.get(props, 'affiliate.order_form.config.confirm_order_without_call.active', 0) == 1 || _.get(props, 'affiliate.order_form.config.contactless_delivery.active', 0) == 1) &&
                <FormLayoutGroup>
                  <Header>Пожелания к заказу</Header>
                  {_.get(props, 'affiliate.order_form.config.confirm_order_without_call.active', 0) == 1 && 
                    <FormItem>
                      <Checkbox checked={props.orderForm.confirm_order_without_call}
                        onChange={ () => {
                          onChangeField('confirm_order_without_call', !props.orderForm.confirm_order_without_call)
                        } }>Не перезванивать</Checkbox>
                    </FormItem>
                  }
                  {_.get(props, 'affiliate.order_form.config.contactless_delivery.active', 0) == 1 && 
                    <FormItem>
                      <Checkbox checked={props.orderForm.contactless_delivery}
                        onChange={ () => {
                          onChangeField('contactless_delivery', !props.orderForm.contactless_delivery)
                        } }>Бесконтактная доставка</Checkbox>
                    </FormItem>
                  }
                </FormLayoutGroup>}
            </FormLayout>
          </Group>

          <Group>
            <Cell  disabled>
              <InfoRow header="Сумма заказа">
                { `${orderSum.toFixed(2)} ${props.affiliate.currency.sign}` }
              </InfoRow>
            </Cell>
            {orderDiscount > 0 && <Cell disabled>
              <InfoRow header="Скидка">
                { `${orderDiscount.toFixed(2)} ${props.affiliate.currency.sign}` }
                {orderDiscountDescription.length > 0 && 
                  <div className="CartItem__Action">
                    <Icon24DiscountOutline width={16} height={16} className="CartItem__ActionIcon" />
                    <Text className="CartItem__ActionText">{ orderDiscountDescription }</Text>
                  </div>
                }
              </InfoRow>
            </Cell>}
            {orderMarkup > 0 && <Cell disabled>
              <InfoRow header="Наценка">
                { `${orderMarkup.toFixed(2)} ${props.affiliate.currency.sign}` }
                {orderMarkupDescription.length > 0 && 
                  <div className="CartItem__Action">
                    <Icon16Add className="CartItem__ActionIcon" />
                    <Text className="CartItem__ActionText">{ orderMarkupDescription }</Text>
                  </div>
                }
              </InfoRow>
            </Cell>}
            {orderPaymentBonus > 0 && <Cell disabled>
              <InfoRow header="Бонусами">
                { `${orderPaymentBonus.toFixed(2)} ${props.affiliate.currency.sign}` }
              </InfoRow>
            </Cell>}
            <Cell disabled>
              <InfoRow header="Доставка">
                { (props.orderForm.delivery == 'self') ? 'Самовывоз' : (deliveryPrice > 0 ? `${deliveryPrice.toFixed(2)} ${props.affiliate.currency.sign}` : 'Бесплатно') }
              </InfoRow>
            </Cell>
            <Cell disabled>
              <InfoRow header="ИТОГО к оплате">
                { `${orderTotal.toFixed(2)} ${props.affiliate.currency.sign}` }
              </InfoRow>
            </Cell>
          </Group>

          {typeof props.affiliate.order_page_text !== 'undefined' && props.affiliate.order_page_text.length > 0 &&
            <Group>
              <Div>
                <Text style={{fontSize: '15px', lineHeight: '21px', color: 'var(--text_secondary)'}} dangerouslySetInnerHTML={{ __html: props.affiliate.order_page_text }}></Text>
              </Div>
            </Group>}

          {orderConditions.min_sum.value > orderSumDiscount && props.orderForm.delivery !== 'self' &&
            <Group>
              <Cell disabled
                before={<Icon24DoNotDisturb style={{color: 'var(--dynamic_red)' }} />}
                description={ 'Не хватает '+(orderConditions.min_sum.value - orderSumDiscount).toFixed(2)+' '+props.affiliate.currency.sign }>
                <Text style={{color: 'var(--dynamic_red)' }}>{ `Минимальный заказ: ${orderConditions.min_sum.value} ${props.affiliate.currency.sign}`}</Text>
              </Cell>
            </Group>}

          {
            (
              props.affiliate.gifts_required &&
              (!props.basketRulesActions.giftsDisable || !props.basketRulesActions.giftsSumDisable) &&
              !(props.promocodeCheckStatus.code == 'success' && props.promocode.is_stop_actions) &&
              /*(
                (_.get(props, 'affiliate.gifts_from', []).length > 0 && !props.basketRulesActions.giftsSumDisable) ||
                giftSetsItems.length > 0
              ) &&*/
              (allGiftsMaxCount > allGiftsCount)
            ) 
            ?
            <FixedLayout vertical="bottom" style={{ backgroundColor: '#ffffff', borderTop: '.5px solid #ddd'}}>
              <Div>
                <Button stretched before={<Icon16Gift style={{color: '#fff'}} />} size="l" 
                  onClick={() => router.navigate('cart')}>{ (allGiftsMaxCount > 1) ? 'Выбрать подарки' : 'Выбрать подарок'}</Button>
              </Div>
            </FixedLayout>
            :
            ((orderConditions.min_sum.value <= orderSumDiscount || props.orderForm.delivery === 'self') 
            && !!props.owner.active
            && (!!props.affiliate.is_now_open || !!props.affiliate.allow_ordering_at_any_time)
            && !basketRulesClose
            ) &&
            <FixedLayout vertical="bottom" style={{ backgroundColor: '#ffffff', borderTop: '.5px solid #ddd'}}>
              <Div>
                <Button size="l" stretched 
                  disabled={submitLoading} 
                  before={submitLoading && <Spinner size="small" style={{color: '#fff'}} />}
                  onClick={() => submit() }>{(submitLoading)?'Оформляем...':'Оформить заказ'}</Button>
              </Div>
            </FixedLayout>
          }
	    	</div>
	    }	   
	  </Panel>
	)
}

const mapStateToProps = state => {
  return { 
    products: state.catalog.products,
    cartProducts: state.cart.products,
    cartGifts: state.cart.gifts,
    cartMores: state.cart.mores,
    promocode: state.cart.promocode,
    promocodeStatus: state.cart.promocodeStatus,
    promocodeCheckStatus: state.cart.promocodeCheckStatus,
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    orderForm: state.cart.orderForm,
    transaction: state.cart.transaction,
    user: state.user.user,
    client: state.user.client,
    isAuthenticated: state.user.isAuthenticated,
    deliveryArea: state.cart.deliveryArea,
    deliveryAreaStatus: state.cart.deliveryAreaStatus,
    basketRulesActions: state.cart.basketRulesActions,
    checkPhoneStatus: state.user.checkPhoneStatus,
    setPhoneStatus: state.user.setPhoneStatus,
    source: state.app.source,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearClient: () => dispatch(userActions.clearClient()),
    clearCart: () => dispatch(cartActions.clearCart()),
    saveOrderForm: (data) => dispatch(cartActions.saveOrderForm(data)),
    // clearOrderForm: () => dispatch(clearOrderForm()),
    saveOrder: (affiliate_id, form) => dispatch(cartActions.saveOrder(affiliate_id, form)),
    initTransaction: (affiliate_id, order_id) => dispatch(cartActions.initTransaction(affiliate_id, order_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);

