import axios from 'axios'

let date = new Date()
let timezoneOffset = date.getTimezoneOffset()

export const HTTP = axios.create({
	baseURL: 'https://dlvry.ru/api/v1/',
	headers: {
    'TimezoneOffset': timezoneOffset,
  },
})