import React, { useState, useEffect } from 'react';
import { useRouter } from 'react-router5'
import { View, Spacing, FixedLayout, Separator, Button, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon28Newsfeed, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import './../empty.css';

const GetStarted = props => {
	const router = useRouter();

	return (
		<Panel id={props.id}>
	    <PanelHeader>С чего начать</PanelHeader>
	    <Group>
	      <RichCell
	        disabled
	        before={<Avatar src={ 'https://dlvry.ru/img/dlvry-logo-sign--blue.png' } />}
	        caption="Приложения для доставки еды"
	      >
	        <b>DLVRY</b>.APPS
	      </RichCell>
	    </Group>
			<Separator style={{ margin: '0 0 10px 0' }} />
  	  <Div>
      	<Text><strong>DLVRY</strong> — Это готовое решение для служб доставки еды.</Text>
				<br />
				<Text>Готовый сайт + Мобильное приложение (iOS и Android) + Приложение сообществ ВКонтакте (VK Mini App).</Text>
      </Div>
	    <SimpleCell expandable href="https://new.dlvry.ru" target="_blank">
	    	<InfoRow header="Веб-сайт разработчика">
	    		DLVRY.RU
	    	</InfoRow>
	    </SimpleCell>
	    <SimpleCell expandable href="https://vk.com/dlvry">
	    	<InfoRow header="Сообщество ВКонтакте">
	    		vk.com/dlvry
	    	</InfoRow>
	    </SimpleCell>
	    <SimpleCell expandable href="https://vk.me/dlvry">
	    	<InfoRow header="Поддержка">
	    		vk.me/dlvry
	    	</InfoRow>
	    </SimpleCell>
	    <Separator style={{ margin: '10px 0 10px 0' }} />
	    <Header>С чего начать</Header>
	    <Div>
				<ol style={{margin: '0', padding: '0 0 0 24px'}}>
					<li>Авторизуйтесь в панели управления – panel.dlvry.ru</li>
					<li>Перейдите в раздел "Компании" и добавьте вашу Компанию</li>
					<li>Затем нужно перейти в раздел "Филиалы" и добавить Филиал</li>
					<li>Затем в разделе "Каталог" нужно добавить Разделы каталога и Товары</li>
				</ol>
      </Div>
	    {/*<Spacing size={60} />
	    <FixedLayout vertical="bottom" style={{ backgroundColor: '#ffffff', borderTop: '.5px solid #ddd', paddingBottom: '60px'}}>
        <Div>
          <Button size="l" stretched target="_blank"
            href="https://panel.dlvry.ru">В панель управления</Button>
        </Div>
      </FixedLayout>*/}    
	  </Panel>
	)
};

export default GetStarted;