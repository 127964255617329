import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import { View, Cell, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon24Done, Icon24Place, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

const MenuInfo = props => {
	const router = useRouter();

	return (
		<Panel id={props.id}>
	    <PanelHeader left={<PanelHeaderBack onClick={() => router.navigate('menu')} />}>Доставка и оплата</PanelHeader>
	    {
	    	!!props.affiliate.delivery_text &&
	    	<Group header={<Header>Доставка</Header>}>
		      <Div>
		      	<Text dangerouslySetInnerHTML={{ __html: props.affiliate.delivery_text }}></Text>
		      </Div>
		    </Group>
	    }
	    {
	    	props.deliveryAreas.length > 0 && 
        <Group header={<Header>Зоны доставки</Header>}>
          {props.deliveryAreas.map(item => 
            <Cell 
              before={ item.id === props.deliveryArea?.id ? <Icon24Done style={{ color: item.color }} /> : <Icon24Place style={{ color: item.color }} />}
              after={ <Icon24Info style={{ color: 'var(--icon_tertiary)'}} /> }
              description={ item.description }
              onClick={() => {
              	props.onModal(item)
              }}
              // onPress={ () => {
              //   setOpenModal('area')
              //   setArea(item)
              //   // console.log(item)
              // } }
              >
              {/*<Text style={{
                fontSize: 16,
                lineHeight: 22,
                fontWeight: (item.id === props.deliveryArea?.id) ? '600' : '400',
                // color: (item.id === props.deliveryArea?.id) ? colors.tint_color : colors.text_primary
              }}>{ item.name }</Text>*/}
              { item.name }
            </Cell>)}
        </Group>
      }
      {
      	!!props.affiliate.payment_text &&
      	<Group header={<Header>Оплата</Header>}>
		      <Div>
		      	<Text dangerouslySetInnerHTML={{ __html: props.affiliate.payment_text }}></Text>
		      </Div>
		    </Group>
      }	    
	    <Spacing size={20} />
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
  	// owner: state.owner.item,
    affiliate: state.affiliates.item,
    deliveryAreas: state.cart.deliveryAreas,
    // deliveryArea: state.cart.deliveryArea,
  };
}

export default connect(mapStateToProps)(MenuInfo);


