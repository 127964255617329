import { HTTP } from '../../utils/http-common';
import { 
  GET_OWNER_REQUEST, 
  GET_OWNER_SUCCESS,
  GET_OWNER_ERROR,

  GET_REVIEWS_REQUEST, 
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_ERROR,
} from "../ActionTypes";
// import { OWNER_ID } from '../../constants/Owner';

function commitAction(type, data) {
	return { type, data }
}

// export function getOwner() {
//   return function (dispatch) {
//     return new Promise((resolve, reject) => {
//       dispatch(commitAction(GET_OWNER_REQUEST))

//       HTTP.get("owners/" + OWNER_ID) // '403676' //Constants.manifest.extra.ownerId
//     	.then(resp => {
//         dispatch(commitAction(GET_OWNER_SUCCESS, resp.data.data))
//         resolve(resp.data.data)
//       })
//       .catch(err => {
//         dispatch(commitAction(GET_OWNER_ERROR, err))
//         reject(err)
//       })
//     })
//   }
// }

export function getReviews(owner_id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      if(!owner_id) {
        let err = ERROR.make(400, "Invalid parameter: owner_id")
        dispatch(commitAction(GET_REVIEWS_ERROR, err))
        reject(err)
        return;
      }

      dispatch(commitAction(GET_REVIEWS_REQUEST))

      HTTP.get("owners/"+owner_id+"/reviews")
      .then(resp => {
        dispatch(commitAction(GET_REVIEWS_SUCCESS, resp.data.data.items))
        resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(GET_REVIEWS_ERROR, err))
        reject(err)
      })
    })
  }
}

export function sendStatistic ({affiliate_id, vk_group_id, vk_app_id, vk_user}) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP.post("stat", {affiliate_id, vk_group_id, vk_app_id, vk_user})
      .then(resp => {
        resolve(resp)
      })
      .catch(err => {
        reject(err)
      })
    })
  }
}