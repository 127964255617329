import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { View, Div, ModalRoot, ModalPage, usePlatform, ModalPageHeader, PanelHeaderButton, PanelHeaderClose } from '@vkontakte/vkui';
import { Icon24Dismiss } from '@vkontakte/icons';

import HomeDefault from '../panels/Home';
import HomeSection from '../panels/HomeSection';

import ActionDetailModal from '../modals/ActionDetail';
import ProductDetailModal from '../modals/ProductDetail';
import ContactsModal from '../modals/Contacts';
import AddressModal from '../modals/Address';
import PickupPointModal from '../modals/PickupPoint';
import AffiliatesModal from '../modals/Affiliates';

const Home = props => {

	const platform = usePlatform()

	const [modalData, setModalData] = useState({})
	const [activeModal, setActiveModal] = useState(null);
	const [popout, setPopout] = useState(null);

	useEffect(() => {
		setPopout(props.popout)
	}, [props.popout]);

	const modal = (
    <ModalRoot activeModal={activeModal} onClose={() => setActiveModal(null)}>
      <ModalPage id="ACTION_DETAIL" settlingHeight={100}>
      	<div style={{ position: 'fixed', zIndex: '9', top: '10px', right: '10px'}}>
      		<PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss width={44} height={44} /></PanelHeaderButton>
	      </div>
      	<ActionDetailModal action={modalData?.action} onPopout={togglePopout} onModal={toggleModal} />
      </ModalPage>
      <ModalPage id="PRODUCT_DETAIL" settlingHeight={100}>
      	<div style={{ position: 'fixed', zIndex: '9', top: '10px', right: '10px'}}>
      		<PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss width={44} height={44} /></PanelHeaderButton>
	      </div>
      	<ProductDetailModal product={modalData?.product} onPopout={togglePopout} />
      </ModalPage>
      <ModalPage id="CONTACTS" settlingHeight={75}
	      header={
	        <ModalPageHeader
	          right={platform === 'ios' && <PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss/></PanelHeaderButton>}
	          left={platform === 'android' && <PanelHeaderClose onClick={() => setActiveModal(null)}/>}
	        >
	        Контакты
        	</ModalPageHeader>
      	}>
      	<ContactsModal />
      </ModalPage>
      <ModalPage id="ADDRESS" dynamicContentHeight
	      header={
	        <ModalPageHeader
	          right={platform === 'ios' && <PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss/></PanelHeaderButton>}
	          left={platform === 'android' && <PanelHeaderClose onClick={() => setActiveModal(null)}/>}
	        >
	        Адрес доставки
        	</ModalPageHeader>
      	}>
      	<AddressModal nPopout={togglePopout} onModal={toggleModal} source="home" />
      </ModalPage>
      <ModalPage id="PICKUP_POINT" dynamicContentHeight
	      header={
	        <ModalPageHeader
	          right={platform === 'ios' && <PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss/></PanelHeaderButton>}
	          left={platform === 'android' && <PanelHeaderClose onClick={() => setActiveModal(null)}/>}
	        >
	        Точка самовывоза
        	</ModalPageHeader>
      	}>
      	<PickupPointModal nPopout={togglePopout} onModal={toggleModal} />
      </ModalPage>
      <ModalPage id="AFFILIATES" dynamicContentHeight
	      header={
	        <ModalPageHeader
	          right={platform === 'ios' && <PanelHeaderButton onClick={() => setActiveModal(null)}><Icon24Dismiss/></PanelHeaderButton>}
	          left={platform === 'android' && <PanelHeaderClose onClick={() => setActiveModal(null)}/>}
	        >
	        Выбор филиала
        	</ModalPageHeader>
      	}>
      	<AffiliatesModal nPopout={togglePopout} onModal={toggleModal} />
      </ModalPage>
    </ModalRoot>
  );

  function toggleModal(modal) {
  	if(!modal) {
  		setActiveModal(null);
  	} else {
  		setModalData(modal.data);
  		setActiveModal(modal.type);
  	}
  }

  function togglePopout(popout) {
  	if(!popout) {
  		setPopout(null);
  	} else {
  		setPopout(popout);
  	}
  }

	return (
		<View id={props.id} activePanel={props.activePanel} modal={modal} popout={popout}>
			<HomeDefault id="default" onModal={toggleModal} onPopout={togglePopout} />		
			<HomeSection id="section" onModal={toggleModal} onPopout={togglePopout} />		
			<HomeSection id="product" onModal={toggleModal} onPopout={togglePopout} />		
		</View>
	)
};

export default Home;
