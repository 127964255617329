export default [
  { name: 'home', path: '/' },
  { name: 'home/product', path: '/catalog/:section_id/:product_id' },
  { name: 'home/section', path: '/catalog/:section_id' },
  { name: 'home/catalog', path: '/catalog' },
  { name: 'actions', path: '/actions' },
  { name: 'cart', path: '/cart' },
  { name: 'cart/order', path: '/order' },
  { name: 'cart/gifts', path: '/cart/gifts' },
  { name: 'cart/giftsRule', path: '/cart/giftsRule' },
  { name: 'personal', path: '/personal' },  
  { name: 'personal/addresses', path: '/personal/addresses' },
  { name: 'personal/address', path: '/personal/address' },
  { name: 'personal/orders', path: '/personal/orders' },
  { name: 'personal/order', path: '/personal/order' },
  { name: 'menu', path: '/menu' },
  { name: 'menu/about', path: '/menu/about' },
  { name: 'menu/contacts', path: '/menu/contacts' },
  { name: 'menu/reviews', path: '/menu/reviews' },
  { name: 'menu/info', path: '/menu/info' },
  { name: 'menu/aboutApp', path: '/menu/aboutApp' },
  { name: '404', path: '/404' },
  { name: 'empty', path: '/empty' },
  { name: 'getStarted', path: '/getStarted' },
]