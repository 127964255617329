import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import { Cell, Div, Group, Spinner, Header, SimpleCell, InfoRow, withModalRootContext } from '@vkontakte/vkui';
import { Icon24CheckCircleOn, Icon24CheckCircleOff, Icon24Add, Icon24Place } from '@vkontakte/icons';

import * as userActions from '../store/actions/user';
import * as cartActions from '../store/actions/cart';

const Address = props => {
	const router = useRouter();

  useEffect(() => {
    // async function getActions() {
    //   await props.dispatch(actionsActions.getActions(props.affiliate.id));
    // }
    props.getAddresses()
    .then(data => {
      props.updateModalHeight();
      if(!data.items || !data.items.length) {
        // props.navigation.navigate('NewAddress', {address_id: 0})
      }
    })
    .catch(err => {})
    // function initOrderForm() {
    //   let initOrderForm = _.cloneDeep(props.orderForm) || {}
    //   if(!initOrderForm.delivery || !!props.affiliate.pickup_only) {
    //     initOrderForm.delivery = (!!props.affiliate.pickup_only) ? 'self' : 'delivery'
    //   }
    //   if(!initOrderForm.pickup_point) {
    //     // initOrderForm.pickup_point = _.get(props, 'affiliate.pickup_points[0].name')
    //   }
    //   if(!initOrderForm.address_district) {
    //     initOrderForm.address_district = _.get(props, 'affiliate.districts[0].name')
    //   }
    //   props.dispatch(cartActions.saveOrderForm(initOrderForm));
    // }
    // if(props.affiliate.id) {
    //   if(props.actionsStatus.code !== 'success' && !props.actions.length) {
    //     getActions();
    //   }
    //   initOrderForm();
    // }
  }, []);

	return (
		<div>
      {_.get(props, 'addressesStatus.code', '') === 'loading' && <Spinner style={{ margin: '12px 0'}} />}
      {_.get(props, 'addressesStatus.code', '') === 'success' &&
        (!props.addresses.length)
        ? <div>
            <Group>
              <Cell 
                after={<Icon24Add style={{color: 'var(--color-1)'}} />}
                onClick={() => {
                  router.navigate('personal/newAddress', {address_id: 0, source_page: props.source})
                }}
                >Добавить адрес</Cell>
            </Group>
          </div>
        : <div>
            <Group>
            {
              props.addresses
              .map(item => {
                return (
                  <Cell 
                    key={item.id}
                    before={
                      item.id === props.orderForm.address_id
                      ? <Icon24CheckCircleOn style={{color: 'var(--color-1)'}} />
                      : <Icon24CheckCircleOff style={{color: 'var(--icon_secondary)'}} />
                    }
                    onClick={() => {
                      let address = {}
                      Object.keys(item).map(field => {
                        address['address_' + field] = item[field]
                      })
                      props.saveOrderForm({...props.orderForm, ...address})
                      // props.navigation.navigate(pageSource)
                      props.onModal(null)
                    }}
                    description={item.street + ' ' + item.house}
                    >{item.name}</Cell>)
              })
            }
              <Cell 
                after={<Icon24Add style={{color: 'var(--color-1)'}} />}
                onClick={() => {
                  router.navigate('personal/address', {address_id: 0, source_page: props.source})
                }}
                >Добавить новый адрес</Cell>
              <Cell 
                after={<Icon24Place style={{color: 'var(--color-1)'}} />}
                onClick={() => {
                  router.navigate('personal/addresses')
                }}
                >Редактировать адреса</Cell>
            </Group>
          </div>
      
      }
	  </div>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    addresses: state.user.addresses,
    addressesStatus: state.user.addressesStatus,
    orderForm: state.cart.orderForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAddresses: () => dispatch(userActions.getAddresses()),
    saveOrderForm: (data) => dispatch(cartActions.saveOrderForm(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withModalRootContext(Address));

