import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import { View, Spacing, Div, InfoRow, MiniInfoCell, Cell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group } from '@vkontakte/vkui';
import { Icon24Place, Icon24LogoInstagram, Icon24LogoVkOutline, Icon24Phone } from '@vkontakte/icons';

const MenuContacts = props => {
	const router = useRouter();

	return (
		<Panel id={props.id}>
	    <PanelHeader left={<PanelHeaderBack onClick={() => router.navigate('menu')} />}>Контакты</PanelHeader>
	    <Group header={<Header>Контакты</Header>}>
	      {_.get(props, 'affiliate.addresses', []).map((item, key) =>
          <Cell 
          	disabled 
          	multiline
		        before={<Icon24Place style={{color: 'var(--icon_tertiary)'}} />}
		        description={item.description}
		        >
		        {item.address}
		      </Cell>
        )}
        {_.get(props, 'affiliate.phones', []).map((item, key) =>
          <Cell
          	expandable 
          	multiline
          	href={`tel:${item.phone}`}
            before={<Icon24Phone style={{color: 'var(--icon_tertiary)'}} />}
            description={item.description}
            >
            {item.phone}
          </Cell>
        )}
        {!!_.get(props, 'affiliate.instagram', '') && 
	        <Cell
	          // description="Наш instagram"
	          expandable
	          before={<Icon24LogoInstagram style={{color: 'var(--icon_tertiary)'}} />}
	          href={`https://instagram.com/${props.affiliate.instagram}`}>
	          {/*{`@${props.affiliate.instagram}`}*/}
	          Наш instagram
	        </Cell>
	      }
	      {_.get(props, 'affiliate.vk_groups', []).length > 0 && 
	      	<Cell
            // description="Мы ВКонтакте"
            expandable
            before={<Icon24LogoVkOutline style={{color: 'var(--icon_tertiary)'}} />}
            href={`https://vk.com/club${props.affiliate.vk_groups[0]['id']}`}>
            Мы ВКонтакте
          </Cell>
        }
	    </Group>
	    <Spacing size={20} />
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    owner: state.owner.item,
  };
}

export default connect(mapStateToProps)(MenuContacts);


