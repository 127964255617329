import lodash from 'lodash';
	
export default class Cart {

	constructor({orderForm, products, cartProducts, cartGifts, cartMores, promocode, promocodeCheckStatus, owner, affiliate, client, deliveryArea, basketRulesActions}) {
    this.orderForm = orderForm || {}
    this.products = products || []
    this.cartProducts = cartProducts || []
    this.cartGifts = cartGifts || []
    this.cartMores = cartMores || []
    this.promocode = promocode || {}
    this.promocodeCheckStatus = promocodeCheckStatus || {}
    this.owner = owner || {}
    this.affiliate = affiliate || {}
    this.orderConditions = JSON.parse(JSON.stringify(_.get(affiliate, 'order_conditions.config', {})))
    this.deliveryArea = deliveryArea || {}
    if(this.deliveryArea && this.deliveryArea.id) {
      const deliveryAreaConditions = this.deliveryArea.order_conditions?.config || {}
      Object.keys(deliveryAreaConditions).forEach(key => {
        this.orderConditions[key] = deliveryAreaConditions[key]
      })
    }
    this.preorderTimeValues = this.orderConditions.preorder_time_values || []
    this.basketRulesDiscountDescription = ''
    this.basketRulesMarkupDescription = ''
    this.basketRulesActions = basketRulesActions || {}
    this.cartGiftsRules = this.getCartGiftsRules()
    this.cartFree = this.getCartFree()
    // Изменяем условия оформления заказа по правилам корзины
    if(this.basketRulesActions?.orderConditions) {
      Object.keys(this.basketRulesActions?.orderConditions).forEach(conditionCode => {
        if(!isNaN(this.basketRulesActions?.orderConditions[conditionCode])) {
          this.orderConditions[conditionCode]['value'] = this.basketRulesActions?.orderConditions[conditionCode]
        }
      })
    }
    this.clientDiscount = client.discount || 0
    this.noFreeCartItems = this.getNoFreeCartItems()
    this.orderSum = this.calcOrderSum()
    // Отдельно, чтоб не учитывалась в правилах корзины!
    this.orderRequiredSum = this.calcProductsRequiredSum()
    this.orderMarkupDescription = ''
    this.orderMarkup = 0 // this.calcOrderMarkup() // TODO ?
    this.orderDiscountDescription = ''
    // const calcOrderDiscount = this.calcOrderDiscount()
    // this.orderDiscount = (this.orderSum < calcOrderDiscount) ? this.orderSum : calcOrderDiscount
    this.orderDiscount = this.calcOrderDiscount()
    this.orderPaymentBonus = this.calcPaymentBonus()
    this.orderSumDiscount = Math.round(100*(this.orderSum + this.orderMarkup + this.orderRequiredSum - this.orderDiscount - this.orderPaymentBonus)) / 100
    this.deliveryPrice = this.calcDeliveryPrice()
    this.orderTotal = this.orderSum + this.deliveryPrice + this.orderMarkup + this.orderRequiredSum - this.orderDiscount - this.orderPaymentBonus
	} 
	
	compareByPrice(a, b) {
    if (Number(a.data.price) < Number(b.data.price))
      return -1;
    if (Number(a.data.price) > Number(b.data.price))
      return 1;
    return 0;
  }

	calcOrderSum() {
    return [].concat(this.noFreeCartItems).filter(item => !!item).reduce((sum, item) => {
      return sum + Number(item.data.quantity) * Math.round(100*(Number(item.data.price) + [].concat(item.data.options).filter(item => !!item).reduce((sum, item) => { return sum + Number(item.price)}, 0))) / 100
    }, 0)
  }

  calcOrderDiscount() {
    let discountType = null
    if(this.basketRulesActions?.discountsDisable) {
      return 0
    }
    if(this.promocode && this.promocode.is_finish) {
      this.orderDiscountDescription = 'Промокод: ' + this.promocode.name
      return this.calcPromocodeDiscount()
    }
    // return (this.calcDiscount([], this.clientDiscount, 0) + this.calcPromocodeDiscount())
    let discountPercent = this.clientDiscount
    discountType = 'personal'
    // Если скидка за самовывоз больше чем клиентская скидка..
    if(this.orderForm.delivery === 'self' && Number(lodash.get(this.orderConditions, 'self_delivery_discount.value', 0)) > this.clientDiscount) {
      discountPercent = Number(lodash.get(this.orderConditions, 'self_delivery_discount.value', 0))
      discountType = 'self_delivery'
    }

    const discount = this.calcDiscount(this.noFreeCartItems, [], discountPercent, 0)
    const promocodeDiscount = this.calcPromocodeDiscount()
    const basketRulesDiscount = this.calcBasketRulesDiscount()

    let finishDiscount = 0
    if(discount > promocodeDiscount) {
      finishDiscount = discount
    } else {
      finishDiscount = promocodeDiscount
      discountType = 'promocode'
    }
    if(finishDiscount < basketRulesDiscount) {
      finishDiscount = basketRulesDiscount
      discountType = 'basket_rules'
    }

    switch(discountType) {
      case 'promocode':
        this.orderDiscountDescription = 'Промокод: ' + this.promocode.name
        break;

      case 'personal':
        this.orderDiscountDescription = 'Персональная скидка клиента'
        break;

      case 'self_delivery':
        this.orderDiscountDescription = 'За самовывоз'
        break;

      case 'basket_rules':
        this.orderDiscountDescription = this.basketRulesDiscountDescription
        break;
    }
    return finishDiscount
  }

  calcPromocodeDiscount() {
    let discount = 0
    if(this.promocodeCheckStatus.code == 'success') {
      if(this.promocode?.type?.code == 'discount') {
        discount = this.calcDiscount(this.noFreeCartItems, this.promocode.for_items, this.promocode.value, 0)
      } else if(this.promocode?.type?.code == 'amount') {
        discount = this.calcDiscount(this.noFreeCartItems, this.promocode.for_items, 0, this.promocode.value)
      }
    }
    return discount
  }

  calcBasketRulesDiscount() {
    let discount = 0
    let markup = 0
    this.basketRulesDiscountDescription = ''
    this.basketRulesMarkupDescription = ''
    this.basketRulesActions?.discounts.forEach(item => {
      if(item.products.length > 0) {
        let curDiscount = 0;
        if(item.unit === 'percent') {
          curDiscount = this.calcDiscount(this.noFreeCartItems, item.products, item.value, 0)
        } else if(item.unit === 'fix_each') {
          curDiscount = this.calcDiscount(this.noFreeCartItems, item.products, 0, 0, item.value)
        } else if(item.unit === 'amount') {
          curDiscount = this.calcDiscount(this.noFreeCartItems, item.products, 0, item.value, 0)
        }
        if(curDiscount > 0) {
          discount += curDiscount
          if(item.title && item.title.length > 0) {
            if(this.basketRulesDiscountDescription.length > 0) {
              this.basketRulesDiscountDescription += ', '
            }
            this.basketRulesDiscountDescription += item.title
          }
        }
      }
    })

    // Добавил 12-02-2021
    this.basketRulesActions?.cheapestDiscounts.forEach(item => {
      if(item.products.length > 0) {
        let curDiscount = 0
        // Получим товары для подсчета скидка (к которым будет применяться скидка)
        let cheapestProducts = [] 
        let itemsCount = Number(item.count)
        const cheapestProductsCandidates = this.noFreeCartItems.filter(item2 => item.products.indexOf(item2.id) != -1).sort(this.compareByPrice)
        cheapestProductsCandidates.forEach(item2 => {
          if(itemsCount > 0) {
            const newItem = _.cloneDeep(item2)
            if(Number(newItem.data.quantity) <= itemsCount) {
              cheapestProducts.push(newItem)
              itemsCount -= Number(newItem.data.quantity)
            } else {
              newItem.data.quantity = itemsCount
              cheapestProducts.push(newItem)
              itemsCount = 0
            }
          }
        })

        if(cheapestProducts.length > 0) {
          if(item.unit === 'percent') {
            curDiscount = this.calcDiscount(cheapestProducts, item.products, item.value, 0)
          } else if(item.unit === 'fix_each') {
            curDiscount = this.calcDiscount(cheapestProducts, item.products, 0, 0, item.value)
          } else if(item.unit === 'amount') {
            curDiscount = this.calcDiscount(cheapestProducts, item.products, 0, item.value, 0)
          }
          if(curDiscount > 0) {
            discount += curDiscount
            if(item.title && item.title.length > 0) {
              if(this.basketRulesDiscountDescription.length > 0) {
                this.basketRulesDiscountDescription += ', ';
              }
              this.basketRulesDiscountDescription += item.title
            }
          }
        }
      }
    })

    this.basketRulesActions?.fixPrices.forEach(item => {
      const fixPriceDiscount = this.calcFixPriceDiscount(item.products_groups, item.value)
      if(fixPriceDiscount > 0) {
        discount += fixPriceDiscount
        if(item.title && item.title.length > 0) {
          if(this.basketRulesDiscountDescription.length > 0) {
            this.basketRulesDiscountDescription += ', ';
          }
          this.basketRulesDiscountDescription += item.title
        }
      } else {
        // Наценка
        markup += -fixPriceDiscount
        if(item.title && item.title.length > 0) {
          if(this.basketRulesMarkupDescription.length > 0) {
            this.basketRulesMarkupDescription += ', ';
          }
          this.basketRulesMarkupDescription += item.title
        }
      }
    })

    this.orderMarkup = markup
    this.orderMarkupDescription = this.basketRulesMarkupDescription
    return discount
  }

  calcDiscount(noFreeCartItems = [], forItems = [], percent = 0, amount = 0, fix_each = 0) {
    if(percent == 0 && amount == 0 && fix_each == 0) return 0
    let discount = 0
    if(percent > 0) {
      discount = [].concat(noFreeCartItems).filter(item => !!item).filter(item => !forItems || forItems.length == 0 || forItems.indexOf(item.id) != -1).reduce((sum, item) => {
        let maxDiscountPercent = Number([].concat(this.products).filter(item2 => item2.id == item.id).pop().max_discount)
        let discountPercent = (maxDiscountPercent > percent) ? percent : maxDiscountPercent
        return sum + item.data.quantity * (Number(item.data.price) + [].concat(item.data.options).filter(item => !!item).reduce((sum, item) => { return Number(sum) + Number(item.price)}, 0)) * discountPercent / 100
      }, 0)
    } else if(amount > 0) {
      let sum = [].concat(noFreeCartItems).filter(item => !!item).filter(item => !forItems || forItems.length == 0 || forItems.indexOf(item.id) != -1).reduce((sum, item) => {
        return sum + item.data.quantity * (Number(item.data.price) + [].concat(item.data.options).filter(item => !!item).reduce((sum, item) => { return Number(sum) + Number(item.price)}, 0))
      }, 0)
      discount = (sum > amount) ? amount : sum
    } else if(fix_each > 0) {
      discount = [].concat(noFreeCartItems).filter(item => !!item).filter(item => !forItems || forItems.length == 0 || forItems.indexOf(item.id) != -1).reduce((sum, item) => {
        let maxDiscountPercent = Number([].concat(this.products).filter(item2 => item2.id == item.id).pop().max_discount)
        const maxDiscount = Number(item.data.quantity) * (Number(item.data.price) + [].concat(item.data.options).filter(item => !!item).reduce((sum, item) => { return Number(sum) + Number(item.price)}, 0)) * maxDiscountPercent / 100
          const fixDiscount = Number(item.data.quantity) * fix_each
          const curItemDiscount = (maxDiscount > fixDiscount) ? fixDiscount : maxDiscount
          return sum + curItemDiscount
      }, 0)
    }
    return Number(discount)
  }

  calcFixPriceDiscount(products_groups, fix_price) {
    //  TODO Один и тот же товар может быть учтен в разных комбо акциях :(
    let fixPriceDiscount = 0
    const sortNoFreeCartItems = JSON.parse(JSON.stringify( [...this.noFreeCartItems].sort((a, b) => Number(a.data.price) - Number(b.data.price)) ))
    // console.log('sortNoFreeCartItems', sortNoFreeCartItems)
    // console.log('noFreeCartItems', this.noFreeCartItems)

    let findCombo = true
    let iteration = 0 // считаем количество итераций (на всякий случай защитимся от зацикливания :) ) == максимальное количество комбо = 20
    do {
      let comboItems = []
      products_groups.forEach(group => {
        let groupCount = Number(group.count)
        sortNoFreeCartItems.forEach(item => {
          if(groupCount > 0) {
            if(group.products.indexOf(item.id) !== -1) {
              // console.log('item', item)
              if(!item.data.in_fix_price_combo) {
                item.data.in_fix_price_combo = 0  
              }
              
              if(groupCount > 0) {
                if(groupCount > Number(item.data.quantity) - Number(item.data.in_fix_price_combo)) {
                  comboItems.push({
                    id: item.id,
                    data: {
                      price: item.data.price,
                      quantity: Number(item.data.quantity) - Number(item.data.in_fix_price_combo),
                    },
                  })
                  groupCount -= Number(item.data.quantity) - Number(item.data.in_fix_price_combo)
                  item.data.in_fix_price_combo += Number(item.data.quantity) - Number(item.data.in_fix_price_combo)
                } else {
                  comboItems.push({
                    id: item.id,
                    data: {
                      price: item.data.price,
                      quantity: groupCount,
                    },
                  })
                  item.data.in_fix_price_combo += groupCount
                  groupCount = 0
                } 
              }
            }
          }
        })
        // Нашлись не все товары для полноценного Комбо набора
        if(groupCount > 0) {
          findCombo = false
        }
      })
      // console.log('comboItems', comboItems)
      if(findCombo) {
        const comboItemsSum = comboItems.reduce((sum, item) => {
          return sum + item.data.price * item.data.quantity
        }, 0)
        fixPriceDiscount += comboItemsSum - fix_price
      }
    } while (findCombo && iteration++ < 20)
    // console.log('fixPriceDiscount', fixPriceDiscount)
    return fixPriceDiscount
  }

  calcPaymentBonus() {
    return this.orderForm.use_bonus && Math.abs(parseInt(this.orderForm.payment_bonus)) || 0
  }

  calcDeliveryPrice() {
    if(this.orderForm.delivery == 'self') {
      return 0
    }
    const OCdeliveryPrice = Number(_.get(this.orderConditions, 'delivery_price.value', 0))
    if(this.basketRulesActions?.freeDeliveryDisable) {
      return OCdeliveryPrice
    }
    const OCfreeDeliveryFrom = Number(_.get(this.orderConditions, 'free_delivery_from.value', 0))
    return (OCfreeDeliveryFrom <= (this.orderSumDiscount - this.orderPaymentBonus) && OCfreeDeliveryFrom > 0) ? 0 : OCdeliveryPrice
  }
  
  // BKP 09-02-2021
  // getNoFreeCartItems() {
  //   let noFreeCartItems = []
  //   if(this.promocodeCheckStatus.code == 'success' && _.get(this.promocode, 'type.code') == 'free') {
  //     let cartItemsPromoCandidates = this.cartProducts.filter(item => this.promocode.for_items.indexOf(item.id) != -1).sort(this.compareByPrice)
  //     let promoItemsCount = Number(this.promocode.count)
  //     cartItemsPromoCandidates.forEach(item => {
  //       if(promoItemsCount > 0) {
  //         if(Number(item.data.quantity) <= Number(this.promocode.count)) {
  //           promoItemsCount -= Number(item.data.quantity)
  //         } else {
  //           let newItem = _.cloneDeep(item)
  //           newItem.data.quantity = Number(item.data.quantity) - promoItemsCount
  //           noFreeCartItems.push(newItem)
  //           promoItemsCount = 0
  //         }
  //       } else {
  //         noFreeCartItems.push(item)
  //       }
  //     })
  //     this.cartProducts.filter(item => this.promocode.for_items.indexOf(item.id) == -1).forEach(item => {
  //       noFreeCartItems.push(item)
  //     })
  //   } else {
  //     noFreeCartItems = this.cartProducts
  //   }
  //   return noFreeCartItems
  // }

  getNoFreeCartItems() {
    let noFreeCartItems = []
    if(this.promocodeCheckStatus.code == 'success' && _.get(this.promocode, 'type.code') == 'free') {
      let cartItemsPromoCandidates = this.cartProducts.filter(item => this.promocode.for_items.indexOf(item.id) != -1).sort(this.compareByPrice)
      let promoItemsCount = Number(this.promocode.count)
      cartItemsPromoCandidates.forEach(item => {
        if(promoItemsCount > 0) {
          // !!!!!!!!!!!!!!!!!! 12-02-2021
          // if(Number(item.data.quantity) <= Number(this.promocode.count)) {
          if(Number(item.data.quantity) <= promoItemsCount) {
            promoItemsCount -= Number(item.data.quantity)
          } else {
            let newItem = _.cloneDeep(item)
            newItem.data.quantity = Number(item.data.quantity) - promoItemsCount
            noFreeCartItems.push(newItem)
            promoItemsCount = 0
          }
        } else {
          noFreeCartItems.push(item)
        }
      })
      this.cartProducts.filter(item => this.promocode.for_items.indexOf(item.id) == -1).forEach(item => {
        noFreeCartItems.push(item)
      })
    } else {
      noFreeCartItems = this.cartProducts
    }

    const cheapestFree = this.basketRulesActions?.cheapestFree || []
    let noCheapestFreeCartItems = noFreeCartItems
    cheapestFree.forEach(ruleAction => {
      if(ruleAction.value > 0 && ruleAction.products.length > 0) {
        let cartItemsRestItemsTMP = [].concat(noCheapestFreeCartItems)
        noCheapestFreeCartItems = []
        let cartItemsCheapestFreeCandidates = cartItemsRestItemsTMP.filter(item => ruleAction.products.indexOf(item.id) != -1).sort(this.compareByPrice)
        let freeItemsCount = Number(ruleAction.value)
        cartItemsCheapestFreeCandidates.forEach(item => {
          if(freeItemsCount > 0) {
            // !!!!!!!!!!!!!!!!! 12-02-2021
            // if(Number(item.data.quantity) <= Number(ruleAction.value)) {
            if(Number(item.data.quantity) <= freeItemsCount) {
              freeItemsCount -= Number(item.data.quantity)
            } else {
              let newItem = _.cloneDeep(item)
              newItem.data.quantity = Number(item.data.quantity) - freeItemsCount
              noCheapestFreeCartItems.push(newItem)
              freeItemsCount = 0
            }
          } else {
            noCheapestFreeCartItems.push(item)
          }
        })
        // и добавляем оставшиеся НЕ кандидаты
        cartItemsRestItemsTMP.filter(item => ruleAction.products.indexOf(item.id) == -1).forEach(item => {
          noCheapestFreeCartItems.push(item)
        })
      }
    })
    noFreeCartItems = noCheapestFreeCartItems
    
    return noFreeCartItems
  }

  // BKP 09-02-2021
  // getCartItems() {
  //   let cartItemsPromoItems = []
  //   let cartItemsPromoRestItems = []
  //   if(this.promocodeCheckStatus.code == 'success' && this.promocode.type.code == 'free') {
  //     let cartItemsPromoCandidates = this.cartProducts.filter(item => this.promocode.for_items.indexOf(item.id) != -1).sort(this.compareByPrice)
  //     let promoItemsCount = Number(this.promocode.count)
  //     cartItemsPromoCandidates.forEach(item => {
  //       if(promoItemsCount > 0) {
  //         let newItem = _.cloneDeep(item)
  //         newItem.data.promo = this.promocode.name
  //         newItem.data.is_promo = true
  //         newItem.data.price = 0
  //         if(Number(item.data.quantity) <= Number(this.promocode.count)) {
  //           promoItemsCount -= Number(item.data.quantity)
  //         } else {
  //           newItem.data.quantity = promoItemsCount
  //           let newItem2 = _.cloneDeep(item)
  //           newItem2.data.quantity = Number(item.data.quantity) - promoItemsCount
  //           cartItemsPromoRestItems.push(newItem2)
  //           promoItemsCount = 0
  //         }
  //         cartItemsPromoItems.push(newItem)
  //       } else {
  //         cartItemsPromoRestItems.push(item)
  //       }
  //     })
  //   }
  //   let newCartProducts = []
  //   if(cartItemsPromoItems.length > 0) {
  //     newCartProducts = [...cartItemsPromoItems, ...cartItemsPromoRestItems]
  //     this.cartProducts.filter(item => this.promocode.for_items.indexOf(item.id) == -1).forEach(item => {
  //       newCartProducts.push(item)
  //     })
  //   } else {
  //     newCartProducts = this.cartProducts
  //   }
  //   return newCartProducts.map(cartProduct => {
  //     let product = this.products.find(item => item.id == cartProduct.id)
  //     let cartProductInfo = {
  //       'id': cartProduct.id,
  //       'name': product.name,
  //       'price': cartProduct.data.price,
  //       'old_price': product.old_price,
  //       'max_discount': product.max_discount,
  //       'property': product.sku_for.title,
  //       'img': product.img,
  //       'more': this.products.filter(function(item){
  //         if(typeof product.more == 'object') {
  //           return product.more.indexOf(item.id) != -1
  //         } else {
  //           return false
  //         }
  //       }),
  //       'quantity': cartProduct.data.quantity,
  //       'options': cartProduct.data.options,
  //       'is_promo': cartProduct.data.is_promo,
  //       'promo': cartProduct.data.promo,
  //     }
  //     return cartProductInfo
  //   }) //.filter(item => !!item)
  // }

  getCartItems() {
    let cartItemsPromoItems = []
    let cartItemsBasketRulesCheapestItems = []
    let cartItemsPromoRestItems = []
    if(this.promocodeCheckStatus.code == 'success' && this.promocode.type?.code == 'free') {
      let cartItemsPromoCandidates = this.cartProducts.filter(item => this.promocode.for_items.indexOf(item.id) != -1).sort(this.compareByPrice)
      let promoItemsCount = Number(this.promocode.count)
      cartItemsPromoCandidates.forEach(item => {
        if(promoItemsCount > 0) {
          let newItem = _.cloneDeep(item)
          newItem.data.promo = this.promocode.name
          newItem.data.is_promo = true
          newItem.data.price = 0
          // !!!!!!!!!!!!!!! 12-02-2021
          // if(Number(item.data.quantity) <= Number(this.promocode.count)) {
          if(Number(item.data.quantity) <= promoItemsCount) {
            promoItemsCount -= Number(item.data.quantity)
          } else {
            newItem.data.quantity = promoItemsCount
            let newItem2 = _.cloneDeep(item)
            newItem2.data.quantity = Number(item.data.quantity) - promoItemsCount
            cartItemsPromoRestItems.push(newItem2)
            promoItemsCount = 0
          }
          cartItemsPromoItems.push(newItem)
        } else {
          cartItemsPromoRestItems.push(item)
        }
      })
    }

    // let newCartProducts = []
    // if(cartItemsPromoItems.length > 0) {
    //   newCartProducts = [...cartItemsPromoItems, ...cartItemsPromoRestItems]
    //   this.cartProducts.filter(item => this.promocode.for_items.indexOf(item.id) == -1).forEach(item => {
    //     newCartProducts.push(item)
    //   })
    // } else {
    //   newCartProducts = this.cartProducts
    // }

    let cartItemsRestItems = []
    if(cartItemsPromoItems.length > 0) {
      cartItemsRestItems = cartItemsPromoRestItems
      this.cartProducts.filter(item => this.promocode.for_items.indexOf(item.id) == -1).forEach(item => {
        cartItemsRestItems.push(item)
      })
    } else {
      cartItemsRestItems = this.cartProducts
    }

    const cheapestFree = this.basketRulesActions?.cheapestFree || []
    cheapestFree.forEach(ruleAction => {
      if(ruleAction.value > 0 && ruleAction.products.length > 0) {
        let cartItemsRestItemsTMP = [].concat(cartItemsRestItems)
        cartItemsRestItems = []
        let cartItemsCheapestFreeCandidates = cartItemsRestItemsTMP.filter(item => ruleAction.products.indexOf(item.id) != -1).sort(this.compareByPrice)
        let freeItemsCount = Number(ruleAction.value)
        cartItemsCheapestFreeCandidates.forEach(item => {
          if(freeItemsCount > 0) {
            // !!!!!!!!!!!!!!! 12-02-2021
            // if(Number(item.data.quantity) <= Number(ruleAction.value)) {
            if(Number(item.data.quantity) <= freeItemsCount) {
              let newItem = _.cloneDeep(item)
              newItem.data.price = 0
              newItem.data.promo = ruleAction.title
              newItem.data.is_promo = true // TODO может заменить на is_cheapest_free ?
              cartItemsPromoItems.push(newItem)
              freeItemsCount -= Number(item.data.quantity)
            } else {
              let newItem = _.cloneDeep(item)
              newItem.data.quantity = freeItemsCount
              newItem.data.price = 0
              newItem.data.promo = ruleAction.title
              newItem.data.is_promo = true // TODO может заменить на is_cheapest_free ?
              cartItemsPromoItems.push(newItem)
              let newItem2 = _.cloneDeep(item)
              newItem2.data.quantity = Number(item.data.quantity) - freeItemsCount
              cartItemsRestItems.push(newItem2)
              freeItemsCount = 0
            }
          } else {
            cartItemsRestItems.push(item)
          }
        })
        // и добавляем оставшиеся НЕ кандидаты
        cartItemsRestItemsTMP.filter(item => ruleAction.products.indexOf(item.id) == -1).forEach(item => {
          cartItemsRestItems.push(item)
        })
      }
    })

    let newCartProducts = []
    if(cartItemsPromoItems.length > 0) {
      newCartProducts = [...cartItemsPromoItems, ...cartItemsRestItems]
    } else {
      newCartProducts = this.cartProducts
    }
      
    return newCartProducts.map(cartProduct => {
      let product = this.products.find(item => item.id == cartProduct.id)
      if(!product) return
      let cartProductInfo = {
        'id': cartProduct.id,
        'name': product.name,
        'price': cartProduct.data.price,
        'old_price': product.old_price,
        'max_discount': product.max_discount,
        'property': product.sku_for.title,
        'img': product.img,
        'more': this.products.filter(function(item){
          if(typeof product.more == 'object') {
            return product.more.indexOf(item.id) != -1
          } else {
            return false
          }
        }),
        'quantity': cartProduct.data.quantity,
        'options': cartProduct.data.options,
        'is_promo': cartProduct.data.is_promo,
        'promo': cartProduct.data.promo,
      }
      return cartProductInfo
    }).filter(item => !!item)
  }

  getProductsRequiredItems() {
    return [].concat(this.basketRulesActions.productsRequired)
    .map(reqItem => {
      let product = this.products.find(item => item.id == reqItem.id)
      if(!product) return
      return {
        "id": product.id,
        "price": product.price,
        "old_price": product.old_price,
        "weight": product.weight,
        "volume": product.volume,
        "size": product.size,
        "name": product.name,
        "preview_text": product.preview_text,
        "property": product.sku_for.title,
        "img": product.img,
        "quantity": reqItem.quantity,
        "promo": reqItem.title,
      }
    }).filter(item => !!item)
  }

  calcProductsRequiredSum() {
    const requiredItems = this.getProductsRequiredItems()
    return requiredItems.reduce((previousValue, currentItem) => {
      return previousValue += Number(currentItem.quantity) * Number(currentItem.price)
    }, 0)
  }

  getAddonItems() {
    return this.products
    .filter(item => {
      return item.addon_type
    })
  }

  getMoresItems() {
    let moresItems = []
    Object.keys(this.cartMores)
    .map(itemId => {
      moresItems = moresItems.concat(this.cartMores[itemId].map(cartMore => {
        const product = this.products.find(item => item.id == cartMore.id)
        if(!product) return
        const cartMoreInfo = {
          'id': product.id,
          'name': product.name,
          'price': 0,
          'property': product.sku_for.title,
          'img': product.img,
          'quantity': 1,
        }
        return cartMoreInfo
      }).filter(item => !!item))
    })
    return moresItems
  }

  getCartFree() {
    return this.basketRulesActions.free
  }

  getFreeItems() {
    return this.basketRulesActions.free
    .map(cartFree => {
      let product = this.products.find(item => item.id == cartFree.id)
      if(!product) return
      let cartFreeInfo = {
        'id': product.id,
        'name': product.name,
        'price': product.price,
        'old_price': product.old_price,
        'property': product.sku_for.title,
        'img': product.img,
        'quantity': cartFree.data.quantity,
        'action_title': cartFree.data.action_title || 'Подарок',
      }
      return cartFreeInfo
    }).filter(item => !!item)
  }

  getGiftsItems() {
    return this.cartGifts
    .map(cartGift => {
      let product = this.products.find(item => item.id == cartGift.id)
      if(!product) return
      let cartGiftInfo = {
        'id': cartGift.id,
        'name': product.name,
        'price': product.price,
        'old_price': product.old_price,
        'property': product.sku_for.title,
        'img': product.img,
        'quantity': cartGift.data.quantity,
        // 'options': cartGift.data.options,
      }
      return cartGiftInfo
    }).filter(item => !!item)
  }

  getCartGiftsRules() {
    let gifts = []
    const sets = [].concat(this.basketRulesActions.giftSets)
    sets.forEach(set => {
      gifts = gifts.concat(set.selected)
    })
    return gifts
  }

  getGiftsRuleItems() {
    let selected = []
    const sets = [].concat(this.basketRulesActions.giftSets)
    sets.forEach(set => {
      this.products
      .filter(item => set.selected.filter(item2 => item.id === item2.id).length > 0)
      .map(item => {
        const selectedItem = set.selected.find(item2 => item.id == item2.id)
        const quantity = selectedItem.data.quantity || 0
        const action_title = set.action_title || 'Подарок на выбор'
        let addItem = Object.assign({}, item)
        addItem = Object.assign(addItem, {
          quantity, 
          property: addItem.sku_for.title,
          action_title
        })
        selected.push(addItem)
      })
    })
    return selected
  }

  getGiftSetsItems() {
    let sets = [].concat(this.basketRulesActions.giftSets)
    sets.forEach((set, index) => {
      sets[index]['items'] = this.products
      .filter(item => set.products.indexOf(item.id) !== -1)
      .map(item => {
        var sku = this.products.filter(item2 => {
          // return item.sku_ids.indexOf(item2.id) != -1
          // SKU у подарков не должно быть
          return item.id == item2.id
        })
        item.sku = sku.map(function(item2){
          return {
            "id": item2.id,
            "price": item2.price,
            "old_price": item2.old_price,
            "weight": item2.weight,
            "volume": item2.volume,
            "size": item2.size,
            "name": item2.name,
            "preview_text": item2.preview_text,
            "property": item2.sku_for.title,
            "img": item2.img,
          }
        })
        return item
      })
    })
    return sets
  }

  getRecommendsItems() {
    const recommendsCatalogItems = this.products
    .filter(item => this.basketRulesActions.recommends.indexOf(parseInt(item.id)) !== -1)
    .filter(item => this.cartProducts.filter(cartItem => cartItem.id == item.id).length == 0 )

    if(!recommendsCatalogItems || !recommendsCatalogItems.length) return false;
    
    return this.basketRulesActions.recommends
    .filter(recItemID => this.cartProducts.filter(cartItem => parseInt(cartItem.id) == parseInt(recItemID)).length == 0 )
    .map(recItemID => {
      const item = recommendsCatalogItems.find(item3 => parseInt(item3.id) == parseInt(recItemID))
      if(!item) return null;

      var sku = this.products.filter(item2 => {
        return item.sku_ids.indexOf(item2.id) != -1
      })
      item.sku = sku.map(function(item2){
        return {
          "id": item2.id,
          "price": item2.price,
          "old_price": item2.old_price,
          "weight": item2.weight,
          "volume": item2.volume,
          "size": item2.size,
          "name": item2.name,
          "preview_text": item2.preview_text,
          "property": item2.sku_for.title,
          "img": item2.img,
          "options_groups": item2.options_groups,
        }
      })
      return item
    })
    .filter(item => !!item)
  }

  // Макс. количество подарков от суммы
  calcGiftsSumMaxCount() {
    if(
        (this.promocodeCheckStatus.code == 'success' && this.promocode.is_stop_actions)
        || this.basketRulesActions.giftsSumDisable
      ) {
      return 0
    }
    let count = 0
    if(_.get(this.owner, 'tariff.scope.actions', 0) > 0 && _.get(this.affiliate, 'gifts_from', []).length > 0 && this.affiliate.gifts_from.filter(item => item.sum <= this.orderSumDiscount).length > 0) {
      for (var i = this.affiliate.gifts_from.length - 1; i >= 0; i--) {
        if(this.orderSumDiscount >= this.affiliate.gifts_from[i].sum && count < this.affiliate.gifts_from[i].max_count) {
          count = Number(this.affiliate.gifts_from[i].max_count)
        }
      }
    }
    return count
  }

  // Количество выбранных подарков от суммы
  calcGiftsSumCount() {
    let count = 0
    for (var i = this.cartGifts.length - 1; i >= 0; i--) {
      count += Number(this.cartGifts[i].data.quantity)
    }
    return count
  }

  // Макс. количество подарков по правилам
  calcGiftsRuleMaxCount() {
    if(
        (this.promocodeCheckStatus.code == 'success' && this.promocode.is_stop_actions)
        || this.basketRulesActions.giftsDisable
      ) {
      return 0
    }
    let count = 0
    let sets = [].concat(this.basketRulesActions.giftSets)
    sets.forEach(set => {
      count += Number(set.max_count)
    })
    return count
  }

  // Количество выбранных подарков по правилам
  calcGiftsRuleCount() {
    let count = 0
    let sets = [].concat(this.basketRulesActions.giftSets)
    sets.forEach(set => {
      set.selected.forEach(item => {
        count += Number(item.data.quantity)
      })
    })
    return count
  }

  // Макс. количество всех подарков (от суммы и по правилам)
  calcAllGiftsMaxCount() {
    return this.calcGiftsSumMaxCount() + this.calcGiftsRuleMaxCount()
  }

  // Количество всех выбранных подарков (от суммы и по правилам)
  calcAllGiftsCount() {
    return this.calcGiftsSumCount() + this.calcGiftsRuleCount()
  }

  getPromocodeGifts() {
    if(this.promocodeCheckStatus.code != 'success' || this.promocode.type?.code != 'gift' || this.promocode.gifts.length == 0) {
      return []
    }
    return _.cloneDeep(this.products).filter(item => {
      return this.promocode.gifts.indexOf(item.id) != -1
    })
    .map(item => {
      let quantity = this.promocode.gifts.filter(item2 => {
        return item.id == item2
      }).length
      item.quantity = quantity
      item.property = item.sku_for.title
      return item
    })
  }
  
}