import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import { Cell, Div, Group, Spinner, Header, SimpleCell, InfoRow } from '@vkontakte/vkui';
import { Icon24CheckCircleOn, Icon24CheckCircleOff, Icon24Add, Icon24Place } from '@vkontakte/icons';

import * as cartActions from '../store/actions/cart';

const PickupPoint = props => {
	const router = useRouter();

  useEffect(() => {
    // async function getActions() {
    //   await props.dispatch(actionsActions.getActions(props.affiliate.id));
    // }
    // props.getAddresses()
    // .then(data => {
    //   if(!data.items || !data.items.length) {
    //     // props.navigation.navigate('NewAddress', {address_id: 0})
    //   }
    // })
    // .catch(err => {})
    // function initOrderForm() {
    //   let initOrderForm = _.cloneDeep(props.orderForm) || {}
    //   if(!initOrderForm.delivery || !!props.affiliate.pickup_only) {
    //     initOrderForm.delivery = (!!props.affiliate.pickup_only) ? 'self' : 'delivery'
    //   }
    //   if(!initOrderForm.pickup_point) {
    //     // initOrderForm.pickup_point = _.get(props, 'affiliate.pickup_points[0].name')
    //   }
    //   if(!initOrderForm.address_district) {
    //     initOrderForm.address_district = _.get(props, 'affiliate.districts[0].name')
    //   }
    //   props.dispatch(cartActions.saveOrderForm(initOrderForm));
    // }
    // if(props.affiliate.id) {
    //   if(props.actionsStatus.code !== 'success' && !props.actions.length) {
    //     getActions();
    //   }
    //   initOrderForm();
    // }
  }, []);

	return (
		<div>
      {
        _.get(props, 'affiliate.pickup_points', []).map((item, key) => {
          return (<Cell key={key} 
          onClick={() => {
            props.saveOrderForm({...props.orderForm, pickup_point: item.code})
            props.onModal(null)
          }}
          before={
            item.code === props.orderForm.pickup_point
            ? <Icon24CheckCircleOn style={{color: 'var(--color-1)'}} />
            : <Icon24CheckCircleOff style={{color: 'var(--icon_secondary)'}} />
          }
          >{ item.name }</Cell>)
        })
      }
	  </div>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    orderForm: state.cart.orderForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveOrderForm: (data) => dispatch(cartActions.saveOrderForm(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupPoint);

