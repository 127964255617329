import { HTTP } from '../../utils/http-common';
import {ERROR} from '../../utils/errors'
import { 
  GET_PRODUCTS_REQUEST, 
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,

  GET_SECTIONS_REQUEST, 
  GET_SECTIONS_SUCCESS,
  GET_SECTIONS_ERROR,
} from "../ActionTypes";

function commitAction(type, data) {
	return { type, data }
}

export function getProducts(affiliate_id) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      if(!affiliate_id) {
        let err = ERROR.make(400, "Invalid parameter: affiliate_id")
        dispatch(commitAction(GET_PRODUCTS_ERROR, err))
        reject(err)
        return;
      }
      dispatch(commitAction(GET_PRODUCTS_REQUEST))

      let section_id = 0
      let active = 1
      let count = 1000

      HTTP.get("affiliates/"+affiliate_id+"/items", {params: {section_id, active, count}})
    	.then(resp => {
        dispatch(commitAction(GET_PRODUCTS_SUCCESS, resp.data.data.items))
        resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(GET_PRODUCTS_ERROR, err))
        reject(err)
      })
    })
  }
}

export function getSections({affiliate_id, section_id}) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      if(!affiliate_id) {
        let err = ERROR.make(400, "Invalid parameter: affiliate_id")
        dispatch(commitAction(GET_SECTIONS_ERROR, err))
        reject(err)
        return;
      }
      if(typeof section_id === 'undefined') {
        let err = ERROR.make(400, "Invalid parameter: section_id")
        dispatch(commitAction(GET_SECTIONS_ERROR, err))
        reject(err)
        return;
      }
      dispatch(commitAction(GET_SECTIONS_REQUEST))

      HTTP.get("affiliates/"+affiliate_id+"/sections", {params: {section_id, global_active: 1, include_subsections: 1}})
      .then(resp => {
        dispatch(commitAction(GET_SECTIONS_SUCCESS, resp.data.data.items))
        resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(GET_SECTIONS_ERROR, err))
        reject(err)
      })
    })
  }
}