import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import { usePlatform, View, Separator, IconButton, Counter, Div, Button, FixedLayout, CardGrid, ContentCard, Header, SimpleCell, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon24GearOutline, Icon24Place, Icon24Note, Icon28UserCircleOutline, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircleOutline, Icon24SmartphoneOutline } from '@vkontakte/icons';

const Personal = props => {
	const router = useRouter();

	return (
		<Panel id={props.id}>
	    <PanelHeader className="PersonalHeader">Личный кабинет</PanelHeader>
	    {
	    	(props.authStatus.code === 'success')
			  ? <Group>
				    <div className="PersonalHeader__Name">
				    	<Div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
				    		{/*{ props.client.name || 'Без имени' }*/}
				    		{ props.user.full_name || props.client.name || 'Без имени' }
				    		{/*<IconButton><Icon24GearOutline width={28} height={28} style={{ color: 'var(--accent)'}} /></IconButton>*/}
				    	</Div>
				    </div>
			      <SimpleCell 
			        before={<Icon24Note style={{color: 'var(--icon_tertiary)'}} />}
			        expandable
			        onClick={() => router.navigate('personal/orders')}
			      >
			        Мои заказы
			      </SimpleCell>
			      <SimpleCell 
			        before={<Icon24Place style={{color: 'var(--icon_tertiary)'}} />}
			        expandable
			        onClick={() => router.navigate('personal/addresses')}
			      >
			        Мои адреса
			      </SimpleCell>
			      <Separator style={{ margin: '10px 0' }} />
			      <SimpleCell 
			        before={<Icon24MoneyCircleOutline style={{color: 'var(--icon_tertiary)'}} />}
			        after={!!props.client?.score && <Counter mode="primary">{ props.client?.score || 0 }</Counter>}
			        onClick={() => props.onModal({type: 'LOYALTY'})}
			      >
			        Бонусные баллы
			      </SimpleCell>
			    </Group>
		    : (props.authStatus.code === 'loading' || props.clientStatus.code === 'loading')
		    	? <Placeholder style={{paddingBottom: 0}} stretched icon={<Icon28UserCircleOutline width={56} height={56} />}
	            header="Авторизация...">
	            Пожалуйста подождите.
	          </Placeholder>
		    	: <Placeholder style={{paddingBottom: 0}} stretched icon={<Icon28UserCircleOutline width={56} height={56} />}
	            header="Давайте знакомиться!"
	            action={<Button size="l" onClick={() => router.navigate('home')}>Авторизоваться</Button>}>
	            Для просмотра истории ваших заказов необходима авторизация.
	          </Placeholder>
		  }
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    owner: state.owner.item,
    affiliate: state.affiliates.item,
    affiliateInitStatus: state.affiliates.itemInitStatus,
    user: state.user.user,
    client: state.user.client,
    clientStatus: state.user.clientStatus,
    authStatus: state.user.authStatus,
  };
}

export default connect(mapStateToProps)(Personal);

