import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRoute, RouteNode } from 'react-router5'
import bridge from '@vkontakte/vk-bridge';
import _ from 'lodash';
import { List, Button, Cell, Alert, Counter, View, Header, SimpleCell, RichCell, Avatar, ScreenSpinner, PanelHeaderButton, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import './custom.css';
import { Icon24Place, Icon20Dropdown, Icon28Newsfeed, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import * as appActions from './store/actions/app';
import * as ownerActions from './store/actions/owner';
import * as affiliatesActions from './store/actions/affiliates';
import * as userActions from './store/actions/user';

import Home from './containers/Home';
import Menu from './containers/Menu';
import Actions from './containers/Actions';
import Personal from './containers/Personal';
import Cart from './containers/Cart';
import PageNotFound from './containers/PageNotFound';
import Empty from './containers/Empty';
import GetStarted from './containers/GetStarted';

const App = props => {

  const queryParams = props.queryParams

  const vk_user_id = Number(queryParams.vk_user_id)
  const vk_group_id = Number(queryParams.vk_group_id)
  const vk_app_id = Number(queryParams.vk_app_id)
  const vk_platform = queryParams.vk_platform

  const isEmpty = !vk_group_id // && vk_app_id == '6408974'
  
  if(vk_group_id) {
    bridge.send("VKWebAppStorageSet", {"key": vk_user_id+"_last_vk_group_id", "value": vk_group_id.toString()});
  }

  // // Работаем с Хэш строкой приложения ВК
          
  // if(Object.keys(QueryHash).length > 0 && Object.keys(QueryHash)[0].indexOf('/') >= 0) {
  //   redirectTo = Object.keys(QueryHash)[0]
  // }

  let utm = []
  Object.keys(queryParams).forEach(item => {
    if (item.indexOf('utm_') >= 0) {
      utm.push({
        name: item,
        value: queryParams[item]
      })
    }
  })

  props.dispatch(appActions.setSource({ type: 'vkapp', vk_group_id, vk_app_id, vk_platform, utm }))
  props.dispatch(appActions.setVkUserID(vk_user_id))
	
	const [activeStory, setActiveStory] = useState('home');
  const [activePanel, setActivePanel] = useState('default');
  // const [loading, setLoading] = useState(false);

  if(props.route?.name) {
    const rName = props.route?.name.split("/")
    const story = rName[0] || 'home'
    if(activeStory !== story) setActiveStory(story)
    const panel = rName[1] || 'default'
    if(activePanel !== panel) setActivePanel(panel)
  } 

  const onStoryChange = (e) => {
    if(!isEmpty) {
      props.router.navigate(e.currentTarget.dataset.story);
    }
  }
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);

	useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});
		async function fetchData() {
      // let affiliate_id = localStorage.getItem('affiliate_id')
      // Если приложение открыто через ВК – affiliate_id может храниться "от другого филиала"
      // if (process.env.NODE_ENV === 'production' && process.env.TARGET === 'VK') {
      // }
      let affiliate_id = 0
      let last_vk_group_id = 0

      await bridge.send("VKWebAppStorageGet", {"keys": [vk_user_id+'_affiliate_id_'+vk_group_id, vk_user_id+"_last_vk_group_id"]})
      .then(data => {
        affiliate_id = data?.keys.find(item => item.key === vk_user_id+'_affiliate_id_'+vk_group_id)?.value || 0
        last_vk_group_id = data?.keys.find(item => item.key === vk_user_id+'_last_vk_group_id')?.value || 0
        if(last_vk_group_id) {
          props.dispatch(appActions.setSource({ last_vk_group_id }))
        }
      })
      .catch(err => {
        
      })
        
      if(isEmpty) {
        props.router.navigate("empty")
        return
      }

      await props.dispatch(affiliatesActions.serverSideInit({vk_group_id, vk_app_id, affiliate_id}))
      .then(ssiData => {
        // const user = await bridge.send('VKWebAppGetUserInfo');
        // await props.dispatch(userActions.set({user}));

        if(!affiliate_id) {
          affiliate_id = ssiData.affiliate.id
        }
        
        bridge.send('VKWebAppGetUserInfo')
        .then(data => {
          const vk_user = data
          vk_user['viewer_type'] = ['admin','editor','moder'].indexOf(queryParams.vk_viewer_group_role) !== -1 ? 2 : 0
          // vk_viewer_group_role
          // none — не состоит в сообществе;
          // member — участник сообщества;
          // moder — модератор сообщества;
          // editor — редактор сообщества;
          // admin — администратор или создатель сообщества.

          // vk_user['access_token'] = queryParams.access_token

          // const affiliate_id = props.affiliate.id

          // Убеждаемся что приложение сообщества ВК запущено "правильно"
          props.dispatch(userActions.checkVkMiniAppSign(queryParams))
          .then(data => {
            // Авторизуем пользователя
            props.dispatch(userActions.vkAuth(vk_user))
            .then(() => {
              // Вроде бы теперь поиск клиента не учитывает vk_user_id (только переданный JWT)
              props.dispatch(userActions.getClient({ affiliate_id, vk_user_id: vk_user['id'] }))
              .then(client => {
                // dlvryStat.push({
                //   'owner_id': affiliate.owner_id,
                //   'affiliate_id': affiliate_id,
                //   'client_id': client.id,
                //   'datetime': new Date(),
                //   'type': 'vkapp.open',
                //   'value': vk_group_id,
                //   'data': {
                //     "vk_group_id": vk_group_id,
                //     "vk_app_id": vk_app_id,
                //     "vk_user_id": vk_user['id'],
                //     "vk_platform": this.$store.getters['source'].vk_platform,
                //   },
                //   'platform': (process.env.NODE_ENV === 'production' && process.env.TARGET === 'VK') ? 'vkapp' : 'site',
                // });
              })
              .catch(err => {
                console.log('getClient', err)
                // dlvryStat.push({
                //   'owner_id': affiliate.owner_id,
                //   'affiliate_id': affiliate_id,
                //   // 'client_id': client.id,
                //   'datetime': new Date(),
                //   'type': 'vkapp.open',
                //   'value': vk_group_id,
                //   'data': {
                //     "vk_group_id": vk_group_id,
                //     "vk_app_id": vk_app_id,
                //     "vk_user_id": vk_user['id'],
                //     "vk_platform": this.$store.getters['source'].vk_platform,
                //   },
                //   'platform': (process.env.NODE_ENV === 'production' && process.env.TARGET === 'VK') ? 'vkapp' : 'site',
                // });
              })
              if(vk_group_id > 0 || vk_app_id > 0) {
                props.dispatch(ownerActions.sendStatistic({ affiliate_id, vk_group_id, vk_app_id, vk_user }))
              }
            })
          })
          .catch(err => {
            console.log('checkVkMiniAppSign', err)
          })

          // Запрос разрешения на отправку сообщений при открытии приложения
          props.dispatch(affiliatesActions.vkGroupAPI({affiliate_id, method: 'messages.isMessagesFromGroupAllowed', data: {group_id: vk_group_id, user_id: vk_user.id}}))
          .then(data => {
            if(!data.is_allowed) {
              bridge.send("VKWebAppAllowMessagesFromGroup", {group_id: vk_group_id})
              .then(data => {
                if(data.result) {
                  props.dispatch(userActions.onAllowMessagesFromCommunity({ affiliate_id, vk_group_id, vk_user }))
                }
              })
            }
          })

        })
        .catch(err => {
          console.log(err)
        })

      })

		}

		fetchData();
	}, []);

  useEffect(() => {
    if(props.affiliateInitStatus?.code === 'success') {
      setPopout(null);
    }
    if(props.affiliateInitStatus?.code === 'error') {
      // setPopout(<Alert 
      //   actions={[{
      //     title: 'Закрыть',
      //     autoclose: true,
      //     mode: 'cancel'
      //   }]}
      //   actionsLayout="vertical"
      //   onClose={() => setPopout(null)}
      //   header="Ошибка"
      //   text={ _.get(props.affiliateInitStatus, 'message', "Не удалось найти филиал") }
      //   />);
      props.router.navigate("getStarted")
    }
  }, [props.affiliateInitStatus?.code]);

  useEffect(() => {
    // TODO
    if(props.affiliate?.id) {
      const color_1 = _.get(props.affiliate, "template['colors.color-1']", 'var(--accent)')
      if(color_1) {
        document.documentElement.style.setProperty('--color-1', color_1)
        // document.documentElement.style.setProperty('--accent', color_1)
        // document.documentElement.style.setProperty('--tabbar_active_icon', color_1)
      }
    }
  }, [props.affiliate?.id]);

  const cartCount = props.cartProducts.reduce(function (previousValue, currentItem, index, arr) {
    return previousValue + currentItem.data.quantity
  }, 0)

	return (
    <ConfigProvider>
  		<AdaptivityProvider>
  			<AppRoot className={(isEmpty || props.affiliateInitStatus?.code !== 'success') && 'isEmpty'}>
  				<Epic activeStory={activeStory} tabbar={
            <Tabbar>
              <TabbarItem
                onClick={onStoryChange}
                selected={activeStory === 'home'}
                data-story="home"
                // text="Каталог"
              ><Icon28Newsfeed /></TabbarItem>
              <TabbarItem
                onClick={onStoryChange}
                selected={activeStory === 'actions'}
                data-story="actions"
                // text="Акции"
              ><Icon28DiscountOutline/></TabbarItem>
              <TabbarItem
                onClick={onStoryChange}
                selected={activeStory === 'cart'}
                data-story="cart"
                indicator={ (activeStory !== 'cart' && cartCount > 0) ? <Counter size="s" mode="prominent">{ cartCount }</Counter> : null }
                // text="Корзина"
              ><Icon28MarketOutline width={34} height={34} /></TabbarItem>
              <TabbarItem
                onClick={onStoryChange}
                selected={activeStory === 'personal'}
                data-story="personal"
                // text="Клиент"
              ><Icon28UserOutline /></TabbarItem>
              <TabbarItem
                onClick={onStoryChange}
                selected={activeStory === 'menu'}
                data-story="menu"
                // text="Ещё"
              ><Icon28Menu /></TabbarItem>
            </Tabbar>
          }>
            
            <Home id="home" activePanel={activePanel} popout={popout} />
            <Actions id="actions" activePanel={activePanel} />
            <Cart id="cart" activePanel={activePanel} />
            <Personal id="personal" activePanel={activePanel} />
            <Menu id="menu" activePanel={activePanel} />
            <PageNotFound id="404" activePanel={activePanel} />
            <Empty id="empty" activePanel={activePanel} />
            <GetStarted id="getStarted" activePanel={activePanel} />            
          </Epic>
  			</AppRoot>
  		</AdaptivityProvider>
    </ConfigProvider>
	);
}

function mapStateToProps(state) {
    return {
        affiliate: state.affiliates.item,
        affiliateInitStatus: state.affiliates.itemInitStatus,
        cartProducts: state.cart.products,
    };
}

export default connect(mapStateToProps)(
    (props) => (
        <RouteNode nodeName="">
            {({ route }) => <App route={route} {...props}/>}
        </RouteNode>
    )
);
