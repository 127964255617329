import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import bridge from '@vkontakte/vk-bridge';
import { View, Spinner, Alert, Headline, Subhead, CardGrid, Card, ContentCard, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon28Newsfeed, Icon28BlockOutline, Icon20FavoriteCircleFillYellow, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon28UnfavoriteOutline, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

import * as actionsActions from '../store/actions/actions';

const Actions = props => {
	const router = useRouter();

	useEffect(() => {
		async function getActions() {
			await props.dispatch(actionsActions.getActions(props.affiliate.id));
		}
		if(props.actionsStatus.code !== 'success' && !props.actions.length) {
			getActions();
		}
	}, []);

	function writeToClipboard(text) {
		bridge.send("VKWebAppCopyText", {text})
		.then(data => {
	    bridge.send("VKWebAppTapticImpactOccurred", {"style": "light"});
	    props.onPopout(<Alert
        actions={[{
          title: 'OK',
          autoclose: true,
          mode: 'cancel'
        }]}
        actionsLayout="vertical"
        onClose={ () => props.onPopout(null)}
        header={text}
        text="Промо-код скопирован"
      />)
	  })
	}

	return (
		<Panel id={props.id}>
	    <PanelHeader>Акции</PanelHeader>
	    {_.get(props, 'actionsStatus.code', '') == 'success' &&
	      (!props.actions.length && !props.affiliate.actions_text)
	      ? <Placeholder style={{paddingBottom: 0}} stretched icon={<Icon28DiscountOutline width={56} height={56} />}
            header="Ничего нет">
            Наши акции готовятся к публикации,<br />
              уже скоро обо всём расскажем.
          </Placeholder>
	      : <View style={{ margin: '12px 0'}}>
	          {
	            props.actions.map(item => 
	            	<CardGrid size="l">
				          <Card mode="outline" style={{ padding: 0, marginBottom: '10px'}}
				          	onClick={() => props.onModal({type: 'ACTION_DETAIL', data: {action: item}})}
				          	>
										<div class='Action__Img'>
											<img src={'https://dlvry.ru' + item.img?.src} alt={item.name} />
										</div>
										<Div>
											<Headline style={{ marginBottom: '5px'}} weight="semibold">{ item.name }</Headline>
											<Text style={{ color: 'var(--text_secondary)'}} dangerouslySetInnerHTML={{ __html: item.preview_text }}></Text>
											{
							          !!item.promocode && 
							          <View style={{ marginTop: '10px', textAlign: 'right'}}>
							            <Button size="m" mode='outline'
							            	className="Action__Promocode"
							            	onClick={(e) => {
								            	e.stopPropagation()
								            	writeToClipboard(item.promocode) 
								            }}
							            	>
							              { item.promocode }
							            </Button>
							          </View>
							        }
				          	</Div>
				          </Card>
				        </CardGrid>
	            )
	          }
	          { !!props.affiliate.actions_text &&
          		<Div>
          			<Text dangerouslySetInnerHTML={{ __html: props.affiliate.actions_text }}></Text>
          		</Div>
	          }
          	<Spacing size={20} />
          </View>
	    }
	    {_.get(props, 'actionsStatus.code', '') == 'loading' && <Spinner style={{ margin: '12px 0'}} />}
	    {
	    	_.get(props, 'actionsStatus.code', '') == 'error' &&
	      <Placeholder stretched icon={<Icon28BlockOutline width={56} height={56} />}
          header="Ошибка!">
          { _.get(props, 'actionsStatus.message', 'Не удалось получить акции') }
        </Placeholder>
      }
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    actions: state.actions.items,
    actionsStatus: state.actions.itemsStatus,
  };
}

export default connect(mapStateToProps)(Actions);


