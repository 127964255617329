import React, { useState, useEffect } from 'react';
import { useRouter } from 'react-router5'
import { View, Spacing, Separator, Button, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon28Newsfeed, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

const MenuAboutApp = props => {
	const router = useRouter();

	return (
		<Panel id={props.id}>
	    <PanelHeader left={<PanelHeaderBack onClick={() => router.navigate('menu')} />}>О приложении</PanelHeader>
	    <Group>
	      <RichCell
	        disabled
	        before={<Avatar src={ 'https://dlvry.ru/img/dlvry-logo-sign--blue.png' } />}
	        caption="Приложения для доставки еды"
	      >
	        <b>DLVRY</b>.APPS
	      </RichCell>
	    </Group>
			<Separator style={{ margin: '0 0 10px 0' }} />
  	  <Div>
      	<Text><strong>DLVRY</strong> — Это готовое решение для служб доставки еды.</Text>
				<br />
				<Text>Готовый сайт + Мобильное приложение (iOS и Android) + Приложение сообществ ВКонтакте (VK Mini App).</Text>
      </Div>
	    <SimpleCell expandable href="https://new.dlvry.ru" target="_blank">
	    	<InfoRow header="Веб-сайт разработчика">
	    		DLVRY.RU
	    	</InfoRow>
	    </SimpleCell>
	    <SimpleCell expandable href="https://vk.com/dlvry">
	    	<InfoRow header="ВКонтакте">
	    		vk.com/dlvry
	    	</InfoRow>
	    </SimpleCell>
	    {/*<Div>
	    	<Button size="l">Сайт</Button>
	    </Div>*/}
	    <Spacing size={20} />
	  </Panel>
	)
};

export default MenuAboutApp;