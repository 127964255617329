import bridge from '@vkontakte/vk-bridge';
import { HTTP } from '../../utils/http-common';
import { ERROR } from '../../utils/errors'

import { 
  GET_AFFILIATES_REQUEST, 
  GET_AFFILIATES_SUCCESS,
  GET_AFFILIATES_ERROR,

  GET_AFFILIATE_REQUEST, 
  GET_AFFILIATE_SUCCESS,
  GET_AFFILIATE_ERROR,

  GET_CITIES_REQUEST,
  GET_CITIES_SUCCESS,
  GET_CITIES_ERROR,

  GET_STREETS_REQUEST,
  GET_STREETS_SUCCESS,
  GET_STREETS_ERROR,

  SET_AFFILIATE,

  SSI_REQUEST,
  SSI_LOADED,
  SSI_ERROR,

  SOURCE,

  GET_BASKET_RULES_SUCCESS,
  GET_DELIVERY_AREAS_SUCCESS,
  GET_SECTIONS_SUCCESS,
  GET_PRODUCTS_SUCCESS,
  GET_OWNER_SUCCESS
} from "../ActionTypes";
// import { AsyncStorage } from 'react-native';

import { getProducts, getSections } from "./catalog";
import { getClient } from "./user";
import { getBasketRules, getDeliveryAreas } from "./cart";
import { getActions } from "./actions";

function commitAction(type, data) {
	return { type, data }
}

export function getAffiliate(affiliate_id ) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      if (!affiliate_id) {
        reject('!affiliate_id in getItem')
        return
      }
      dispatch(commitAction(GET_AFFILIATE_REQUEST))
      const arFields = ['id', 'name', 'preview_text', 'detail_text', 'preview_picture', 'active', 'active_from', 'owner_id', 'city', 'cities', 'domains', 'districts', 
          'vk_app', // 'vk_groups', 
          'instagram', 'phones', 'gmt', 'schedule', 'allow_ordering_at_any_time','gifts_required', 'addresses', 'iframe_map', 'min_sum', 'payment_text', 'delivery_text', 'actions_text', 'cart_page_text', 'order_page_text', 'footer_text', 'gifts_from', 'discounts', 'vk_admins', 'emails', 'sms_admin', 'sms_client', 'template', 'order_form', 'pickup_only', 'order_conditions', 'addresses_source', 'delivery_zones', 'delivery_types', 'pickup_points', 'currency', 'phone_code', 'header_images', 'msgs', 'notifications_open_vkapp', 'yandex_metrika', 'yandex_verification', 'google_analytics', 'google_site_verification', 'google_tag_manager', 'vk_rtrg', 'fb_rtrg', 'ios_app_url', 'android_app_url', 'vk_widget_msgs', 'need_phone_check', 'allow_phone_check' ,'authorization']
      const fields = arFields.join()
      HTTP.get('/affiliates/' + affiliate_id, { params: { fields } })
        .then(resp => {
          if (!resp.data.data.template) {
              resp.data.data.template = {}
          }
          dispatch(commitAction(GET_AFFILIATE_SUCCESS, resp.data.data))
          dispatch(setAffiliate(resp.data.data))
          resolve(resp.data.data)
        })
        .catch(err => {
          dispatch(commitAction(GET_AFFILIATE_ERROR, err))
          reject(err)
        })
    })
  }
}

export function serverSideInit({ vk_group_id, vk_app_id, domain, route, affiliate_id }) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      if(!vk_group_id && !domain && !vk_app_id) {
        reject('!vk_group_id && !domain && !vk_app_id in serverSideInit')
        return
      }
      dispatch(commitAction(SSI_REQUEST))
      const params = {}
      if (vk_group_id) {
          params.vk_group_id = vk_group_id
      } //else 
      if (vk_app_id) { 
          params.vk_app_id = vk_app_id
      } //else 
      if (domain) { 
          params.domain = domain
      }
      if (affiliate_id) params.affiliate_id = affiliate_id
      if (route) params.route = route

      HTTP.get('/affiliates/init', { params: params })
      .then(resp => {
          if(resp.data.data.vk_group_id) {
            dispatch(commitAction(SOURCE, { vk_group_id: Number(resp.data.data.vk_group_id) }))
          }
          dispatch(commitAction(GET_OWNER_SUCCESS, resp.data.data.owner))
          dispatch(commitAction(GET_SECTIONS_SUCCESS, resp.data.data.sections))
          // TODO ?
          // dispatch('catalog/initSection', resp.data.data.section_id, { root: true })
          dispatch(commitAction(GET_PRODUCTS_SUCCESS, resp.data.data.products))
          dispatch(commitAction(GET_BASKET_RULES_SUCCESS, resp.data.data.basket_rules))
          dispatch(commitAction(GET_DELIVERY_AREAS_SUCCESS, resp.data.data.delivery_areas))
          dispatch(commitAction(SSI_LOADED, resp.data.data))
          resolve(resp.data.data)
      })
      .catch(err => {
        dispatch(commitAction(SSI_ERROR, err))
          reject(err)
      })
      
    })
  }
}

// export function getAffiliates() {
//   return function (dispatch) {
//     return new Promise((resolve, reject) => {
//       dispatch(commitAction(GET_AFFILIATES_REQUEST))

//     	let arFields = ["id","name","preview_text","detail_text","preview_picture","active","active_from","owner_id","city","cities","domains","districts","vk_app","vk_groups","instagram","phones","gmt","schedule","allow_ordering_at_any_time","gifts_required","addresses","min_sum","payment_text","delivery_text","actions_text","loyalty_text","cart_page_text","order_page_text","gifts_from","discounts","vk_admins","emails","sms_admin","sms_client","order_form","pickup_only","addresses_source","delivery_zones","delivery_types","pickup_points","currency","phone_code","header_images","template","msgs","order_conditions","native_apps_versions"];
//       let fields = arFields.join();
//       let params = {
//         owner_id: OWNER_ID, // '403676', // Constants.manifest.extra.ownerId, 
//         native_app_code: NATIVE_APP_CODE,
//         fields
//       }

//       HTTP.get("affiliates", {params: params})
//     	.then(resp => {
//         dispatch(commitAction(GET_AFFILIATES_SUCCESS, resp.data.data.items))
//         resolve(resp.data.data.items)
//       })
//       .catch(err => {
//         dispatch(commitAction(GET_AFFILIATES_ERROR, err))
//         reject(err)
//       })
//     })
//   }
// }

export function setAffiliate(data) { 
  return function (dispatch, getState) {
    if(data) {
      const { app } = getState()
      const vk_group_id = app.source.vk_group_id
      const vk_user_id = app.vk_user_id

      const affiliate_id = data.id

      if(vk_group_id && affiliate_id) {
        if(vk_user_id) {
          bridge.send("VKWebAppStorageSet", {"key": vk_user_id+'_affiliate_id_'+vk_group_id, "value": affiliate_id.toString()});
        }
      }
      dispatch(commitAction(SET_AFFILIATE, data))

      dispatch(getSections({affiliate_id, section_id: 0}))
      .catch(err => {
        // ERROR.show(err)
      })
      
      dispatch(getProducts(affiliate_id))
      .catch(err => {
        // ERROR.show(err)
      })
      
      dispatch(getClient({affiliate_id}))
      .catch(err => {})
      
      dispatch(getBasketRules(affiliate_id))
      .catch(err => {})
      
      dispatch(getDeliveryAreas(affiliate_id))
      .catch(err => {})

      dispatch(getActions(affiliate_id))
      .catch(err => {})

    }
  }
}

// vkGroupAccessTokenSave: ({ commit, dispatch }, { access_token, owner_id, affiliate_id, vk_group_id }) => {
//         return new Promise((resolve, reject) => {
//             // let owner_id = store.getters["owner/item"].id
//             // let affiliate_id = store.getters["affiliates/item"].id
//             // let vk_group_id = store.getters["source"].vk_group_id
//             commit('VK_GROUP_ACCESS_TOKEN_REQUEST')
//             HTTP.post('/owners/' + owner_id + '/affiliates/' + affiliate_id + '/vkGroupAccessTokenSave', { vk_group_id, access_token })
//                 .then(resp => {
//                     commit('VK_GROUP_ACCESS_TOKEN_SUCCESS', resp.data.data)
//                     resolve(resp.data.data)
//                 })
//                 .catch(err => {
//                     commit('VK_GROUP_ACCESS_TOKEN_ERROR', err)
//                     reject(err)
//                 })
//         })
//     },

export function vkGroupAPI({ affiliate_id, method, data }) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
        if (!affiliate_id) {
            const err = ERROR.make(400, { text: 'Invalid parameters', options: { params: 'affiliate_id' } })
            // commit('VK_GROUP_API_ERROR', err)
            reject(err)
            return
        }
        if (!method) {
            const err = ERROR.make(400, { text: 'Invalid parameters', options: { params: 'method' } })
            // commit('VK_GROUP_API_ERROR', err)
            reject(err)
            return
        }
        // commit('VK_GROUP_API_REQUEST')
        HTTP.post('vkGroupAPI', { affiliate_id, method, data })
            .then(resp => {
                // commit('VK_GROUP_API_LOADED', resp.data.data)
                resolve(resp.data.data)
            })
            .catch(err => {
                // commit('VK_GROUP_API_ERROR', err)
                reject(err)
            })
    })
  }
}

export function getCities(affiliate_id, q) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(commitAction(GET_CITIES_REQUEST))

      HTTP.get('/affiliates/' + affiliate_id + '/cities', {params: {q}})
    	.then(resp => {
        dispatch(commitAction(GET_CITIES_SUCCESS, resp.data.data.items))
        resolve(resp.data.data.items)
      })
      .catch(err => {
        dispatch(commitAction(GET_CITIES_ERROR, err))
        reject(err)
      })
    })
  }
}

export function getStreets(affiliate_id, q, city) {
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(commitAction(GET_STREETS_REQUEST))

      HTTP.get('/affiliates/' + affiliate_id + '/streets', {params: {q, city}})
    	.then(resp => {
        dispatch(commitAction(GET_STREETS_SUCCESS, resp.data.data.items))
        resolve(resp.data.data.items)
      })
      .catch(err => {
        dispatch(commitAction(GET_STREETS_ERROR, err))
        reject(err)
      })
    })
  }
}