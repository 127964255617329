import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/ru'
import { usePlatform, PanelSpinner, Cell, Separator, Text, Footer, Spinner, IconButton, Counter, Div, Button, FixedLayout, CardGrid, ContentCard, Header, SimpleCell, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon24Notification, Icon24CheckCircleOn, Icon24Recent, Icon24DoNotDisturb, Icon24GearOutline, Icon32Note, Icon24Place, Icon24Note, Icon28UserCircleOutline, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon24Favorite, Icon24MoneyCircleOutline, Icon24SmartphoneOutline } from '@vkontakte/icons';

import declOfNum from '../utils/utils'
import * as userActions from '../store/actions/user'

moment.locale('ru')

const PersonalOrders = props => {
	const router = useRouter();

	const [loadingMore, setLoadingMore] = useState(false)
  const [allLoaded, setAllLoaded] = useState(false)
  const [offset, setOffset] = useState(0)
  const [orders, updateOrders] = useState([])

  useEffect(() => {
    // props.navigation.setParams({Colors})
    getMoreOrders()
  }, [])

  async function getMoreOrders() {
    const page_count = 15
    if(loadingMore || allLoaded) return
    setLoadingMore(true)
    const newData = await props.getOrders(props.affiliate.id, page_count, offset)
    updateOrders([].concat(orders).concat(newData.items))
    if(newData.count <= offset + page_count) {
      setAllLoaded(true)
    } else {
      setOffset(offset + page_count)
    }
    setLoadingMore(false)
  }

  function makeAddress(order) {
    let result = ''
    if(order.delivery.code == 'self') {
      result = 'Самовывоз'
    } else {
      if(order.address.street) result = order.address.street
      if(order.address.house) result += ' ' + order.address.house
    }
    return result || 'Не указан'
  }

  function makeTotal(order) {
    if(!order.total) {
      return null
    }
    return order.total + ' ' + props.affiliate.currency.sign
  }

	return (
		<Panel id={props.id}>
	    <PanelHeader left={<PanelHeaderBack onClick={() => router.navigate('personal')} />} className="PersonalHeader">Мои заказы</PanelHeader>
      {
        (_.get(props, 'ordersStatus.code', '') === 'loading' && !orders.length) && 
        <PanelSpinner />
      }
      {
        ((_.get(props, 'ordersStatus.code', '') === 'success' && !orders.length) || _.get(props, 'ordersStatus.code', '') === 'error' ) &&
        <Placeholder style={{paddingBottom: 0}} stretched icon={<Icon32Note width={56} height={56} />}
          header="Заказов нет"
          action={<Button size="l" onClick={() => {
                  router.navigate('home')
                }}>Перейти в меню</Button>}>
          Здесь будем хранить все ваши заказы.<br />Чтобы сделать первый, перейдите в меню.
        </Placeholder>
      }
      {
        ((_.get(props, 'ordersStatus.code', '') === 'success' || _.get(props, 'ordersStatus.code', '') === 'loading') && !!orders.length ) &&
        <div>
      		{
      			orders.map(item => {
      				let icon = <Icon24DoNotDisturb style={{color: 'var(--dynamic_red)'}} />
	            if(item.is_new) {
	              icon = <Icon24Notification style={{color: 'var(--icon_secondary)'}} />
	            } else {
	              if(item.status.code == 'done') {
	                icon = <Icon24CheckCircleOn style={{color: 'var(--dynamic_green)'}} />
	              } else 
	              if(item.status.code == 'active') {
	                icon = <Icon24Recent style={{color: 'var(--dynamic_orange)'}} />
	              } else
	              if(item.status.code == 'cancel') {
	                icon = <Icon24DoNotDisturb style={{color: 'var(--dynamic_red)'}} />
	              } else
	              if(item.status.code) {
	                icon = <Icon24Recent style={{color: 'var(--dynamic_red)'}} />
	              }
	            }
      				return (
        				<Cell key={item.id}
        					before={icon}
	                description={moment(item.date, "DD.MM.YYYY HH:mm:ss").format("D MMMM в HH:mm")}
	                after={<Text style={{color: 'var(--text_secondary)'}}>{makeTotal(item)}</Text>}
	                onClick={() => router.navigate('personal/order', {order_id: item.id})}>
        					{makeAddress(item)}
        				</Cell>
        			)
      			})
      		}
      		<Footer>
      		  {loadingMore ? 'Подгружаем заказы...' : ''}
            {allLoaded ? (orders.length + declOfNum(orders.length, [' заказ', ' заказа', ' заказов'])) : '' }
          </Footer>
      	</div>
      }
	  </Panel>
	)
};

const mapStateToProps = state => {
  return { 
    affiliate: state.affiliates.item,
    owner: state.owner.item,
    client: state.user.client,
    orders: state.user.orders,
    ordersStatus: state.user.ordersStatus,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getOrders: (affiliate_id, count, offset) => dispatch(userActions.getOrders(affiliate_id, count, offset)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalOrders);

