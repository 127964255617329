import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'react-router5'
import bridge from '@vkontakte/vk-bridge';
import { View, Spinner, Alert, Headline, Subhead, CardGrid, Card, ContentCard, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
// import { Icon28Newsfeed, Icon28BlockOutline, Icon20FavoriteCircleFillYellow, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon28UnfavoriteOutline, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';

const PageNotFound = props => {
	const router = useRouter();

	return (
		<Panel id={props.id}>
	    {/*<PanelHeader></PanelHeader>*/}
	    <Placeholder stretched 
	    	// icon={<Text>404</Text>}
	    	action={<Button size="l" onClick={() => router.navigate('home')}>Перейти в меню</Button>}
        header="Not Found">
        Страница не найдена
      </Placeholder>
	  </Panel>
	)
};

function mapStateToProps(state) {
  return {
    affiliate: state.affiliates.item,
    owner: state.owner.item,
  };
}

export default connect(mapStateToProps)(PageNotFound);


