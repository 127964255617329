// import {ERROR} from '../../utils/errors'

import { 
  GET_PUSH,
  SOURCE,
  VK_USER_ID,
} from "../ActionTypes";

const initialState = {
  push: {},
  source: {},
  vk_user_id: '',
};

export default (state = initialState, action) => {
  let status = {}
  switch (action.type) {
  
    case GET_PUSH:
      return {
        ...state,
        push: action.data,
      }

    case SOURCE:
      return {
        ...state,
        source: {...state.source, ...action.data},
      }

    case VK_USER_ID:
      return {
        ...state,
        vk_user_id: action.data,
      }    
    
    default:
      return state
  }
};