import React from 'react'
import { connect } from 'react-redux'
import { usePlatform, PanelHeaderButton, Alert, Text } from '@vkontakte/vkui'
import { Icon24Delete } from '@vkontakte/icons'
import * as cartActions from '../store/actions/cart'

const mapStateToProps = state => {
  return { 
    cartProducts: state.cart.products,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    clearCart: () => dispatch(cartActions.clearCart()),
  }
}

function MyCartClearHeaderButton(props) {

  const platform = usePlatform()

	function getCount() {
		return props.cartProducts.reduce(function (previousValue, currentItem) {
      return previousValue + currentItem.data.quantity
    }, 0)
	}

  function clearCart() {
    props.onPopout(<Alert
      actions={[{
        title: 'Отмена',
        autoclose: true,
        mode: 'cancel'
      },
      {
        title: 'Очистить',
        autoclose: true,
        mode: 'destructive',
        action: () => props.clearCart(),
      }]}
      actionsLayout="horizontal"
      onClose={ () => props.onPopout(null) }
      header="Очистить корзину?"
      text="Вы уверены, что хотите удалить все товары из корзины?"
    />)
  }

  if(getCount() > 0) {
    return (
      <PanelHeaderButton onClick={clearCart}>
        <Icon24Delete />
        {platform === 'ios' && <Text style={{color: 'var(--color-1)', fontSize: '16px', fontWeight: '600'}}>Очистить</Text>}
      </PanelHeaderButton>
    )
  } else {
    return null
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyCartClearHeaderButton)