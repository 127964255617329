import {ERROR} from '../../utils/errors'

import { 
  GET_ACTIONS_REQUEST, 
  GET_ACTIONS_SUCCESS,
  GET_ACTIONS_ERROR,
} from "../ActionTypes";

const initialState = {
  items: [],
  itemsStatus: {},
};

export default (state = initialState, action) => {
  let status = {}
  switch (action.type) {
    
    case GET_ACTIONS_REQUEST:
      status = { code: 'loading' }
      return {
      	...state,
        itemsStatus: status,
      }

    case GET_ACTIONS_SUCCESS:
      status = { code: 'success' }
      return {
        ...state,
        itemsStatus: status,
        items: action.data,
      }

    case GET_ACTIONS_ERROR:
      status = ERROR.format(action.data)
      return {
        ...state,
        itemsStatus: status,
        items: [],
      }
    
    default:
      return state
  }
};