// import React, {useState} from 'react';
// import { Platform, StyleSheet, View, Text, TouchableOpacity } from 'react-native';
// import { useTheme } from '@react-navigation/native';
// import UIFormField from '../../components/UI/FormField';
// import UIIcon from '../../components/UI/Icon';
// import * as Haptics from 'expo-haptics';

import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { usePlatform, View, Spinner, Alert, PanelHeaderButton, Headline, Subhead, CardGrid, Card, ContentCard, Button, Spacing, Div, InfoRow, MiniInfoCell, SimpleCell, Text, Header, RichCell, Avatar, ScreenSpinner, ConfigProvider, AdaptivityProvider, AppRoot, Epic, Tabbar, TabbarItem, Panel, PanelHeader, PanelHeaderBack, Group, Placeholder } from '@vkontakte/vkui';
import { Icon28Newsfeed, Icon24Place, Icon20Dropdown, Icon28BlockOutline, Icon20FavoriteCircleFillYellow, Icon28DiscountOutline, Icon28MarketOutline, Icon28UserOutline, Icon28Menu, Icon24Info, Icon24Phone, Icon28UnfavoriteOutline, Icon24MoneyCircle, Icon24SmartphoneOutline } from '@vkontakte/icons';


const TabsSelect = ({
  status,
  items,
  defaultValue,
  onChange,
  size,
  borderColor,
  ...restProps
}) => {

  const platform = usePlatform()

  let borderRadius = null
  if(size == 'm') {
    borderRadius = (platform == 'android') ? 8 : 8;
  }

  return (
    <div className={`TabsSelect TabsSelect__sz_${size}`} status={status} borderColor={borderColor} borderRadius={borderRadius}>
      {items.map(item => 
        <Button 
          // activeOpacity={.7}
          className={`TabsSelect__el TabsSelect__el__sz_${size}`}
          // style={[styles., styles[], borderRadius && {borderRadius: borderRadius - 3}, item.value === defaultValue && styles.TabsSelect__el_active]}
          // onPress={() => {
          //   onChange(item.value)
          //   Haptics.selectionAsync()
          // }}
          >
          {/*<Text style={{fontSize: styles.TabsSelect__el.fontSize, lineHeight: styles[`TabsSelect__el__sz_${size}`]['lineHeight'], color: (item.value === defaultValue) ? styles.TabsSelect__el_active.color : styles.TabsSelect__el.color }}>{ item.label }</Text>*/}
          <Text>{ item.label }</Text>
        </Button>
      )}
    </div>
  );
}

TabsSelect.defaultProps = {
  onChange: () => {},
  items: [],
  status: '',
  size: 'l',
}

export default TabsSelect